import { Container, Empty, List, NotificationWindow, Title } from "./Notifications.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { useRef, useState } from "react";
import { useOutside } from "../../../../../hooks";
import { BellIcon } from "./images";

interface NotificationsAIProps {}

export const NotificationsAI = observer(({}: NotificationsAIProps) => {
	const { authStore } = useStores();

	// Текущий стейт для окна уведомлдений
	const [isShow, setIsShow] = useState(false);

	// Ссылка на контейнер с уведомлениями
	const windowNotificationRef = useRef<HTMLDivElement>(null);

	const handleOnToggleShow = () => {
		setIsShow((prevState) => !prevState);
	};

	const handleOnClose = () => setIsShow(false);

	// Автозакрытие окна
	useOutside(windowNotificationRef, handleOnClose);

	// Если не авторизован
	if (!authStore.isAuth) {
		return null;
	}

	return (
		<Container>
			<BellIcon onClick={handleOnToggleShow} />

			<NotificationWindow isShow={isShow}>
				<Title>Notifications</Title>

				<List>
					<Empty>Empty</Empty>
				</List>
			</NotificationWindow>
		</Container>
	);
});
