import { Container } from "./Accordion.styled";
import { AccordionItem } from "./AccordionItem";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";

interface AccordionProps {}

export const Accordion = observer(({}: AccordionProps) => {
	const { grandCharts } = useStores();

	return (
		<>
			<Container>
				<AccordionItem name={"the_core"} title="The Core" isCore grandCharts={grandCharts.theCore} />
				<AccordionItem
					name={"pre_course"}
					title={"Pre-Courses"}
					isCore
					isPreCourse
					grandCharts={grandCharts.preCourse}
				/>
				<AccordionItem name={"skill_bundles"} title={"Skill bundles"} grandCharts={grandCharts.skill_bundles} />
			</Container>
		</>
	);
});
