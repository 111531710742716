import { Container } from "./SubmitAnalysis.styled";
import { Button } from "../../../../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";

interface SubmitAnalysisProps {}

export const SubmitAnalysis = observer(({}: SubmitAnalysisProps) => {
	const { aiStore, lessonStore, viewerStore } = useStores();

	const handleOnSend = async () => {
		aiStore.setSendAnalysis(true);

		try {
			// Отправляем данные
			const resultMidi = await aiStore.sendAnalyze(
				{
					score_item_id: viewerStore.chooseItemID,
					score_item_ai_midi_id: viewerStore.chooseItem.ai_midi.id,
					bpm: aiStore.chosenBPM,
					instrument: lessonStore.currentInstrument,
					lesson_uuid: lessonStore.uuid,
				},
				aiStore.audioBlob,
			);

			// Добавляем в текущий item результирующие midi
			if (resultMidi) {
				console.log("SET RESULT");
				viewerStore.chooseItem.addResult(resultMidi);
				console.log("Finaly result", viewerStore.chooseItem);
				const currentItemID = viewerStore.chooseItemID;
				viewerStore.setChooseItemId(0);

				viewerStore.setChooseItemId(currentItemID);
			}
		} catch (e) {
		} finally {
			aiStore.setSendAnalysis(false);
		}
	};

	if (!aiStore.audioBlob || aiStore.sendAnalysis) {
		return null;
	}

	return (
		<Container>
			<Button onClick={handleOnSend}>Submit for analysis</Button>
		</Container>
	);
});
