import styled from "styled-components";
import { COLORS, fontMixin } from "../../styles";

export const Container = styled.div`
	display: flex;
	align-items: start;
	width: 100%;
	height: 100%;
	background: ${COLORS.Bgrnd};
`;

export const Body = styled.div`
	padding: 50px 40px 50px 40px;
	background: ${COLORS.White};
	max-width: 480px;
	height: auto;
`;

export const SocialContainer = styled.div`
	display: grid;
	grid-template-rows: 1fr;
	row-gap: 20px;
	margin-bottom: 30px;
`;

export const Description = styled.div`
	display: grid;
	justify-content: center;
	align-items: center;
	${fontMixin({ size: 14 })};
	line-height: 17px;
	color: ${COLORS.Dark_Gray};
	text-align: center;
`;

export const Form = styled.form`
	display: grid;
	gap: 20px;
	margin: 40px 0 50px;
`;
