import styled, { css } from "styled-components";
import { phoneQuery } from "../../../../../styles";

export const LogotypeContainer = styled.div`
	display: flex;
	cursor: pointer;

	${phoneQuery(css`
		picture {
			width: 37px;
			overflow: hidden;
		}
	`)}
`;
