import { Arrow, Container, Dot, Dots } from "./Banners.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { BannerItem } from "./BannerItem";
import { useEffect, useRef, useState } from "react";
import { ICON_ARROW_LEFT_COMPONENT, ICON_ARROW_RIGHT_COMPONENT } from "../../../common/Icons";

interface BannersProps {}

export const Banners = observer(({}: BannersProps) => {
	const { bannersStore } = useStores();

	const [totalSlides, setTotalSlide] = useState(0);
	const [currentSlide, setCurrentSlide] = useState(1);

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setTotalSlide(bannersStore.list.length);
		renderSlide(1);
	}, [bannersStore.list]);

	const renderSlide = (n: number) => {
		let showSlide = n;

		if (containerRef.current) {
			const slides = containerRef.current.getElementsByClassName("slide");

			if (slides.length === 0) {
				return false;
			}

			if (n > slides.length) {
				showSlide = 1;
			}

			if (n < 1) {
				showSlide = slides.length;
			}

			for (let i = 0; i < slides.length; i++) {
				const slide = slides[i] as HTMLDivElement;
				slide.style.display = "none";
			}

			const currentSlide = slides[showSlide - 1] as HTMLDivElement;
			currentSlide.style.display = "flex";
		}
	};

	const nextSlide = (n: number) => {
		if (n > totalSlides) {
			setCurrentSlide(1);
			renderSlide(1);
			return false;
		}

		if (n < 1) {
			setCurrentSlide(totalSlides);
			renderSlide(totalSlides);
			return false;
		}

		setCurrentSlide(n);
		renderSlide(n);
	};

	if (bannersStore.list.length === 0) {
		return null;
	}

	return (
		<>
			<Container ref={containerRef}>
				{bannersStore.list.map((banner) => {
					return <BannerItem key={banner.id} banner={banner} />;
				})}

				<Arrow onClick={() => nextSlide(currentSlide - 1)}>
					<ICON_ARROW_LEFT_COMPONENT />
				</Arrow>
				<Arrow isRight onClick={() => nextSlide(currentSlide + 1)}>
					<ICON_ARROW_RIGHT_COMPONENT />
				</Arrow>
				<Dots>
					{Array(totalSlides)
						.fill(null)
						.map((_, i) => (
							<Dot key={i} onClick={() => nextSlide(i + 1)} isActive={currentSlide === i + 1} />
						))}
				</Dots>
			</Container>
		</>
	);
});
