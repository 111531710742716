import { action, makeObservable, observable } from "mobx";
import { ModalStore } from "./index";

export class FormStore {
	@observable isValid = false;
	@observable isLoading = false;

	@observable modal = new ModalStore();

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsValid(value: boolean) {
		this.isValid = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}
}
