import { Container, Divider } from "./SidebarAI.styled";
import { BpmList } from "./BpmList";
import { SampleButton } from "./SampleButton";
import { RecordBlock } from "./RecordBlock";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";

interface SidebarAIProps {}

export const SidebarAI = observer(({}: SidebarAIProps) => {
	const { authStore } = useStores();

	/*if (!authStore.userStore.accessAI) {
		return null;
	}*/

	return (
		<Container>
			<BpmList />
			<Divider />
			<SampleButton />
			<RecordBlock />
		</Container>
	);
});
