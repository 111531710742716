import styled, { css } from "styled-components";
import { ICON_OOPS, ICON_STARTS } from "../../components/common/Icons";
import { COLORS, fontMixins, phoneQuery } from "../../styles";

export const Container = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 72px 0;
	height: 100%;
	background: ${COLORS.White};
`;

export const StarsWrapper = styled(ICON_STARTS)`
	position: absolute;
	top: 72px;
	left: 50%;
	transform: translateX(-50%);

	${phoneQuery(css`
		display: none;
	`)}
`;

export const OopsContainer = styled(ICON_OOPS)`
	position: absolute;
	top: 163px;
	left: calc(50% - 10px);
	transform: translateX(-50%);

	path {
		fill: ${COLORS.Gold} !important;
	}

	${phoneQuery(css`
		width: 350px;
		top: 80px;
	`)}
`;

export const OopsText = styled.div`
	position: absolute;
	width: 100%;
	top: 232px;
	text-align: center;
	${fontMixins(46, 700)};
	color: ${COLORS.White};

	${phoneQuery(css`
		top: 150px;
	`)}
`;

export const TitleError = styled.div`
	position: relative;
	${fontMixins(60, 700)};
	line-height: 73px;
	text-align: center;
	color: ${COLORS.Dark_Gray};
	padding: 46px 0 0;

	${phoneQuery(css`
		${fontMixins(24, 700)};
		line-height: 32px;
	`)}
`;

export const ContainerMessage = styled.div`
	position: absolute;
	width: 100%;
	top: 364px;

	${phoneQuery(css`
		top: 300px;
	`)}
`;

export const DescriptionError = styled.div`
	position: relative;
	text-align: center;
	max-width: 520px;
	margin: 96px auto 0;
	color: ${COLORS.Dark_Gray};

	p {
		${fontMixins(18, 400)};
		line-height: 25px;

		&:last-child {
			margin-top: 35px;
		}
	}

	${phoneQuery(css`
		margin: 40px auto 0;
		width: 100%;
		padding-inline: 15px;

		p {
			${fontMixins(16, 300)};
		}
	`)}
`;
