import styled from "styled-components";
import { fontMixin } from "../../../styles/mixins.styled";
import { COLORS } from "../../../styles";

export const DividerContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	${fontMixin({ size: 14 })};
	color: ${COLORS.Dark_Gray};
	line-height: 20px;

	&:before {
		content: "";
		position: absolute;
		top: 50%;
		right: calc(50% + 30px);
		width: calc(50% - 60px);
		height: 1px;
		background: ${COLORS.MidGray};
	}

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: calc(50% + 30px);
		width: calc(50% - 60px);
		height: 1px;
		background: ${COLORS.MidGray};
	}
`;
