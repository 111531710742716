import * as React from "react";
import { Container } from "./UserBlock.styled";
import { Avatar } from "./Avatar/Avatar";
import { FullName } from "./FullName/FullName";
import { EditProfileButton } from "./EditProfileButton/EditProfileButton";

interface UserBlockProps {}

export const UserBlock = ({}: UserBlockProps) => {
	return (
		<Container>
			<Avatar />
			<FullName />
			<EditProfileButton />
		</Container>
	);
};
