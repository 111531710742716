import { action, makeObservable, observable } from "mobx";
import { GoodStore } from "./good.store";
import { API } from "../../core";
import { Good } from "../../interfaces";

export class GoodsStore {
	@observable list: GoodStore[] = [];
	@observable current: GoodStore = new GoodStore(null);

	constructor() {
		makeObservable(this);
	}

	@action.bound
	clearCurrent() {
		this.current = new GoodStore(null);
	}

	@action.bound
	async getList(instrument_id: number, category_id: number, duration_id: number) {
		try {
			const data = await API.request<Good[]>(
				`pricing?instrument_id=${instrument_id}&category_id=${category_id}&duration_id=${duration_id}`,
			);

			this.setList(data);
		} catch (e) {
			console.error(`Error in method getList: `, e);
		}
	}

	@action.bound
	setList(values: Good[]) {
		this.list = (values || []).map((good) => new GoodStore(good));
	}
}
