import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 24px;
	width: 100%;
`;

interface ItemProps {
	isShow: boolean;
}

export const Item = styled.div<ItemProps>`
	display: flex;
	flex-direction: column;

	${(props) =>
		props.isShow &&
		css`
			${IconArrow} {
				transform: rotate(90deg);
			}

			${ButtonToggle} {
				margin-bottom: 24px;
			}
		`}
`;

interface ButtonToggleProps {
	color: string;
	isPreCourse?: boolean;
}

export const ButtonToggle = styled.button<ButtonToggleProps>`
	background: ${(props) => props.color};
	display: inline-flex;
	align-items: center;
	height: 32px;
	padding: 3px 10px 3px 11px;
	color: ${COLORS.White};
	${fontMixins(16, 500)};
	text-transform: uppercase;
	border-radius: 6px;
	cursor: pointer;
	width: fit-content;
	border: 1px solid transparent;

	${(props) =>
		props.isPreCourse &&
		css`
			color: ${(props1) => props.color};
			background: ${COLORS.White};
			border-color: ${(props1) => props.color};

			${IconArrow} {
				path {
					stroke: ${(props1) => props.color};
				}
			}
		`}
`;

export const IconArrow = styled.div`
	display: flex;
	margin-right: 8px;
	transition: all 0.2s;

	svg {
		width: 10px;
		height: 10px;
	}
`;
