import * as React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { HEADER_TYPE } from "../../constants";
import { loadStripe } from "@stripe/stripe-js";
import { Helmet } from "react-helmet";
import { Instruments } from "./Instruments/Instruments";
import { Container } from "./PricingPage.styled";
import { ContainerGrid } from "../../components/common";
import { Categories } from "./Categories/Categories";
import { Durations } from "./Durations/Durations";
import { Goods } from "./Goods/Goods";
import { DescriptionPricing } from "./DescriptionPricing/DescriptionPricing";
import { API } from "../../core";
import { TransactionBlock } from "./TransactionBlock";

export const PricingPage = observer(() => {
	const {
		categoriesStore,
		settingsStore,
		servicesStore,
		stripeStore,
		productInstrumentsStore,
		durationsStore,
		goodsStore,
	} = useStores();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);

		settingsStore.showFooter();

		(async () => {
			await servicesStore.getList();
		})();
	}, []);

	/**
	 * Stripe JS
	 */
	useEffect(() => {
		(async () => {
			const result = await loadStripe(API.stripeKey);

			if (result) {
				stripeStore.setInstance(result);
			}

			// Получение данных
			await productInstrumentsStore.getList();
			await categoriesStore.getList();
			await durationsStore.getList();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await goodsStore.getList(
				productInstrumentsStore.chooseInstrumentID,
				categoriesStore.choose_id,
				durationsStore.choose_id,
			);
		})();
	}, [productInstrumentsStore.chooseInstrumentID, categoriesStore.choose_id, durationsStore.choose_id]);

	return (
		<>
			<Helmet>
				<title>Pricing | MUSICABINET</title>
				<meta
					name="description"
					content="Comprehensive Music Education. Learn to play music instruments 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals."
				/>
			</Helmet>

			<Container>
				<ContainerGrid>
					<Instruments />
					<Categories />
					<Durations />

					<Goods />

					<DescriptionPricing />
				</ContainerGrid>
			</Container>

			<TransactionBlock />

			{/*<Pricing />*/}
		</>
	);
});
