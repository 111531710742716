import * as React from "react";
import { ServiceListContainer, ServiceListDescription, ServiceListItem, ServiceListName } from "./ServiceList.styled";
import { ReactComponent as SchoolIcon } from "./school.svg";
import { ReactComponent as CollegeIcon } from "./college.svg";
import { ReactComponent as UniversityIcon } from "./university.svg";
import { ServiceName } from "../../../../constants";

export const ServiceList = () => {
	return (
		<ServiceListContainer>
			<ServiceListItem>
				<SchoolIcon />
				<ServiceListName service={ServiceName.School}>Beginner</ServiceListName>
				<ServiceListDescription>From level Zero to Beginner</ServiceListDescription>
			</ServiceListItem>

			<ServiceListItem>
				<CollegeIcon />
				<ServiceListName service={ServiceName.College}>Advanced</ServiceListName>
				<ServiceListDescription>From level Beginner to Advanced</ServiceListDescription>
			</ServiceListItem>

			<ServiceListItem>
				<UniversityIcon />
				<ServiceListName service={ServiceName.University}>Professional</ServiceListName>
				<ServiceListDescription>From level Advanced to Professional</ServiceListDescription>
			</ServiceListItem>
		</ServiceListContainer>
	);
};
