import styled, { css } from "styled-components";
import { COLORS, phoneQuery } from "../../../styles";

export const CustomModalContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
	display: flex;
	justify-content: center;
`;

export const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
`;

export const ModalContainer = styled.div`
	height: 100%;
	outline: 0;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: center;
	display: flex;
	flex-direction: column;
`;

export const ModalDialog = styled.div`
	max-width: 960px;
	display: inline-block;
	text-align: left;
	vertical-align: middle;
	background: transparent;
	box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
	margin: 7px 0 20px;
	padding: 1.2rem;
	position: relative;
	overflow-y: auto;
`;

export const CloseContainer = styled.div`
	max-width: 960px;
	display: inline-block;
	text-align: right;
	margin: 20px 0 0;

	${phoneQuery(css`
		margin-inline: 20px;
	`)}
`;

export const CloseButton = styled.button`
	position: relative;
	padding: 0;
	margin: 0;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 2px solid ${COLORS.Red};
	background: transparent;
	cursor: pointer;

	&:before,
	&:after {
		position: absolute;
		top: 6px;
		left: 12px;
		content: " ";
		height: 14px;
		width: 2px;
		background-color: ${COLORS.Red};
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`;
