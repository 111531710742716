import { action, makeObservable, observable } from "mobx";
import { GrandChartModuleResult } from "../../interfaces";

export class GrandChartModuleResultStore implements GrandChartModuleResult {
	@observable result_minutes = 0;
	@observable result_percent = 0;

	constructor(initialData: GrandChartModuleResult | null) {
		makeObservable(this);
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: GrandChartModuleResult) {
		const { result_minutes, result_percent } = data;

		this.result_minutes = result_minutes;
		this.result_percent = result_percent;
	}
}
