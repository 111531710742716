import styled from "styled-components";

export const Container = styled.div`
	display: grid;
`;

export const FormWrapper = styled.form`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 15px 30px;

	[data-action]:last-child {
		grid-column: 2;
	}
`;

export const Actions = styled.div`
	display: flex;
	justify-content: end;
`;
