import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../../../../styles";
import { rgba } from "polished";

export const Container = styled.div`
	display: grid;
`;

export const LinkItem = styled(NavLink)`
	position: relative;
	display: flex;
	align-items: center;
	min-height: 50px;
	text-decoration: none;
	color: ${COLORS.Dark_Gray};
	transition: all 0.2s;
	padding-left: 20px;

	&:hover {
		background: ${rgba(COLORS.White, 0.5)};
	}

	&.active {
		color: ${COLORS.Red};
		background: ${rgba(COLORS.White, 0.5)};
		cursor: default;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 10px;
			height: 100%;
			background: ${COLORS.Red};
		}
	}
`;
