import { AI_SETTINGS, AiBpm, AITimeRecord, INSTRUMENT_NAME } from "../constants";
import {
	ICON_INSTRUMENT_GUITAR,
	ICON_INSTRUMENT_KEYBOARD,
	ICON_INSTRUMENT_SAXOPHONE,
} from "../components/common/Icons";
import { UserStore } from "../stores/user/user.store";
import { ProductPurchaseStore } from "../stores/products/product-purchase.store";
import { TrialVersionStore } from "../stores/user/trial-version.store";
import { AiBpmStorage } from "../interfaces";

export const ErrorAPI = (nameMethod: string, e: unknown) => {
	console.error(`Error in method ${nameMethod} : `, e);

	throw e;
};

export const removeEmptyProperty = (obj: { [key: string]: string | number }) => {
	for (const key in obj) {
		if (obj[key] === "") {
			delete obj[key];
		}
	}

	return obj;
};

export const getIconInstrument = (name_instrument: INSTRUMENT_NAME) => {
	switch (name_instrument) {
		case INSTRUMENT_NAME.GUITAR:
			return <ICON_INSTRUMENT_GUITAR />;
		case INSTRUMENT_NAME.KEYBOARD:
			return <ICON_INSTRUMENT_KEYBOARD />;
		case INSTRUMENT_NAME.SAXOPHONE:
			return <ICON_INSTRUMENT_SAXOPHONE />;
		default:
			return "none";
	}
};

export const checkProduct = (
	grand_chart_id: number,
	user: UserStore,
): ProductPurchaseStore | TrialVersionStore | null => {
	let isPurchase: ProductPurchaseStore | TrialVersionStore | null = null;

	// Проверяем может продукт купили
	user.product_purchases.forEach((productPurchases) => {
		if (productPurchases.product.grand_chart_id === grand_chart_id && !productPurchases.is_expired) {
			isPurchase = productPurchases;
		}
	});

	// Если продкт куплен отдаем ответ
	if (isPurchase) return isPurchase;

	//  Если продукта нет в купленных проверяем триал версию
	if (!user.trial_version.is_expired) return user.trial_version;

	return null;
};

export const createLabel = (number: number, titles: Array<string>) => {
	const cases = [2, 0, 1, 1, 1, 2];
	return `${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]}`;
};

export const getNameService = (service_id: number) => {
	switch (service_id) {
		case 1:
			return "School";
		case 2:
			return "College";
		case 3:
			return "University";
		default:
			return "None";
	}
};

export const ucFirst = (str: string) => {
	if (!str) return str;

	return str[0].toUpperCase() + str.slice(1);
};

export const getTimeByService = (service_id: number): number => {
	switch (service_id) {
		case 1:
			return 30;
		case 2:
			return 45;
		case 3:
			return 60;
		default:
			return 0;
	}
};

export const getTimeFromMin = (min: number) => {
	let hours = Math.trunc(min / 60);
	let minutes = min % 60;

	if (minutes < 10) {
		return hours + ":0" + minutes;
	}

	return hours + ":" + minutes.toFixed(0);
};

export const secondToDhm = (second: number) => {
	second = Number(second);
	let d = Math.floor(second / (3600 * 24));
	let h = Math.floor((second % (3600 * 24)) / 3600);
	let m = Math.floor((second % 3600) / 60);
	let s = Math.floor(second % 60);

	let daysCurrent = d > 0 ? d : 0;
	let hoursCurrent = h > 0 ? h : 0;
	let minutesCurrent = m > 0 ? m : 0;
	let secondsCurrent = s > 0 ? s : 0;

	return {
		daysCurrent,
		hoursCurrent,
		minutesCurrent,
		secondsCurrent,
	};
};

export const isMobileDevice = (): boolean => {
	return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export { Portal } from "./Portal/Portal";

export const gtmEvent = (values: any) => {
	// @ts-ignore
	window?.dataLayer?.push(values);
};

export const removeEmoji = (value: string) => {
	return value
		.replace(
			/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
			"",
		)
		.replace(/\s+/g, " ")
		.trim();
};

export const convertMinuteToHours = (minutes: number): number => {
	return Math.floor(minutes / 60);
};

export const calculatePercent = (totalProgress: number, passedProgress: number) => {
	const totalPercent = Math.round((passedProgress * 100) / totalProgress);
	const completePercent = parseInt(String(totalPercent));
	return completePercent > 100 ? 100 : completePercent || 0;
};

export const convertSecondsToMS = (value: number) => {
	const hours = Math.floor(value / 3600);
	const minutes = Math.floor(value / 60) % 60;
	const seconds = value % 60;

	return [hours, minutes, seconds].map((v) => (v < 10 ? "0" + v : v));
};

export const isURL = (path: string) => {
	try {
		return Boolean(new URL(path));
	} catch (_) {
		return false;
	}
};

export const getDataFromStorageByItemId = (id: number): null | AiBpmStorage => {
	const result = localStorage.getItem(`${AI_SETTINGS.RESULT_ELEMENT}-${id}`);

	if (result) {
		return JSON.parse(result);
	}
	return null;
};

export const setBpmByItemId = (id: number, bpm: number) => {
	localStorage.setItem(
		`${AI_SETTINGS.RESULT_ELEMENT}-${id}`,
		JSON.stringify({
			bpm,
		}),
	);
};

export const getTotalTimeRecordAI = (currentBPM: number) => {
	if (currentBPM === AiBpm.Bpm60) {
		return AITimeRecord.Total;
	}

	switch (currentBPM) {
		case 60:
			return AITimeRecord.Total;
		case AiBpm.Bpm90:
			return 7.5;
		case AiBpm.Bpm120:
			return 5;
		default:
			return AITimeRecord.Total;
	}
};
