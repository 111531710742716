import styled from "styled-components";
import { COLORS, fontMixins, getColorByService } from "../../../styles";
import { ServiceName } from "../../../constants";

export const SecondBlockContainer = styled.div`
	width: 100%;
	height: 100%;
	background: ${COLORS.White};
	padding: 80px 0;
`;

export const Container = styled.div`
	width: 960px;
	margin: 0 auto;
`;

export const Columns = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 50px 0;
`;

interface ColumnItemI {
	service: ServiceName;
}

export const ColumnItem = styled.div<ColumnItemI>`
	${fontMixins(18)};
	line-height: 25px;
	color: ${COLORS.Dark_Gray};
	padding: 10px 20px;
	border-left: ${(props) => `2px solid ${getColorByService(props.service)}`};
`;

export const Information = styled.div`
	display: grid;
	grid-template-columns: 400px 1fr;

	picture {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	p {
		${fontMixins()}
		line-height: 25px;
		margin: 0 0 25px;
		padding: 0;
	}
`;
