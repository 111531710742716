import { observer } from "mobx-react";
import { Container } from "./AI.styled";
import { LeftSide } from "./LeftSide/LeftSide";
import { WorkArea } from "./WorkArea";

export const AI = observer(() => {
	return (
		<Container>
			<LeftSide />
			<WorkArea />
		</Container>
	);
});
