import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const StatisticsMonthContainer = styled.div`
	padding: 0 0 15px 0;
`;

export const StatisticsMonthTitle = styled.div`
	display: flex;
	justify-content: center;
	${fontMixins(14)};
	line-height: 20px;
	margin-bottom: 10px;
`;

export const StatisticsLabels = styled.div`
	position: relative;
	top: -5px;
	display: flex;
	flex-direction: column;
	row-gap: 20px;
`;

export const StatisticsMonthRating = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, 10px);
	grid-auto-flow: column;
	justify-content: space-around;
	column-gap: 10px;

	span {
		display: flex;
		width: 20px;
		height: 20px;
		font-size: 12px;
		align-items: center;
	}
`;

interface StatisticsMonthRatingItemI {
	percent: number;
}

export const StatisticsMonthRatingItem = styled.div<StatisticsMonthRatingItemI>`
	position: relative;
	background: ${COLORS.MidGray};
	height: 130px;
	border-radius: 5px 5px 0 0;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 10px;
		height: ${(props) => props.percent}%;
		background: ${COLORS.Red};
		border-radius: 10px 10px 0 0;
	}
`;

export const StatisticsMonthRatingDay = styled.div`
	position: absolute;
	${fontMixins(8)};
	bottom: -20px;
	width: 10px;
	text-align: center;
`;
