import { action, makeObservable, observable } from "mobx";
import { ScoreType } from "../../interfaces/scores";

export class ScoreTypeStore implements ScoreType {
	@observable id = 0;
	@observable title = "";
	@observable name = "";

	constructor(initialData: ScoreType | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ScoreType) {
		const { id, title, name } = data;

		this.id = id;
		this.title = title;
		this.name = name;
	}
}
