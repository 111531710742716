import styled from "styled-components";

export const ImageBackgroundContainer = styled.div`
	position: relative;
	width: 100%;
`;

export const Picture = styled.picture`
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.2);
		z-index: 50;
	}

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 60;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
