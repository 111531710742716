import { action, makeObservable, observable } from "mobx";
import { ChartType } from "../../interfaces";

export class ChartTypeStore implements ChartType {
	@observable id = 0;
	@observable title = "";
	@observable name = "";

	constructor(initialData: ChartType | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ChartType) {
		const { id, name, title } = data;

		this.id = id;
		this.name = name;
		this.title = title;
	}
}
