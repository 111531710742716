import * as React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useNavigate, useParams } from "react-router-dom";
import { INSTRUMENT_NAME, SEO, ServiceName } from "../../constants";
import { FirstBlock } from "./FirstBlock/FirstBlock";
import { SecondBlock } from "./SecondBlock/SecondBlock";
import { ThirdBlock } from "./ThirdBlock/ThirdBlock";
import { FourthBlock } from "./FourthBlock/FourthBlock";
import { FifthBlock } from "./FifthBlock/FifthBlock";
import { SixthBlock } from "./SixthBlock/SixthBlock";
import { SeoContentI } from "../../interfaces";
import { Helmet } from "react-helmet";
import { NotFoundPage } from "../NotFoundPage/NotFoundPage";

interface ServicePageProps {}

export const ServicePage = observer(({}: ServicePageProps) => {
	const { authStore, pageStore, settingsStore } = useStores();
	const { service, instrument } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		pageStore.setService(service as ServiceName);
		pageStore.setCurrentService(service as any);
		pageStore.setInstrument(instrument as INSTRUMENT_NAME);

		settingsStore.showFooter();
	}, [service, instrument]);

	useEffect(() => {
		if (authStore.isAuth) {
			navigate("/cabinet/my-day");
		}
	}, [authStore.isAuth]);

	const getMetaData = (): SeoContentI => {
		return SEO[`${service}-${instrument}`];
	};

	if (!getMetaData()) {
		return <NotFoundPage />;
	}

	return (
		<>
			<Helmet>
				<title>{getMetaData().title}</title>
				<meta name="description" content={getMetaData().description} />
			</Helmet>

			<div style={{ display: "grid" }}>
				<FirstBlock />
				<SecondBlock />
				<ThirdBlock />
				<FourthBlock />
				<FifthBlock />
				<SixthBlock />
			</div>
		</>
	);
});
