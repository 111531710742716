import * as React from "react";

interface TestIFramePageProps {}

export const TestIFramePage = ({}: TestIFramePageProps) => {
	return (
		<div style={{ background: "silver" }}>
			<iframe
				title="test-iframe"
				src="http://localhost:3000/iframe"
				onLoad={(e) => {
					if (e.currentTarget.contentWindow) {
						const height = e.currentTarget.contentWindow.document.body.scrollHeight;
						e.currentTarget.style.height = `${height}px`;
					}
				}}
			></iframe>
		</div>
	);
};
