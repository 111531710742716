import styled, { css } from "styled-components";
import { phoneQuery } from "../../../../../styles";

export const Container = styled.div`
	display: none;

	${phoneQuery(css`
		display: flex;
	`)}
`;
