import { Container } from "./WaveAudio.styled";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import WaveSurfer from "wavesurfer.js";
import { getColorByService } from "../../../../../../../styles";
import { SubmitAnalysis } from "../SubmitAnalysis/SubmitAnalysis";
import { darken } from "polished";
import { Notes } from "../Notes/Notes";

interface WaveAudioProps {}

export const WaveAudio = observer(({}: WaveAudioProps) => {
	const { aiStore, lessonStore, viewerStore } = useStores();
	const visualizerRef = useRef<HTMLCanvasElement>(null);

	// Контейнер для WaveSurfer
	const waveSurferRef = useRef({} as WaveSurfer);

	// Упрвление записью
	const [currentWidth, setCurrentWidth] = useState(1345);

	// Получение записи пользователем
	const pathUserTrackRecord = viewerStore.chooseItem.getAiResultByBpm(aiStore.chosenBPM)?.fullPathUserTrack;

	useEffect(() => {
		(async () => {
			const findElementWaveSurfer = document.querySelector("[data-wave-surfer]") as HTMLElement;

			if (aiStore.waveSurferInstance) {
				// Очищаем
				aiStore.waveSurferInstance.destroy();
			}

			if (findElementWaveSurfer) {
				// Инстанс wave surfer
				const waveform = WaveSurfer.create({
					container: findElementWaveSurfer,
					waveColor: getColorByService(lessonStore.currentService),
					progressColor: darken(0.3, getColorByService(lessonStore.currentService)),
					height: 330,
				});

				// Записываем инстанс waveSurfer
				aiStore.setWaveSurferInstance(waveform);

				if (aiStore.waveSurferInstance) {
					// Если файл уже записан

					// Загрузка записанного аудио
					if (aiStore.waveSurferInstance) {
						// Если есть живая запись то загружаем ее первую
						if (aiStore.audioBlob) {
							console.log(1);
							await aiStore.waveSurferInstance.loadBlob(aiStore.audioBlob);
						} else if (pathUserTrackRecord) {
							console.log(2);
							await aiStore.waveSurferInstance.load(pathUserTrackRecord);
						}
					}

					console.log("success");
				}

				return () => {
					if (aiStore.waveSurferInstance) {
						aiStore.waveSurferInstance.destroy();
					}
				};
			}
		})();
	}, [aiStore.audioBlob, pathUserTrackRecord, viewerStore.chooseItem.ai_midi_result]);

	return (
		<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
			<Container data-continer-wave>
				{((aiStore.existRecord && !aiStore.recordNow) || pathUserTrackRecord) && (
					<div data-wave-surfer style={{ width: "100%", height: 400 }} />
				)}

				<Notes />
			</Container>

			<SubmitAnalysis />
		</div>
	);
});
