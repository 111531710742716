import { action, makeObservable, observable } from "mobx";
import { ServiceStore } from "./service.store";
import { Service } from "../../interfaces";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";

export class ServicesStore {
	@observable list: ServiceStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: Service[]) {
		this.list = (values || []).map((value) => new ServiceStore(value));
	}

	@action.bound
	async getList() {
		try {
			const data = await API.request<Service[]>(`services`);

			this.setList(data);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}
}
