import { ButtonControl, Container, PauseButton, Title } from "./LessonTotalTimeLeft.styled";
import { ReactComponent as IconPlayLessonList } from "./icon_play.svg";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import * as React from "react";
import { CountDownTimer } from "./CountDownTimer/CountDownTimer";

interface LessonTotalTimeLeftProps {}

export const LessonTotalTimeLeft = observer(({}: LessonTotalTimeLeftProps) => {
	const { lessonStore } = useStores();

	const handleOnStartStop = (e: React.MouseEvent) => {
		e.stopPropagation();
		lessonStore.timer.setIsStart(!lessonStore.timer.isStart);
	};

	return (
		<Container>
			<Title>Lesson's total time left</Title>

			<ButtonControl onClick={handleOnStartStop}>
				{lessonStore.timer.isStart ? <PauseButton /> : <IconPlayLessonList />}
			</ButtonControl>

			<CountDownTimer />
		</Container>
	);
});
