import * as React from "react";
import { DividerContainer } from "./Divider.styled";

interface DividerProps {
	children?: React.ReactNode;
}

export const Divider = ({ children = null }: DividerProps) => {
	return <DividerContainer>{children}</DividerContainer>;
};
