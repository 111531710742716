import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins, getColorByService } from "../../../styles";
import { ServiceName } from "../../../constants";
import { Button } from "../../../components/common";
import { CancelSubscription, Days, Period, ProgressLine } from "./InformationPlan/InformationPlan.styled";

export const Body = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	justify-content: center;
	margin: 10px;
	padding: 31.69px 9.2px 5px;
	border-radius: 10px;
	background: ${COLORS.White};
	border: 1px solid transparent;
`;

export const Icon = styled.div`
	text-align: center;

	svg {
		width: 40px;
		height: 44.11px;
	}
`;

export const NameInstrument = styled.div`
	display: flex;
	justify-content: center;
	text-align: center;
	margin-top: 15px;
	${fontMixin({ size: 18, weight: 700 })};
	line-height: 22px;
	color: ${COLORS.Dark_Gray};
	text-transform: capitalize;
	min-height: 45px;
`;

export const ButtonAction = styled(Button)`
	width: 100%;
`;

interface ContainerI {
	service?: ServiceName;
}

export const Container = styled.div<ContainerI>`
	display: grid;
	grid-template-columns: 1fr;
	background: ${COLORS.White};
	border-radius: 10px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);

	${(props) => css`
		${Body} {
			border-color: ${getColorByService(props.service)};
		}

		${Icon} {
			svg {
				path {
					stroke: ${getColorByService(props.service)};
				}

				&#keys {
					path {
						&:nth-child(2),
						&:nth-child(4),
						&:nth-child(8),
						&:nth-child(10),
						&:nth-child(12) {
							fill: ${getColorByService(props.service)};
						}
					}
				}
			}
		}

		${ButtonAction} {
			background: ${getColorByService(props.service)};
		}

		${Period}, ${Days}, ${CancelSubscription} {
			color: ${getColorByService(props.service)};
		}

		${ProgressLine} {
			background: ${getColorByService(props.service)};
		}
	`}
`;

export const CountCourseLessons = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48px;
	${fontMixins(14)};
	line-height: 7px;
`;
