import * as React from "react";
import PaginationRC from "rc-pagination";
import { PaginationButtonFirstEnd, PaginationButtonJump, PaginationContainer } from "./Pagination.styled";
import { observer } from "mobx-react";
import { ReactComponent as IconArrowRight } from "./arrow-right.svg";
import { ReactComponent as IconArrowLeft } from "./arrow-left.svg";

interface PaginationProps {
	currentPage: number;
	totalPages: number;
	onChange: (pageNumber: number) => void;
}

export const Pagination = observer(({ currentPage, totalPages, onChange }: PaginationProps) => {
	const handleOnChangePage = (page: number) => {
		onChange(page - 1);
	};

	const renderItem = (current: number, type: string, element: React.ReactNode): React.ReactNode => {
		const newPage = current;

		if (type === "prev") {
			return (
				<a data-page-prev={true} onClick={() => handleOnChangePage(newPage)}>
					<IconArrowLeft />
				</a>
			);
		}
		if (type === "next") {
			return (
				<a data-page-next={true} onClick={() => handleOnChangePage(newPage)}>
					<IconArrowRight />
				</a>
			);
		}

		if (type === "page") {
			return (
				<a data-page-link={true} onClick={() => handleOnChangePage(newPage)}>
					{current}
				</a>
			);
		}

		return element;
	};

	const handleOnPage = (page: number) => {
		onChange(page - 1);
	};

	return (
		<PaginationContainer>
			<PaginationButtonFirstEnd left disabled={currentPage === 1} onClick={() => handleOnPage(1)}>
				<IconArrowLeft />
				<IconArrowLeft />
			</PaginationButtonFirstEnd>
			<PaginationRC
				current={currentPage}
				total={totalPages}
				itemRender={renderItem}
				pageSize={1}
				jumpNextIcon={() => <PaginationButtonJump>...</PaginationButtonJump>}
				jumpPrevIcon={() => <PaginationButtonJump>...</PaginationButtonJump>}
			/>

			<PaginationButtonFirstEnd
				left={false}
				disabled={currentPage === totalPages}
				onClick={() => handleOnPage(totalPages)}
			>
				<IconArrowRight />
				<IconArrowRight />
			</PaginationButtonFirstEnd>
		</PaginationContainer>
	);
});
