import styled from "styled-components";
import { COLORS } from "../../../styles";
import { fontMixin } from "../../../styles/mixins.styled";

export const Container = styled.div`
	position: fixed;
	z-index: 9999;
	right: 20px;
	top: 20px;
	width: 340px;
	overflow: auto;
	transition: all 0.2s;
`;

interface ItemI {
	isShow: boolean;
}

export const Item = styled.div<ItemI>`
	position: relative;
	background: ${COLORS.University};
	width: 100%;
	padding: 15px 20px;
	border-radius: 12px;
	left: ${(props) => (props.isShow ? "-320px" : "0px")};
`;

export const Title = styled.div`
	${fontMixin({ size: 14, weight: 600 })}
`;
