import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService, getColorDarkByService } from "../../../../styles";
import { ServiceName } from "../../../../constants";
import { CourseContainer } from "./Course/Course.styled";
import {
	CircleBackground,
	CircleMainBackground,
	CircleProgress,
	DataInside,
} from "../../../common/CircleProgressBar/CircleProgressBar.styled";
import { PercentageCurrent } from "./Percentage/Percentage.styled";
import { GroupLessonCurrentName } from "./GroupLesson/GroupLesson.styled";
import { LessonCircle } from "./Lesson/Lesson.styled";
import { ModuleItemProgressPassed } from "./Modules/Modules.styled";

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 220px;
`;

export const MainDays = styled.div``;

export const List = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 11px;
`;

interface ItemProps {
	theme: ServiceName;
}

export const Item = styled.div<ItemProps>`
	background: ${COLORS.White};
	height: 360px;
	border-radius: 10px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	padding: 40px 0 0 40px;

	&:last-child {
		margin-bottom: 20px;
	}

	${(props) => css`
		
		${ModuleItemProgressPassed} {
      background: ${getColorByService(props.theme)};
        }
		
		${GroupLessonCurrentName} {
      color: ${getColorByService(props.theme)};
		}
		
		${PercentageCurrent}{
      color: ${getColorByService(props.theme)};
		}
		
    ${DataInside} {
      color: ${COLORS.White};
    }

    ${CircleProgress} {
      stroke: ${getColorDarkByService(props.theme)};
    }

    ${CircleMainBackground} {
      fill: ${getColorByService(props.theme)};
    }

    ${CircleBackground} {
      stroke: ${COLORS.White};
    }

    ${CourseContainer} {
      background: ${getColorByService(props.theme)};
    }
		
		${LessonCircle} {
      ${CircleBackground} {
        stroke: ${COLORS.MidGray};
      }
		}

    ${Icon} {
      svg {
        circle {
          stroke: ${getColorByService(props.theme)};
        }

        path {
          stroke: ${getColorByService(props.theme)};
        }

        &#keys {
          path {
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(8),
            &:nth-child(10),
            &:nth-child(12) {
              fill: ${getColorByService(props.theme)};
            }
          }
        }
      }
    }
  }
  `}
`;

export const Icon = styled.div`
	width: 40px;
	height: 44.1px;

	svg {
		width: 40px;
		height: 44.1px;
	}
`;

export const Header = styled.div`
	display: flex;
	column-gap: 30px;
	align-items: center;
`;

export const Title = styled.div`
	${fontMixins(24, 700)};
	color: ${COLORS.Dark_Gray};
`;

export const Body = styled.div`
	display: grid;
	grid-template-columns: 1fr 275px;
	padding-left: 70px;
`;
