import * as React from "react";
import {
	NotificationBody,
	NotificationButton,
	NotificationContainer,
	NotificationDescription,
	NotificationIcon,
	NotificationTitle,
} from "./Notifications.styled";
import { NotificationStore } from "../../../stores/notifications/notification.store";
import { NOTIFICATION_TYPE } from "../../../constants";
import { ReactComponent as IconSuccess } from "./success.svg";
import { ReactComponent as IconError } from "./error.svg";
import { observer } from "mobx-react";

interface NotificationProps {
	notificationStore: NotificationStore;
}

export const Notification = observer(({ notificationStore }: NotificationProps) => {
	const getIcon = () => {
		switch (notificationStore.type) {
			case NOTIFICATION_TYPE.SUCCESS:
				return <IconSuccess />;
			case NOTIFICATION_TYPE.ERROR:
				return <IconError />;
			default:
				return null;
		}
	};

	const handleOnDelete = () => {
		notificationStore.delete();
	};

	return (
		<NotificationContainer
			type={notificationStore.type}
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			exit={{
				opacity: 0,
			}}
		>
			<NotificationIcon>{getIcon()}</NotificationIcon>

			<NotificationBody>
				<NotificationTitle>{notificationStore.title}</NotificationTitle>
				<NotificationDescription dangerouslySetInnerHTML={{ __html: notificationStore.message }} />
			</NotificationBody>

			<NotificationButton onClick={handleOnDelete}>Ok</NotificationButton>
		</NotificationContainer>
	);
});
