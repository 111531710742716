import styled from "styled-components";
import { COLORS } from "../../../../../../../styles";

export const Container = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: ${COLORS.Dark_Gray};
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s;

	&:hover {
		background: ${COLORS.Dark_Gray_Hover};
	}

	&:active {
		background: ${COLORS.Dark_Gray_Active};
	}

	svg {
		width: 76px;
		height: 107px;
		opacity: 0.3;
	}
`;

export const TextUpload = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	color: ${COLORS.White};
`;
