import styled, { css } from "styled-components";
import { COLORS, fontMixin, phoneQuery } from "../../../../../../styles";
import { rgba } from "polished";

export const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 140px;
	border-radius: 50%;
	cursor: pointer;
	transition: all 0.2s;
	margin-bottom: 20px;

	&:active {
		transform: scale(0.95);
	}

	img {
		object-fit: cover;
	}

	${phoneQuery(css`
		margin-inline: auto;
	`)}
`;

interface LoaderI {
	isShow: boolean;
}

export const Image = styled.img`
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 50%;
`;

export const Loader = styled.div<LoaderI>`
	position: absolute;
	display: ${(props) => (props.isShow ? "flex" : "none")};
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background: ${rgba(COLORS.White, 1)};
	border: 1px solid ${COLORS.Red};
	animation: borderSpinner 2s infinite;
	text-transform: uppercase;
	${fontMixin({ size: 14, weight: 600 })}

	@keyframes borderSpinner {
		0% {
			border-width: 1px;
		}
		50% {
			border-width: 15px;
		}
		100% {
			border-width: 1px;
		}
	}
`;

export const LoadText = styled.span`
	${fontMixin({ size: 18 })};
	line-height: 22px;
	color: ${COLORS.White};
`;

export const InputUpload = styled.input`
	display: none;
`;
