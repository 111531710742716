import styled, { css } from "styled-components";
import { rgba } from "polished";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	display: flex;
	column-gap: 13px;
	align-items: center;
	background: ${rgba(COLORS.Gold, 0.5)};

	padding-inline: 36px;
	height: 55px;
`;

interface BodyProps {
	isShow: boolean;
}

export const Body = styled.div<BodyProps>`
	display: none;
	background: ${COLORS.White};
	height: 0;
	transition: all 0.2s;

	${(props) =>
		props.isShow &&
		css`
			display: flex;
			flex-direction: column;
			height: auto;
			padding: 28px 33px 28px 65px;
		`}
`;

interface ItemProps {
	isShow: boolean;
}

export const Item = styled.div<ItemProps>`
	${fontMixins(18, 500)};
	line-height: 25px;
	cursor: pointer;
	user-select: none;
	background: ${COLORS.White};

	&:first-child {
		border-radius: 8px 8px 0 0;

		${Header} {
			border-radius: 8px 8px 0 0;
		}
	}

	&:last-child {
		${Body} {
			border-radius: 0 0 8px 8px;
		}
	}

	${(props) =>
		props.isShow &&
		css`
			${Header} {
				background: ${rgba(COLORS.Gold, 0.2)};
			}
		`}
`;

interface IconProps {
	isShow: boolean;
}

export const Icon = styled.div<IconProps>`
	svg {
		display: flex;
		transform: rotate(-90deg);
		transition: all 0.2s;
	}
	${(props) =>
		props.isShow &&
		css`
			svg {
				transform: rotate(0deg);
			}
		`}
`;

export const Title = styled.div`
	text-transform: uppercase;
`;
