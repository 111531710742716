import { action, computed, makeObservable, observable } from "mobx";
import { Lesson } from "../../interfaces/lessons/lesson.interface";
import dayjs from "dayjs";
import { LessonColorStore } from "./lesson-color.store";
import { GrandChartGroupLessonStore } from "../grand-charts/grand-chart-group-lesson.store";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { TimerStore } from "./timer/timer.store";
import { ListStore } from "./list/list.store";
import { Score } from "../../interfaces/scores";
import { ScoresStore } from "../scores/scores.store";
import { AccompanimentsStore } from "../accompaniment/accompaniments.store";
import { AccompanimentI } from "../../interfaces/accompaniments/accompaniment.interface";
import { ChartI } from "../../interfaces";
import { ChartsStore } from "../charts/charts.store";
import { INSTRUMENT_NAME, ServiceName } from "../../constants";
import { LessonMyDayStore } from "./my-day";

export class LessonStore implements Lesson {
	public PATH = "lessons";

	@observable id = 0;
	@observable uuid = "";
	@observable grand_chart_group_lesson_id = 0;
	@observable sort = 0;
	@observable slug = "";
	@observable meta_title = "";
	@observable meta_description = "";
	@observable meta_keywords = "";
	@observable name = "";
	@observable description = "";
	@observable duration_minute = 0;
	@observable is_active = false;
	@observable lesson_color_id = 0;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable color = new LessonColorStore(null);
	@observable grand_chart_group_lesson = new GrandChartGroupLessonStore(null);
	@observable progress_second = 0;
	@observable total_progress_second = 0;
	@observable scoresList: Score[] = [];
	@observable chartsList: ChartI[] = [];
	@observable accompanimentsList: AccompanimentI[] = [];

	@observable accompaniments = new AccompanimentsStore();
	@observable scores = new ScoresStore();
	@observable charts = new ChartsStore();

	@observable myDay = new LessonMyDayStore(null);

	@observable timer = new TimerStore();
	@observable list = new ListStore();

	@observable total_progress_minute = 0;

	constructor(initialData: Lesson | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	async getByUUID(uuid: string) {
		try {
			// Получаем урок
			const response = await API.request<Lesson>(`${this.PATH}/${uuid}`);

			// Записываем данные
			this.fillStore(response);
		} catch (e) {
			ErrorAPI("getByUUID", e);
		}
	}

	@action.bound
	incrementProgress() {
		try {
			this.progress_second += 1;
		} catch (e) {
			console.error(`Error in method incrementProgress : `, e);
		}
	}

	@computed
	get numberCourse() {
		return this.grand_chart_group_lesson.grand_chart_course.sort;
	}

	@computed
	get nameCourse() {
		return this.grand_chart_group_lesson.grand_chart_course.course.name;
	}

	@computed
	get numberModule() {
		return this.grand_chart_group_lesson.grand_chart_module.sort;
	}

	@computed
	get nameModule() {
		return this.grand_chart_group_lesson.grand_chart_module.module.name;
	}

	@computed
	get nameLessonGroup() {
		return this.grand_chart_group_lesson.name;
	}

	@computed
	get duration_second(): number {
		return this.duration_minute * 60;
	}

	@computed
	get timeLeft() {
		let time = "00:00:00";

		if (this.progress_second <= this.duration_second) {
			let left_duration = this.duration_second - this.progress_second;
			let hours = (left_duration / 3600) ^ 0;
			let minutes = ((left_duration - hours * 3600) / 60) ^ 0;
			let second = left_duration - hours * 3600 - minutes * 60;
			time =
				(hours < 10 ? "0" + hours : hours) +
				":" +
				(minutes < 10 ? "0" + minutes : minutes) +
				":" +
				(second < 10 ? "0" + second : second);
		}
		return time.split(":");
	}

	@computed
	get timePassed() {
		let time = "00:00:00";

		if (this.progress_second <= this.duration_second) {
			let left_duration = this.progress_second;
			let hours = (left_duration / 3600) ^ 0;
			let minutes = ((left_duration - hours * 3600) / 60) ^ 0;
			let second = left_duration - hours * 3600 - minutes * 60;
			time =
				(hours < 10 ? "0" + hours : hours) +
				":" +
				(minutes < 10 ? "0" + minutes : minutes) +
				":" +
				(second < 10 ? "0" + second : second);
		} else {
			let left_duration = this.duration_second;

			let hours = (left_duration / 3600) ^ 0;
			let minutes = ((left_duration - hours * 3600) / 60) ^ 0;
			let second = left_duration - hours * 3600 - minutes * 60;
			time =
				(hours < 10 ? "0" + hours : hours) +
				":" +
				(minutes < 10 ? "0" + minutes : minutes) +
				":" +
				(second < 10 ? "0" + second : second);
		}

		return time.split(":");
	}

	@computed
	get getPassedLesson(): number {
		let percent = Number((this.progress_second * 100) / this.duration_second);

		if (percent >= 100) return 100;
		else return Number(percent);
	}

	@computed
	get isCollegeGuitar() {
		return (
			this.grand_chart_group_lesson.grand_chart_current.service.slug === ServiceName.College &&
			this.grand_chart_group_lesson.grand_chart_current.instrument.slug === "guitar"
		);
	}

	@computed
	get currentServiceId() {
		return this.grand_chart_group_lesson.grand_chart_current.service_id;
	}

	@computed
	get currentService(): ServiceName {
		return this.grand_chart_group_lesson.grand_chart_current.service.slug;
	}

	@computed
	get currentInstrument(): INSTRUMENT_NAME {
		return this.grand_chart_group_lesson.grand_chart_current.instrument.slug;
	}

	@computed
	get totalTimeHour() {
		return this.duration_minute;
	}

	@computed
	get breadcrumbs() {
		return `${this.grand_chart_group_lesson.grand_chart_current.instrument.slug} <span/> ${this.grand_chart_group_lesson.grand_chart_current.service.slug}`;
	}

	@action
	fillStore(data: Lesson) {
		const {
			id,
			uuid,
			grand_chart_group_lesson_id,
			sort,
			slug,
			meta_title,
			meta_description,
			meta_keywords,
			name,
			description,
			duration_minute,
			is_active,
			lesson_color_id,
			created_at,
			updated_at,
			color,
			grand_chart_group_lesson,
			progress_second,
			total_progress_second,
			scoresList,
			chartsList,
			accompanimentsList,
			total_progress_minute,
		} = data;

		this.id = id;
		this.uuid = uuid;
		this.grand_chart_group_lesson_id = grand_chart_group_lesson_id;
		this.sort = sort;
		this.slug = slug;
		this.meta_title = meta_title;
		this.meta_description = meta_description;
		this.meta_keywords = meta_keywords;
		this.name = name;
		this.description = description;
		this.duration_minute = duration_minute;
		this.is_active = is_active;
		this.lesson_color_id = lesson_color_id;
		this.created_at = created_at;
		this.updated_at = updated_at;
		this.color = new LessonColorStore(color);
		this.grand_chart_group_lesson = new GrandChartGroupLessonStore(grand_chart_group_lesson);
		this.progress_second = progress_second;
		this.total_progress_second = total_progress_second || 0;

		this.total_progress_minute = total_progress_minute || 0;

		this.scores.setList(scoresList);
		this.charts.setList(chartsList);
		this.accompaniments.setList(accompanimentsList);
	}
}
