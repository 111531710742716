import { Container, TextUpload } from "./UploadAvatar.styled";
import { ICON_AVATAR_DEFAULT } from "../../../../../../common/Icons";

interface UploadAvatarProps {}

export const UploadAvatar = ({}: UploadAvatarProps) => {
	return (
		<Container>
			<ICON_AVATAR_DEFAULT />
			<TextUpload>Load</TextUpload>
		</Container>
	);
};
