import { PayButtonPrice } from "./PayButton.styled";
import { IconCard } from "../../ui/Other/Pricing/Products/icons";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { GoodStore } from "../../../stores/goods";
import { useNavigate } from "react-router-dom";

interface PayButtonProps {
	goodStore: GoodStore;
}

export const PayButton = observer(({ goodStore }: PayButtonProps) => {
	const { authStore, stripeStore } = useStores();

	const navigation = useNavigate();

	const handleOnStripe = async () => {
		if (!authStore.isAuth) {
			navigation("/sign?tab=sign_in");
			return false;
		}

		await stripeStore.redirectPaymentLink({ good_id: goodStore.id, price_id: goodStore.stripe_default_price });

		/*await stripeStore.instance?.redirectToCheckout({
			successUrl: `${process.env.REACT_APP_SUCCESS_URL}?session_id={CHECKOUT_SESSION_ID}&good_id=${goodStore.id}`,
			cancelUrl: process.env.REACT_APP_CANCEL_URL,
			customerEmail: authStore.userStore.email,
			locale: "en",
			lineItems: [
				{
					price: goodStore.stripe_default_price,
					quantity: 1,
				},
			],
			// @ts-ignore
			allow_promotion_codes: true,
			mode: "subscription",
		});*/
	};

	return (
		<PayButtonPrice onClick={handleOnStripe}>
			<IconCard /> Pay by Card
		</PayButtonPrice>
	);
});
