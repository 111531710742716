import styled, { css } from "styled-components";
import { COLORS, fontMixin } from "../../../styles";

export const Container = styled.div`
	position: relative;
`;

interface InputI {
	isValid: boolean;
	isSmall: boolean;
}

export const Input = styled.input<InputI>`
	display: flex;
	line-height: 25px;
	width: 100%;
	border: 1px solid ${COLORS.MidGray};
	border-radius: 10px;
	padding: 11.5px 20px;
	${fontMixin({ size: 18 })};
	color: ${COLORS.Dark_Gray};
	transition: all 0.5s;

	${(props) =>
		props.isSmall &&
		css`
			font-size: 14px;
			padding: 5px 10px;
		`}

	&::placeholder {
		color: ${COLORS.MidGray};
	}

	&:focus {
		border-color: ${COLORS.Red};
	}
`;

export const Label = styled.label`
	display: flex;
	${fontMixin({ size: 12 })};
	color: ${COLORS.Dark_Gray};
	min-height: 25px;
	align-items: center;
`;

export const ErrorMessage = styled.div`
	position: absolute;
	left: 20px;
	bottom: -15px;
	text-transform: capitalize;
	${fontMixin({ size: 10, weight: 600 })}
	color: ${COLORS.Red_Down}
`;
