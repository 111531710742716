import * as React from "react";
import { Container, Description, Picture, SixthBlockContainer } from "./SixthBlock.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { COLORS, getColorByService } from "../../../styles";
import { ButtonStartTrial, Title } from "../../../components/common";

interface SixthBlockProps {}

export const SixthBlock = observer(({}: SixthBlockProps) => {
	const { pageStore } = useStores();

	return (
		<SixthBlockContainer style={{ background: getColorByService(pageStore.service) }}>
			<Container>
				<Title
					style={{
						fontSize: "42px",
						textAlign: "left",
						marginBottom: "50px",
						color: COLORS.White,
						width: "400px",
					}}
				>
					Wherever and whenever
				</Title>

				<Description>Study at home or while traveling from your computer, tablet or smartphone.</Description>

				<Picture>
					<source srcSet={`${pageStore.gadgetImage}.webp, ${pageStore.gadgetImage}@2x.webp 2x`} type="image/webp" />
					<img src={`${pageStore.gadgetImage}.png`} srcSet={`${pageStore.gadgetImage}@2x.png 2x`} alt="" />
				</Picture>

				<Title
					style={{
						fontSize: "42px",
						color: COLORS.White,
						width: "600px",
						margin: "0 auto 50px",
					}}
				>
					Join us, if you're serious about education
				</Title>

				<div style={{ textAlign: "center" }}>
					<ButtonStartTrial />
				</div>
			</Container>
		</SixthBlockContainer>
	);
});
