import styled, { css } from "styled-components";
import { NOTIFICATION_TYPE } from "../../../constants";
import { COLORS, fontMixins, getColorByTypeNotification } from "../../../styles";
import { motion } from "framer-motion";

export const NotificationsContainer = styled(motion.ul)`
	position: fixed;
	right: 20px;
	top: 20px;
	z-index: 99999999;
	width: 440px;
	overflow-y: auto;
`;

interface NotificationContainerI {
	type: NOTIFICATION_TYPE;
}

export const NotificationContainer = styled(motion.li)<NotificationContainerI>`
	display: grid;
	grid-template-columns: 30px 1fr 70px;
	column-gap: 25px;
	align-items: center;
	background: ${(props) => getColorByTypeNotification(props.type)};
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	margin-bottom: 15px;
	border-radius: 10px;
	padding: 20px 30px;

	&:last-child {
		margin-bottom: 0;
	}

	${(props) => css`
		${NotificationButton} {
			color: ${getColorByTypeNotification(props.type)};
		}
	`}
`;

export const NotificationIcon = styled.div``;

export const NotificationBody = styled.div``;

export const NotificationTitle = styled.div`
	${fontMixins(18, 700)};
	line-height: 22px;
	color: ${COLORS.White};
	min-height: 30px;
`;

export const NotificationDescription = styled.div`
	${fontMixins(12, 300)};
	line-height: 15px;
	color: ${COLORS.White};
`;

export const NotificationButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 50px;
	background: ${COLORS.White};
	border: 0;
	border-radius: 10px;
	${fontMixins(18)};
	line-height: 25px;
	transition: all 0.2s;
	cursor: pointer;

	&:active {
		transform: scale(0.98);
	}
`;
