import { CourseBody, CourseContainer, CourseHeader, CoursePie } from "./Course.styled";
import { CircleProgressBar } from "../../../../common";
import { observer } from "mobx-react";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";

interface CourseProps {
	grandChartMyDayStore: GrandChartMyDayStore;
}

export const Course = observer(({ grandChartMyDayStore }: CourseProps) => {
	return (
		<CourseContainer>
			<CourseHeader>Course {grandChartMyDayStore.sort}</CourseHeader>

			<CourseBody>{grandChartMyDayStore.course.name}</CourseBody>

			<CoursePie>
				<CircleProgressBar
					strokeWidth={2}
					sqSize={40}
					percentage={grandChartMyDayStore.percentPassed}
					payload={`${grandChartMyDayStore.percentPassed}%`}
				/>
			</CoursePie>
		</CourseContainer>
	);
});
