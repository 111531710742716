import { action, makeObservable, observable } from "mobx";
import { GrandChartUnited, StringNull } from "../../interfaces";
import { GrandChartStore } from "./grand-chart.store";

export class GrandChartUnitedStore implements GrandChartUnited {
	@observable id = 0;
	@observable grand_chart_united_id = 0;
	@observable grand_chart_id = 0;
	@observable sort = 0;
	@observable title: StringNull = null;
	@observable description = "";
	@observable grand_chart = new GrandChartStore(null);

	constructor(initialData: GrandChartUnited | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: GrandChartUnited) {
		const { id, grand_chart_united_id, grand_chart_id, sort, title, description, grand_chart } = data;

		this.id = id;
		this.grand_chart_united_id = grand_chart_united_id;
		this.grand_chart_id = grand_chart_id;
		this.sort = sort;
		this.title = title;
		this.description = description;
		this.grand_chart = new GrandChartStore(grand_chart);
	}
}
