import { action, makeObservable, observable } from "mobx";
import { RootStore } from "../index";
import { ErrorAPI } from "../../helpers";
import * as Tone from "tone";
import { LibraryStore } from "../library/library.store";
import { API } from "../../core";
import { LibraryTrackTypeId, PLAYER_CONST, SPACE_CONTROL, SPACE_CONTROL_CURRENT } from "../../constants";
import { LibraryScoreItemStore } from "../library/library-score-item.store";
import { LibraryChartItemStore } from "../library/library-chart-item.store";

export class AudioPlayerStore {
	@observable isLoading = false;
	@observable isPlay = false;
	@observable isDisabled = false;

	@observable player: Tone.Player[] = [];
	@observable selectedLibrary: LibraryStore | LibraryScoreItemStore | LibraryChartItemStore = new LibraryStore(null);

	@observable drumsMute: boolean = false;
	@observable bassMute: boolean = false;
	@observable keysMute: boolean = false;

	@observable duration_time: number = 0;
	@observable current_percent: number = 0;

	@observable isShowTrackList = false;

	clearUpdateTime: number = 0;

	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	setIsShowTrackList(value: boolean) {
		this.isShowTrackList = value;
	}

	@action.bound
	closeTrackList() {
		this.isShowTrackList = false;
	}

	@action.bound
	setCurrentPercent(value: number) {
		this.current_percent = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setIsPlay(value: boolean) {
		this.isPlay = value;
	}

	@action.bound
	setIsDisabled(value: boolean) {
		this.isDisabled = value;
	}

	@action.bound
	setSelectedLibrary(value: LibraryStore | LibraryScoreItemStore | LibraryChartItemStore) {
		this.selectedLibrary = value;
	}

	@action.bound
	clearSelectedLibrary() {
		this.selectedLibrary = new LibraryStore(null);
	}

	getMuteTrack = (track: LibraryTrackTypeId): boolean => {
		if (track === LibraryTrackTypeId.BASS) {
			return this.bassMute;
		} else if (track === LibraryTrackTypeId.DRUMS) {
			return this.drumsMute;
		} else if (track === LibraryTrackTypeId.KEYBOARDS) {
			return this.keysMute;
		}
		return false;
	};

	@action.bound
	async loadTrack() {
		this.setIsLoading(true);

		this.onStop();

		try {
			this.player = [];

			console.log("volume ", localStorage.getItem(PLAYER_CONST.VOLUME));

			// Получаем громкость
			// @ts-ignore
			const currentVolume: number = localStorage.getItem(PLAYER_CONST.VOLUME) || -6;

			console.log("set currentVolume", currentVolume);

			let promise_list: any[] = [];

			// Загружаем треки
			this.selectedLibrary.tracks.forEach((track) => {
				promise_list.push(
					new Promise((resolve, reject) => {
						this.player.push(
							new Tone.Player({
								url: `${API.getFilesPath}${track.path}`,
								loop: true,
								loopStart: 0.015,
								volume: currentVolume, // -100 0
								mute: this.getMuteTrack(track.library_track_type_id),

								onload: () => {
									this.setDurationTime(this.player[0].buffer.duration);
									resolve(1);
								},
								onerror: () => {
									reject();
								},
							}).toDestination(),
						);
					}),
				);
			});

			this.setVolume(currentVolume);

			Promise.all(promise_list)
				.then((result) => {
					if (this.player.length === result.length) {
						this.setVolume(currentVolume);
					}
				})
				.finally(() => {
					this.setIsLoading(false);
				});

			this.setCurrentPercent(0);
		} catch (e) {
			ErrorAPI("loadTracks", e);
		} finally {
		}
	}

	@action.bound
	onPlay() {
		// Записываем что управление клавишей пробел передано метроному
		localStorage.setItem(SPACE_CONTROL_CURRENT, SPACE_CONTROL.PLAYER);

		Tone.Transport.stop();

		Tone.Transport.cancel(0);

		let now = Tone.now();
		Tone.Transport.start(now);

		Tone.Transport.bpm.value = 240;

		console.log(Tone.Transport.bpm.get(), this.player);
		this.player.forEach((player) => {
			player.toMaster().start(0);
		});
		console.log(Tone.Transport.bpm.get());

		this.clearUpdateTime = window.setInterval(() => {
			let currentSecond = Tone.Transport.toSeconds(Tone.Transport.ticks + "i");
			let percent = Math.round((100 * currentSecond) / this.duration_time);

			if (percent >= 100) {
				Tone.Transport.ticks = 0;
			}

			this.setCurrentPercent(percent);
		}, 25);

		this.setIsPlay(true);
	}

	@action.bound
	onStop() {
		this.player.forEach((player) => {
			player.stop();
		});
		Tone.Transport.stop();

		this.setIsPlay(false);
		clearInterval(this.clearUpdateTime);
	}

	@action.bound
	onBack() {
		Tone.Transport.stop(0);
		this.player.forEach((player) => {
			player.stop(0);
		});

		this.current_percent = 0;
		this.isPlay = false;
	}

	@action.bound
	onMute(player: 0 | 1 | 2) {
		if (!this.player[player]) {
			console.log(`Not player for mute`);
			return false;
		}

		const current_mute = this.player[player].mute;
		this.player[player].mute = !current_mute;

		if (player === 0) {
			this.bassMute = !current_mute;
		} else if (player === 1) {
			this.drumsMute = !current_mute;
		} else if (player === 2) {
			this.keysMute = !current_mute;
		}
	}

	@action.bound
	setDurationTime(duration: number) {
		this.duration_time = duration;
	}

	@action.bound
	setVolume(value: number) {
		localStorage.setItem(PLAYER_CONST.VOLUME, String(value));

		this.player.forEach((player) => {
			if (!player.mute) {
				player.volume.value = value;
			}
		});
	}
}
