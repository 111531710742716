import { action, makeObservable, observable } from "mobx";
import { NotificationStore } from "./notification.store";
import { Notification } from "../../interfaces";
import { NOTIFICATION_TYPE } from "../../constants";

export class NotificationsStore {
	@observable list: NotificationStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	add(value: Notification) {
		this.list.push(new NotificationStore(value, this));
	}

	@action.bound
	deleteById(value: string) {
		this.list = (this.list || []).filter((notification) => notification.id !== value);
	}

	@action.bound
	error(value: Omit<Notification, "type" | "title">) {
		this.add({
			type: NOTIFICATION_TYPE.ERROR,
			title: "Error",
			message: value.message,
		});
	}
}
