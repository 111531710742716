import * as React from "react";
import { useEffect } from "react";
import { ActionButton, Actions, Container, Description, Form, Title } from "./ForgotModal.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Button, InputField, Modal } from "../../../common";
import { ButtonTheme } from "../../../../constants";
import { useFormik } from "formik";
import { AuthForgotRequest } from "../../../../requests";
import { ForgotSchema } from "../../../../validation-schems";

interface ForgotModalProps {}

export const ForgotModal = observer(({}: ForgotModalProps) => {
	const { authStore } = useStores();

	useEffect(() => {
		if (authStore.forgotForm.modal.isClose) {
			formik.resetForm();
		}
	}, [authStore.forgotForm.modal.isClose]);

	const handleOnSignUp = () => {
		authStore.forgotForm.modal.close();
		authStore.signUpForm.modal.open();
	};

	const handleOnSignIn = () => {
		authStore.forgotForm.modal.close();
		authStore.signInForm.modal.open();
	};

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: async (values) => {
			// Отправляем запрос
			await authStore.forgotPassword(values as AuthForgotRequest);
			// Закрываем окно
			authStore.forgotForm.modal.close();
		},
		validationSchema: ForgotSchema,
	});

	return (
		<Modal modalStore={authStore.forgotForm.modal} isShow={authStore.forgotForm.modal.isShow} maxWidth={520}>
			<Container>
				<Title>Reset your password</Title>
				<Description>
					Enter your email address that you used to register. We’ll send you an email with a link to reset your
					password.
				</Description>

				<Form onSubmit={formik.handleSubmit}>
					<InputField
						placeholder={"Your email"}
						type={"email"}
						name={"email"}
						value={formik.values.email}
						errorMessage={formik.errors.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>
					<Button
						type={"submit"}
						disabled={!formik.dirty || formik.errors.email !== undefined || authStore.forgotForm.isLoading}
						isLoading={authStore.forgotForm.isLoading}
					>
						Reset password
					</Button>
				</Form>

				<Actions>
					<ActionButton>
						<Description>Don't have an account?</Description>
						<Button theme={ButtonTheme.LINK_LARGE} style={{ marginTop: 5 }} onClick={handleOnSignUp}>
							Sign up
						</Button>
					</ActionButton>
					<ActionButton>
						<Description>Already have an account?</Description>
						<Button theme={ButtonTheme.LINK_LARGE} style={{ marginTop: 5 }} onClick={handleOnSignIn}>
							Log in
						</Button>
					</ActionButton>
				</Actions>
			</Container>
		</Modal>
	);
});
