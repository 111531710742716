import { action, makeObservable, observable } from "mobx";
import { UserNotificationStore } from "./user-notification.store";
import { UserNotification } from "../../interfaces";

export class UserNotificationsStore {
	@observable list: UserNotificationStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	add(value: UserNotification) {
		this.list.push(new UserNotificationStore(value));
	}
}
