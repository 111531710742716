import { ModulesContainer } from "./Modules.styled";
import { observer } from "mobx-react";
import { GrandChartModuleStore } from "../../../../../stores/grand-charts/grand-chart-module.store";
import { ModuleItem } from "./ModuleItem";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";

interface ModulesProps {
	grandChartMyDayStore: GrandChartMyDayStore;
	grand_chart_modules: GrandChartModuleStore[];
}

export const Modules = observer(({ grand_chart_modules, grandChartMyDayStore }: ModulesProps) => {
	return (
		<ModulesContainer>
			{grand_chart_modules.map((grand_chart_module, index) => {
				return (
					<ModuleItem
						key={grand_chart_module.id}
						grandChartMyDayStore={grandChartMyDayStore}
						position={index + 1}
						grand_chart_module={grand_chart_module}
						is_active={grandChartMyDayStore.lastLessonData?.grand_chart_module_id === grand_chart_module.id}
					/>
				);
			})}
		</ModulesContainer>
	);
});
