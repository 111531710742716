import styled, { css } from "styled-components";
import { fontMixin, fontMixins, phoneQuery, pxToEm } from "../../../styles";
import { COLORS } from "../../../styles";
import { ButtonTheme } from "../../../constants";
import { rgba } from "polished";

interface ButtonContainer {
	theme: ButtonTheme;
	full?: boolean;
	isLoading: boolean;
	isWhite: boolean;
}

export const ButtonContainer = styled.button<ButtonContainer>`
	position: relative;
	${fontMixins(18)};
	padding: ${pxToEm(14)} ${pxToEm(20)};
	border-radius: 10px;
	min-width: 140px;
	border: 1px solid transparent;
	cursor: pointer;
	transition: all 0.2s;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	${(props) =>
		props.isWhite &&
		phoneQuery(css`
			&:focus {
				background: transparent !important;
				border: 1px solid ${COLORS.White};
			}
		`)}

	${phoneQuery(css`
		padding: 7px 8px 8px 9px;
		min-width: auto;
		${fontMixins(12)};
		border-radius: 4px;
	`)}

	${(props) =>
		props.theme === ButtonTheme.GRAY &&
		css`
			background: ${COLORS.Dark_Gray};
			color: ${COLORS.White};
		`}

	${(props) =>
		props.theme === ButtonTheme.GOLD &&
		css`
			background: ${COLORS.Gold};
			color: ${COLORS.White};
		`}

	${(props) =>
		props.theme === ButtonTheme.RED &&
		css`
			background: ${COLORS.Red};
			color: ${COLORS.White};
		`}

	${(props) =>
		props.theme === ButtonTheme.FACEBOOK &&
		css`
			background: ${COLORS.Facebook};
			color: ${COLORS.White};
			width: 100%;
			padding: 14px;
		`}
	
  ${(props) =>
		props.theme === ButtonTheme.GOOGLE &&
		css`
			background: ${COLORS.Google};
			color: ${COLORS.White};
			width: 100%;
			padding: 14px;
		`}
	
  &:active {
		transform: scale(0.98);
		box-shadow: 0 6px 10px 0 rgba(34, 60, 80, 0.11) inset;
	}

	${(props) =>
		props.theme === ButtonTheme.LINK &&
		css`
			background: transparent;
			color: ${COLORS.Red};
			display: inline-flex;
			padding: 0;
			${fontMixin({ size: 14 })};
			line-height: 17px;
			text-decoration: underline;
			justify-content: center;

			&:active {
				transform: scale(0.98);
				box-shadow: none;
			}
		`}

	${(props) =>
		props.theme === ButtonTheme.LINK_LARGE &&
		css`
			background: transparent;
			color: ${COLORS.Red};
			display: inline-flex;
			padding: 0;
			${fontMixin({ size: 18, weight: 700 })};
			line-height: 22px;
			text-decoration: underline;
			justify-content: center;

			&:active {
				transform: scale(0.98);
				box-shadow: none;
			}
		`}
	

	&:disabled {
		cursor: not-allowed;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			background: ${rgba(COLORS.White, 0.4)};
			width: 100%;
			height: 100%;
		}

		&:active {
			transform: scale(1);
			box-shadow: none;
		}
	}
`;

interface ButtonLoadingI {
	isLoading: boolean;
}

export const ButtonLoading = styled.div<ButtonLoadingI>`
	color: ${COLORS.White};
	${fontMixin({ size: 12, weight: 600 })};
	z-index: 9999;
	opacity: ${(props) => (props.isLoading ? 1 : 0)};
	transition: opacity 0.3s linear;
	width: 15px;
	height: 15px;
	border: 2px solid #f3f3f3; /* Light grey */
	border-top: 2px solid transparent; /* Black */
	border-radius: 50%;
	animation: spinner 1s linear infinite;
	margin-right: 10px;
	display: ${(props) => (props.isLoading ? "flex" : "none")};

	@keyframes spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;
