import { FlatPurchases } from "../interfaces";
import { UserPurchaseStore } from "../stores/user-purchases/user-purchase.store";

export const useCheckPurchase = (flatPurchases: FlatPurchases, grand_chart_id: number) => {
	const findPurchase: UserPurchaseStore = flatPurchases[grand_chart_id];

	if (findPurchase) {
		return [findPurchase];
	} else {
		return [null];
	}
};
