import styled, { css } from "styled-components";
import { ServiceName } from "../../../../../constants";
import { COLORS, getColorByService } from "../../../../../styles";
import { CircleBackground, CircleProgress } from "../../../../common/CircleProgressBar/CircleProgressBar.styled";

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, 50px);
`;

interface LessonItemWrapperI {
	theme: ServiceName;
	disabled: boolean;
}

export const LessonItemWrapper = styled.div<LessonItemWrapperI>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 10px;
	border: 2px solid transparent;

	&.active {
		${(props) => css`
			background: ${getColorByService(props.theme)};

			${CircleProgress} {
				stroke: ${COLORS.Dark_Gray};
			}

			${CircleBackground} {
				stroke: ${COLORS.White};
			}
		`}
	}

	${(props) =>
		props.disabled &&
		css`
			filter: grayscale(60%);
			cursor: not-allowed; !important;
			
			svg{
        cursor: not-allowed; !important;
			}
			
			&:hover{
				border-color: transparent;
			}
		`}
`;

export const Lock = styled.div`
	position: absolute;
	right: 4px;
	bottom: 1.5px;

	svg {
		width: 13px;
		height: 13px;

		rect {
			fill: ${COLORS.Dark_Gray};
		}

		path {
			stroke: ${COLORS.Dark_Gray};
		}
	}
`;
