export const PRICING_DESCRIPTION: { [key: string]: string } = {
	"guitar-school":
		"GUITAR BEGINNER is made for those, who are eager to get high quality fundamental music education as fast as possible.\n" +
		"<br/><br/>" +
		"In just 7 days of systematic studying you will get to learn basic chords, arpeggios and scales to play your first of 30 compositions provided inside.\n" +
		"<br/><br/>" +
		"Once you complete the entire program you will learn 30 classical compositions in all the techniques, chords of the CAGED system in 12 keys, which as a result gives solid skills to play your favourite songs and step up to move to our MUSICABINET | GUITAR ADVANCED program.",

	"guitar-college":
		"GUITAR ADVANCED was created for those who want to get a fundamental musical education quickly and efficiently.\n" +
		"<br/><br/>" +
		"In just 7 days of systematic studying you will get to learn the fundamentals of playing over the all types of chords in 12 keys and one of 48 compositions provided inside.\n" +
		"<br/><br/>" +
		"Once you complete the entire program you will learn all the 48 compositions of the finest musicians of the world, play using multiple various phrases, and improvise over any chord progression. In addition you will be more than qualified to get into our MUSICABINET | GUITAR PROFESSIONAL program.",

	"guitar-university":
		"GUITAR PROFESSIONAL is made for those, who are striving to get the most out of fundamental music education in a systematic way.\n" +
		"<br/><br/>" +
		"Adding up to the BEGINNER and ADVANCED program, the PROFESSIONAL  is going to get your skills to to the same level as of the top guitar players of the world.\n" +
		"<br/><br/>" +
		"The enormous amount of various phrases, all possible chords and arpeggios, polychords and outsides, exotic and altered pentatonic scales, plenty jazz standards and fusion compositions from the top level guitarists - all this will get you to the highest level of conscious playing. ",

	"1-1":
		"GUITAR BEGINNER is made for those, who are eager to get high quality fundamental music education as fast as possible.\n" +
		"<br/><br/>" +
		"In just 7 days of systematic studying you will get to learn basic chords, arpeggios and scales to play your first of 30 compositions provided inside.\n" +
		"<br/><br/>" +
		"Once you complete the entire program you will learn 30 classical compositions in all the techniques, chords of the CAGED system in 12 keys, which as a result gives solid skills to play your favourite songs and step up to move to our MUSICABINET | GUITAR ADVANCED program.",

	"1-2":
		"GUITAR ADVANCED was created for those who want to get a fundamental musical education quickly and efficiently.\n" +
		"<br/><br/>" +
		"In just 7 days of systematic studying you will get to learn the fundamentals of playing over the all types of chords in 12 keys and one of 48 compositions provided inside.\n" +
		"<br/><br/>" +
		"Once you complete the entire program you will learn all the 48 compositions of the finest musicians of the world, play using multiple various phrases, and improvise over any chord progression. In addition you will be more than qualified to get into our MUSICABINET | GUITAR PROFESSIONAL program.",

	"1-3":
		"GUITAR PROFESSIONAL is made for those, who are striving to get the most out of fundamental music education in a systematic way.\n" +
		"<br/><br/>" +
		"Adding up to the BEGINNER and ADVANCED program, the PROFESSIONAL  is going to get your skills to to the same level as of the top guitar players of the world.\n" +
		"<br/><br/>" +
		"The enormous amount of various phrases, all possible chords and arpeggios, polychords and outsides, exotic and altered pentatonic scales, plenty jazz standards and fusion compositions from the top level guitarists - all this will get you to the highest level of conscious playing. ",
};
