import { Container, Divider } from "./DescriptionPricing.styled";
import * as React from "react";

interface DescriptionPricingProps {}

export const DescriptionPricing = ({}: DescriptionPricingProps) => {
	return (
		<Container>
			<p>
				We have created a variety of programs and plans to ensure your journey towards becoming a skilled musician is as
				easy as possible. All you have to do is decide which option is right for you and join MUSICABINET's Complete
				System for the best musical education available.
			</p>

			<p>
				The MUSICABINET is tailored for those who want to start their music career without any fuss, and we provide
				support every step of the way.
			</p>

			<p>* IGM — Improvisational Guide Machine.</p>

			<Divider />

			<ul>
				<li>
					<sup>1</sup> GYM — Technique Exercises
				</li>
				<li>
					<sup>2</sup> SRM — Sight Reading Machine
				</li>
				<li>
					<sup>3</sup> REM — Rhythm Exercise Machine
				</li>
			</ul>
		</Container>
	);
};
