import styled, { css } from "styled-components";
import { COLORS, phoneQuery } from "../../../../styles";

export const Container = styled.div`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 10000;
	width: 80%;
	height: 90vh;
	display: flex;
	flex-direction: column;

	${phoneQuery(css`
		top: auto;
		bottom: 0;
		transform: translateY(0) translateX(-50%);
	`)}
`;

export const Shadow = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 5000;
`;

export const IFrame = styled.iframe`
	width: 100%;
	height: 100%;
`;

export const CloseButton = styled.button`
	position: relative;
	padding: 0;
	margin: 0;
	width: 30px;
	height: 34px;
	border-radius: 50%;
	border: 2px solid ${COLORS.Red};
	background: transparent;
	cursor: pointer;
	margin-bottom: 20px;
	align-self: flex-end;

	&:before,
	&:after {
		position: absolute;
		top: 6px;
		left: 12px;
		content: " ";
		height: 14px;
		width: 2px;
		background-color: ${COLORS.Red};
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`;
