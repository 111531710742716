import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../styles";

interface ContainerProps {
	isBlack?: boolean;
}

export const Container = styled.div<ContainerProps>`
	${(props) =>
		props.isBlack &&
		css`
			background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%), #4d4d4d;
		`}

	${(props) =>
		props.isBlack &&
		css`
			${Name}, ${Post}, ${Social} {
				text-align: left;
				
				span, li{
					justify-content: left;
				}
			}

			${InformationCard} {
				grid-template-columns: 250px 1fr;
			}

			${Title} {
				color: ${COLORS.White};
			}

			${Quotes} {
				text-align: right;
				color: ${COLORS.Gold};
			}

			${CardId}, ${Content} {
				color: ${COLORS.White};
			}

			${HeaderCard} {
				color: ${COLORS.Gold};
				margin: 116px 0 58px;
			}

			${CardId} {
				text-align: left;
			}

			${Social} {
				a {
					color: ${COLORS.White};
				}
			}
		`}
`;

export const Title = styled.div`
	display: flex;
	padding: 105px 0 110px;
	justify-content: center;
	${fontMixins(48, 700)}
`;

export const Body = styled.div`
	display: flex;
	margin: 0 auto;
	width: 960px;
	flex-direction: column;
	padding-bottom: 200px;
`;

export const InformationCard = styled.div`
	display: grid;
	grid-template-columns: 1fr 250px;
	column-gap: 40px;
`;

export const PhotoBlock = styled.div`
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		display: flex;
		margin-bottom: 70px;
	}
`;

export const Quotes = styled.div`
	${fontMixins(18)};
	line-height: 25px;
	color: ${COLORS.Gold};

	p {
		margin-bottom: 20px;

		span {
			font-weight: 700;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const CardId = styled.div`
	text-align: right;
`;

export const Name = styled.div`
	${fontMixins(36, 300)};
	margin-bottom: 30px;
	text-align: right;
`;

export const Post = styled.div`
	span {
		display: flex;
		margin-bottom: 10px;
		justify-content: right;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const Social = styled.ul`
	padding: 0;
	margin: 25px 0 0;

	li {
		position: relative;
		left: -10px;
		display: flex;
		align-items: center;
		justify-content: right;

		a {
			color: ${COLORS.Dark_Gray};

			&:hover {
				text-decoration: none;
			}
		}
	}
`;

export const HeaderCard = styled.div`
	${fontMixins(36, 700)};
	line-height: 46px;
	color: ${COLORS.Gold};
	margin-top: 99px;
`;

export const Content = styled.div`
	margin-top: 60px;

	p {
		${fontMixins(18)};
		line-height: 25px;
		margin-bottom: 25px;
	}
`;
