import * as React from "react";
import { Container } from "./Modules.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ModuleItem } from "./ModuleItem";

interface ModulesProps {}

export const Modules = observer(({}: ModulesProps) => {
	const { grandCharts } = useStores();
	const { selected } = grandCharts;

	return (
		<Container>
			{selected.grand_chart_modules.map((grandChartModule) => {
				return <ModuleItem key={grandChartModule.id} grandChartModule={grandChartModule} />;
			})}
		</Container>
	);
});
