import { action, makeObservable, observable } from "mobx";
import { ProductInstrumentStore } from "./product-instrument.store";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { Instrument } from "../../interfaces/instrument/instrument.interface";
import { INSTRUMENT_NAME } from "../../constants";
import { ProductPeriodsStore } from "./product-periods.store";

export class ProductInstrumentsStore {
	@observable list: ProductInstrumentStore[] = [];
	@observable chooseInstrument: INSTRUMENT_NAME = INSTRUMENT_NAME.GUITAR;
	@observable chooseInstrumentID: number = 1;

	@observable periods = new ProductPeriodsStore();

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList() {
		try {
			const data = await API.request<Instrument[]>(`product-instruments`);
			this.setList(data);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}

	@action.bound
	setList(values: Instrument[]) {
		this.list = (values || []).map((productInstrument) => new ProductInstrumentStore(productInstrument));
	}

	@action.bound
	setChooseInstrument(value: INSTRUMENT_NAME) {
		this.chooseInstrument = value;
	}

	@action.bound
	setChooseInstrumentId(value: number) {
		this.chooseInstrumentID = value;
	}
}
