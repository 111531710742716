import { INSTRUMENT_IDS } from "./instrument.constants";

export enum FILTER_SUBSCRIPTIONS {
	ALL = 1,
	PURCHASED = 2,
}

export enum FILTER_INSTRUMENTS {
	GUITAR = INSTRUMENT_IDS.GUITAR,
	KEYBOARD = INSTRUMENT_IDS.KEYBOARD,
	SAXOPHONE = INSTRUMENT_IDS.SAXOPHONE,
}

export enum FILTER_LEVEL {
	SCHOOL = 1,
	COLLEGE = 2,
	UNIVERSITY = 3,
}
