import * as React from "react";
import { LogotypeContainer } from "./Logotype.styled";
import { useNavigate } from "react-router-dom";
import { ImagePng } from "../../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";

interface LogotypeProps {}

export const Logotype = observer(({}: LogotypeProps) => {
	const { authStore } = useStores();
	const navigation = useNavigate();

	const handleOnClick = () => {
		if (authStore.isAuth) {
			navigation("/cabinet/my-day");
		} else {
			navigation("/");
		}
	};

	return (
		<LogotypeContainer onClick={handleOnClick}>
			<ImagePng src={"/images/logotype/common"} />
		</LogotypeContainer>
	);
});
