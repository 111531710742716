import { StatusContainer } from "../GrandChartItem.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { useCheckPurchase } from "../../../../hooks/useCheckPurchase";
import { UserPurchaseStore } from "../../../../stores/user-purchases/user-purchase.store";

interface StatusProps {
	grandChart: GrandChartStore;
}

export const Status = observer(({ grandChart }: StatusProps) => {
	const {
		authStore: { userStore },
	} = useStores();

	let userPurchaseStore: UserPurchaseStore | null = null;

	// Условие для группового гранд чарта
	if (userStore.unitingPurchases[grandChart.id]) {
		userPurchaseStore = userStore.unitingPurchases[grandChart.id];
	} else {
		const result = useCheckPurchase(userStore.flatPurchases, grandChart.id);
		if (result[0]) {
			userPurchaseStore = result[0];
		}
	}

	return (
		<StatusContainer
			isTrial={!userPurchaseStore}
			durationType={(userPurchaseStore && userPurchaseStore.duration) || null}
		>
			{userPurchaseStore ? userPurchaseStore.duration : "Free plan"}
		</StatusContainer>
	);
});
