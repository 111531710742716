import { action, computed, makeObservable, observable } from "mobx";
import { ProductI } from "../../interfaces";
import { PRODUCT_DESCRIPTION, PRODUCT_PERIOD, ServiceName } from "../../constants";

export class ProductStore implements ProductI {
	@observable id = 0;
	@observable instrument_id = 0;
	@observable product_period_id = PRODUCT_PERIOD.MONTH;
	@observable grand_chart_id = 0;
	@observable icon = "";
	@observable name = "";
	@observable description = "";
	@observable extra_description = "";
	@observable price = 0;
	@observable sale = 0;

	constructor(initialData: ProductI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get serviceName(): ServiceName {
		return this.name.toLowerCase() as ServiceName;
	}

	@computed
	get descriptionID() {
		return `${this.instrument_id}-${this.product_period_id}`;
	}

	@computed
	get fullDescription(): Array<string> {
		const findService = PRODUCT_DESCRIPTION[this.serviceName];

		if (findService) {
			return findService[this.descriptionID].items;
		}

		return [];
	}

	@computed
	get extraDescription(): string {
		const findService = PRODUCT_DESCRIPTION[this.serviceName];

		if (findService) {
			return findService[this.descriptionID].extra;
		}

		return "";
	}

	@action
	fillStore(data: ProductI) {
		const {
			id,
			instrument_id,
			product_period_id,
			grand_chart_id,
			icon,
			name,
			description,
			extra_description,
			price,
			sale,
		} = data;

		this.id = id;
		this.instrument_id = instrument_id;
		this.product_period_id = product_period_id;
		this.grand_chart_id = grand_chart_id;
		this.icon = icon;
		this.name = name;
		this.description = description;
		this.extra_description = extra_description;
		this.price = price;
		this.sale = sale;
	}
}
