import * as React from "react";
import { observer } from "mobx-react";
import { Button } from "../../../../common";
import { useStores } from "../../../../../stores";
import { useNavigate } from "react-router-dom";

interface SignInProps {}

export const SignInButton = observer(({}: SignInProps) => {
	const { authStore } = useStores();
	const navigate = useNavigate();

	const handleOnShow = () => {
		navigate("/sign?tab=sign_in");
	};

	if (authStore.isAuth) return null;

	return (
		<>
			<Button onClick={handleOnShow}>Sign In</Button>
		</>
	);
});
