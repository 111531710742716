import { MethodRequest } from "../constants";
import FormData from "form-data";

class APIWrapper {
	public async request<T>(url: string = "", options: RequestInit = {}): Promise<T> {
		let defaultOptions = {
			method: MethodRequest.Get,
			headers: {
				Authorization: `Bearer ${localStorage.getItem(API.nameToken)}`,
				"x-requested-with": "XMLHttpRequest",
			},
		};

		options = { ...defaultOptions, ...options };

		const result = await fetch(`${this.getCorePath}${url}`, options);
		const response = await result.json();

		if (!result.ok) {
			throw response;
		}

		return response;
	}

	public getFormData(data: any, notEmpty = true): BodyInit {
		const formData = new FormData();

		Object.keys(data).forEach((key) => {
			if (data[key] || !notEmpty) {
				if (Array.isArray(data[key])) {
					data[key].forEach((item: any) => {
						if (item instanceof File) {
							formData.append(`${key}[]`, item);
						} else {
							formData.append(`${key}[]`, JSON.stringify(item));
						}
					});
				} else if (data[key] instanceof File) {
					formData.append(key, data[key]);
				} else if (typeof data[key] === "object") {
					formData.append(key, JSON.stringify(data[key]));
				} else if (typeof data[key] === "boolean") {
					formData.append(key, +data[key]);
				} else {
					formData.append(key, data[key]);
				}
			} else {
				console.log("empty data[key]", key, data[key]);
			}
		});

		// @ts-ignore
		return formData;
	}

	public get nameToken(): string {
		return process.env.REACT_APP_NAME_TOKEN || "";
	}

	public set saveToken(value: string) {
		localStorage.setItem(this.nameToken, value);
	}

	public removeToken() {
		localStorage.removeItem(this.nameToken);
	}

	public get getCorePath(): string {
		return process.env.REACT_APP_CORE_API_URL || "/not-set-api-path/";
	}

	public get getFilesPath(): string {
		return process.env.REACT_APP_CORE_FILES || "/not-set-files-path/";
	}

	public get wsPath(): string {
		return process.env.REACT_APP_WEBSOCKET_URL || "ws://not-set";
	}

	public get stripeKey(): string {
		return process.env.REACT_APP_STRIPE_KEY || "";
	}

	public get jwtToken() {
		return localStorage.getItem(API.nameToken) || "";
	}
}

export const API = new APIWrapper();
