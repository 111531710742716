import * as React from "react";
import { ListItemContainer, ListNameLesson, ListNumberLesson } from "./List.styled";
import { LessonStore } from "../../../../../stores/lessons/lesson.store";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { useNavigate } from "react-router-dom";
import { GrandChartAccess } from "../../../../../interfaces";
import { Lock } from "../../../../../components/ui/GrandChart/GroupLessonView/Lessons/Lessons.styled";
import { IconLock } from "../../../../../components/common/Icons";

interface ListItemProps {
	nameGroupLesson: string;
	lessonStore: LessonStore;
	access: GrandChartAccess;
	isFirst: boolean;
}

export const ListItem = observer(({ nameGroupLesson, lessonStore, access, isFirst }: ListItemProps) => {
	const { lessonStore: currentLesson } = useStores();
	const navigate = useNavigate();

	const handleOnClick = async (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();

		if ([GrandChartAccess.TRIAL].includes(access) && !isFirst) {
			return false;
		}

		navigate(`/cabinet/lessons/${lessonStore.uuid}`);
		currentLesson.list.close();
	};

	return (
		<ListItemContainer
			isActive={currentLesson.id === lessonStore.id}
			className={currentLesson.id === lessonStore.id ? "active" : ""}
			onClick={handleOnClick}
		>
			<ListNumberLesson color={lessonStore.color.color}>
				{lessonStore.sort}

				{access === GrandChartAccess.DENIED ||
					(access === GrandChartAccess.TRIAL && !isFirst && (
						<Lock style={{ right: -2, bottom: -5 }}>
							<IconLock />
						</Lock>
					))}
			</ListNumberLesson>
			<ListNameLesson>
				{nameGroupLesson} / {lessonStore.name}
			</ListNameLesson>
		</ListItemContainer>
	);
});
