export interface BurgerI {
	title: string;
	link: string | null;
	children?: BurgerI[];
}

export const BURGER_CONSTANTS: BurgerI[] = [
	{
		title: "Lesson Zero",
		link: null,
		children: [
			{
				title: "1. Types Of Guitars",
				link: "https://edu.musicabinet.com/iframe/lessonzero1",
			},
			{
				title: "2. The Correct Guitarist Position ",
				link: "https://edu.musicabinet.com/iframe/lessonzero2",
			},
			{
				title: "3. Hands Placement",
				link: "https://edu.musicabinet.com/iframe/lessonzero3",
			},
			{
				title: "4. Sound Production",
				link: "https://edu.musicabinet.com/iframe/lessonzero4",
			},
			{
				title: "5. Acoustic Guitar",
				link: "https://edu.musicabinet.com/iframe/lessonzero5",
			},
			{
				title: "6. Electric Guitar",
				link: "https://edu.musicabinet.com/iframe/lessonzero6",
			},
			{
				title: "7. Notes",
				link: "https://edu.musicabinet.com/iframe/lessonzero13",
			},
			{
				title: "8. Open String Exercises",
				link: "https://edu.musicabinet.com/iframe/lessonzero7",
			},
			{
				title: "9. Diatonic Scale Exercises",
				link: "https://edu.musicabinet.com/iframe/lessonzero8",
			},
			{
				title: "10. Chromatic Exercises",
				link: "https://edu.musicabinet.com/iframe/lessonzero9",
			},
			{
				title: "11. Double Sounds Exercises",
				link: "https://edu.musicabinet.com/iframe/lessonzero10",
			},
			{
				title: "12. Arpeggio Exercises",
				link: "https://edu.musicabinet.com/iframe/lessonzero11",
			},
			{
				title: "13. Chords Exercises",
				link: "https://edu.musicabinet.com/iframe/lessonzero12",
			},
		],
	},
	{
		title: "Circle of Fifths",
		link: "https://edu.musicabinet.com/iframe/circleoffifths",
	},
	{
		title: "Fretboard (ABC)",
		link: "https://edu.musicabinet.com/iframe/fretboardabc",
	},
	{
		title: "Fretboard (do re mi)",
		link: "https://edu.musicabinet.com/iframe/fretboarddoremi",
	},
];
