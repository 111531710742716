import * as React from "react";
import { ScoresContainer, ScoresSubTitleBlock, ScoresTitleBlock } from "./Scores.styled";
import { ScoresHeader } from "./ScoresHeader";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ScoreItem } from "./ScoreItem";
import { ScoreView } from "./ScoreView/ScoreView";

interface ScoresProps {}

export const Scores = observer(({}: ScoresProps) => {
	const { lessonStore, viewerStore, audioPlayerStore } = useStores();
	const { name, items, sub_title } = lessonStore.scores.currentScore;

	const handleOnShow = (idx: number, orderItem: number) => {
		viewerStore.setList(lessonStore.scores.currentScore);
		viewerStore.setChooseItemId(idx);

		// НАстройка для треков
		lessonStore.scores.currentScore.setIsView(true);
		lessonStore.scores.currentScore.setViewScoreIndex(idx);
		audioPlayerStore.setSelectedLibrary(lessonStore.scores.currentScore.firstLibrary);

		viewerStore.modal.open();
	};

	return (
		<>
			<ScoresContainer>
				<ScoresHeader />

				<ScoresTitleBlock>{name}</ScoresTitleBlock>
				{sub_title && <ScoresSubTitleBlock>{sub_title}</ScoresSubTitleBlock>}

				{items.map((scoreItem, idx) => {
					return <ScoreItem key={scoreItem.id} scoreItem={scoreItem} order={idx} onClick={handleOnShow} />;
				})}
			</ScoresContainer>

			<ScoreView show={lessonStore.scores.scoreView.isShow} />
		</>
	);
});
