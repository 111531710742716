import * as React from "react";
import { VideoBlockContainer } from "./VideoBlock.styled";

export const VideoBlock = () => {
	return (
		<VideoBlockContainer>
			<div style={{ padding: "28%", position: "relative" }}>
				<iframe
					src="https://player.vimeo.com/video/787346198?h=ea2887b5c1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
					allow="autoplay; fullscreen; picture-in-picture"
					allowFullScreen
					style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
					title="Welcome Video"
				></iframe>
			</div>
			<script src="https://player.vimeo.com/api/player.js"></script>
		</VideoBlockContainer>
	);
};
