import * as React from "react";
import { Tutorials } from "../../components";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useEffect } from "react";
import { HEADER_TYPE } from "../../constants";

export const TutorialsPage = observer(() => {
	const { settingsStore } = useStores();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
	}, []);

	return <Tutorials />;
});
