import * as React from "react";
import { Button } from "../Button/Button";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { SETTINGS } from "../../../constants";
import { useNavigate } from "react-router-dom";

interface StartTrialProps {}

export const StartTrial = observer(({}: StartTrialProps) => {
	const { authStore } = useStores();
	const navigate = useNavigate();

	const getTitle = () => {
		return authStore.isAuth ? `Continue learning` : `Start FREE trial`;
	};

	const handleOnClick = () => {
		if (authStore.isAuth) {
			const lastUUID = localStorage.getItem(SETTINGS.UUID);

			if (lastUUID) {
				navigate(`/cabinet/lessons/${lastUUID}`);
			} else {
				navigate("/cabinet/my-subscriptions");
			}
		} else {
			authStore.signInForm.modal.open();
		}
	};

	return <Button onClick={handleOnClick}>{getTitle()}</Button>;
});
