import * as React from "react";
import { Container, Description } from "./NeedSubscription.styled";

export const NeedSubscription = () => {
	return (
		<Container>
			<Description>In order to access these learning materials you need to purchase or prolong the plan</Description>
		</Container>
	);
};
