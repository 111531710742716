import { action, makeObservable, observable } from "mobx";
import { Track } from "../../interfaces/track/track.interface";
import { LibraryTrackTypeId } from "../../constants";

export class TrackStore implements Track {
	@observable id = 0;
	@observable library_id = 0;
	@observable library_track_type_id: LibraryTrackTypeId = LibraryTrackTypeId.TRACK;
	@observable name = "";
	@observable path = "";
	@observable origin_name = "";

	constructor(initialData: Track | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: Track) {
		const { id, library_id, library_track_type_id, name, path, origin_name } = data;

		this.id = id;
		this.library_id = library_id;
		this.library_track_type_id = library_track_type_id;
		this.name = name;
		this.path = path;
		this.origin_name = origin_name;
	}
}
