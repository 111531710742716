import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const FifthBlockContainer = styled.div`
	padding: 100px 0;
	background: ${COLORS.Dark};
`;

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	width: 960px;
	margin: 0 auto;
`;

export const Paragraph = styled.p`
	width: 80%;
	position: relative;
	${fontMixins(22)};
	line-height: 35px;
	margin: 50px 0;
	padding: 0;
	color: ${COLORS.White};
`;

export const GrandChartContainer = styled.div`
	display: flex;
	justify-content: center;
	overflow-x: auto;
`;

export const List = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 15px;
	margin: 45px 0 0;
`;

export const Item = styled.div`
	padding-left: 40px;
	display: grid;
`;

export const Count = styled.div`
	${fontMixins(42, 700)};
	line-height: 53px;
	margin-bottom: 80px;
`;

export const Description = styled.div`
	line-height: 26px;
	color: ${COLORS.White};
	margin-top: auto;
`;

export const ContainerImage = styled.div`
	display: flex;
	justify-content: center;
	max-width: 70%;
	margin: 0 auto;

	img {
		width: 100%;
	}
`;
