import { GoodStore } from "../../../stores/goods";
import {
	PackActions,
	PackCoverContainer,
	PackItem,
	PackPay,
	PackPicture,
	PackPreview,
	PackPreviewSource,
	PackPrice,
	PackTitle,
} from "./Goods.styled";
import { IconCard } from "../../../components/ui/Other/Pricing/Products/icons";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { useNavigate } from "react-router-dom";
import { gtmEvent } from "../../../helpers";

interface GoodPackProps {
	goodStore: GoodStore;
}

export const GoodPack = observer(({ goodStore }: GoodPackProps) => {
	const { authStore, stripeStore } = useStores();
	const navigation = useNavigate();

	const handleOnStripe = async () => {
		if (!authStore.isAuth) {
			navigation("/sign?tab=sign_in");
			return false;
		}

		await stripeStore.redirectPaymentLink({ good_id: goodStore.id, price_id: goodStore.stripe_default_price });

		gtmEvent({
			event: "begin_checkout",
			ecommerce: {
				items: [
					{
						item_name: goodStore.name,
						item_id: goodStore.id,
						price: goodStore.price / 100,
						item_brand: "Brand Musicabinet",
						item_category: goodStore.name,
						quantity: "1",
					},
				],
			},
		});
	};

	return (
		<PackItem>
			<PackCoverContainer>
				{goodStore.fullPathCover && (
					<PackPicture>
						<PackPreviewSource srcSet={goodStore.fullPathCoverWebp} type={"image/webp"} />
						<PackPreview src={goodStore.fullPathCover} />
					</PackPicture>
				)}
			</PackCoverContainer>

			<PackPrice>${goodStore.priceFormatted}</PackPrice>
			<PackTitle>{goodStore.name.replace("Yearly Plan", "").replace("Monthly Plan", "")}</PackTitle>

			<PackActions>
				<PackPay onClick={handleOnStripe}>
					<IconCard /> Pay by Card
				</PackPay>
			</PackActions>
		</PackItem>
	);
});
