import styled, { css } from "styled-components";
import { COLORS } from "../../../styles";

interface RadioCheckContainerProps {
	checked: boolean;
}

export const RadioCheckContainer = styled.div<RadioCheckContainerProps>`
	position: relative;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	border: 1px solid ${COLORS.MidGray};
	background: ${COLORS.White};

	${(props) =>
		props.checked &&
		css`
			&:after {
				top: 3px;
				left: 3px;
				position: absolute;
				content: "";
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: ${COLORS.Dark_Gray};
			}
		`}
`;
