import * as React from "react";
import { Container, ContentBlockArrowNext, ContentBlockArrowPrev, Title } from "./ContentBlock.styled";

interface ContentBlockProps {
	title?: string | React.ReactNode;
	children?: React.ReactNode;
	controls?: boolean;
	onNext?: () => void;
	onPrev?: () => void;
}

export const ContentBlock = ({ title = "", controls = false, children = null, onNext, onPrev }: ContentBlockProps) => {
	return (
		<Container>
			{controls && <ContentBlockArrowPrev onClick={onPrev} />}
			{controls && <ContentBlockArrowNext onClick={onNext} />}

			{title && <Title>{title}</Title>}
			{children}
		</Container>
	);
};
