import { ModalPortal } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import {
	Body,
	ButtonClose,
	ButtonGrandChart,
	Container,
	Description,
	Divider,
	Information,
	InformationPlan,
	Plan,
	Title,
	TitlePlan,
	VideoBlock,
} from "./DetailGrandChartModal.styled";
import { getIconInstrument } from "../../../../helpers";
import { IconCloseButtonCircle, IconGrandChart } from "../../../common/Icons";
import { Status } from "../../../../pages/MySubscriptionsPage/GrandChartItem/Status/Status";
import { MouseEvent } from "react";

interface DetailGrandChartModalProps {}

export const DetailGrandChartModal = observer(({}: DetailGrandChartModalProps) => {
	const { grandCharts, statisticsStore } = useStores();
	const { selected, detailModal } = grandCharts;

	const handleOnClose = () => {
		detailModal.close();
	};

	const handleOnGrandChart = async (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
		e.stopPropagation();
		e.preventDefault();

		grandCharts.setSelected(selected);
		grandCharts.modal.open();
		await statisticsStore.getByGrandChart(selected.id);
		await grandCharts.selected.get();
	};

	return (
		<>
			<ModalPortal modalStore={detailModal}>
				<Container theme={selected.service.slug}>
					<Title>
						<span style={{ flex: 1 }}>
							{getIconInstrument(selected.instrument.slug)} {selected.title}
						</span>

						<ButtonGrandChart onClick={handleOnGrandChart}>
							<IconGrandChart /> <span>Grand Chart</span>
						</ButtonGrandChart>

						<ButtonClose onClick={handleOnClose}>
							<IconCloseButtonCircle />
						</ButtonClose>
					</Title>

					<Divider />

					<Body>
						<Information>
							{selected.vimeoBlock && (
								<>
									<VideoBlock dangerouslySetInnerHTML={{ __html: selected.vimeoBlock.html }} />
									<Description dangerouslySetInnerHTML={{ __html: selected.description }} />
								</>
							)}
						</Information>

						<Plan>
							<TitlePlan>Your current plan</TitlePlan>

							<InformationPlan>
								<Status grandChart={selected} />
							</InformationPlan>
						</Plan>
					</Body>
				</Container>
			</ModalPortal>

			{/*<Modal modalStore={detailModal} isShow={detailModal.isShow} transparent hideClose>

			</Modal>*/}
		</>
	);
});
