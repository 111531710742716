import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div`
	display: flex;
	height: 29px;
	background: ${COLORS.Red};
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 500;

	.arrow-left {
		transform: rotate(180deg);
	}
`;

export const Body = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${COLORS.White};
	${fontMixins(18, 300)};
	width: 265px;
`;
