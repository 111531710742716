import * as React from "react";
import {
	Actions,
	Container,
	CountDay,
	Descriptions,
	MainMessage,
	ProgressLine,
	ProgressSuccess,
} from "./TrialNotification.styled";
import { createLabel } from "../../../../helpers";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Button } from "../../../common";
import { ButtonTheme, TRIAL_VERSION } from "../../../../constants";
import { LocalStorage } from "../../../../core";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface TrialNotificationProps {}

export const TrialNotification = observer(({}: TrialNotificationProps) => {
	const { authStore } = useStores();
	const {
		userStore: { trial_version: currentTrialStore },
	} = authStore;
	const [isShowNotify, setIsShowNotify] = useState(false);

	const navigation = useNavigate();

	const getTitle = () => {
		if (currentTrialStore.daysLeft > 0) {
			return `${currentTrialStore.daysLeft} ${createLabel(currentTrialStore.daysLeft, ["day", "days", "days"])}`;
		} else {
			return `Your FREE TRIAL has ended…`;
		}
	};

	const getMessage = () => {
		if (currentTrialStore.days_remain > 1) {
			return `Don’t miss a chance to get a 50% discount on our subscription plans`;
		} else if (currentTrialStore.days_remain === 1) {
			return `Don’t miss a chance to get a 50% discount on our subscription plans, if you subscribe right now`;
		} else {
			return `This is a last chance to get a 50% discount on our subscription plans, if you subscribe until the end of the day!`;
		}
	};

	const getShow = () => {
		let last_date: string | null = LocalStorage.get(TRIAL_VERSION.LAST_DATE);
		const current_date: Dayjs = dayjs();

		// Если не установлен флаг проставляем его
		if (!last_date) {
			LocalStorage.set(TRIAL_VERSION.LAST_DATE, current_date.format("DD-MM-YYYY"));
			last_date = current_date.format("DD-MM-YYYY");
		}

		const isFirstDay = current_date.diff(currentTrialStore.date_start, "day") === 0;

		if (isFirstDay) {
			return false;
		}

		if (last_date) {
			// Парсинг даты
			const [day, month, year]: Array<string> = last_date.split("-");
			const parseLastDate = dayjs(Date.parse(`${year}-${month}-${day}`));

			return current_date.diff(parseLastDate, "day") !== 0 && !isShowNotify;
		}

		return false;
	};

	const handleOnClose = () => {
		currentTrialStore.close();
		LocalStorage.set(TRIAL_VERSION.LAST_DATE, dayjs().format("DD-MM-YYYY"));
		setIsShowNotify(true);
	};

	const handleGoToPrice = () => {
		currentTrialStore.close();
		LocalStorage.set(TRIAL_VERSION.LAST_DATE, dayjs().format("DD-MM-YYYY"));
		setIsShowNotify(true);
		navigation("/pricing");
	};

	return (
		<Container isShow={authStore.isAuth && getShow()} isFinish={currentTrialStore.daysLeft === 0}>
			<CountDay>{getTitle()}</CountDay>

			<MainMessage>left until the end of your FREE TRIAL</MainMessage>

			<ProgressLine>
				<ProgressSuccess style={{ width: `${currentTrialStore.passedPercent}%` }} />
			</ProgressLine>

			<Descriptions>{getMessage()}</Descriptions>

			<Actions>
				<Button theme={ButtonTheme.GRAY} onClick={handleOnClose}>
					Cancel
				</Button>
				<Button onClick={handleGoToPrice}>Get 50% OFF</Button>
			</Actions>
		</Container>
	);
});
