import * as React from "react";
import { ScoreImageBlock, ScoresItemContainer, ScoreTextBlock } from "./Scores.styled";
import { ScoreItemStore } from "../../../../stores/scores/score-item.store";
import { observer } from "mobx-react";

interface ScoreItemProps {
	scoreItem: ScoreItemStore;
	order: number;
	onClick: (idx: number, orderItem: number) => void;
}

export const ScoreItem = observer(({ scoreItem, order, onClick }: ScoreItemProps) => {
	if (scoreItem.isTypeImage) {
		return (
			<ScoreImageBlock
				src={scoreItem.content.imagePath}
				onClick={() => onClick(scoreItem.id, order)}
				loading={"lazy"}
				onDragStart={(e) => e.preventDefault()}
				draggable={false}
			/>
		);
	}

	if (scoreItem.isTypeTitle) {
		return (
			<ScoreTextBlock style={{ textAlign: scoreItem.content.align as "left" | "right" | "center" }}>
				{scoreItem.content.title}
			</ScoreTextBlock>
		);
	}

	return <ScoresItemContainer>item</ScoresItemContainer>;
});
