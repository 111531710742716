import { action, computed, makeObservable, observable } from "mobx";
import { DayJsNull, DurationPurchase, NumberNull, StringNull, UserPurchase } from "../../interfaces";
import dayjs from "dayjs";
import { API } from "../../core";
import { MethodRequest } from "../../constants";
import { CoreResponse } from "../../response";

export class UserPurchaseStore implements UserPurchase {
	@observable id = 0;
	@observable user_id = 0;
	@observable good_id = 0;
	@observable grand_chart_id = 0;
	@observable session_id: StringNull = null;
	@observable amount_total: NumberNull = null;
	@observable mode = "";
	@observable customer_id: StringNull = null;
	@observable email = "";
	@observable full_name = "";
	@observable payment_status: StringNull = null;
	@observable payment_method_types: StringNull = null;
	@observable subscription_id: StringNull = null;
	@observable period_start = dayjs();
	@observable period_end: DayJsNull = null;
	@observable current_status = "";
	@observable duration: DurationPurchase = DurationPurchase.Monthly;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable good_name = "";
	@observable token = "";
	@observable uniting_ids: Array<number> = [];

	constructor(initialData: UserPurchase | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	async cancelSubscribe() {
		try {
			const { data } = await API.request<CoreResponse<UserPurchase>>(`user-purchases/${this.id}`, {
				method: MethodRequest.Delete,
			});

			this.fillStore(data);
		} catch (e) {
			console.error(`Error in method cancelSubscribe : `, e);
		}
	}

	@computed
	get finalDate() {
		if (this.duration === DurationPurchase.Monthly) {
			// Получаем текущий месяц и прибавляем еще месяц
			const countMonth = dayjs().diff(this.period_start, "month") + 1;
			return dayjs(this.period_start).add(countMonth, "month").format("DD MMM YYYY");
		}

		if (this.duration === DurationPurchase.Yearly) {
			// Получаем текущий год и прибавляем еще год
			const countYears = dayjs().diff(this.period_start, "year") + 1;
			return dayjs(this.period_start).add(countYears, "year").format("DD MMM YYYY");
		}

		return dayjs().format("DD MMM YYYY");
	}

	@computed
	get is_expired() {
		if (!this.period_end) {
			return false;
		}

		return this.period_end && dayjs().isAfter(this.period_end);
	}

	@action
	fillStore(data: UserPurchase) {
		const {
			id,
			user_id,
			good_id,
			grand_chart_id,
			session_id,
			amount_total,
			mode,
			customer_id,
			email,
			full_name,
			payment_status,
			payment_method_types,
			subscription_id,
			period_start,
			period_end,
			current_status,
			duration,
			created_at,
			updated_at,
			good_name,
			token,
			uniting_ids,
		} = data;

		this.id = id;
		this.user_id = user_id;
		this.good_id = good_id;
		this.grand_chart_id = grand_chart_id;
		this.session_id = session_id;
		this.amount_total = amount_total;
		this.mode = mode;
		this.customer_id = customer_id;
		this.email = email;
		this.full_name = full_name;
		this.payment_status = payment_status;
		this.payment_method_types = payment_method_types;
		this.subscription_id = subscription_id;
		this.period_start = dayjs(period_start);
		this.period_end = period_end ? dayjs(period_end) : null;
		this.current_status = current_status;
		this.duration = duration;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.good_name = good_name;
		this.token = token;
		this.uniting_ids = uniting_ids || [];
	}
}
