import { GroupLessonContainer, GroupLessonCurrentName } from "./GroupLesson.styled";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";

interface GroupLessonProps {
	grandChartMyDayStore: GrandChartMyDayStore;
}

export const GroupLesson = ({ grandChartMyDayStore }: GroupLessonProps) => {
	return (
		<GroupLessonContainer>
			Lesson Group :{" "}
			<GroupLessonCurrentName>{grandChartMyDayStore.lastLessonData?.name_group_lesson}</GroupLessonCurrentName>
		</GroupLessonContainer>
	);
};
