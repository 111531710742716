import { Container, Part } from "./LeftSide.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";

interface LeftSideProps {}

export const LeftSide = observer(({}: LeftSideProps) => {
	const { aiStore, lessonStore } = useStores();

	return (
		<Container>
			<Part theme={lessonStore.currentService}>Your Tempo</Part>
			<Part>
				Sample Tempo <br />
				{aiStore.chosenBPM} bpm
			</Part>
			<Part>Notes</Part>
		</Container>
	);
});
