import * as React from "react";
import {
	Actions,
	Description,
	Logotype,
	NextLessonContainer,
	Progress,
	ProgressLine,
	Timer,
} from "./NextLesson.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { getIconInstrument } from "../../../helpers";
import { useEffect, useState } from "react";
import { Button } from "../Button/Button";
import { COLORS, getColorByService } from "../../../styles";
import { useNavigate } from "react-router-dom";

interface NextLessonProps {}

export const NextLesson = observer(({}: NextLessonProps) => {
	const { lessonStore, nextLessonStore, lessonProgressStore } = useStores();
	const [time, setTime] = useState({
		minute: Math.floor((nextLessonStore.totalSeconds % 3600) / 60)
			.toString()
			.padStart(2, "0"),
		second: Math.floor(nextLessonStore.totalSeconds % 60)
			.toString()
			.padStart(2, "0"),
		percent: 0,
	});

	const navigate = useNavigate();

	useEffect(() => {
		let interval = 0;

		if (nextLessonStore.isShow) {
			let duration = nextLessonStore.totalSeconds;
			let endTime = new Date();
			endTime.setSeconds(endTime.getSeconds() + duration);
			let min = 0;
			let sec = duration;
			let totalSecond = 0;

			interval = window.setInterval(() => {
				sec--;
				if (sec === -1) {
					sec = 59;
					min--;
				}

				if (min === 0 && sec === 0) {
					clearInterval(interval);
				}

				totalSecond++;

				setTime({
					minute: Math.floor(((nextLessonStore.totalSeconds - totalSecond) % 3600) / 60)
						.toString()
						.padStart(2, "0"),
					second: Math.floor((nextLessonStore.totalSeconds - totalSecond) % 60)
						.toString()
						.padStart(2, "0"),
					percent: parseFloat(((totalSecond * 100) / 600).toFixed(2)),
				});
			}, 1000);
		}

		return () => clearInterval(interval);
	}, [nextLessonStore.isShow]);

	let formatNumber = (num: number) => {
		return num.toLocaleString("en-US", {
			minimumIntegerDigits: 2,
			useGrouping: false,
		});
	};

	const handleOnNextModule = async () => {
		const lesson_uuid = lessonProgressStore.next();
		await lessonStore.getByUUID(lesson_uuid);
		navigate(`/cabinet/lessons/${lesson_uuid}`);
		nextLessonStore.close();
	};

	const getPercent = (): number => {
		return 100 - ((parseInt(time.minute) * 60 + parseInt(time.second)) * 100) / nextLessonStore.totalSeconds;
	};

	if (!nextLessonStore.isShow && nextLessonStore.totalSeconds !== 0) {
		return null;
	}

	return (
		<NextLessonContainer isShow={nextLessonStore.isShow} service={lessonStore.currentService}>
			<Logotype>{getIconInstrument(lessonStore.currentInstrument)}</Logotype>

			<Description>We recommend to take a break and move to the next module</Description>

			<Timer>
				{time.minute}:{time.second}
			</Timer>

			<Progress>
				<ProgressLine style={{ width: `${getPercent()}%` }} />
			</Progress>

			<Actions>
				<Button
					style={{ background: COLORS.Dark_Gray }}
					onClick={() => {
						nextLessonStore.setIsView(true);
						nextLessonStore.close();
					}}
				>
					Stay Here
				</Button>
				<Button style={{ background: getColorByService(lessonStore.currentService) }} onClick={handleOnNextModule}>
					Next Module
				</Button>
			</Actions>
		</NextLessonContainer>
	);
});
