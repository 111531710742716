import { createGlobalStyle } from "styled-components";
import { COLORS } from "./colors.styled";

export const GlobalStyled = createGlobalStyle`
  @font-face {
    font-family: 'FuturaDemiC';
    src: url('/fonts/futura/FuturaDemiC.eot');
    src: local('Futura Demi Italic Cyrillic'), local('FuturaDemiC-Italic');
    src: url('/fonts/futura/FuturaDemiC.eot?#iefix') format('embedded-opentype');
    src: url('/fonts/futura/FuturaDemiC.woff') format('woff');
    src: url('/fonts/futura/FuturaDemiC.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'FuturaMediumC';
    src: url('/fonts/futura/FuturaMediumC.eot');
    src: local('Futura Medium Cyrillic'), local('FuturaMediumC');
    src: url('/fonts/futura/FuturaMediumC.eot?#iefix') format('embedded-opentype');
    src: url('/fonts/futura/FuturaMediumC.woff') format('woff');
    src: url('/fonts/futura/FuturaMediumC.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  html {
    height: 100vh;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
  }

  body {
    margin: 0;
    background: ${COLORS.White};
    color: ${COLORS.Dark_Gray};
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
  }

  #mc_app {
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  *:focus-visible {
    outline: none;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .overflow-hidden-payment {
    overflow: hidden;
  }

  body {
  }

  strong {
    font-weight: 600;
  }

`;
