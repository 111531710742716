import * as React from "react";
import { MouseEvent, useState } from "react";
import { MenuListItem, MenuListLink } from "./Menu.styled";
import { MenuI } from "../../../../../interfaces/menu/menu.interface";
import { DropDown } from "./DropDown/DropDown";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { useNavigate } from "react-router-dom";

interface MenuItemProps extends MenuI {}

export const MenuItem = observer(({ name, link, type, newTab, children = [] }: MenuItemProps) => {
	const { authStore } = useStores();
	const navigation = useNavigate();

	const [isShow, setIsShow] = useState(false);

	const handleOnEnter = () => setIsShow(true);

	const handleOnLeave = () => setIsShow(false);

	// to={link} target={authStore.isAuth && newTab ? "_blank" : "_self"}
	const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
		if (children?.length > 0) {
			return false;
		}

		if (link === "/pricing") {
			if (authStore.isAuth) {
				window.open(`${link}`, "_blank");
			} else {
				navigation(link);
			}
		} else {
			navigation(link);
		}
	};

	return (
		<MenuListItem type={type} onMouseEnter={handleOnEnter} onMouseLeave={handleOnLeave}>
			<MenuListLink onClick={handleOnClick} type={link}>
				{name}
			</MenuListLink>

			{children?.length > 0 && <DropDown isShow={isShow} items={children} />}
		</MenuListItem>
	);
});
