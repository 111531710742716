import styled from "styled-components";

export const PieContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
	width: 96px;
	height: 96px;
`;

export const ClickContainer = styled.div`
	position: absolute;
	top: 20px;
	left: 5px;
	z-index: 300;
	width: 85px;
	height: 85px;
	background: transparent;
`;
