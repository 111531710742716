import { action, makeObservable, observable } from "mobx";
import { Notification } from "../../interfaces";
import { NOTIFICATION_TYPE } from "../../constants";
import { v4 as uuidV4 } from "uuid";
import { NotificationsStore } from "./notifications.store";

export class NotificationStore implements Notification {
	@observable id = "";
	@observable type = NOTIFICATION_TYPE.SUCCESS;
	@observable title = "";
	@observable message = "";

	clearTimeOut = 0;

	public notificationsStore: NotificationsStore;

	constructor(initialData: Notification | null, notificationsStore: NotificationsStore) {
		makeObservable(this);

		this.notificationsStore = notificationsStore;

		if (initialData) {
			this.fillStore(initialData);
		}

		this.autoClose();
	}

	@action.bound
	autoClose() {
		this.clearTimeOut = window.setTimeout(() => {
			this.delete();
		}, 4000);
	}

	@action.bound
	delete() {
		this.notificationsStore.deleteById(this.id);
		clearTimeout(this.clearTimeOut);
	}

	@action
	fillStore(data: Notification) {
		const { type, title, message } = data;

		this.id = uuidV4();
		this.type = type ?? NOTIFICATION_TYPE.SUCCESS;
		this.title = title;
		this.message = message ?? "";
	}
}
