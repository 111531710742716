import { action, makeObservable, observable } from "mobx";
import { PricePlan } from "../../interfaces";
import { PriceDuration } from "../../constants";

export class PricePlanStore implements PricePlan {
	@observable id = 0;
	@observable duration_id = PriceDuration.Monthly;
	@observable price_id = 0;
	@observable default_discount = 0;

	constructor(initialData: PricePlan | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: PricePlan) {
		const { id, duration_id, price_id, default_discount } = data;

		this.id = id;
		this.duration_id = duration_id;
		this.price_id = price_id;
		this.default_discount = default_discount;
	}
}
