export enum ServiceName {
	School = "school",
	College = "college",
	University = "university",
	Packs = "packs",
	PreBeginner = "pre_school",
	PreAdvanced = "pre_college",
	PreProfessional = "pre_university",
	SkillBundles = "skill_bundles",
}

export const mappingService = {
	[ServiceName.School]: "Beginner",
	[ServiceName.College]: "Advanced",
	[ServiceName.University]: "Professional",
	[ServiceName.Packs]: "Packs",
	[ServiceName.PreBeginner]: "Pre-School",
	[ServiceName.PreAdvanced]: "Pre-College",
	[ServiceName.PreProfessional]: "Pre-University",
	[ServiceName.SkillBundles]: "Skill Tracks",
};
