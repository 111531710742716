import { DurationStore } from "../../../stores/durations";
import { Discount, Item } from "./Durations.styled";

interface DurationItemProps {
	duration: DurationStore;
	isActive: boolean;
	onClick: (id: number) => void;
}

export const DurationItem = ({ duration, isActive, onClick }: DurationItemProps) => {
	return (
		<Item isActive={isActive} onClick={() => onClick(duration.id)}>
			{duration.name}

			{duration.name === "Yearly" && <Discount>-50%</Discount>}
		</Item>
	);
};
