import * as React from "react";
import {
	AudioPlayerBackIcon,
	AudioPlayerBodyBlock,
	AudioPlayerBreak,
	AudioPlayerPauseButton,
	AudioPlayerPlayButton,
	AudioPlayerPlayIcon,
	AudioPlayerProgressLine,
} from "./Body.styled";
import { observer } from "mobx-react";
import { ReactComponent as PrevTrackIcon } from "./prev-track.svg";
import { ReactComponent as PlayIcon } from "./play.svg";
import { ChangeEvent } from "react";
import { useStores } from "../../../../../stores";

interface BodyProps {}

export const Body = observer(({}: BodyProps) => {
	const { audioPlayerStore } = useStores();

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		console.log(e.target.value);
	};

	const handleOnPlay = async () => {
		audioPlayerStore.isPlay ? audioPlayerStore.onStop() : audioPlayerStore.onPlay();
	};

	return (
		<AudioPlayerBodyBlock>
			<AudioPlayerBackIcon onClick={audioPlayerStore.onBack}>
				<PrevTrackIcon />
			</AudioPlayerBackIcon>

			<AudioPlayerPlayIcon>
				{audioPlayerStore.isPlay ? (
					<AudioPlayerPauseButton onClick={handleOnPlay} />
				) : (
					<AudioPlayerPlayButton onClick={handleOnPlay}>
						<PlayIcon style={{ marginLeft: 4 }} />
					</AudioPlayerPlayButton>
				)}
			</AudioPlayerPlayIcon>

			<AudioPlayerBreak>Break</AudioPlayerBreak>

			<AudioPlayerProgressLine
				type="range"
				name="progress"
				step={0.1}
				max={100}
				min={0}
				value={audioPlayerStore.current_percent}
				onChange={handleOnChange}
			/>
		</AudioPlayerBodyBlock>
	);
});
