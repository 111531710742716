import { Icon, Item } from "./Instruments.styled";
import { ProductInstrumentStore } from "../../../stores/product-instruments/product-instrument.store";
import { getIconInstrument } from "../../../helpers";

interface InstrumentItemProps {
	isActive: boolean;
	productInstrument: ProductInstrumentStore;
	onClick: (instrument_id: number) => void;
}

export const InstrumentItem = ({ isActive, productInstrument, onClick }: InstrumentItemProps) => {
	return (
		<Item isActive={isActive} onClick={() => onClick(productInstrument.id)}>
			<Icon>{getIconInstrument(productInstrument.slug)}</Icon>
			{productInstrument.name}
		</Item>
	);
};
