import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`;

export const Period = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	${fontMixin({ size: 18, weight: 700 })};
	line-height: 22px;
`;

export const ProgressContainer = styled.div`
	position: relative;
	display: flex;
	height: 7px;
	background: ${COLORS.MidGray};
	border-radius: 10px;
	margin-bottom: 6px;
`;

export const ProgressLine = styled.div`
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 10px;
	width: 0%;
	height: 100%;
	background: ${COLORS.School};
`;

export const CounterDays = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	margin: 5px 0 0;
`;

export const Days = styled.div`
	${fontMixin({ size: 24, weight: 300 })};
	margin-bottom: 5px;
`;

export const DaysInformation = styled.div`
	${fontMixin({ size: 12 })};
	width: 65px;
	color: ${COLORS.Black};
`;

export const CounterPassed = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CounterRemain = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: right;
	text-align: right;

	${DaysInformation} {
		margin-left: auto;
	}

	${Days} {
		color: ${COLORS.Red} !important;
	}
`;

export const InfinitySymbol = styled.div`
	display: inline-flex;

	&:before {
		content: "\\221E";
		font-size: 40px;
		line-height: 24px;
	}
`;

export const ForeverBlock = styled.div`
	${fontMixin({ size: 18, weight: 800 })};
`;

export const CancelSubscription = styled.button`
	background: transparent;
	text-decoration: underline;
	${fontMixin({ size: 12 })};
	line-height: 15px;
	border: 0;
	margin: 15px 0 0;
	padding: 0;
	transition: all 0.2s;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
`;
