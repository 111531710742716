import * as React from "react";
import {
	Container,
	ContainerMessage,
	DescriptionError,
	OopsContainer,
	OopsText,
	StarsWrapper,
	TitleError,
} from "./NotFoundPage.styled";
import { useEffect } from "react";
import { HEADER_TYPE } from "../../constants";
import { observer } from "mobx-react";
import { useStores } from "../../stores";

interface NotFoundPageProps {}

export const NotFoundPage = observer(({}: NotFoundPageProps) => {
	const { settingsStore } = useStores();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
	}, []);

	return (
		<Container>
			<StarsWrapper />
			<OopsContainer />
			<OopsText>Oops!</OopsText>

			<ContainerMessage>
				<TitleError>
					404
					<br />
					Something went wrong...
				</TitleError>

				<DescriptionError>
					<p>It looks like the page you are looking for doesn't exist or there is an error on the server side.</p>
					<p>Contact us at support@musicabinet.com if you believe that this problem occurred on our side.</p>
				</DescriptionError>
			</ContainerMessage>
		</Container>
	);
});
