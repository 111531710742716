import { action, makeObservable, observable } from "mobx";
import { ToasterI, ToasterType } from "../../interfaces";
import { v4 as uuidv4 } from "uuid";
import { ToasterStore } from "./toaster.store";

export class ToasterItemStore implements ToasterI {
	@observable isShow = false;
	@observable id = "";

	@observable title = "";
	@observable type = ToasterType.DEFAULT;
	@observable message = "";

	private toasterStore = new ToasterStore();

	constructor(initialData: ToasterI | null, toasterStore: ToasterStore) {
		makeObservable(this);
		this.toasterStore = toasterStore;

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	generateID() {
		this.id = uuidv4();
	}

	@action.bound
	setIsShow(value: boolean) {
		this.isShow = value;
	}

	@action.bound
	remove() {}

	@action
	fillStore(data: ToasterI) {
		const { title, type, message } = data;

		this.generateID();
		this.title = title;
		this.type = type || ToasterType.DEFAULT;
		this.message = message || "";
	}
}
