import styled, { css, keyframes } from "styled-components";
import { BannerStore } from "../../../../stores/banners/banner.store";
import { COLORS, fontMixins } from "../../../../styles";
import { rgba } from "polished";

export const Container = styled.div`
	width: 100%;
	height: 180px;
	position: relative;
	margin: 0 auto 10px;
`;

const animation = keyframes`
  from {opacity: .4}
  to {opacity: 1}
`;

interface ItemSlideProps {
	banner: BannerStore;
}

export const ItemSlide = styled.div<ItemSlideProps>`
	position: relative;
	display: none;
	animation-name: ${animation};
	animation-duration: 1.5s;
	height: 160px;
	border-radius: 10px;
	padding: 20px 0 0 40px;
	flex-direction: column;
	cursor: pointer;

	${(props) => css`
		background: url("${props.banner.fullPathBannerImage}");

		${Button} {
			background: ${props.banner.text_button_color};
			color: ${COLORS.Dark_Gray};
		}
	`}
`;

export const Title = styled.div`
	${fontMixins(18, 700)};
	color: ${COLORS.Black};
	margin-bottom: 13px;
`;

export const Description = styled.div`
	max-width: 453px;
	${fontMixins(14, 400)};
	line-height: 18px;
	color: ${COLORS.Black};
`;

export const Button = styled.button`
	display: flex;
	position: absolute;
	right: 40px;
	bottom: 30px;
	padding: 14px 30px;
	border-radius: 10px;
	border: 0;
	${fontMixins(18, 400)};
	cursor: pointer;
`;

interface ArrowProps {
	isRight?: boolean;
}

export const Arrow = styled.button<ArrowProps>`
	position: absolute;
	top: 60px;
	width: 30px;
	height: 40px;
	border-radius: 0 10px 10px 0;
	border: 0;
	background: ${rgba("#4d4d4d", 0.25)};
	cursor: pointer;

	svg {
		margin: 4px 3px 0 0;
	}

	${(props) =>
		props.isRight &&
		css`
			right: 0;
			border-radius: 10px 0 0 10px;

			svg {
				margin: 4px 0 0 3px;
			}
		`}
`;

export const Dots = styled.div`
	display: flex;
	justify-content: center;
	column-gap: 20px;
	margin: 10px 0 0;
`;

interface DotProps {
	isActive: boolean;
}

export const Dot = styled.button<DotProps>`
	display: flex;
	width: 8px;
	height: 8px;
	background: ${COLORS.MidGray};
	border-radius: 50%;
	margin: 0;
	padding: 0;
	border: 0;
	cursor: pointer;

	${(props) =>
		props.isActive &&
		css`
			background: ${COLORS.Gold};
		`}
`;
