import * as React from "react";
import { useEffect } from "react";
import {
	Container,
	ContainerImage,
	Count,
	Description,
	FifthBlockContainer,
	Item,
	List,
	Paragraph,
} from "./FifthBlock.styled";
import { getColorByService } from "../../../styles";
import { ImagePng, Title } from "../../../components/common";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";

interface FifthBlockProps {}

export const FifthBlock = observer(({}: FifthBlockProps) => {
	const { pageStore, grandCharts } = useStores();

	useEffect(() => {
		(async () => {
			grandCharts.selected.setId(pageStore.grandChartId);
			await grandCharts.selected.get();
		})();
	}, [pageStore.currentServiceInstrument]);

	return (
		<FifthBlockContainer>
			<Container>
				<Title
					style={{
						fontSize: "42px",
						textAlign: "left",
						marginBottom: "50px",
						color: getColorByService(pageStore.service),
						width: "80%",
					}}
				>
					Grand Chart is the heart of our training system
				</Title>

				<Paragraph>
					It is a table through which you get instant access to any topic and any lesson. And most importantly - you get
					to see your learning progress in real time.
				</Paragraph>
			</Container>

			<ContainerImage>
				<ImagePng src={`/images/grand-charts/${pageStore.currentServiceInstrument}`} withRetina={false} />
			</ContainerImage>

			<Container>
				<List>
					{pageStore.aboutService.map((item, index) => {
						return (
							<Item style={{ borderLeft: `1px solid ${getColorByService(pageStore.service)}` }}>
								<Count style={{ color: getColorByService(pageStore.service) }}>
									{item.count}
									<br />
									{item.title}
								</Count>

								<Description>{item.description}</Description>
							</Item>
						);
					})}
				</List>
			</Container>
		</FifthBlockContainer>
	);
});
