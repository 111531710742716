import { GrandChartModuleStore } from "../../../../../stores/grand-charts/grand-chart-module.store";
import {
	ModuleArrow,
	ModuleItemBody,
	ModuleItemName,
	ModuleItemProgress,
	ModuleItemProgressPassed,
} from "./Modules.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(weekday);

interface ModuleItemProps {
	position: number;
	grand_chart_module: GrandChartModuleStore;
	grandChartMyDayStore: GrandChartMyDayStore;
	is_active: boolean;
}

export const ModuleItem = observer(
	({ grand_chart_module, position, is_active, grandChartMyDayStore }: ModuleItemProps) => {
		const { grandChartsMyDayStore } = useStores();
		const navigate = useNavigate();

		const getPercent = (margin = 0) => {
			let timeSystem = 25;

			if (grandChartMyDayStore.service.id === 1) {
				timeSystem = 20;
			} else if (grandChartMyDayStore.service.id === 2) {
				timeSystem = 30;
			} else if (grandChartMyDayStore.service.id === 3) {
				timeSystem = 40;
			}

			const passedTime =
				grandChartsMyDayStore.listProgress[`${grandChartMyDayStore.course_id}-${grand_chart_module.id}`];

			let totalPercent: number = (passedTime?.count_minute * 100) / timeSystem;
			totalPercent = totalPercent >= 100 ? 100 : totalPercent;

			if (totalPercent === 0) {
				return 0;
			}

			return totalPercent - margin;
		};

		const handleOnClick = () => {
			// Получаем урок от модуля
			const findKey = `${grandChartMyDayStore.id}-${grand_chart_module.id}`;

			if (grandChartsMyDayStore.listProgress[findKey]) {
				navigate(`/cabinet/lessons/${grandChartsMyDayStore.listProgress[findKey].lesson_uuid}`);
			}
		};

		return (
			<ModuleItemBody onClick={handleOnClick}>
				{is_active && (
					<ModuleArrow
						initial={{
							left: 0,
						}}
						animate={{
							left: `${getPercent(7)}%`,
						}}
					/>
				)}

				<ModuleItemProgress>
					<ModuleItemProgressPassed
						initial={{
							width: 0,
						}}
						animate={{
							width: `${getPercent(0)}%`,
						}}
					/>
				</ModuleItemProgress>
				<ModuleItemName>Module {position}</ModuleItemName>
			</ModuleItemBody>
		);
	},
);
