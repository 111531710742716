import { action, makeObservable, observable } from "mobx";
import { ScoreStore } from "./score.store";

export class ScoreViewStore {
	@observable list: ScoreStore[] = [];
	@observable isShow = false;

	@observable currentScore = 0;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setCurrent(value: number) {
		this.currentScore = value;
	}

	@action.bound
	open() {
		this.isShow = true;
	}

	@action.bound
	close() {
		this.isShow = false;
	}
}
