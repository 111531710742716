import { ModalPortal } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import * as React from "react";
import { useEffect } from "react";
import { IFrame } from "../../Other/ViewIrame/ViewIframe.styled";
import { Container } from "./WelcomeModal.styled";
import { USER_CONST } from "../../../../constants";

interface WelcomeModalProps {}

export const WelcomeModal = observer(({}: WelcomeModalProps) => {
	const { settingsStore } = useStores();

	useEffect(() => {
		const firstEnter = localStorage.getItem(USER_CONST.FIRST);

		if (!firstEnter) {
			settingsStore.welcomeModal.open();
			localStorage.setItem(USER_CONST.FIRST, "true");
		}
	}, []);

	return (
		<ModalPortal modalStore={settingsStore.welcomeModal} center>
			<Container>
				<IFrame src={"https://edu.musicabinet.com/iframe/tutorial/welcome1"} width={1000} height={777} />
			</Container>
		</ModalPortal>
	);
});
