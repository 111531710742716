import { action, computed, makeObservable, observable } from "mobx";

export class ModalStore {
	@observable isShow = false;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setShow(value: boolean) {
		this.isShow = value;
	}

	@action.bound
	close() {
		this.setShow(false);
	}

	@action.bound
	open() {
		this.setShow(true);
	}

	@computed
	get isClose() {
		return !this.isShow;
	}
}
