import styled from "styled-components";
import { fontMixins } from "../../../../styles";

export const Container = styled.div`
	${fontMixins(12, 400)};

	svg {
		display: flex;
	}
`;
