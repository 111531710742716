import * as React from "react";
import { SwitcherBody, SwitcherContainer, SwitcherLabel, SwitcherPick } from "./Switcher.styled";

interface SwitcherProps {
	checked: boolean;
	onChange: () => void;
}

export const Switcher = ({ checked, onChange }: SwitcherProps) => {
	return (
		<SwitcherContainer>
			<SwitcherLabel>Method</SwitcherLabel>
			<SwitcherBody className={`${checked ? "active" : ""}`} onClick={onChange}>
				<SwitcherPick />
			</SwitcherBody>
			<SwitcherLabel>Diagrams</SwitcherLabel>
		</SwitcherContainer>
	);
};
