import { action, computed, makeObservable, observable } from "mobx";
import { ChartI } from "../../interfaces";
import { ChartStore } from "./chart.store";

export class ChartsStore {
	@observable list: ChartStore[] = [];
	@observable currentNumber = 0;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ChartI[]) {
		this.list = (values || []).map((chart) => new ChartStore(chart));
	}

	@action.bound
	setCurrentNumber(value: number) {
		if (value < 0 || value > this.total - 1) {
			return false;
		}

		this.currentNumber = value;
	}

	@computed
	get currentChart(): ChartStore {
		return this.list[this.currentNumber] || new ChartStore(null);
	}

	@computed
	get total() {
		return this.list.length;
	}
}
