import { action, makeObservable, observable } from "mobx";
import { API } from "../../core";
import { CoreResponse } from "../../response";
import { Category } from "../../interfaces";
import { CategoryStore } from "./category.store";

export class CategoriesStore {
	@observable list: CategoryStore[] = [];
	@observable choose_id = 1;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setChooseId(value: number) {
		this.choose_id = value;
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<Category[]>>(`categories`);

			this.setList(data);
		} catch (e) {
			console.error(`Error in method getList: `, e);
		}
	}

	@action.bound
	setList(value: Category[]) {
		this.list = (value || []).map((value) => new CategoryStore(value));
	}
}
