import styled from "styled-components";
import { COLORS } from "../../../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 10px;
	justify-content: center;
	min-height: 60px;
`;

export const TextField = styled.div`
	text-align: center;
	color: ${COLORS.Dark_Gray};
`;
