import styled, { css } from "styled-components";
import { COLORS, phoneQuery } from "../../../../../styles";

export const Container = styled.div`
	display: grid;
	border-radius: 10px;
	background: ${COLORS.White};
	padding: 20px 30px;

	${phoneQuery(css`
		margin-inline: 15px;
	`)}
`;
