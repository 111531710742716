import { LessonCircle, LessonContainer, LessonName } from "./Lesson.styled";
import { CircleProgressBar } from "../../../../common";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";

interface LessonProps {
	grandChartMyDayStore: GrandChartMyDayStore;
}

export const Lesson = ({ grandChartMyDayStore }: LessonProps) => {
	const getPercentLessonPassed = () => {
		let timeSystem = 20;

		if (grandChartMyDayStore.service.id === 1) {
			timeSystem = 20;
		} else if (grandChartMyDayStore.service.id === 2) {
			timeSystem = 30;
		} else if (grandChartMyDayStore.service.id === 3) {
			timeSystem = 40;
		}

		const completeResult = Math.ceil((grandChartMyDayStore.last_lesson.total_progress_minute * 100) / timeSystem);

		return completeResult <= 100 ? completeResult : 100;
	};

	return (
		<LessonContainer>
			<LessonCircle>
				<CircleProgressBar strokeWidth={2} sqSize={40} percentage={0} payload={`${getPercentLessonPassed()}%`} />
			</LessonCircle>

			<LessonName>{grandChartMyDayStore.lastLessonData?.lesson_name}</LessonName>
		</LessonContainer>
	);
};
