import { action, makeObservable, observable } from "mobx";
import { NumberNull, UserNotification } from "../../interfaces";
import { userNotificationStatus } from "../../constants";

export class UserNotificationStore implements UserNotification {
	@observable id = 0;
	@observable service_id: NumberNull = 0;
	@observable instrument_id: NumberNull = 0;
	@observable lesson_id: NumberNull = 0;
	@observable score_item_id: NumberNull = 0;
	@observable chart_item_id: NumberNull = 0;
	@observable user_id: NumberNull = 0;
	@observable title = "";
	@observable content = "";
	@observable status = userNotificationStatus.Info;

	constructor(initialData: UserNotification | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: UserNotification) {
		const { id, service_id, lesson_id, score_item_id, chart_item_id, user_id, title, content, status } = data;

		this.id = id;
		this.service_id = service_id;
		this.lesson_id = lesson_id;
		this.score_item_id = score_item_id;
		this.chart_item_id = chart_item_id;
		this.user_id = user_id;
		this.title = title;
		this.content = content;
		this.status = status;
	}
}
