import styled from "styled-components";
import { COLORS } from "../../../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 121px;
	border: 1px solid ${COLORS.MidGray};
	padding: 20px 8px;
	border-radius: 10px;
	row-gap: 20px;
`;
