import * as React from "react";
import { ActionButton, ContentServiceContainer, Logotype, SubTitle, Title } from "./ContentService.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { ButtonStartTrial } from "../../../components/common";

export const ContentService = observer(() => {
	const { pageStore } = useStores();

	return (
		<ContentServiceContainer>
			<Logotype>
				<source
					srcSet={`/images/logotype/${pageStore.service}.webp, /images/logotype/${pageStore.service}@2x.webp 2x`}
					type="image/webp"
				/>
				<img
					alt={""}
					src={`/images/logotype/${pageStore.service}.png`}
					srcSet={`/images/logotype/${pageStore.service}@2x.png 2x`}
				/>
			</Logotype>

			<Title dangerouslySetInnerHTML={{ __html: pageStore.title }} />
			<SubTitle dangerouslySetInnerHTML={{ __html: pageStore.subTitle }} />

			<ActionButton>
				<ButtonStartTrial />
			</ActionButton>
		</ContentServiceContainer>
	);
});
