import { makeObservable, observable } from "mobx";
import { ModalStore } from "../common";

export class AccessLimitedStore {
	@observable modal = new ModalStore();

	constructor() {
		makeObservable(this);
	}
}
