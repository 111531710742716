import styled, { css } from "styled-components";
import { COLORS, getColorByService } from "../../../styles";
import { ServiceName } from "../../../constants";
import { ModuleItemBlock, NumberModule } from "./Modules/Modules.styled";
import { CourseItemBlock, NumberCourse } from "./Matrix/Courses/Courses.styled";
import {
	BackButton,
	HoursPassed,
	HoursTime,
	PercentGroupLesson,
	PercentProgressGroupLesson,
} from "./GroupLessonView/GroupLessonView.styled";
import {
	CircleMainBackground,
	CircleProgress,
	DataInside,
} from "../../common/CircleProgressBar/CircleProgressBar.styled";
import {
	GroupLessonItemBlock,
	GroupLessonItemCount,
	GroupLessonItemProgressLine,
} from "./Matrix/GroupLessons/GroupLessons.styled";
import { LessonItemWrapper } from "./GroupLessonView/Lessons/Lessons.styled";

interface ContainerI {
	theme: ServiceName;
}

export const Container = styled.div<ContainerI>`
	position: relative;
	display: grid;
	align-items: start;
	grid-template-columns: 170px 1fr;
	grid-template-rows: 86px repeat(auto-fill, 180px);
	grid-template-areas:
		"logotype modules"
		"matrix matrix";
	gap: 20px 10px;
	overflow-x: auto;
	overflow-y: hidden;
	background: ${COLORS.Light_Gray};
	border-radius: 10px;
	padding: 5px;
	min-width: 80vmin;

	${DataInside} {
		color: ${COLORS.White};
	}

	${(props) =>
		props.theme &&
		css`
			${GroupLessonItemProgressLine} {
				&:before {
					background: ${getColorByService(props.theme)};
				}
			}

			${NumberModule}, ${NumberCourse} {
				background: ${getColorByService(props.theme)};
			}

			${HoursPassed} > ${HoursTime} {
				color: ${getColorByService(props.theme)};
			}

			${PercentGroupLesson} {
				color: ${getColorByService(props.theme)};
			}

			${ModuleItemBlock}, ${CourseItemBlock} {
				&.active {
					background: ${getColorByService(props.theme)};

					${NumberModule}, ${NumberCourse} {
						background: ${COLORS.Dark_Gray};
					}
				}
			}

			${BackButton} {
				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}
				}
			}

			${CircleProgress} {
				stroke: ${getColorByService(props.theme)};
			}

			${CircleMainBackground} {
				fill: ${getColorByService(props.theme)};
			}
		`}

	${(props) => css`
		${GroupLessonItemBlock} {
			${GroupLessonItemCount} {
				background: ${getColorByService(props.theme)};
			}

			&.is_active {
				background: ${getColorByService(props.theme)};
				color: ${COLORS.White};

				${GroupLessonItemProgressLine} {
					&:after {
						background: ${COLORS.Dark_Gray} !important;
					}
				}

				${GroupLessonItemCount} {
					background: ${COLORS.White};
					color: ${getColorByService(props.theme)};
				}
			}
		}

		${PercentProgressGroupLesson} {
			&:after {
				background: ${getColorByService(props.theme)};
			}
		}

		${LessonItemWrapper} {
			&:hover {
				border-color: ${getColorByService(props.theme)};
			}
		}
	`}
`;
