import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 58px 76px 30px;
`;

export const Title = styled.div`
	display: flex;
	justify-content: center;
	flex: 1;
	${fontMixins(36, 700)};
	color: ${COLORS.Gold};
`;

export const NameProduct = styled.div`
	${fontMixins(28, 700)};
	display: flex;
	align-items: center;
	flex: 1;
	justify-content: center;
	padding: 45px 0;
	text-transform: uppercase;
	line-height: 36px;
`;

export const Description = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	${fontMixins(18)};
	row-gap: 5px;
	margin-bottom: 30px;

	span {
		font-weight: 700;
	}
`;

export const ActionsButton = styled.div`
	display: flex;
	justify-content: space-between;

	button {
		width: 200px;
		font-weight: 700;
	}
`;
