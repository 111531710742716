import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Button } from "../Button/Button";
import { ButtonHTMLAttributes } from "react";
import { SETTINGS } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";

interface ButtonStartTrialProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const ButtonStartTrial = observer(({ ...props }: ButtonStartTrialProps) => {
	const { authStore } = useStores();
	const navigate = useNavigate();
	const location = useLocation();

	const getTitle = () => {
		return authStore.isAuth ? `Continue Learning` : `Sign Up for FREE`;
	};

	const handleOnClick = () => {
		if (authStore.isAuth) {
			const lastUUID = localStorage.getItem(SETTINGS.UUID);

			if (lastUUID) {
				navigate(`/cabinet/lessons/${lastUUID}`);
			} else {
				navigate("/cabinet/my-subscriptions");
			}
		} else {
			const search = ["school", "college", "university"];
			let isFind = false;

			search.forEach((findService) => {
				if (location.pathname.includes(findService)) {
					isFind = true;
				}
			});

			navigate("/sign?tab=sign_in");

			if (isFind) {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
			}
		}
	};

	return (
		<>
			<Button {...props} onClick={handleOnClick}>
				{getTitle()}
			</Button>
		</>
	);
});
