import styled from "styled-components";
import { COLORS } from "../../../styles";

export const FourthBlockContainer = styled.div`
	position: relative;
	background: ${COLORS.White};
	padding: 100px 0;
`;

export const Container = styled.div`
	margin: 0 auto;
	width: 960px;
`;

export const Information = styled.div`
	display: grid;
	grid-template-columns: 400px 1fr;
	column-gap: 20px;
	align-items: center;
`;

export const Picture = styled.picture`
	display: flex;

	img {
		width: 100%;
		object-fit: cover;
	}
`;

export const List = styled.ul`
	padding: 0;
	margin: 30px 0 0;
	list-style-position: inside;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 15px;

	li {
		line-height: 30px;
	}
`;
