import * as React from "react";
import { Button, Modal } from "../../../common";
import { Container, EmailUser, Icons, Message, Title } from "./CongratulationsModal.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ReactComponent as StartsIcon } from "./stars.svg";
import { SIGN_PAGE } from "../../../../constants";

interface CongratulationsModalProps {
	onChangeForm: (value: SIGN_PAGE) => void;
}

export const CongratulationsModal = observer(({ onChangeForm }: CongratulationsModalProps) => {
	const { authStore } = useStores();

	const handleOnClose = () => {
		authStore.setCongratulationsEmail("");
		authStore.congratulationsModal.close();
		onChangeForm(SIGN_PAGE.SIGN_IN);
	};

	return (
		<Modal modalStore={authStore.congratulationsModal} isShow={authStore.congratulationsModal.isShow} maxWidth={540}>
			<Container>
				<Icons>
					<StartsIcon />
				</Icons>
				<Title>Congratulations!</Title>

				<Message>
					<p>Please check your email and verify your account. If you don’t see our email, check your spam folder.</p>
					<p>Use the credentials from the email to Sign In & make sure to change your password in the settings.</p>
				</Message>

				<EmailUser>{authStore.congratulationsEmail}</EmailUser>

				<Button style={{ width: "100%" }} onClick={handleOnClose}>
					OK
				</Button>
			</Container>
		</Modal>
	);
});
