import * as React from "react";
import { BodyContainer } from "./Body.styled";
import { Scores } from "./Scores/Scores";
import { Method } from "./Method/Method";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Charts } from "./Charts/Charts";

interface BodyProps {}

export const Body = observer(({}: BodyProps) => {
	const { lessonStore } = useStores();

	return (
		<BodyContainer>
			{lessonStore.isCollegeGuitar ? <Charts /> : <Scores />}
			<Method />
		</BodyContainer>
	);
});
