import { Body, Container } from "./TrialMode.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { createLabel } from "../../../helpers";
import { ImagePng } from "../ImagePng/ImagePng";

export const TrialMode = observer(() => {
	const { authStore } = useStores();

	const { userStore } = authStore;

	if (!authStore.isAuth) {
		return null;
	}

	if (authStore.userStore.existPurchases) {
		return null;
	}

	return (
		<Container>
			<ImagePng src={"/images/arrow-right"} />
			<Body>Free Plan</Body>
			<ImagePng src={"/images/arrow-right"} className={"arrow-left"} />
		</Container>
	);
});
