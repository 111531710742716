import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	height: 60px;
	border: 1px solid ${COLORS.Gold};
	border-radius: 30px;
	margin-bottom: 43px;
`;

interface ItemProps {
	isActive: boolean;
}

export const Item = styled.div<ItemProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	${fontMixins(32, 400)};
	color: ${COLORS.Gold};
	opacity: 0.5;
	height: 58px;
	border-radius: 28px;
	cursor: pointer;
	transition: all 0.2s;

	${(props) =>
		props.isActive &&
		css`
			${fontMixins(40, 700)};
			opacity: 1;
			color: ${COLORS.Bgrnd};
			background: ${COLORS.Gold};
		`}
`;
