import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const EmptyContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	height: 250px;
	${fontMixins(18, 700)};
	line-height: 28px;
	background: ${COLORS.White};
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	padding-inline: 25px;
	text-align: center;
`;
