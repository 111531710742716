import { makeObservable, observable } from "mobx";
import { MenuI } from "../../interfaces/menu/menu.interface";
import { ServiceName } from "../../constants";

export class MenuStore {
	@observable list: MenuI[] = [
		{
			name: "School",
			link: "/school/guitar",
			type: ServiceName.School,
			children: [
				{
					name: "Guitar",
					link: "/school/guitar",
					type: ServiceName.School,
					payload: {
						grandChartId: 1,
					},
				},
				{
					name: "Keyboard",
					link: "/school/keyboard",
					type: ServiceName.School,
					payload: {
						grandChartId: 2,
					},
				},
				{
					name: "Saxophone",
					link: "/school/saxophone",
					type: ServiceName.School,
					payload: {
						grandChartId: 4,
					},
				},
			],
		},
		{
			name: "College",
			link: "/college/guitar",
			type: ServiceName.College,
			children: [
				{
					name: "Guitar",
					link: "/college/guitar",
					type: ServiceName.College,
					payload: {
						grandChartId: 3,
					},
				},
				{
					name: "Keyboard",
					link: "/college/keyboard",
					type: ServiceName.College,
					payload: {
						grandChartId: 5,
					},
				},
				{
					name: "Saxophone",
					link: "/college/saxophone",
					type: ServiceName.College,
					payload: {
						grandChartId: 6,
					},
				},
			],
		},
		{
			name: "University",
			link: "/university/guitar ",
			type: ServiceName.University,
			children: [
				{
					name: "Guitar",
					link: "/university/guitar",
					type: ServiceName.College,
					payload: {
						grandChartId: 8,
					},
				},
				{
					name: "Keyboard",
					link: "/university/keyboard",
					type: ServiceName.College,
					payload: {
						grandChartId: 7,
					},
				},
				{
					name: "Saxophone",
					link: "/university/saxophone",
					type: ServiceName.College,
					payload: {
						grandChartId: 9,
					},
				},
			],
		},
		{
			name: "Pricing",
			link: "/pricing",
			type: undefined,
			newTab: true,
		},
		{
			name: "Contact",
			link: "/contact",
			type: undefined,
		},
	];

	constructor() {
		makeObservable(this);
	}
}
