import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService, phoneQuery } from "../../../../styles";
import { ServiceName } from "../../../../constants";

export const ServiceListContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 88.6px 0 0;

	${phoneQuery(css`
		margin: 25px 0 0;
		grid-template-columns: repeat(1, 1fr);
		row-gap: 45px;
	`)}
`;

export const ServiceListItem = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	align-self: flex-start;
	text-align: center;

	svg {
		margin: 0 auto;
		height: 85px;
	}
`;

interface ServiceListNameI {
	service: ServiceName;
}

export const ServiceListName = styled.div<ServiceListNameI>`
	${fontMixins(36)};
	font-weight: 700;
	line-height: 44px;
	text-align: center;
	margin: 20px 0 25px;
	color: ${(props) => getColorByService(props.service)};

	${phoneQuery(css`
		${fontMixins(30, 700)};
		line-height: 40px;
		margin: 15px 0;
	`)}
`;

export const ServiceListDescription = styled.div`
	${fontMixins(18)};
	line-height: 25px;
	text-align: center;
	color: ${COLORS.Dark_Gray};
	padding: 0 60px;
	min-height: 78px;
	margin-bottom: 81px;

	${phoneQuery(css`
		padding: 0 60px;
		margin-bottom: 0;
		min-height: auto;
	`)}
`;
