import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../../styles";

export const Container = styled.div`
	display: flex;
	justify-content: center;
`;

export const Button = styled.button`
	display: flex;
	align-items: center;
	padding-left: 12px;
	width: 121px;
	height: 50px;
	border-radius: 25px;
	border: 0;
	background: ${COLORS.Gold};
	${fontMixins(14, 700)};
	color: ${COLORS.White};
	cursor: pointer;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 31px;
	}
`;

export const StopButton = styled.span`
	display: flex;
	width: 17px !important;
	height: 17px !important;
	background: ${COLORS.White};
	border-radius: 2px;
`;
