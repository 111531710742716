import { Route, Routes, useLocation } from "react-router-dom";
import {
	HistoryPage,
	IFrame,
	LessonPage,
	MainPage,
	MyDayPage,
	MyStatisticsPage,
	MySubscriptionsPage,
	NotFoundPage,
	PricingPage,
	PrivacyPolicyPage,
	ProfilePage,
	RemPage,
	ServicePage,
	SignPage,
	TheTeamPage,
	TutorialsPage,
	UserAgreementPage,
} from "./pages";
import { BaseLayout, CabinetLayout } from "./components";
import { observer } from "mobx-react";
import { ProtectedRoute } from "./components/common";
import { useStores } from "./stores";
import { useEffect } from "react";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { TestIFramePage } from "./pages/TestIFramePage/TestIFramePage";
import { gtmEvent } from "./helpers";
import { VerifyEmailPage } from "./pages/VerifyEmail/VerifyEmail";
import { NotificationsPage } from "./pages/NotificationsPage";

const App = observer(() => {
	const { authStore, geoPluginStore } = useStores();
	const location = useLocation();

	useEffect(() => {
		(async () => {
			// Получение страны пользователя
			//await geoPluginStore.getCurrent();
			await authStore.check();
		})();
	}, []);

	useEffect(() => {
		if (location.pathname) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [location.pathname]);

	useEffect(() => {
		if (authStore.isAuth) {
			gtmEvent({ event: "User_ID", user_id: authStore.userStore.id });
		}
	}, [location.pathname]);

	return (
		<Routes>
			<Route path={"/"} element={<BaseLayout />}>
				<Route index element={<MainPage />} />
				<Route path={"verify/:id/:hash"} element={<VerifyEmailPage />} />
				<Route path={"sign"} element={<SignPage />} />
				<Route path={"the-team"} element={<TheTeamPage />} />
				<Route path={"user-agreement"} element={<UserAgreementPage />} />
				<Route path={"privacy-policy"} element={<PrivacyPolicyPage />} />
				<Route path={"pricing"} element={<PricingPage />} />
				<Route path={"contact"} element={<ContactPage />} />
				<Route path={":service/:instrument"} element={<ServicePage />} />
				<Route path={"cabinet"} element={<ProtectedRoute />}>
					<Route element={<CabinetLayout />}>
						<Route path={"my-day"} element={<MyDayPage />} />
						<Route path={"my-statistics"} element={<MyStatisticsPage />} />
						<Route path={"my-statistics/history/:date"} element={<HistoryPage />} />
						<Route path={"my-subscriptions"} element={<MySubscriptionsPage />} />
						<Route path={"tutorials"} element={<TutorialsPage />} />
						<Route path={"profile"} element={<ProfilePage />} />
						<Route path={"rem"} element={<RemPage />} />
						<Route path={"notifications"} element={<NotificationsPage />} />
					</Route>
					<Route path={"lessons/:uuid"} element={<LessonPage />} />
				</Route>
				<Route path={"*"} element={<NotFoundPage />} />
			</Route>

			<Route path={"iframe"} element={<IFrame />} />
			<Route path={"test-iframe"} element={<TestIFramePage />} />
		</Routes>
	);
});

export default App;
