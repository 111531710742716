import styled, { css } from "styled-components";
import { phoneQuery } from "../../../styles";

export const BodyContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
	margin-bottom: 20px;

	${phoneQuery(css`
		grid-template-columns: 1fr;
		row-gap: 10px;
	`)}
`;
