import { Container } from "./AnalysisProcess.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { ScoreItemAiMidiStatus } from "../../../../../../../constants";

interface AnalysisProcessProps {}

export const AnalysisProcess = observer(({}: AnalysisProcessProps) => {
	const { aiStore, viewerStore } = useStores();

	const currentChooseItem = viewerStore?.chooseItem?.getAiResultByBpm(aiStore.chosenBPM);

	console.log("currentChooseItem", currentChooseItem);

	if (!currentChooseItem) {
		return null;
	}

	if (![ScoreItemAiMidiStatus.Create, ScoreItemAiMidiStatus.Analyze].includes(currentChooseItem.status)) {
		return null;
	}

	return <Container>Recording analysis...</Container>;
});
