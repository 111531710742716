import styled, { css } from "styled-components";
import { phoneQuery } from "../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-template-columns: repeat(3, minmax(240px, 1fr));
	grid-auto-flow: column;
	gap: 20px 10px;

	${phoneQuery(css`
		grid-auto-flow: row;
		grid-template-rows: 1fr;
		grid-template-columns: 1fr;
		margin: 25px 15px;
	`)}
`;
