import styled, { css } from "styled-components";
import { phoneQuery } from "../../../styles";

interface ContainerProps {
	isLarge: boolean;
}

export const Container = styled.div<ContainerProps>`
	display: grid;
	width: 1269px;
	grid-template-columns: 200px 1fr;
	height: 100%;
	padding: 10px 0;
	column-gap: 11px;
	margin: 0 auto;

	${phoneQuery(css`
		grid-template-columns: 1fr;
		width: 100%;
	`)}
`;
