import * as React from "react";
import { Container, Icon, Text } from "./Logotype.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { getIconInstrument } from "../../../../helpers";

interface LogotypeProps {}

export const Logotype = observer(({}: LogotypeProps) => {
	const { grandCharts } = useStores();
	const { selected } = grandCharts;
	return (
		<Container theme={selected.service.slug}>
			<Icon>{getIconInstrument(selected.instrument.slug)}</Icon>
			<Text>Grand Chart</Text>
		</Container>
	);
});
