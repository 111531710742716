import { action, makeObservable } from "mobx";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { MethodRequest } from "../../constants";

export class PurchasesStore {
	constructor() {
		makeObservable(this);
	}

	@action.bound
	async create(values: any) {
		try {
			await API.request(`purchase`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});
		} catch (e) {
			ErrorAPI("create", e);
		}
	}
}
