import * as React from "react";
import { observer } from "mobx-react";
import { Container } from "./BaseLayout.styled";
import { Header } from "./Header/Header";
import { Outlet } from "react-router-dom";
import { Main } from "./Main/Main";
import { Footer } from "./Footer/Footer";
import { useStores } from "../../../stores";
import { HEADER_TYPE } from "../../../constants";

interface BaseLayoutProps {}

export const BaseLayout = observer(({}: BaseLayoutProps) => {
	const {
		settingsStore,
		authStore: { userStore },
		lessonStore,
	} = useStores();

	return (
		<>
			<Container
				theme={lessonStore.grand_chart_group_lesson.grand_chart_current.service.slug}
				trialMode={settingsStore.type_header === HEADER_TYPE.RELATIVE && !userStore.existPurchases}
			>
				<Header />
				<Main>
					<Outlet />
				</Main>
				<Footer />
			</Container>
		</>
	);
});
