import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
	user-select: none;

	.rc-pagination {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0;
		column-gap: 10px;

		.rc-pagination-item {
			a {
				background: ${COLORS.MidGray};
				border-radius: 5px;
			}
		}

		.rc-pagination-item-active {
			a {
				color: ${COLORS.White};
				box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
				background: ${COLORS.Gold};
			}
		}

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			cursor: pointer;
			padding: 0;
			margin: 0;
		}
	}

	.rc-pagination-prev,
	.rc-pagination-next {
		display: flex;
		width: 14px;
		height: 14px;

		a {
			display: flex;
			width: 14px;
			height: 14px;

			svg {
				height: 14px;
			}
		}
	}

	.rc-pagination-prev {
		margin-right: 22px;
	}

	.rc-pagination-next {
		margin-left: 22px;
	}

	.rc-pagination-jump-prev,
	.rc-pagination-jump-next {
		button {
			background: transparent;
			border: 0;
		}
	}

	.rc-pagination-disabled {
		a {
			cursor: not-allowed;
		}

		svg {
			filter: grayscale();
		}
	}
`;

export const PaginationButtonJump = styled.button`
	background: transparent;
	border: 0;
	margin: 0;
	padding: 0;
	${fontMixins(18)};
	line-height: 22px;
`;

interface PaginationButtonFirstEndI {
	left: boolean;
}

export const PaginationButtonFirstEnd = styled.button<PaginationButtonFirstEndI>`
	background: transparent;
	border: 0;
	padding: 0;
	margin: ${(props) => (props.left ? `0 20px 0 0` : `0 0 0 20px`)};
	cursor: pointer;

	&:disabled {
		filter: grayscale();
		cursor: not-allowed;
	}

	svg {
		position: relative;
		top: 1.5px;
		height: 14px;

		&:last-child {
			left: -2px;
		}
	}
`;
