import { Container, Line } from "./Lines.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { getTotalTimeRecordAI } from "../../../../../../../helpers";

interface LinesProps {}

export const Lines = observer(({}: LinesProps) => {
	const { viewerStore, aiStore } = useStores();
	const { chooseItem } = viewerStore;

	const resultChooseBpm = chooseItem.getAiResultByBpm(aiStore.chosenBPM);

	if (!resultChooseBpm || aiStore.recordNow) {
		return null;
	}

	return (
		<Container>
			{resultChooseBpm.mergeResultNotes(getTotalTimeRecordAI(aiStore.chosenBPM)).map((note, index) => {
				const [nameNote, start, end, marginLeftPercent, width] = note;

				const delay = 0.02 * (index + 1);

				return (
					<Line
						key={start}
						style={{ marginLeft: `${marginLeftPercent}%`, width: `${width}%` }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.5, delay: delay }}
					/>
				);
			})}
		</Container>
	);
});
