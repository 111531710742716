import { action, computed, makeObservable, observable } from "mobx";
import { MethodRequest } from "../../constants";
import { API } from "../../core";
import { ErrorAPI } from "../../helpers";

interface CoreStoreI<T> {
	fillStore: (data: T) => void;
}

export class CrudStore<T> implements CoreStoreI<T> {
	public PATH = "";

	@observable id = 0;
	@observable isLoading = false;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setId(value: number) {
		this.id = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	async get() {
		try {
			const data = await API.request<T>(`${this.PATH}/${this.id}`);
			this.fillStore(data);
		} catch (e) {
			console.error(`Error in method ${this.PATH} : `, e);
		}
	}

	@action.bound
	async create(values: T) {
		try {
			const created = await API.request<T>(`${this.PATH}`, {
				method: MethodRequest.Post,
				body: API.getFormData(values, false),
			});

			this.fillStore(created);

			return created;
		} catch (e) {
			console.error(`Error in method ${this.PATH} : `, e);
		}
	}

	@action.bound
	async update(values: { [key: string]: string | number | boolean | File } | T) {
		this.setIsLoading(true);

		try {
			const updated = await API.request<T>(`${this.PATH}/${this.id}`, {
				method: MethodRequest.Post,
				body: API.getFormData({ ...{ _method: MethodRequest.Put }, ...values }, false),
			});

			this.fillStore(updated);
		} catch (e) {
			ErrorAPI("update user", e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async delete(soft = true) {
		try {
			if (soft) {
				await API.request(`${this.PATH}/${this.id}`, {
					method: MethodRequest.Post,
					body: API.getFormData({ ...{ _method: MethodRequest.Put }, ...{ is_delete: true } }, false),
				});
			} else {
				await API.request(`${this.PATH}/${this.id}`, {
					method: MethodRequest.Delete,
				});
			}
		} catch (e) {
			console.error(`Error in method ${this.PATH} : `, e);
		}
	}

	@computed
	get isNotNewStore() {
		return this.id !== 0;
	}

	fillStore(data: T): void {}
}
