import styled, { css } from "styled-components";
import { COLORS } from "../../../../../../../../styles";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	column-gap: 3px;
	margin-top: 10px;
`;

interface DotProps {
	isActive?: boolean;
}

export const Dot = styled.div<DotProps>`
  display: flex;
  width: 6px;
  height: 6px;
  background: ${COLORS.MidGray};
  border-radius: 50%;


  ${(props) =>
		props.isActive &&
		css`
			&:nth-child(-n + 4) {
				background: ${COLORS.University};
			}

			&:nth-child(5) {
				background: ${COLORS.College};
			}

			&:nth-child(6) {
				background: ${COLORS.Red};
			}
		`}
}
`;
