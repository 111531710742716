import * as React from "react";
import { Container, ExtraInformation, InformationBlock } from "./BackgroundBlock.styled";
import { ButtonStartTrial, ImagePng } from "../../../components/common";
import { ImageJpg } from "../../../components/common/ImageJpg/ImageJpg";

export const BackgroundBlock = () => {
	return (
		<Container>
			<ImageJpg src={"/images/background/main"} />
			<InformationBlock>
				<ImagePng src={"/images/MC_logo_platform"} />
				<h1>
					<span>Comprehensive</span>
					<br /> Music Education
				</h1>
				<p>
					Learn to play music instruments 5 times faster
					<br />
					than the regular music education with our Unique Methodology,
					<br />
					Advanced AI, and a Modular Education System.
				</p>

				<p>
					5100+ Complete Systematized Lessons <br />
					for Beginners, Advanced, Professionals and Teachers
				</p>
				<ButtonStartTrial style={{ paddingInline: 20 }} />

				<ExtraInformation>
					The "Stats & Advice" system is operational. Currently, the "Test Me" service for students to evaluate their
					understanding of each lesson and the "Examiner" mode are being developed.
				</ExtraInformation>
			</InformationBlock>
		</Container>
	);
};
