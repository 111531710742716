import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface ProgressRingProps {
	backgroundPercent?: number;
}

export const ProgressRing = styled.svg<ProgressRingProps>`
	position: relative;
	width: 130px;
	height: 130px;
	cursor: pointer !important;

	${(props) =>
		props.backgroundPercent &&
		css`
			${LessonBreak} {
				opacity: 1;
			}

			${props.backgroundPercent === 50 &&
			css`
				${LessonBreak} {
					transform: rotate(60deg);
				}
			`}

			${props.backgroundPercent === 75 &&
			css`
				${LessonBreak} {
					transform: rotate(120deg);
				}
			`}


            ${props.backgroundPercent === 100 &&
			css`
				${LessonBreak} {
					transform: rotate(180deg);
				}
			`}
		`}
`;

export const CircleBody = styled.circle`
	fill: ${COLORS.White};
`;

export const ProgressCircle = styled.circle`
	position: absolute;
	top: 0;
	left: 0;
	stroke: transparent;
	fill: transparent;
	paint-order: fill;
	transform-origin: center;
	transform: rotate(-90deg);
	transition: all 0.2s;
	z-index: 10;
`;

export const ProgressGhost = styled.circle`
	position: absolute;
	top: 0;
	left: 0;
	stroke: transparent;
	fill: transparent;
	paint-order: fill;
	transform-origin: center;
	transform: rotate(-90deg);
	transition: all 0.2s;
	opacity: 0.25;
	z-index: 1;
`;

export const LessonBreak = styled.circle`
	position: absolute;
	top: 0;
	left: 0;
	stroke: ${COLORS.Gold};
	fill: transparent;
	paint-order: fill;
	transform-origin: center;
	transform: rotate(120deg);
	transition: all 0.2s;
	z-index: 10;
`;

export const TimeBlock = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: grid;
	grid-template-columns: 1fr 4px 1fr;
	column-gap: 4px;
	justify-content: center;
	z-index: 20;
	color: ${COLORS.Dark_Gray};
	${fontMixins(18, 300)};
	cursor: pointer !important;

	span {
		display: flex;
		justify-content: center;
	}
`;
