import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const AudioPlayerBodyBlock = styled.div`
	display: flex;
	align-items: start;
	margin: 0;
`;

export const AudioPlayerBackIcon = styled.div`
	display: flex;
	align-items: center;
	margin: 38px 0 0 19px;
	cursor: pointer;

	svg {
		path {
			fill: ${COLORS.Gold};
		}
	}
`;

export const AudioPlayerPlayIcon = styled.div`
	display: flex;
	margin: 25px 16px 0 16px;
`;

export const AudioPlayerPlayButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 0;
	cursor: pointer;
	background: ${COLORS.Gold};
`;

export const AudioPlayerPauseButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 0;
	background: ${COLORS.Gold};

	&:before,
	&:after {
		content: "";
		display: flex;
		position: absolute;
		width: 4px;
		height: 20px;
		background: ${COLORS.White};
	}

	&:before {
		margin-right: 10px;
	}

	&:after {
		margin-left: 10px;
	}
`;

export const AudioPlayerProgressLine = styled.input`
	position: relative;
	-webkit-appearance: none;
	width: calc(100% - 208px);
	margin-left: 16px;
	background: ${COLORS.Black};
	border-radius: 5px;
	margin-top: 48px;

	&:before {
		position: absolute;
		top: 50%;
		left: -10px;
		transform: translateY(-50%);
		content: "";
		display: flex;
		width: 1px;
		height: 70px;
		background: ${COLORS.Dark_Gray};
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 4px;
		cursor: pointer;
		background: ${COLORS.Dark_Gray};
		border: none;
		border-radius: 5px;
	}

	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		position: relative;
		top: -7.5px;
		left: 0;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px -1px 2px rgba(0, 0, 0, 0.25);
		background: ${COLORS.White};
	}
`;

export const AudioPlayerBreak = styled.div`
	${fontMixins(12)};
	color: ${COLORS.Dark_Gray};
	padding-top: 44px;
	margin-right: 4px;
`;
