import styled, { css } from "styled-components";
import { COLORS, fontMixin } from "../../../../../styles";

export const SwitcherContainer = styled.div`
	position: relative;
	display: flex;
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const SwitcherBody = styled.div`
	position: relative;
	width: 50px;
	height: 20px;
	background: ${COLORS.MidGray};
	border-radius: 10px;
	overflow: hidden;
	box-shadow: inset 1px 3px rgba(0, 0, 0, 0.25);

	&_disabled {
		cursor: not-allowed;
	}

	span {
		position: absolute;
		color: ${COLORS.White};
		${fontMixin({ size: 11 })};
		line-height: 13px;
		left: 23px;
		top: 5px;
	}
`;

export const SwitcherLabel = styled.label`
	line-height: 13px;
	color: ${COLORS.Dark_Gray};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 10px;
	cursor: pointer;
	${fontMixin({ size: 11 })}
`;

interface SwitcherElementI {
	isMute: boolean;
}

export const SwitcherElement = styled.label<SwitcherElementI>`
	position: absolute;
	width: 100%;
	height: 100%;
	${fontMixin({ size: 11 })}
	color: ${COLORS.White};
	display: flex;
	align-items: center;
	padding: 0 0 0 8px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	transition: all 0.2s;
	right: 0;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
	background: ${COLORS.Gold};

	${(props) =>
		props.isMute &&
		css`
			right: 30px;
			background: ${COLORS.Light_Gray} !important;
			cursor: not-allowed !important;
		`}
`;

export const SwitcherCircle = styled.div`
	position: absolute;
	width: 20px;
	height: 20px;
	top: 0;
	right: 0;
	border-radius: 50%;
	border: 10px solid ${COLORS.White};
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px -1px 2px rgba(0, 0, 0, 0.25);
	cursor: pointer;
`;
