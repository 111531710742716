import { action, makeObservable, observable } from "mobx";
import { ProductStore } from "./product.store";
import { ProductI } from "../../interfaces";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";

export class ProductsStore {
	@observable list: ProductStore[] = [];
	@observable chooseProductId = 0;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: ProductI[]) {
		this.list = (values || []).map((productStore) => new ProductStore(productStore));
	}

	@action.bound
	async getList(instrument_id: number, product_period_id: number) {
		try {
			const data = await API.request<ProductI[]>(
				`products?instrument_id=${instrument_id}&product_period_id=${product_period_id}`,
			);
			this.setList(data);

			if (Array.isArray(data) && data.length > 0) {
				this.setChooseProductId(data[0].id);
			}
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}

	@action.bound
	setChooseProductId(value: number) {
		this.chooseProductId = value;
	}
}
