import { action, makeObservable, observable } from "mobx";
import { BannerStore } from "./banner.store";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { CoreResponse } from "../../response";
import { Banner } from "../../interfaces";

export class BannersStore {
	@observable list: BannerStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<Banner[]>>(`banners`);
			this.setList(data);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}

	@action.bound
	setList(values: Banner[]) {
		this.list = (values || []).map((banner) => new BannerStore(banner));
	}
}
