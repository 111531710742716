import * as React from "react";
import {
	FooterActions,
	FooterCenterContainer,
	FooterContainer,
	FooterCopyright,
	FooterHr,
	FooterImageContainer,
	FooterInformation,
	FooterLeftSide,
	FooterMenu,
	FooterMenuColumn,
	FooterMenuLink,
	FooterRightSide,
	FooterTitle,
} from "./Footer.styled";
import { ButtonStartTrial, ImagePng } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";

interface FooterProps {}

export const Footer = observer(({}: FooterProps) => {
	const { settingsStore } = useStores();

	return (
		<FooterContainer>
			<FooterCenterContainer>
				{!settingsStore.hide_footer && (
					<>
						<FooterTitle>Study at MUSICABINET using any device</FooterTitle>

						<FooterImageContainer>
							<ImagePng src={"/images/background/footer"} />
						</FooterImageContainer>

						<FooterActions>
							<ButtonStartTrial />
						</FooterActions>

						<FooterMenu>
							<FooterMenuColumn>
								{/*<FooterMenuLink to={"#"}>MUSICABINET app</FooterMenuLink>*/}
								<FooterMenuLink to={"beginner/guitar"}>Beginner</FooterMenuLink>
								<FooterMenuLink to={"advanced/guitar"}>Advanced</FooterMenuLink>
								<FooterMenuLink to={"professional/guitar"}>Professional</FooterMenuLink>
								{/*<FooterMenuLink to={"#"}>Guitar</FooterMenuLink>4
								<FooterMenuLink to={"#"}>Saxophone</FooterMenuLink>
								<FooterMenuLink to={"#"}>Keyboard</FooterMenuLink>*/}
							</FooterMenuColumn>

							<FooterMenuColumn>
								{/*<FooterMenuLink to={"#"}>Extra Lessons</FooterMenuLink>
								<FooterMenuLink to={"#"}>Blog</FooterMenuLink>*/}
								<FooterMenuLink to={"pricing"}>Pricing</FooterMenuLink>
								<FooterMenuLink to={"user-agreement"}>User Agreement</FooterMenuLink>
								<FooterMenuLink to={"privacy-policy"}>Privacy Policy</FooterMenuLink>
								{/*<FooterMenuLink to={"#"}>Patent Warning</FooterMenuLink>*/}
							</FooterMenuColumn>

							<FooterMenuColumn>
								{/*<FooterMenuLink to={"#"}>FAQ</FooterMenuLink>
								<FooterMenuLink to={"#"}>Press about us</FooterMenuLink>
								<FooterMenuLink to={"#"}>Career</FooterMenuLink>*/}
								<FooterMenuLink to={"the-team"}>Team</FooterMenuLink>
							</FooterMenuColumn>

							<FooterMenuColumn>
								<FooterMenuLink to={"contact"}>Contact us</FooterMenuLink>
							</FooterMenuColumn>
						</FooterMenu>

						<FooterHr />
					</>
				)}

				<FooterInformation only={settingsStore.hide_footer}>
					<FooterLeftSide>
						&copy; MUSICABINET - FZCO, {new Date().getFullYear()}
						<FooterCopyright>All rights reserved</FooterCopyright>
					</FooterLeftSide>

					<FooterRightSide>
						<a href="https://www.youtube.com/@MUSICABINET" target={"_blank"} rel="noreferrer">
							<ImagePng src={"/images/social/youtube"} />
						</a>

						<a href="https://Twitter.com/musicabinet" target={"_blank"} rel="noreferrer">
							<ImagePng src={"/images/social/twitter"} />
						</a>

						<a href="https://www.linkedin.com/company/musicabinet-official/" target={"_blank"} rel="noreferrer">
							<ImagePng src={"/images/social/linkedin"} />
						</a>

						<a href="https://www.facebook.com/musicabinet" target={"_blank"} rel="noreferrer">
							<ImagePng src={"/images/social/facebook"} />
						</a>

						<a href="https://www.instagram.com/musicabinet/" target={"_blank"} rel="noreferrer">
							<ImagePng src={"/images/social/instagram"} />
						</a>
					</FooterRightSide>
				</FooterInformation>
			</FooterCenterContainer>
		</FooterContainer>
	);
});
