import styled, { css } from "styled-components";
import { ServiceName } from "../../../constants";
import { COLORS, fontMixins, getColorByService, pxToEm } from "../../../styles";
import { PayButtonPrice } from "../../../components/common/PayButton/PayButton.styled";

interface ContainerProps {
	isMarginBottom: boolean;
}

export const Container = styled.div<ContainerProps>`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin: 43px 0;
	column-gap: 15px;
	row-gap: 15px;

	${(props) =>
		props.isMarginBottom &&
		css`
			row-gap: 35px;
		`}
`;

interface ItemProps {
	theme: ServiceName;
	isWhite: boolean;
}

export const Item = styled.div<ItemProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	color: white;
	border: 2px solid transparent;
	border-radius: 10px;
	padding: 30px 0;
	border: 2px solid ${COLORS.White};
	background: ${COLORS.White};

	${(props) =>
		props.isWhite &&
		css`
			${PayButtonPrice}, ${Divider} {
				background: ${getColorByService(props.theme)};
			}

			${IconGood} {
				svg {
					path {
						fill: ${getColorByService(props.theme)};
					}

					circle {
						fill: ${getColorByService(props.theme)};
					}
				}
			}

			${Name} {
				color: ${getColorByService(props.theme)};
				${fontMixins(28, 300)};
			}

			${Price} {
				color: ${getColorByService(props.theme)};
			}

			${DescriptionItem} {
				svg {
					path {
						stroke: ${COLORS.Gold};
					}
				}
			}

			${LearnMoreBlock} {
				color: ${COLORS.Gold};

				svg {
					path {
						stroke: ${COLORS.Gold};
					}

					circle {
						fill: ${COLORS.Gold};
					}
				}
			}

			${DescriptionItem} {
				color: ${getColorByService(props.theme)};

				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}
				}
			}

			${ExtraInformation} {
				color: ${COLORS.Dark_Gray};
			}

			${LearnMoreBlock} {
				color: ${getColorByService(props.theme)};

				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}

					circle {
						fill: ${getColorByService(props.theme)};
					}
				}
			}
		`}

	${(props) =>
		props.theme === ServiceName.School &&
		!props.isWhite &&
		css`
			border-color: ${COLORS.School};
			background: linear-gradient(180deg, #2d9cdb 0%, #000000 100%), #4d4d4d;
			transition: all 0.2s;

			&:hover {
				background: linear-gradient(180deg, #30a5e8 0%, #000000 100%), #4d4d4d;
			}
		`}

  ${(props) =>
		props.theme === ServiceName.College &&
		!props.isWhite &&
		css`
			border-color: ${COLORS.College};
			background: linear-gradient(180deg, ${COLORS.College} 0%, #000000 100%), #4d4d4d;

			&:hover {
				background: linear-gradient(180deg, #ff8b09 0%, #000000 100%), #4d4d4d;
			}
		`}


  ${(props) =>
		props.theme === ServiceName.University &&
		!props.isWhite &&
		css`
			border-color: ${COLORS.University};
			background: linear-gradient(180deg, ${COLORS.University} 0%, #000000 100%), #4d4d4d;

			&:hover {
				background: linear-gradient(180deg, #00b57a 0%, #000000 100%), #4d4d4d;
			}
		`}

  ${(props) =>
		props.theme &&
		!props.isWhite &&
		css`
			${PayButtonPrice}, ${Divider} {
				background: ${getColorByService(props.theme)};
			}

			${DescriptionItem} {
				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}
				}
			}

			${LearnMoreBlock} {
				color: ${getColorByService(props.theme)};

				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}

					circle {
						fill: ${getColorByService(props.theme)};
					}
				}
			}
		`}
`;

export const IconGood = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 20px;
	height: 96px;
`;

export const Name = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${fontMixins(36, 300)};
	height: 70px;
	text-transform: uppercase;
	margin-bottom: 40px;
`;

export const Price = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: center;
	${fontMixins(36, 700)};
	color: ${COLORS.White};
	margin: 0 0 20px;

	span {
		font-size: ${pxToEm(24)};

		&.currency {
			margin-right: 6px;
		}
	}
`;

export const Actions = styled.div`
	display: flex;
	margin-top: 60px;
`;

export const PackItem = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PackCoverContainer = styled.div`
	width: 100%;
	height: 300px;
	border-radius: 10px;
	margin-bottom: 20px;
	background: ${COLORS.Gold};
`;

export const PackPicture = styled.picture``;

export const PackPreview = styled.img`
	display: flex;
	width: 100%;
	height: 300px;
	border-radius: 10px;
	object-fit: cover;
`;

export const PackPreviewSource = styled.source``;

export const PackPrice = styled.div`
	${fontMixins(20)};
	color: ${COLORS.White};
	margin-bottom: 20px;
`;

export const PackTitle = styled.div`
	color: ${COLORS.White};
	${fontMixins(20, 600)};
	margin-bottom: 20px;
	line-height: 25px;
`;

export const PackActions = styled.div`
	margin-top: auto;
`;
export const PackPay = styled.button`
	display: flex;
	align-items: center;
	border: 0;
	background: ${COLORS.Gold};
	color: ${COLORS.White};
	height: 34px;
	border-radius: 4px;
	${fontMixins(11, 700)};
	padding-inline: 10px;
	cursor: pointer;

	svg {
		width: 18px;
		height: 11px;
		margin-right: 10px;
	}
`;

export const DescriptionList = styled.div`
	display: flex;
	flex-direction: column;
	margin: 30px 0;
`;

export const DescriptionItem = styled.div`
	display: flex;
	align-items: center;
	column-gap: 10px;
	margin-inline: 20px;
	${fontMixins(14)};
	color: ${COLORS.White};
	line-height: 30px;
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: auto;
`;

export const Divider = styled.div`
	margin: 0 20px;
	height: 1px;
`;

export const ExtraInformation = styled.div`
	${fontMixins(14)};
	color: ${COLORS.White};
	margin: 20px 20px;
	text-align: center;
`;

export const LearnMoreBlock = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 10px;
	${fontMixins(14)};
	margin: 10px 20px 0;
	text-align: center;
	cursor: pointer;
`;
