import * as React from "react";
import { Container, Image, InputUpload, Loader, LoadText } from "./Avatar.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";
import { ChangeEvent, useRef } from "react";
import { UploadAvatar } from "./UploadAvatar/UploadAvatar";

interface AvatarProps {}

export const Avatar = observer(({}: AvatarProps) => {
	const { authStore } = useStores();
	const { userStore } = authStore;

	const inputUploadRef = useRef<HTMLInputElement>(null);

	const handleOnShowDialogFile = () => inputUploadRef.current && inputUploadRef.current.click();

	const handleOnUploadAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;

		if (files && files.length) {
			await authStore.userStore.update({ file: files[0] });
		}
	};

	return (
		<>
			<InputUpload type={"file"} ref={inputUploadRef} accept={".jpg, .jpeg, .png"} onChange={handleOnUploadAvatar} />
			<Container onClick={handleOnShowDialogFile}>
				{userStore.existAvatar ? <Image src={userStore.pathAvatar} /> : <UploadAvatar />}
				<Loader isShow={userStore.isLoading}>Update...</Loader>
			</Container>
		</>
	);
});
