import * as React from "react";
import { Divider, IconLeftSide, IconRightSide, WeAreComingSoonContainer } from "./WeAreComingSoon.styled";
import { Container } from "../WhatIsMusicabinet/WhatIsMusicabinet.styled";
import { Title } from "../../../components/common";
import { COLORS } from "../../../styles";
import { CounterLeft } from "./CounterLeft/CounterLeft";
import { ReactComponent as IconLeft } from "./icons_left.svg";
import { ReactComponent as IconRight } from "./icons_right.svg";
import dayjs from "dayjs";

interface WeAreComingSoonProps {}

export const WeAreComingSoon = ({}: WeAreComingSoonProps) => {
	if (dayjs().format("YYYY-MM-DD") >= dayjs("2023-01-09").format("YYYY-MM-DD")) {
		return null;
	}

	return (
		<WeAreComingSoonContainer>
			<Container style={{ position: "relative" }}>
				<IconLeftSide>
					<IconLeft />
				</IconLeftSide>

				<Title style={{ color: COLORS.White }}>We are coming soon</Title>
				<Divider />
				<CounterLeft />

				<IconRightSide>
					<IconRight />
				</IconRightSide>
			</Container>
		</WeAreComingSoonContainer>
	);
};
