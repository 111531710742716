import styled from "styled-components";
import { COLORS } from "../../../styles";

export const CheckboxContainer = styled.div`
	width: 16px;
	height: 16px;
	background: ${COLORS.White};
	border: 1px solid ${COLORS.MidGray};

	svg {
		margin-left: 2px;
	}
`;
