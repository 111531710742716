import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../styles";

export const Container = styled.div`
	padding: 50px 40px 40px;
`;

export const Title = styled.div`
	display: grid;
	justify-content: center;
	${fontMixin({ size: 36, weight: 700 })};
	line-height: 44px;
	color: ${COLORS.Dark_Gray};
	margin-bottom: 17px;
`;

export const Description = styled.div`
	display: grid;
	align-items: center;
	justify-content: center;
	${fontMixin({ size: 14, weight: 400 })};
	line-height: 17px;
	color: ${COLORS.Dark_Gray};
	text-align: center;
`;

export const Form = styled.form`
	display: grid;
	row-gap: 30px;
	margin: 30px 0 60px;
`;

export const Actions = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 19px;
`;

export const ActionButton = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: transparent;
	border: 1px solid ${COLORS.MidGray};
	border-radius: 10px;
	padding: 20px 10px 30px;
`;
