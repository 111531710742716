import { Item, Link } from "./DropDown.styled";
import { MenuI } from "../../../../../../interfaces/menu/menu.interface";
import { HTMLAttributes, MouseEvent } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";

interface DropDownItemProps extends HTMLAttributes<HTMLDivElement> {
	item: MenuI;
}

export const DropDownItem = observer(({ item }: DropDownItemProps) => {
	const { authStore, grandCharts } = useStores();

	const getTitle = () => {
		if (authStore.isAuth) {
			return `${item.name} Grand Chart`;
		} else {
			return `${item.name}`;
		}
	};

	const handleOnClick = async (e: MouseEvent<HTMLAnchorElement>) => {
		if (authStore.isAuth) {
			e.preventDefault();
			e.stopPropagation();

			const idGrandChart = item.payload?.grandChartId;

			if (idGrandChart) {
				grandCharts.selected.setId(idGrandChart);
				await grandCharts.selected.get();
				await grandCharts.selected.setShowGroupLesson(false);
				grandCharts.modal.open();
			}
		}
	};

	return (
		<Item>
			<Link to={item.link} onClick={handleOnClick}>
				{getTitle()}
			</Link>
		</Item>
	);
});
