import { Container, Dot } from "./DetectedVolume.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../../stores";

interface DetectedVolumeProps {}

export const DetectedVolume = observer(({}: DetectedVolumeProps) => {
	const { aiStore } = useStores();
	const { recordVolume } = aiStore;

	return (
		<Container>
			{[...Array(6).keys()].map((index) => {
				const maxVolume = 16 * (index + 1);
				return <Dot key={maxVolume.toString()} isActive={recordVolume > maxVolume} />;
			})}
		</Container>
	);
});
