import * as React from "react";
import { Outlet } from "react-router-dom";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { MainPage } from "../../../pages";

interface ProtectedRouteProps {}

export const ProtectedRoute = observer(({}: ProtectedRouteProps) => {
	const { authStore } = useStores();

	if (authStore.isAuth) {
		return <Outlet />;
	}

	return !authStore.isAuth && <MainPage />;
});
