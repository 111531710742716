export enum AiBpm {
	Bpm60 = 60,
	Bpm90 = 90,
	Bpm120 = 120,
}

export enum AITimeRecord {
	Total = 10,
}

export const AI_SETTINGS = {
	CURRENT_BPM: "current_bpm",
	RESULT_ELEMENT: "ai-element",
};
