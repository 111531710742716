import * as React from "react";
import { useEffect } from "react";
import { Container } from "./CabinetLayout.styled";
import { Outlet } from "react-router-dom";
import { LeftSide } from "./LeftSide/LeftSide";
import { RightSide } from "./RightSide/RightSide";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { Helmet } from "react-helmet";

interface CabinetLayoutProps {}

export const CabinetLayout = observer(({}: CabinetLayoutProps) => {
	const { settingsStore } = useStores();

	useEffect(() => {
		settingsStore.hideFooter();
	}, []);

	return (
		<>
			<Helmet>
				<title>Cabinet | MUSICABINET</title>
			</Helmet>

			<Container isLarge={settingsStore.isLargeWidth}>
				<LeftSide />
				<RightSide>
					<Outlet />
				</RightSide>
			</Container>
		</>
	);
});
