import styled, { css } from "styled-components";
import { COLORS, phoneQuery } from "../../../../../styles";

export const DividerContainer = styled.div`
	width: 1px;
	background: ${COLORS.White};

	${phoneQuery(css`
		display: none;
	`)}
`;
