import styled from "styled-components";
import { COLORS } from "../../../styles";

export const VolumeContainer = styled.div`
	position: relative;
	top: 0;
	width: 16px;
	height: 80px;
	z-index: 150;
	border-radius: 8px;
	//box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
`;

export const VolumeRange = styled.input`
	-webkit-appearance: none;
	height: 20px;
	width: 80px;
	transform: rotate(-90deg);
	position: relative;
	left: -33px;
	top: 28px;
	cursor: pointer;
	background: transparent;
	border-radius: 10px;
	margin-bottom: 8px;
	z-index: 100;
	touch-action: none;

	&::-webkit-slider-thumb {
		display: flex;
		-webkit-appearance: none; /* Override default look */
		appearance: none;
		margin-top: -8px; /* Centers thumb on the track */
		background-color: ${COLORS.Gold};
		height: 20px;
		border-radius: 16px;
		width: 10px;
		position: relative;
		z-index: 1200;
		//box-shadow: 0px -4px 5px -4px rgba(0, 0, 0, 0.2) inset;

		&:after {
			content: "";
			position: absolute;
			width: 5px;
			height: 1px;
			background: red;
			left: 0;
			top: 0;
		}
	}

	&::-webkit-slider-runnable-track {
		background: ${COLORS.Dark_Gray_2};
		height: 4px;
		border-radius: 20px;
	}

	&::-moz-range-track {
		//background: ${COLORS.Dark_Gray};
		height: 2px;
	}

	/*&::-webkit-slider-thumb {
		-webkit-appearance: none;
		position: relative;
		top: -7.5px;
		left: 0;
		width: 20px;
		height: 20px;
		border: 5px solid ${COLORS.White};
		border-radius: 50%;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px -1px 2px rgba(0, 0, 0, 0.25);
	}

	&::-webkit-slider-runnable-track {
		position: relative;
		height: 3px;
		border: 0;
		box-shadow: none;
		border-radius: 10px;
		-webkit-appearance: none;
		//background: red;
	}*/
`;

export const VolumeProgressLine = styled.div`
	position: absolute;
	z-index: 100;
	left: 7px;
	bottom: 0;
	width: 4px;
	border-radius: 6px;
	background: ${COLORS.Gold};
`;
