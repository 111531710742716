import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 50px;
`;

interface ItemProps {
	isActive: boolean;
}

export const Item = styled.div<ItemProps>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 220px;
	border: 2px solid ${COLORS.Gold};
	border-radius: 20px;
	${fontMixins(18, 400)};
	color: ${COLORS.Gold};
	opacity: 0.5;
	transition: all 0.2s;
	cursor: pointer;

	${(props) =>
		props.isActive &&
		css`
			opacity: 1;
			color: ${COLORS.Bgrnd};
			background: ${COLORS.Gold};
			font-weight: 700;

			${Discount} {
				background: ${COLORS.Bgrnd};
				color: ${COLORS.Gold};
			}
		`}
`;

export const Discount = styled.div`
	position: absolute;
	right: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${COLORS.Gold};
	width: 60px;
	height: 30px;
	border-radius: 30px;
	color: ${COLORS.Bgrnd};
	${fontMixins(18, 700)}
`;
