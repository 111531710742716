import * as React from "react";
import {
	Container,
	ContainerAudioPlayer,
	ContainerBreadcrumbsAndListLessons,
	ContainerExtra,
	ContainerLogotype,
} from "./Header.styled";
import { Logotype } from "./Logotype/Logotype";
import { LessonList } from "./LessonList/LessonList";
import { Metronome } from "./Metronome/Metronome";
import { Modules } from "./Modules/Modules";
import { AudioPlayer } from "./AudioPlayer/AudioPlayer";
import { LessonTotalTimeLeft } from "./LessonTotalTimeLeft/LessonTotalTimeLeft";

export const Header = () => {
	return (
		<Container>
			<ContainerLogotype>
				<Logotype />
			</ContainerLogotype>

			<ContainerBreadcrumbsAndListLessons>
				<LessonList />
				<LessonTotalTimeLeft />
			</ContainerBreadcrumbsAndListLessons>

			<ContainerAudioPlayer>
				<AudioPlayer isLesson />
			</ContainerAudioPlayer>

			<ContainerExtra>
				<Metronome />
				<Modules />
			</ContainerExtra>
		</Container>
	);
};
