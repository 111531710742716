import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../../styles";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
	grid-area: footer;
	background: ${COLORS.Black};
	//height: 1460px;
`;

export const FooterCenterContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	width: 960px;
	margin: 47px auto 0;

	${phoneQuery(css`
		width: auto;
		padding: 0 25px;
	`)}
`;

export const FooterTitle = styled.h1`
	${fontMixins(48)};
	font-weight: 700;
	line-height: 59px;
	padding: 41px 0;
	margin: 0 auto;
	color: ${COLORS.White};
	width: 602px;
	text-align: center;

	${phoneQuery(css`
		${fontMixins(30, 700)};
		line-height: 40px;
		width: auto;
		padding: 25px 0;
	`)}
`;

export const FooterImageContainer = styled.div`
	display: flex;

	${phoneQuery(css`
		img {
			width: 100%;
		}
	`)}
`;

export const FooterActions = styled.div`
	display: grid;
	justify-content: center;
	margin: 47px 0 125px;

	${phoneQuery(css`
		margin: 25px 0 50px;
	`)}
`;

export const FooterMenu = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	align-items: start;
	column-gap: 53px;
	margin: 0 0 54px;

	${phoneQuery(css`
		grid-template-columns: repeat(1, 1fr);
		column-gap: 0;
		row-gap: 45px;
	`)}
`;

export const FooterMenuColumn = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 20px;
`;

export const FooterMenuLink = styled(Link)`
	${fontMixins(14, 300)}
	text-decoration: none;
	color: ${COLORS.White};
	height: 20px;
	transition: all 0.2s;

	&:hover {
		color: ${COLORS.Gold};
	}

	&:active {
		color: ${COLORS.Gold_Down};
	}
`;

export const FooterHr = styled.hr`
	display: flex;
	width: 100%;
	height: 1px;
	background: ${COLORS.Dark_Gray};
	border: 0;
	padding: 0;
	margin: 0 0 32px;
`;

interface FooterInformationI {
	only: boolean;
}

export const FooterInformation = styled.div<FooterInformationI>`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 0 0 67px;

	${(props) =>
		props.only &&
		css`
			padding: 40px 0 60px !important;
		`}

	${phoneQuery(css`
		grid-template-columns: repeat(1, 1fr);
		padding: 0 0 25px;
	`)}
`;

export const FooterLeftSide = styled.div`
	${fontMixins(18)};
	line-height: 22px;
	color: ${COLORS.White};

	${phoneQuery(css`
		order: 2;
		margin-top: 25px;
	`)}
`;

export const FooterCopyright = styled.div`
	${fontMixins(12)};
	font-weight: 300;
	color: ${COLORS.White};
	padding-left: 20px;
	margin: 5px 0 0;
`;

export const FooterRightSide = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: start;
	column-gap: 18px;

	a {
		display: flex;
		cursor: pointer;
	}

	${phoneQuery(css`
		order: 1;
		justify-content: center;
	`)}
`;
