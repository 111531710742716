import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins } from "../../../../styles";
import { ReactComponent as StarIcon } from "./images/star.svg";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 30px;
	width: 600px;
	border: 2px solid ${COLORS.Gold};
	background: ${COLORS.White};
	padding: 50px 40px 40px;
	border-radius: 10px;
`;

export const Title = styled.div`
	display: grid;
	justify-content: center;
	${fontMixin({ size: 36, weight: 700 })};
	line-height: 44px;
	color: ${COLORS.Gold};
`;

export const Info = styled.div`
	display: flex;
	justify-content: center;
	${fontMixins(18, 700)};
	color: ${COLORS.Gold};
`;

export const StartList = styled.div`
	display: flex;
	justify-content: center;

	svg {
		width: 36px;
		height: 36px;

		path {
			fill: ${COLORS.Dark_Gray};
		}
	}
`;

export const ListResult = styled.ul`
	display: flex;
	flex-direction: column;
	width: 300px;
	margin: 0 auto;
	row-gap: 10px;

	li {
		display: flex;

		span {
			display: flex;
			width: 50px;
			${fontMixins(18, 700)};
			color: ${COLORS.Dark_Gray};
			margin-right: 20px;
		}
	}
`;

export const Description = styled.div`
	${fontMixins()};
	color: ${COLORS.Dark_Gray};
	text-align: center;
	line-height: 26px;
`;

export const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface WrapperStarIconProps {
	isGold?: boolean;
}

export const WrapperStarIcon = styled(StarIcon)<WrapperStarIconProps>`
	${(props) =>
		props.isGold &&
		css`
			path {
				fill: ${COLORS.Gold} !important;
			}
		`}
`;
