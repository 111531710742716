import * as React from "react";
import { useEffect, useRef, useState } from "react";
import {
	Container,
	CurrentLessonName,
	NumberLesson,
	TooltipLesson,
	TooltipList,
	TooltipTitle,
} from "./LessonList.styled";
import { ButtonLessonList } from "./Button/Button";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { List } from "./List/List";
import { ListItem } from "./List/ListItem";
import { useOutside } from "../../../../hooks";
import { CircleProgressBar } from "../../../../components/common";
import { convertMinuteToHours } from "../../../../helpers";

export const LessonList = observer(() => {
	const {
		lessonStore,
		websocketStore,
		grandCharts: { selected: selectedGrandChart },
	} = useStores();

	const lessonListRef = useRef<HTMLDivElement>(null);
	const lessonNumberRef = useRef<HTMLDivElement>(null);

	const [visibleTooltip, setVisibleTooltip] = useState(false);

	useEffect(() => {
		if (lessonStore.timer.isStart) {
			websocketStore.addCallback("timer", () => {
				lessonStore.incrementProgress();
			});
		} else {
			websocketStore.removeCallback("timer");
		}
	}, [lessonStore.timer.isStart]);

	useEffect(() => {
		//  Подгружаем текущий гранд чарт
		(async () => {
			selectedGrandChart.setId(lessonStore.grand_chart_group_lesson.grand_chart_id);
			await selectedGrandChart.get();
		})();
	}, [lessonStore.uuid]);

	useEffect(() => {
		if (lessonNumberRef.current) {
			lessonNumberRef.current.addEventListener("mouseover", showTooltip);
			lessonNumberRef.current.addEventListener("mouseleave", closeTooltip);
		}
	}, []);

	const showTooltip = () => {
		setVisibleTooltip(true);
	};

	const closeTooltip = () => {
		setVisibleTooltip(false);
	};

	useOutside(lessonListRef, lessonStore.list.close);

	const handleOnClick = () => lessonStore.list.setIsShow(!lessonStore.list.isShow);

	return (
		<Container onClick={handleOnClick} ref={lessonListRef}>
			<NumberLesson ref={lessonNumberRef}>
				<CircleProgressBar
					strokeWidth={2}
					sqSize={39}
					percentage={lessonStore.getPassedLesson}
					payload={lessonStore.sort}
				/>

				{visibleTooltip && (
					<TooltipLesson>
						<TooltipTitle>
							<span>Lesson {lessonStore.sort}</span> / {convertMinuteToHours(lessonStore.duration_minute)} Hours Total
							Time
						</TooltipTitle>

						<TooltipList>
							<li>
								{lessonStore.timePassed[0]}:{lessonStore.timePassed[1]} Time Passed
							</li>
							<li>
								{lessonStore.timeLeft[0]}:{lessonStore.timeLeft[1]} Time Remain
							</li>
						</TooltipList>
					</TooltipLesson>
				)}
			</NumberLesson>

			<CurrentLessonName>
				<div>
					{lessonStore.grand_chart_group_lesson.name} / {lessonStore.name}
				</div>
			</CurrentLessonName>

			<ButtonLessonList isShow={lessonStore.list.isShow} />

			<List isShow={lessonStore.list.isShow}>
				{lessonStore.grand_chart_group_lesson.lessons.map((lesson, index) => {
					return (
						<ListItem
							key={lesson.id}
							nameGroupLesson={lessonStore.grand_chart_group_lesson.name}
							lessonStore={lesson}
							access={selectedGrandChart.access}
							isFirst={index === 0}
						/>
					);
				})}
			</List>
		</Container>
	);
});
