import styled from "styled-components";
import { COLORS } from "../../../../../../styles";

export const CircleProgressContainer = styled.div`
	position: relative;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid transparent;
	width: 97px;
	height: 96px;
	border-radius: 50%;
	transition: all 0.2s;
	background: ${COLORS.White};
`;

export const CircleProgressSvgContainer = styled.svg`
	width: 95px;
	height: 95px;
`;

export const CircleProgressBackgroundBlock = styled.circle`
	fill: none;
	stroke: ${COLORS.White};
`;

export const CircleProgressPercentBlock = styled.circle`
	fill: none;
	//stroke-linecap: round;
	//stroke-linejoin: round;
	stroke: ${COLORS.College};
`;

export const CircleProgressMainBackgroundBlock = styled.circle``;

export const PieContainer = styled.svg`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	max-height: 85px;
	border-radius: 50%;
	border: 1px solid #eee;
	background: ${COLORS.White};

	path {
		fill: ${COLORS.School};
		transform: translate3d(0, 0, 0, -1px);
	}
`;
