import { action, observable } from "mobx";
import { AccompanimentI } from "../../interfaces/accompaniments/accompaniment.interface";
import { LibraryStore } from "../library/library.store";

export class AccompanimentStore implements AccompanimentI {
	@observable id = 0;
	@observable lesson_id = 0;
	@observable sort = 0;
	@observable name = "";
	@observable libraries: LibraryStore[] = [];

	@observable selectedLibrary: LibraryStore = new LibraryStore(null);

	constructor(initialData: AccompanimentI | null) {
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setLibrary(value: LibraryStore) {
		this.selectedLibrary = value;
	}

	@action.bound
	setFirst() {
		if (this.libraries.length > 0) {
			this.setLibrary(this.libraries[0]);
		}
	}

	@action
	fillStore(data: AccompanimentI) {
		const { id, lesson_id, sort, name, libraries } = data;

		this.id = id;
		this.lesson_id = lesson_id;
		this.sort = sort;
		this.name = name;
		this.libraries = (libraries || []).map((library) => new LibraryStore(library));
	}
}
