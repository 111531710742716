import styled from "styled-components";
import { COLORS } from "../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	padding: 20px;
	background: ${COLORS.Dark_Gray};
	border-radius: 10px;
	text-align: center;
	color: ${COLORS.White};
	font-weight: 600;
`;
