import * as React from "react";
import { ImagePngContainer } from "./ImageJpg.styled";
import { ImgHTMLAttributes } from "react";

interface ImageJpg extends ImgHTMLAttributes<HTMLImageElement> {
	ext?: string;
}

export const ImageJpg = ({ src, alt, ext = "jpg", loading }: ImageJpg) => {
	return (
		<ImagePngContainer>
			<source srcSet={`${src}.webp 1x`} type="image/webp" />
			<img src={`${src}.${ext}`} srcSet={`${src}@2x.${ext} 2x`} alt={alt} loading={"lazy"} />
		</ImagePngContainer>
	);
};
