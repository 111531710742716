import { action, computed, makeObservable } from "mobx";
import { StatisticsData, StatisticsGrandChart } from "../../interfaces";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";

export class StatisticsGrandChartStore implements StatisticsGrandChart {
	PATH = "statistics";

	courses_progress: StatisticsData = {};
	group_lessons_progress: StatisticsData = {};
	lessons_progress: StatisticsData = {};

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getByGrandChart(grand_chart_id: number) {
		try {
			const data = await API.request<StatisticsGrandChart>(`${this.PATH}/${grand_chart_id}`);

			this.setCoursesProgress(data.courses_progress);
			this.setGroupLessonsProgress(data.group_lessons_progress);
			this.setLessonsProgress(data.lessons_progress);
		} catch (e) {
			ErrorAPI("get", e);
		}
	}

	@action.bound
	setCoursesProgress(values: StatisticsData) {
		this.courses_progress = values;
	}

	@action.bound
	setGroupLessonsProgress(values: StatisticsData) {
		this.group_lessons_progress = values;
	}

	@action.bound
	setLessonsProgress(values: StatisticsData) {
		this.lessons_progress = values;
	}

	getByCourseId = (id: number): number => {
		return computed(() => {
			return this.courses_progress[String(id)] || 0;
		}).get();
	};

	getByGroupLessonId = (id: number): number => {
		return computed(() => {
			return this.group_lessons_progress[String(id)] || 0;
		}).get();
	};

	getByLessonUUID = (uuid: string): number => {
		return computed(() => {
			return this.lessons_progress[uuid] || 0;
		}).get();
	};
}
