import styled, { css } from "styled-components";
import { COLORS, fontMixin } from "../../../../../styles";

interface ListContainerI {
	isShow: boolean;
}

export const ListContainer = styled.div<ListContainerI>`
	position: absolute;
	left: 0;
	top: 50px;
	width: 100%;
	max-height: 600px;
	overflow-y: auto;
	border-radius: 0 0 10px 10px;
	background: ${COLORS.Light_Gray};
	display: ${(props) => (props.isShow ? "flex" : "none")};
	flex-direction: column;
	z-index: 999;
	border-width: 0 1px 1px 1px;
	border-color: ${COLORS.MidGray};
	border-style: solid;
`;

interface ListItemContainerI {
	isActive: boolean;
}

interface ListNumberLessonI {
	color: string;
}

export const ListNumberLesson = styled.div<ListNumberLessonI>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 1px solid ${COLORS.Bgrnd};
	${fontMixin({ size: 14 })};
	margin-right: 15px;
	color: ${COLORS.White};

	${(props) =>
		props.color &&
		css`
			background: ${props.color} !important;
		`}
`;

export const ListNameLesson = styled.div`
	${fontMixin({ size: 14 })};
	color: ${COLORS.Dark_Gray};
`;

export const ListItemContainer = styled.div<ListItemContainerI>`
	display: flex;
	align-items: center;
	padding: 10px 20px;
	min-height: 50px;
	color: ${COLORS.Dark_Gray};
	border-bottom: 1px solid ${COLORS.MidGray};
	cursor: pointer;

	&:last-child {
		border-bottom: 0;
	}

	&.active {
		background: ${COLORS.Dark_Gray};

		${ListNameLesson} {
			color: ${COLORS.White};
		}
	}

	&:hover {
		background: ${COLORS.MidGray};
	}
`;
