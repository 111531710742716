import { Description, GoodItem, GoodsList, Title } from "../UnitingModal.styled";
import { GrandChartStore } from "../../../../../stores/grand-charts/grand-chart.store";
import { Button } from "../../../../common";

interface AccessDeniedProps {
	grandChart: GrandChartStore;
}

export const AccessDenied = ({ grandChart }: AccessDeniedProps) => {
	const handleOnSubscription = () => {
		grandChart.choosePlanModal.open();
	};

	return (
		<>
			<Title>{grandChart.title}</Title>
			<Description>
				This product includes:
				<br />
				<br />
				{grandChart.goods.map((good) => {
					return <GoodItem key={good.id}>{good.name}</GoodItem>;
				})}
				<br />
				Subscribe to receive access to all of them with discount!
			</Description>

			<GoodsList>
				{grandChart.goods.map((good) => {
					return (
						<GoodItem key={good.id}>
							<img src={good.fullPathCover || ""} />
						</GoodItem>
					);
				})}
			</GoodsList>

			<Button onClick={handleOnSubscription}>Subscribe</Button>
		</>
	);
};
