import * as Yup from "yup";

export const AuthSchema = Yup.object({
	email: Yup.string().email().required(),
});

export const ForgotSchema = Yup.object({
	email: Yup.string().email().required(),
});

export const VerificationEmailSchema = Yup.object({
	password: Yup.string()
		.min(6, "Your password is too short.")
		.oneOf([Yup.ref("password_confirmation"), null], "Passwords confirm must match")
		.required(),
	password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
});
