import styled from "styled-components";
import { COLORS, fontMixins } from "../../styles";

export const Container = styled.div`
	background: ${COLORS.White};
	border-radius: 10px;
	height: 80%;
	display: grid;
	grid-template-rows: 50px 1fr 50px;
`;

export const Header = styled.div`
	background: ${COLORS.Light_Gray_2};
	height: 50px;
	border-radius: 10px 10px 0 0;
`;

export const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-weight: 600;
`;

export const Content = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	${fontMixins(16, 600)};
`;
