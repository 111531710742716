import { Body, Header, Icon, Item, Title } from "./Accordion.styled";
import { ReactNode, useState } from "react";
import { IconArrowInCircle } from "../../../components/common/Icons";
import { GrandChartStore } from "../../../stores/grand-charts/grand-chart.store";
import { TheCore } from "./TheCore/TheCore";
import { GrandChartItem } from "../GrandChartItem/GrandChartItem";
import { boolean } from "boolean";

interface AccordionItemProps {
	name: string;
	title?: string;
	isCore?: boolean;
	isPreCourse?: boolean;
	children?: ReactNode;
	grandCharts?: Array<GrandChartStore[]>;
}

export const AccordionItem = ({
	name,
	title,
	children,
	isCore = false,
	isPreCourse = false,
	grandCharts,
}: AccordionItemProps) => {
	const currentIsShow = localStorage.getItem(name);
	const [isShow, setIsShow] = useState(currentIsShow ? boolean(currentIsShow) : true);

	const handleOnToggle = () => {
		setIsShow((prevIsShow) => {
			const currentState = !prevIsShow;

			localStorage.setItem(name, String(currentState));

			return currentState;
		});
	};

	return (
		<>
			<Item onClick={handleOnToggle} isShow={isShow}>
				<Header>
					<Icon isShow={isShow}>
						<IconArrowInCircle />
					</Icon>
					<Title>{title}</Title>
				</Header>

				<Body onClick={(e) => e.stopPropagation()} isShow={isShow}>
					{isCore && grandCharts && <TheCore grandCharts={grandCharts} isPreCourse={isPreCourse} />}
					{!isCore &&
						grandCharts &&
						grandCharts
							.map((levelFirst) => levelFirst)
							.map((levelSecond) =>
								levelSecond.map((grandChart) => <GrandChartItem isPack key={grandChart.id} grandChart={grandChart} />),
							)}
				</Body>
			</Item>
		</>
	);
};
