import styled, { css } from "styled-components";

interface ButtonLessonListWrapperProps {
	isShow: boolean;
}

export const ButtonLessonListWrapper = styled.button<ButtonLessonListWrapperProps>`
	position: absolute;
	right: 0;
	top: 0;
	width: 50px;
	height: 50px;
	border-radius: 0 10px 10px 0;
	border: 0;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	background: transparent;

	&:after {
		position: relative;
		content: "";
		border: solid black;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 4px;
		transform: ${(props) => (props.isShow ? `rotate(225deg)` : `rotate(45deg)`)};
		transition: all 0.2s;

		${(props) =>
			props.isShow &&
			css`
				top: 4px;
			`}
	}
`;
