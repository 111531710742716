import * as React from "react";
import { observer } from "mobx-react";
import { Container, InstrumentIcon } from "./Logotype.styled";
import { getIconInstrument } from "../../../../helpers";
import { useStores } from "../../../../stores";

export const Logotype = observer(() => {
	const { lessonStore, grandCharts } = useStores();
	const { grand_chart_group_lesson } = lessonStore;

	const handleOnOpenGrandChart = async () => {
		await grandCharts.getById(grand_chart_group_lesson.grand_chart_id);

		// Записываем акутальные данные по гранд чарту
		grandCharts.selected.setSelectedGrandChartGroupLesson(lessonStore.grand_chart_group_lesson);
		grandCharts.modal.open();
		grandCharts.selected.setShowGroupLesson(true);
	};

	return (
		<Container onClick={handleOnOpenGrandChart}>
			<InstrumentIcon>
				{getIconInstrument(lessonStore.grand_chart_group_lesson.grand_chart_current.instrument.slug)}
			</InstrumentIcon>
		</Container>
	);
});
