import { action, computed, makeObservable, observable } from "mobx";
import { Good, GrandChartCurrent } from "../../interfaces";
import dayjs from "dayjs";
import { ServiceStore } from "../services/service.store";
import { InstrumentStore } from "../instruments/instrument.store";
import { API } from "../../core";
import { PricePlanStore } from "../price-plans";
import { CrudStore } from "../common/crud.store";

export class GoodStore extends CrudStore<Good> implements Good {
	PATH = "goods";

	@observable price_plan_id = 0;
	@observable grand_chart_id = 0;
	@observable name = "";
	@observable price = 0;
	@observable stripe_id = "";
	@observable stripe_name = "";
	@observable stripe_is_active = false;
	@observable stripe_default_price = "";
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable grand_chart: GrandChartCurrent = {
		id: 0,
		sort: 0,
		service_id: 0,
		instrument_id: 0,
		title: "",
		is_active: false,
		service: new ServiceStore(null),
		instrument: new InstrumentStore(null),
	};
	@observable cover = "";
	@observable price_plan = new PricePlanStore(null);

	constructor(initialData: Good | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get priceFormatted() {
		return String(this.price.toFixed(2));
	}

	@computed
	get keyDescription() {
		return `${this.grand_chart.instrument_id}-${this.grand_chart.service_id}`;
	}

	@computed
	get fullPathCover() {
		if (!this.cover) {
			return null;
		}

		return `${API.getFilesPath}${this.cover}`;
	}

	@computed
	get fullPathCoverWebp() {
		if (!this.cover) {
			return "";
		}

		let pos = this.cover.lastIndexOf(".");
		return `${API.getFilesPath}${this.cover.substring(0, pos < 0 ? this.cover.length : pos) + ".webp"}`;
	}

	@computed
	get priceFormat() {
		return this.price;
	}

	@action
	fillStore(data: Good) {
		const {
			id,
			price_plan_id,
			grand_chart_id,
			name,
			price,
			stripe_id,
			stripe_name,
			stripe_is_active,
			stripe_default_price,
			created_at,
			updated_at,
			grand_chart,
			cover,
			price_plan,
		} = data;

		this.id = id;
		this.price_plan_id = price_plan_id;
		this.grand_chart_id = grand_chart_id;
		this.name = name;
		this.price = price;
		this.stripe_id = stripe_id;
		this.stripe_name = stripe_name;
		this.stripe_is_active = stripe_is_active;
		this.stripe_default_price = stripe_default_price;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.grand_chart = grand_chart;
		this.cover = cover;
		this.price_plan = new PricePlanStore(price_plan);
	}
}
