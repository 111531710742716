import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const SwitcherContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	left: -50px;
	margin: 0 auto;
	column-gap: 10px;
`;

export const SwitcherLabel = styled.label`
	${fontMixins(18)};
	line-height: 22px;
	color: ${COLORS.Dark_Gray};
`;

export const SwitcherPick = styled.div`
	position: absolute;
	top: 4px;
	left: 4px;
	width: 22px;
	height: 22px;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px -1px 2px rgba(0, 0, 0, 0.25);
	transition: all 0.2s;

	&:after {
		content: "";
		position: absolute;
		width: 4px;
		height: 16px;
		left: 9px;
		top: 3px;
		background: ${COLORS.White};
		border-radius: 5px;
		box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);
	}
`;

export const SwitcherBody = styled.div`
	position: relative;
	display: flex;
	width: 50px;
	height: 30px;
	border-radius: 8px;
	background: ${COLORS.MidGray};
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.25);

	&.active {
		${SwitcherPick} {
			left: 24px;
		}
	}
`;
