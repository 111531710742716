import * as React from "react";
import { useEffect } from "react";
import { ModalPortal } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { GrandChart } from "../../GrandChart/GrandChart";

interface GrandChartModalProps {}

export const GrandChartModal = observer(({}: GrandChartModalProps) => {
	const { grandCharts } = useStores();

	useEffect(() => {
		window.addEventListener("keyup", handleCloseByEsc);

		return () => {
			window.removeEventListener("keyup", handleCloseByEsc);
		};
	}, []);

	const handleCloseByEsc = (e: KeyboardEvent) => {
		if (e.key === "Escape") {
			grandCharts.modal.close();
		}
	};

	return (
		<ModalPortal modalStore={grandCharts.modal} transparent>
			<GrandChart />
		</ModalPortal>

		/*<Modal modalStore={grandCharts.modal} isShow={grandCharts.modal.isShow} auto grandChart>
			<GrandChart />
		</Modal>*/
	);
});
