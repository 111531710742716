import { action, computed, makeObservable, observable } from "mobx";
import { GrandChartCourse } from "../../interfaces";
import { CourseStore } from "../courses/course.store";

export class GrandChartCourseStore implements GrandChartCourse {
	PATH = "grand-chart-courses";

	@observable id = 0;
	@observable grand_chart_id = 0;
	@observable course_id = 0;
	@observable sort = 0;
	@observable total_second = 0;
	@observable total_progress_second = 0;
	@observable course = new CourseStore(null);

	constructor(initialData: GrandChartCourse | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get passedProgress(): number {
		const totalPercent = Math.round((this.total_progress_second * 100) / this.total_second);
		const completePercent = parseInt(String(totalPercent));
		return completePercent > 100 ? 100 : completePercent || 0;
	}

	@action
	fillStore(data: GrandChartCourse) {
		const { id, grand_chart_id, course_id, sort, total_second, total_progress_second, course } = data;

		this.id = id || 0;
		this.grand_chart_id = grand_chart_id;
		this.course_id = course_id;
		this.sort = sort;
		this.total_second = total_second || 0;
		this.total_progress_second = total_progress_second || 0;
		this.course = new CourseStore(course || null);
	}
}
