import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../styles";

export const Container = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	height: 861px;
	background: ${COLORS.Black};

	img {
		width: 100%;
		max-height: 861px;
		object-fit: cover;
	}
`;

export const Background = styled.img`
	display: flex;
	width: 100%;
	height: 750px;
	object-fit: cover;
`;

export const InformationBlock = styled.div`
	position: absolute;
	transform: translateX(-50%);
	top: 125px;
	left: 50%;
	z-index: 200;
	padding: 0 0 70px;
	width: 1200px;
	text-align: center;

	${phoneQuery(css`
		top: 100px;
		width: 100%;
		padding-inline: 15px;
	`)}

	img {
		${phoneQuery(css`
			width: 200px;
		`)}
	}

	h1 {
		${fontMixins(60, 700)};
		color: ${COLORS.White};
		line-height: 74px;
		margin-bottom: 32px;

		span {
			font-size: 63px;
		}

		${phoneQuery(css`
			${fontMixins(35, 700)};
			line-height: 40px;

			span {
				${fontMixins(35, 700)};
			}
		`)}
	}

	p {
		padding: 0;
		font-weight: 400;
		font-size: 32px;
		color: ${COLORS.White};
		margin-bottom: 32px;
		line-height: 38px;

		&:nth-child(4) {
			font-size: 20px;
			line-height: 26px;
		}

		${phoneQuery(css`
			${fontMixins(24, 300)};
			line-height: 28px;
		`)}
	}
`;

export const ExtraInformation = styled.div`
	color: ${COLORS.White};
	${fontMixins(12, 300)};
	max-width: 636px;
	margin: 25px auto 0;
	line-height: 17px;
`;
