import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const ThirdBlockContainer = styled.div`
	position: relative;
	padding: 100px 0;
`;

export const Container = styled.div`
	display: grid;
	margin: 0 auto;
	width: 960px;
`;

export const List = styled.ul`
	display: grid;
	grid-template-columns: 1fr;
	padding: 0 0 0 20px;
	margin: 0 0 60px;
	list-style: outside;
	row-gap: 20px;

	li {
		color: ${COLORS.White};
		${fontMixins(22)}
	}
`;

export const Actions = styled.div`
	text-align: center;
`;
