import { action, makeObservable, observable } from "mobx";
import { Instrument } from "../../interfaces/instrument/instrument.interface";
import { INSTRUMENT_NAME } from "../../constants";
import dayjs from "dayjs";

export class ProductInstrumentStore implements Instrument {
	@observable id = 0;
	@observable service_id = 0;
	@observable sort = 0;
	@observable slug = INSTRUMENT_NAME.GUITAR;
	@observable meta_title = "";
	@observable meta_description = "";
	@observable meta_keywords = "";
	@observable name = "";
	@observable description = "";
	@observable icon = "";
	@observable is_active = false;
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: Instrument | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	fillStore(data: Instrument) {
		const {
			id,
			service_id,
			sort,
			slug,
			meta_title,
			meta_description,
			meta_keywords,
			name,
			description,
			icon,
			is_active,
			is_delete,
			created_at,
			updated_at,
		} = data;

		this.id = id;
		this.service_id = service_id;
		this.sort = sort;
		this.slug = slug;
		this.meta_title = meta_title;
		this.meta_description = meta_description;
		this.meta_keywords = meta_keywords;
		this.name = name;
		this.description = description;
		this.icon = icon;
		this.is_active = is_active;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
