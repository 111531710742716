import { css, CSSProp } from "styled-components";
import { BREAKPOINT } from "./breakpoints";
import { NOTIFICATION_TYPE, ServiceName } from "../constants";
import { COLORS } from "./colors.styled";

interface FontMixinI {
	size?: number;
	weight?: number;
}

export const fontMixin = ({ size = 18, weight = 400 }: FontMixinI) => css`
	font-size: ${size}px;
	font-family: "Montserrat", sans-serif;
	font-weight: ${weight};
`;

export const mediaQuery = (inner: CSSProp): CSSProp => {
	return css`
		@media (max-width: ${BREAKPOINT.SM}px) {
			${inner};
		}
	`;
};

export const phoneQuery = (innerStyle: CSSProp) => {
	return css`
		@media (max-width: ${BREAKPOINT.LG}px) {
			${innerStyle};
		}
	`;
};

export const fullHDQuery = () => {
	return css`
		@media (min-width: 900px) and (max-width: 1920px) {
			zoom: 80%;
		}
	`;
};

export const fontMixins = (size = 18, weight = 400): CSSProp => css`
	font-size: ${pxToEm(size)};
	font-weight: ${weight};
	font-family: "Montserrat", sans-serif;
`;

export const pxToEm = (size = 18) => {
	return `${size / 18}rem`;
};

export const getColorByService = (service: ServiceName | undefined): string => {
	switch (service) {
		case ServiceName.School:
		case ServiceName.PreBeginner:
			return COLORS.School;
		case ServiceName.College:
		case ServiceName.PreAdvanced:
			return COLORS.College;
		case ServiceName.University:
		case ServiceName.PreProfessional:
			return COLORS.University;
		default:
			return COLORS.Gold;
	}
};

export const getServiceId = (service_id: number): ServiceName => {
	switch (service_id) {
		case 1:
			return ServiceName.School;
		case 2:
			return ServiceName.College;
		case 3:
			return ServiceName.University;

		case 8:
			return ServiceName.School;
		case 9:
			return ServiceName.College;
		case 10:
			return ServiceName.University;
		default:
			return ServiceName.School;
	}
};

export const getColorByServiceId = (service_id: number): string => {
	switch (service_id) {
		case 1:
			return COLORS.School;
		case 2:
			return COLORS.College;
		case 3:
			return COLORS.University;
		default:
			return COLORS.Gold;
	}
};

export const getColorDarkByService = (service: ServiceName | undefined): string => {
	switch (service) {
		case ServiceName.School:
			return COLORS.Dark_Gray;
		case ServiceName.College:
			return COLORS.Dark_Gray;
		case ServiceName.University:
			return COLORS.Dark_Gray;
		default:
			return COLORS.Gold;
	}
};

export const getColorByTypeNotification = (type: NOTIFICATION_TYPE | undefined): string => {
	switch (type) {
		case NOTIFICATION_TYPE.SUCCESS:
			return COLORS.University;
		case NOTIFICATION_TYPE.ERROR:
			return COLORS.Red;
		default:
			return COLORS.Gold;
	}
};
