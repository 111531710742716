import { action, computed, makeObservable, observable } from "mobx";
import { GrandChartMyDayStore } from "./grand-chart-my-day.store";
import { GrandChartMyDay, GrandChartMyDayProgress } from "../../../interfaces";
import { ErrorAPI } from "../../../helpers";
import { API } from "../../../core";
import { CoreResponse, GrandChartsMyDayResponse } from "../../../response";
import { FILTER_INSTRUMENTS, FILTER_LEVEL, FILTER_SUBSCRIPTIONS } from "../../../constants";
import { RootStore } from "../../index";

export class GrandChartsMyDayStore {
	@observable isLoading = false;

	@observable list: GrandChartMyDayStore[] = [];
	@observable listProgress: GrandChartMyDayProgress = {};

	@observable filtersSubscriptions: FILTER_SUBSCRIPTIONS =
		Number(localStorage.getItem("filtersSubscriptions")) || FILTER_SUBSCRIPTIONS.ALL;
	@observable filtersInstruments: FILTER_INSTRUMENTS[] = localStorage.getItem("filtersInstruments")
		? [Number(localStorage.getItem("filtersInstruments"))]
		: [FILTER_INSTRUMENTS.GUITAR];
	@observable filtersLevels: FILTER_LEVEL[] = localStorage.getItem("filtersLevel")
		? [Number(localStorage.getItem("filtersLevel"))]
		: [FILTER_LEVEL.SCHOOL];
	@observable instrument = 1;
	@observable level = 1;

	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	setInstrument(value: number) {
		this.instrument = value;
	}

	@action.bound
	setLevel(value: number) {
		this.level = value;
	}

	@action.bound
	setFiltersSubscriptions(value: FILTER_SUBSCRIPTIONS) {
		localStorage.setItem("filtersSubscriptions", String(value));
		this.filtersSubscriptions = value;
	}

	@action.bound
	setFiltersInstruments(value: FILTER_INSTRUMENTS) {
		localStorage.setItem("filtersInstruments", String(value));
		this.filtersInstruments = [value];
	}

	@action.bound
	setAllInstrument() {
		if (this.filtersInstruments.length === 3) {
			this.filtersInstruments = [];
		} else {
			this.filtersInstruments = [FILTER_INSTRUMENTS.GUITAR, FILTER_INSTRUMENTS.KEYBOARD, FILTER_INSTRUMENTS.SAXOPHONE];
		}
	}

	@action.bound
	setFiltersLevel(value: FILTER_LEVEL) {
		localStorage.setItem("filtersLevel", String(value));
		this.filtersLevels = [value];
	}

	@action.bound
	setAllLevels() {
		if (this.filtersLevels.length === 3) {
			this.filtersLevels = [];
		} else {
			this.filtersLevels = [FILTER_LEVEL.SCHOOL, FILTER_LEVEL.COLLEGE, FILTER_LEVEL.UNIVERSITY];
		}
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setList(values: GrandChartMyDay[]) {
		this.list = (values || []).map((value) => new GrandChartMyDayStore(value, this.listProgress));
	}

	@action.bound
	setListProgress(values: GrandChartMyDayProgress) {
		this.listProgress = values;
	}

	@action.bound
	async getList() {
		this.setIsLoading(true);

		try {
			const { data } = await API.request<CoreResponse<GrandChartsMyDayResponse>>(
				`my-day?instrument_id=${this.filtersInstruments[0]}&service_id=${this.filtersLevels[0]}`,
			);

			this.setListProgress(data.courses_progress);
			this.setList(data.courses);
		} catch (e) {
			ErrorAPI("getList", e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@computed
	get filteredData() {
		let list = [...this.list];

		if (this.filtersSubscriptions === FILTER_SUBSCRIPTIONS.PURCHASED) {
			const product_purchases = this.rootStore.authStore.userStore.product_purchases
				.filter((product_purchases) => !product_purchases.is_expired)
				.map((product) => product.product.grand_chart_id);

			list = list.filter((grandChartMyDay) => product_purchases.includes(grandChartMyDay.id));
		}

		list = list.filter((item) => this.filtersInstruments.includes(item.instrument.id));
		list = list.filter((item) => this.filtersLevels.includes(item.service.id));

		let completeSorting: GrandChartMyDayStore[] = [];

		if (this.instrument === 1) {
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 1)];
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 2)];
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 4)];
		} else if (this.instrument === 2) {
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 2)];
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 4)];
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 1)];
		} else if (this.instrument === 4) {
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 4)];
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 1)];
			completeSorting = [...completeSorting, ...list.filter((item) => item.instrument.id === 2)];
		}

		let resultInstrument: GrandChartMyDayStore[] = [...completeSorting];
		let completeInstrumentsSorting: GrandChartMyDayStore[] = [];

		// Level
		if (this.level === 1) {
			completeInstrumentsSorting = resultInstrument.filter((item) => item.service.id === 1);

			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 2),
			];

			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 3),
			];
		} else if (this.level === 2) {
			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 2),
			];
			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 3),
			];
			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 1),
			];
		} else if (this.level === 3) {
			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 3),
			];
			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 1),
			];
			completeInstrumentsSorting = [
				...completeInstrumentsSorting,
				...resultInstrument.filter((item) => item.service.id === 2),
			];
		}

		return completeInstrumentsSorting;
	}
}
