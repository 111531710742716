import * as React from "react";
import { Container, Content, Footer, Header } from "./RemPage.styled";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useEffect } from "react";
import { HEADER_TYPE } from "../../constants";

interface RemPageProps {}

export const RemPage = observer(({}: RemPageProps) => {
	const { settingsStore } = useStores();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
	}, []);

	return (
		<Container>
			<Header />
			<Content>Coming soon</Content>
			<Footer>@MUSICABINET</Footer>
		</Container>
	);
});
