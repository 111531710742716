import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../styles";
import { fontMixin } from "../../../styles/mixins.styled";

interface ModalContainerI {
	isShow: boolean;
	auto: boolean;
	grandChart: boolean;
}

export const ModalContainer = styled.div<ModalContainerI>`
	width: 100vw;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	transition: all 0.2ms;
	z-index: 100000;

	${(props) =>
		props.grandChart &&
		css`
			align-items: self-start;
			overflow: auto;
		`}

	${(props) =>
		!props.grandChart &&
		css`
			align-items: center;
		`}

  ${(props) =>
		props.grandChart &&
		css`
			${ModalDialog} {
				display: grid;
				margin: 50px 0;
				overflow: auto;
			}
		`}

  ${(props) =>
		!props.auto &&
		css`
			${ModalDialog} {
				flex: 1;
				max-width: 960px;
			}
		`}

  ${(props) =>
		props.isShow
			? css`
					opacity: 1;
					pointer-events: all;
			  `
			: css`
					opacity: 0;
					pointer-events: none;
			  `};
`;

interface ModalDialogI {
	isShow: boolean;
	transparent: boolean;
}

export const ModalDialog = styled.div<ModalDialogI>`
	position: relative;

	background: ${(props) => (props.transparent ? "transparent" : COLORS.White)};
	transition: all 0.2s;
	transform: ${(props) => (props.isShow ? css`scale(1)` : css`scale(0.01)`)};
	border-radius: 10px;
`;

export const ModalHeader = styled.header`
	${fontMixin({ size: 36, weight: 700 })};
	line-height: 44px;
	color: ${COLORS.White};

	${phoneQuery(css`
		${fontMixins(30, 700)};
		line-height: 38px;
	`)}
`;

interface ModalCloseButtonI {
	hide: boolean;
}

export const ModalCloseButton = styled.button<ModalCloseButtonI>`
	position: absolute;
	top: -37px;
	right: 7px;
	width: 30px;
	height: 30px;
	border: 2px solid ${COLORS.White};
	border-radius: 50%;
	background: transparent;
	padding: 0;
	margin: 0;
	cursor: pointer;
	transition: all 0.2s;
	display: ${(props) => (props.hide ? "none" : "flex")};

	&:active {
		transform: scale(0.95);
	}

	&:before,
	&:after {
		content: " ";
		position: absolute;
		top: 4px;
		left: 12px;
		height: 18px;
		width: 2px;
		background-color: ${COLORS.White};
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`;
