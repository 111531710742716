import { action, computed, makeObservable, observable } from "mobx";
import { TrialVersionI } from "../../interfaces";
import dayjs from "dayjs";

export class TrialVersionStore implements TrialVersionI {
	@observable date_start = dayjs();
	@observable date_end = dayjs();
	@observable is_expired = true;
	@observable days_remain = 0;
	@observable days_passed = 0;
	@observable total_days = 0;

	@observable isShow = false;

	constructor(initialData: TrialVersionI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	close() {
		this.isShow = false;
	}

	@action.bound
	setIsShow(value: boolean) {
		this.isShow = value;
	}

	@computed
	get passedPercent() {
		return (this.days_passed * 100) / this.total_days;
	}

	@computed
	get daysLeft() {
		if (this.total_days === this.days_passed) {
			return 0;
		}

		return this.days_remain;
	}

	@action
	fillStore(data: TrialVersionI) {
		const { date_start, date_end, is_expired, days_remain, days_passed, total_days } = data;

		this.date_start = dayjs(date_start);
		this.date_end = dayjs(date_end);
		this.is_expired = is_expired;
		this.days_remain = days_remain;
		this.days_passed = days_passed;
		this.total_days = total_days;
	}
}
