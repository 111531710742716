import * as React from "react";
import { TitleContainer } from "./Title.styled";
import { HTMLAttributes } from "react";

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode;
}

export const Title = ({ children, ...props }: TitleProps) => {
	return <TitleContainer {...props}>{children}</TitleContainer>;
};
