import styled, { css } from "styled-components";
import { ServiceName } from "../../../constants";
import { COLORS, fontMixins, getColorByService } from "../../../styles";
import { rgba } from "polished";
import { Switcher } from "./AutoRenew/AutoRenew.styled";
import { DurationPurchase } from "../../../interfaces";

export const Logotype = styled.div`
	display: flex;
	margin-left: 15px;

	svg {
		width: 27px;
		height: 29px;
	}
`;

export const NameInstrument = styled.div`
	display: flex;
	${fontMixins(14, 500)}
`;

interface ContainerProps {
	theme: ServiceName;
}

export const Container = styled.div<ContainerProps>`
	display: grid;
	grid-template-columns: 60px 200px 30px 160px minmax(50px, 100px) 130px 160px auto;
	height: 66px;
	align-items: center;
	border-bottom: 1px solid #d5d5d5;
	transition: all 0.2s;
	padding-inline: 0 16px;

	&:last-child {
		border-bottom: 0;
	}

	${(props) =>
		props.theme &&
		css`
			&:hover {
				background: #f8f8f8;

				${Logotype} {
					svg {
						filter: ${(props) => `drop-shadow(3px 5px 4px ${rgba(getColorByService(props.theme), 0.4)} )`};
					}
				}

				${NameInstrument} {
					color: ${getColorByService(props.theme)};
				}
			}
		`}

	${(props) =>
		props.theme &&
		css`
			${Logotype} {
				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}

					&#keys {
						path {
							&:nth-child(2),
							&:nth-child(4),
							&:nth-child(8),
							&:nth-child(10),
							&:nth-child(12) {
								fill: ${getColorByService(props.theme)};
							}
						}
					}
				}
			}

			${GrandChartButton} {
				svg {
					rect {
						fill: ${getColorByService(props.theme)};
					}
				}

				&:hover {
					background: ${getColorByService(props.theme)};

					svg {
						rect {
							fill: ${COLORS.White};
						}
					}
				}
			}

			${Switcher} {
				background: ${getColorByService(props.theme)};
			}
		`}
`;

export const GrandChartButton = styled.button`
	border: 0;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: transparent;
	width: 30px;
	height: 30px;
	border-radius: 3px;
	transition: all 0.2s;
	cursor: pointer;
`;

interface VideoWatchProps {
	isExist: boolean;
}

export const VideoWatch = styled.div<VideoWatchProps>`
	display: flex;
	align-items: center;
	margin-left: 18px;
	${fontMixins(14)};
	color: #7a7a7a;
	cursor: not-allowed;
	margin-right: 24px;
	transition: all 0.2s;

	svg {
		display: flex;
		margin-right: 10px;
	}

	${(props) =>
		props.isExist &&
		css`
			color: #6a6a6a;
			cursor: pointer;

			svg {
				path {
					fill: #646464;
				}
			}

			&:active {
				transform: scale(0.98);
			}
		`}
`;

interface StatusContainerProps {
	isTrial: boolean;
	durationType: DurationPurchase | null;
}

export const StatusContainer = styled.div<StatusContainerProps>`
	display: flex;
	align-items: center;
	height: 24px;
	background: ${COLORS.Gold};
	${fontMixins(14)};
	color: ${COLORS.White};
	width: fit-content;
	padding-inline: 5px;
	border-radius: 4px;

	${(props) =>
		props.isTrial &&
		css`
			background: #e50000;
		`}

	${(props) =>
		props.durationType === DurationPurchase.Monthly &&
		css`
			background: #f9d900;
			color: #544f2c;
		`}

  ${(props) =>
		props.durationType === DurationPurchase.Yearly &&
		css`
			background: #d4ae05;
		`}
`;
