import styled from "styled-components";

export const Container = styled.div``;

export const FormWrapper = styled.form`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 15px 30px;

	[data-input]:first-child {
		grid-column: 1 / span 2;
	}

	[data-action] {
		grid-column: 1 / span 2;
	}
`;

export const Actions = styled.div`
	display: flex;
	justify-content: end;
`;
