import * as React from "react";
import { HTMLAttributes } from "react";
import { Actions, Container, List, ThirdBlockContainer } from "./ThirdBlock.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { COLORS, getColorByService } from "../../../styles";
import { ButtonStartTrial, Title } from "../../../components/common";

interface ThirdBlockProps extends HTMLAttributes<HTMLDivElement> {}

export const ThirdBlock = observer(({}: ThirdBlockProps) => {
	const { pageStore } = useStores();

	console.log("pageStore.service", pageStore.service);

	return (
		<ThirdBlockContainer style={{ background: getColorByService(pageStore.currentServiceMap) }}>
			<Container>
				<Title style={{ fontSize: "42px", textAlign: "left", color: COLORS.White, marginBottom: "50px" }}>
					{pageStore.familiarHeader}
				</Title>

				<List>
					<li>Sign up</li>
					<li>Get familiar with the interface</li>
					<li>Start learning</li>
					<li>Study every day</li>
					<li>You will feel the results in a week</li>
				</List>

				<Actions>
					<ButtonStartTrial />
				</Actions>
			</Container>
		</ThirdBlockContainer>
	);
});
