import { action, makeObservable, observable } from "mobx";
import { ToasterItemStore } from "./toaster-item.store";
import { ToasterI } from "../../interfaces";

export class ToasterStore {
	@observable list: ToasterItemStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	addToast(value: ToasterI) {
		let list = [...this.list];
		list.push(new ToasterItemStore(value, this));
		this.list.push(new ToasterItemStore(value, this));
		this.list = list;
	}
}
