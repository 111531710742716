import { Container } from "./CurrentStatus.styled";
import { IconInfinity } from "../../../../components/common/Icons";
import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useCheckPurchase } from "../../../../hooks/useCheckPurchase";
import { DurationPurchase } from "../../../../interfaces";
import dayjs from "dayjs";
import { UserPurchaseStore } from "../../../../stores/user-purchases/user-purchase.store";

interface CurrentStatusProps {
	grandChart: GrandChartStore;
}

export const CurrentStatus = observer(({ grandChart }: CurrentStatusProps) => {
	const {
		authStore: { userStore },
	} = useStores();

	let userPurchaseStore: UserPurchaseStore | null = null;

	// Условие для группового гранд чарта
	if (userStore.unitingPurchases[grandChart.id]) {
		userPurchaseStore = userStore.unitingPurchases[grandChart.id];
	} else {
		const result = useCheckPurchase(userStore.flatPurchases, grandChart.id);
		if (result[0]) {
			userPurchaseStore = result[0];
		}
	}

	const getInformation = () => {
		if (userPurchaseStore) {
			if (userPurchaseStore.duration === DurationPurchase.Forever) {
				return (
					<span>
						<IconInfinity />
					</span>
				);
			}

			if (userPurchaseStore.duration === DurationPurchase.Monthly) {
				// Получаем текущий год и прибавляем еще год
				const countMonth = dayjs().diff(userPurchaseStore.period_start, "month") + 1;
				const completeDate = dayjs(userPurchaseStore.period_start).add(countMonth, "month").format("DD MMM YYYY");

				if (!userPurchaseStore.period_end) {
					return (
						<div>
							Renews on <span>{completeDate}</span>
						</div>
					);
				} else {
					return <div>Expires on {userPurchaseStore.period_end.format("DD MMM YYYY")}</div>;
				}
			}

			if (userPurchaseStore.duration === DurationPurchase.Yearly) {
				// Получаем текущий год и прибавляем еще год
				const countYears = dayjs().diff(userPurchaseStore.period_start, "year") + 1;
				const completeDate = dayjs(userPurchaseStore.period_start).add(countYears, "year").format("DD MMM YYYY");

				if (!userPurchaseStore.period_end) {
					return (
						<div>
							Renews on <span>{completeDate}</span>
						</div>
					);
				} else {
					return <div>Expires on {userPurchaseStore.period_end.format("DD MMM YYYY")}</div>;
				}
			}
		} else {
			return <span />;
		}
	};

	return <Container>{getInformation()}</Container>;
});
