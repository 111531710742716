import * as React from "react";
import { Container } from "./GroupLessons.styled";
import { GrandChartModuleStore } from "../../../../../stores/grand-charts/grand-chart-module.store";
import { GrandChartCourseStore } from "../../../../../stores/grand-charts/grand-chart-course.store";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { GroupLessonsItem } from "./GroupLessonsItem";

interface GroupLessonsProps {
	grandChartModule: GrandChartModuleStore;
	grandChartCourse: GrandChartCourseStore;
}

export const GroupLessons = observer(({ grandChartCourse, grandChartModule }: GroupLessonsProps) => {
	const { grandCharts } = useStores();
	const { selected } = grandCharts;

	return (
		<Container>
			{(selected.group_lessons_map[`${grandChartCourse.id}-${grandChartModule.id}`] || []).map(
				(grandChartGroupLesson) => {
					return <GroupLessonsItem key={grandChartGroupLesson.id} grandChartGroupLesson={grandChartGroupLesson} />;
				},
			)}
		</Container>
	);
});
