import * as React from "react";
import { MenuContainer, MenuList } from "./Menu.styled";
import { MenuItem } from "./MenuItem";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";

interface MenuProps {}

export const Menu = observer(({}: MenuProps) => {
	const { menuStore } = useStores();

	return (
		<MenuContainer>
			<MenuList>
				{menuStore.list.map((menu) => {
					return <MenuItem key={menu.link} {...menu} />;
				})}
			</MenuList>
		</MenuContainer>
	);
});
