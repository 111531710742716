import { action, makeObservable, observable } from "mobx";
import { LibraryScoreItemPivot } from "../../interfaces/scores";

export class LibraryScoreItemPivotStore implements LibraryScoreItemPivot {
	@observable id = 0;
	@observable score_item_id = 0;
	@observable library_id = 0;
	@observable name = "";

	constructor(initialData: LibraryScoreItemPivot | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: LibraryScoreItemPivot) {
		const { id, score_item_id, library_id, name } = data;

		this.id = id;
		this.score_item_id = score_item_id;
		this.library_id = library_id;
		this.name = name;
	}
}
