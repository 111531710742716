import * as React from "react";
import { IconContainer, MessageRotateContainer } from "./MessageRotate.styled";
import { isMobileDevice } from "../../../helpers";
import { useEffect, useState } from "react";
import { ICON_PHONE_ROTATE } from "../Icons";
import useWindowOrientation from "use-window-orientation";

interface MessageRotateProps {}

export const MessageRotate = ({}: MessageRotateProps) => {
	const [isResize, setIsResize] = useState(false);
	const [isRotate, setIsRotate] = useState(false);
	const { orientation } = useWindowOrientation();

	useEffect(() => {
		window.addEventListener("resize", handleOnDetect, false);

		return () => {
			window.removeEventListener("resize", handleOnDetect, false);
		};
	}, []);

	useEffect(() => {
		if (orientation === "landscape") {
			setIsRotate(true);
		} else {
			setIsRotate(false);
		}
	}, [isResize]);

	const handleOnDetect = () => {
		setIsResize((prevState) => !prevState);
	};

	if (isRotate || !isMobileDevice()) {
		return null;
	}

	return (
		<MessageRotateContainer>
			Please rotate your device
			<IconContainer>
				<img src={ICON_PHONE_ROTATE} alt="" />
			</IconContainer>
		</MessageRotateContainer>
	);
};
