import styled from "styled-components";
import { COLORS } from "../../../styles";

export const SixthBlockContainer = styled.div`
	padding: 50px 0 100px;
`;

export const Container = styled.div`
	display: grid;
	width: 960px;
	margin: 0 auto;
`;

export const Description = styled.div`
	width: 500px;
	font-size: 22px;
	line-height: 30px;
	color: ${COLORS.White};
`;

export const Picture = styled.picture`
	position: relative;
	display: flex;
	justify-content: flex-end;
	left: 100px;
	top: -100px;
`;
