import { action, makeObservable, observable } from "mobx";
import { DurationStore } from "./duration.store";
import { Duration } from "../../interfaces";
import { API } from "../../core";
import { CoreResponse } from "../../response";

export class DurationsStore {
	@observable choose_id = 1;

	@observable list: DurationStore[] = [];

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setChooseId(value: number) {
		this.choose_id = value;
	}

	@action.bound
	setList(values: Duration[]) {
		this.list = (values || []).map((duration) => new DurationStore(duration));
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<Duration[]>>(`durations`);

			this.setList(data);
		} catch (e) {
			console.error(`Error in method getList : `, e);
		}
	}
}
