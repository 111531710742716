import styled, { css } from "styled-components";
import { ServiceName } from "../../../../constants";
import { COLORS, fontMixins } from "../../../../styles";

interface ContainerProps {
	theme: ServiceName;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	align-items: center;
	${fontMixins(14)};
	column-gap: 7px;
`;

interface SwitcherProps {
	checked: boolean;
}

export const Switcher = styled.div<SwitcherProps>`
	position: relative;
	width: 16.5px;
	height: 9px;

	border-radius: 4px;
	cursor: not-allowed;

	&:after {
		position: absolute;
		left: 0.5px;
		top: 0.5px;
		content: "";
		display: flex;
		width: 8px;
		height: 8px;
		background: ${COLORS.White};
		border-radius: 50%;
	}

	${(props) =>
		props.checked &&
		css`
			&:after {
				left: auto;
				right: 0.5px;
			}
		`}

	${(props) =>
		!props.checked &&
		css`
			background: #7a7a7a !important;
		`}
`;
