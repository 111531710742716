import * as React from "react";
import { Container, Link } from "./EditProfileButton.styled";
import { useNavigate } from "react-router-dom";
import { ICON_GEAR } from "../../../../../common/Icons";

export const EditProfileButton = () => {
	const navigate = useNavigate();

	const handleOnProfile = () => navigate("/cabinet/profile");

	return (
		<Container>
			<Link onClick={handleOnProfile}>
				<>
					<ICON_GEAR />
					Edit Profile
				</>
			</Link>
		</Container>
	);
};
