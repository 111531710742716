import styled from "styled-components";

export const Container = styled.div`
	display: grid;
	align-items: flex-start;
	grid-template-columns: 1fr;
	height: 100%;
	background: linear-gradient(180deg, #000000 30%, rgba(0, 0, 0, 0.6) 100%);
	padding: 0 0 70px;
`;
