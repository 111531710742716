import { PasswordChangeForm, VerifyEmailContainer } from "./VerifyEmail.styled";
import { Container, Title } from "../ContactPage/ContactPage.styled";
import * as React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useEffect } from "react";
import { HEADER_TYPE } from "../../constants";
import { Button, InputField } from "../../components/common";
import { useFormik } from "formik";
import { VerificationEmailSchema } from "../../validation-schems";
import { useNavigate, useParams } from "react-router-dom";

interface VerifyEmailPageProps {}

export const VerifyEmailPage = observer(({}: VerifyEmailPageProps) => {
	const { authStore, settingsStore } = useStores();
	const params = useParams();
	const navigation = useNavigate();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
	}, []);

	useEffect(() => {
		formik.setFieldValue("user_id", Number(params.id));
		formik.setFieldValue("email_verification_hash", String(params.hash));
	}, [params]);

	const formik = useFormik({
		initialValues: {
			user_id: 0,
			email_verification_hash: "",
			password: "",
			password_confirmation: "",
		},
		onSubmit: async (values) => {
			try {
				await authStore.changePassword(values);

				// Очищаем форму
				formik.resetForm();

				// Переадресация
				navigation("/sign?tab=sign_in");
			} catch (e: any) {}
		},
		validationSchema: VerificationEmailSchema,
	});

	return (
		<>
			<VerifyEmailContainer>
				<Container>
					<Title>Verification email</Title>
					<div style={{ height: 30 }} />
					<PasswordChangeForm onSubmit={formik.handleSubmit}>
						<InputField type={"hidden"} name={"user_id"} value={formik.values.user_id} />
						<InputField
							type={"hidden"}
							name={"email_verification_hash"}
							value={formik.values.email_verification_hash}
						/>

						<InputField
							type={"password"}
							name={"password"}
							placeholder={"Password"}
							value={formik.values.password}
							errorMessage={formik.errors.password}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<InputField
							type={"password"}
							name={"password_confirmation"}
							placeholder={"Password confirm"}
							value={formik.values.password_confirmation}
							errorMessage={formik.errors.password_confirmation}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>

						<Button
							type={"submit"}
							disabled={
								!formik.dirty ||
								formik.errors.password !== undefined ||
								formik.errors.password_confirmation !== undefined
							}
						>
							Change password
						</Button>
					</PasswordChangeForm>
				</Container>
			</VerifyEmailContainer>
		</>
	);
});
