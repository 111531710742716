import styled from "styled-components";
import { COLORS, fontMixin } from "../../../styles";

export const CalendarContainer = styled.div``;

export const CalendarHeader = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	${fontMixin({ size: 14 })};
	line-height: 17px;
	height: 40px;
`;

export const CalendarTable = styled.table`
	width: 100%;

	thead {
		th {
			${fontMixin({ size: 10 })};
			line-height: 12px;
			width: 30px;
			height: 20px;
			text-align: center;
			vertical-align: middle;
			border-bottom: 0.3px solid ${COLORS.Light_Gray};
		}
	}

	tbody {
		td {
			width: 30px;
			height: 30px;
			padding: 2.5px 0;
			text-align: center;
			vertical-align: middle;
			${fontMixin({ size: 14 })}
			color: ${COLORS.MidGray};
			border-bottom: 0.3px solid ${COLORS.Light_Gray};
			user-select: none;

			&.default {
				color: ${COLORS.Black};
			}

			&.learning {
				background: ${COLORS.Red};
				color: ${COLORS.White};
				cursor: pointer;
			}

			&.today {
				color: ${COLORS.Dark_Gray};
				border: 2px solid ${COLORS.Red} !important;
				background: ${COLORS.White};
			}
		}

		tr {
			&:last-child {
				td {
					border-bottom: 0;
				}
			}
		}
	}
`;
