import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 30px;
	margin: 90px 0 130px;

	${phoneQuery(css`
		grid-template-columns: 1fr;
		row-gap: 30px;
	`)}
`;

export const ItemInstrument = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const NameInstrument = styled.div`
	display: flex;
	align-items: center;
	${fontMixins(36, 700)};
	color: ${COLORS.Gold};
	height: 70px;
	margin: 20px 0 0;
`;
