import {
	Actions,
	Container,
	Description,
	Info,
	ListResult,
	StartList,
	Title,
	WrapperStarIcon,
} from "./AiResultModal.styled";
import { Button, ModalPortal } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useEffect } from "react";
import { AiBpm } from "../../../../constants";

interface AiResultModalProps {}

export const AiResultModal = observer(({}: AiResultModalProps) => {
	const { aiStore, viewerStore } = useStores();

	const resultAI = viewerStore.chooseItem.getAiResultByBpm(aiStore.chosenBPM);

	useEffect(() => {
		(async () => {
			if (!resultAI) {
				return;
			}
		})();
	}, [resultAI]);

	const handleOnView = async () => {
		if (!resultAI) {
			return;
		}

		if (!resultAI.is_view) {
			// Проставляем что результат просмотрен
			await resultAI.update({
				is_view: true,
			});
		}

		aiStore.resultModal.close();
	};

	if (!resultAI) {
		return null;
	}

	const getStars = () => {
		switch (aiStore.chosenBPM) {
			case AiBpm.Bpm60: {
				return (
					<>
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 20} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 40} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 60} />
					</>
				);
			}
			case AiBpm.Bpm90:
				return (
					<>
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 20} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 40} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 60} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 80} />
					</>
				);
			default:
				return (
					<>
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 20} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 40} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 60} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 80} />
						<WrapperStarIcon isGold={resultAI.totalResultPercent > 85} />
					</>
				);
		}
	};

	return (
		<ModalPortal modalStore={aiStore.resultModal} transparent center>
			<Container>
				<Title>Well Done!</Title>

				<StartList>{getStars()}</StartList>

				<Info>Your overall score is {resultAI.totalResultPercent}%!</Info>

				<ListResult>
					<li>
						<span>{resultAI.notesMatchScorePercent}%</span>
						Note pitch accuracy
					</li>
					<li>
						<span>{resultAI.tempoMatchScorePercent}%</span>
						Tempo accuracy
					</li>
					<li>
						<span>{resultAI.tempoStandScorePercent}%</span>
						Tempo stability
					</li>
				</ListResult>

				<Description>
					Each star is equivalent to 20% of the overall score. To consider the GRAND CHART lesson complete, make sure to
					consistently earn over 60% (3+ stars) in each staff of the lesson!
				</Description>

				<Actions>
					<Button onClick={handleOnView}>Ok</Button>
				</Actions>
			</Container>
		</ModalPortal>
	);
});
