import * as React from "react";
import { ListContainer } from "./List.styled";

interface ListProps {
	isShow: boolean;
	children: React.ReactNode;
}

export const List = ({ isShow, children }: ListProps) => {
	return <ListContainer isShow={isShow}>{children}</ListContainer>;
};
