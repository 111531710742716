import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const ModulesContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 200px;
	background: ${COLORS.Light_Gray};
	border-radius: 10px;
`;

export const ModulesHeader = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin: 10px 10px 9.5px;
	text-align: center;
	${fontMixins(16, 300)};
	color: ${COLORS.Dark_Gray};

	span {
		position: relative;
		top: 6px;
	}
`;

interface ModuleButtonProps {
	left?: boolean;
	right?: boolean;
}

export const ModuleButton = styled.button<ModuleButtonProps>`
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 0;
	cursor: pointer;
	color: ${COLORS.White};
	${fontMixins(30)};
	line-height: 30px;
	transition: all 0.2s;
	background: ${COLORS.Gold};

	${(props) =>
		props.left &&
		css`
			left: 0;

			svg {
				position: relative;
				left: -1px;
			}
		`}
	${(props) =>
		props.right &&
		css`
			right: 0;

			svg {
				position: relative;
				right: -1px;
			}
		`}
  &:active {
		transform: scale(0.95);
	}
`;

export const ModuleCurrentSession = styled.div`
	position: relative;
	top: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 26px;
	${fontMixins(12)};
	color: ${COLORS.Dark_Gray};
`;
