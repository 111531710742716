import { Button, Modal } from "../../../common";
import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { observer } from "mobx-react";
import { ActionsButton, Container, Message, Title } from "./ConfirmCancelPlanModal.styled";
import { useStores } from "../../../../stores";
import { useCheckPurchase } from "../../../../hooks/useCheckPurchase";
import { ButtonTheme } from "../../../../constants";

interface ConfirmCancelPlanModalProps {
	grandChart: GrandChartStore;
}

export const ConfirmCancelPlanModal = observer(({ grandChart }: ConfirmCancelPlanModalProps) => {
	const {
		authStore: { userStore },
	} = useStores();

	const [userPurchaseStore] = useCheckPurchase(userStore.flatPurchases, grandChart.id);

	return (
		<Modal modalStore={grandChart.cancelModal} isShow={grandChart.cancelModal.isShow} maxWidth={395}>
			<Container>
				<Title>Are you sure?</Title>

				<Message>
					<p>
						You are about to cancel your current <span data-title>{grandChart.title}</span> active plan. It will remain
						active until the {userPurchaseStore && userPurchaseStore.finalDate} and you will keep access to the
						education materials until then.
					</p>

					<p>It will not be renewed after this date.</p>
				</Message>

				<ActionsButton>
					<Button theme={ButtonTheme.GRAY} onClick={grandChart.cancelModal.close}>
						Cancel
					</Button>

					<Button
						theme={ButtonTheme.GOLD}
						onClick={async () => {
							userPurchaseStore && (await userPurchaseStore.cancelSubscribe());
							grandChart.cancelModal.close();
						}}
					>
						Confirm
					</Button>
				</ActionsButton>
			</Container>
		</Modal>
	);
});
