import * as React from "react";
import {
	BackButton,
	GroupLessonItem,
	HoursPassed,
	HoursProgress,
	HoursRemain,
	HoursText,
	HoursTime,
	NameGroupLesson,
	PercentGroupLesson,
	PercentProgressGroupLesson,
	StatisticsGroupLesson,
} from "./GroupLessonView.styled";
import { GrandChartGroupLessonStore } from "../../../../stores/grand-charts/grand-chart-group-lesson.store";
import { observer } from "mobx-react";
import { ICON_BACK_COMPONENT } from "../../../common/Icons";
import { useStores } from "../../../../stores";
import { Lessons } from "./Lessons/Lessons";
import { calculatePercent, getTimeFromMin } from "../../../../helpers";
import { LocalStorage } from "../../../../core";
import { GROUP_LESSON_SELECTED } from "../../../../constants";

interface GroupLessonViewItemProps {
	isFirstGroupLesson: boolean;
	isFirst: boolean;
	grandChartGroupLesson: GrandChartGroupLessonStore;
}

export const GroupLessonViewItem = observer(
	({ isFirstGroupLesson, isFirst, grandChartGroupLesson }: GroupLessonViewItemProps) => {
		const { grandCharts, statisticsStore } = useStores();
		const { selected } = grandCharts;

		const currentPassedSecond = statisticsStore.getByGroupLessonId(grandChartGroupLesson.id);
		const currentPassedProgress = calculatePercent(grandChartGroupLesson.totalAllLessonsTime, currentPassedSecond);

		const handleOnBack = () => {
			selected.setShowGroupLesson(false);

			// Переходим снова к группе уроков
			setTimeout(() => {
				const findName = LocalStorage.get(GROUP_LESSON_SELECTED);

				if (findName) {
					const findElement = document.getElementById(findName);
					if (findElement) {
						findElement.scrollIntoView({ behavior: "smooth" });
						LocalStorage.remove(GROUP_LESSON_SELECTED);
					}
				}
			}, 0);
		};

		return (
			<GroupLessonItem id={`group-lesson-${grandChartGroupLesson.id}`}>
				{isFirst && (
					<BackButton onClick={handleOnBack}>
						<ICON_BACK_COMPONENT />
					</BackButton>
				)}
				<NameGroupLesson>{grandChartGroupLesson.name}</NameGroupLesson>
				<StatisticsGroupLesson>
					<PercentGroupLesson>{currentPassedProgress}%</PercentGroupLesson>
					<PercentProgressGroupLesson percent={currentPassedProgress} />
					<HoursProgress>
						<HoursPassed>
							<HoursText>Hours passed</HoursText>
							<HoursTime>{getTimeFromMin(Math.round(grandChartGroupLesson.total_progress_second / 60))}</HoursTime>
						</HoursPassed>

						<HoursRemain>
							<HoursText>Hours remain</HoursText>
							<HoursTime>{getTimeFromMin(Math.round(grandChartGroupLesson.totalAllLessonsTime / 60))}</HoursTime>
						</HoursRemain>
					</HoursProgress>
				</StatisticsGroupLesson>

				<Lessons lessons={grandChartGroupLesson.lessons} isFirstGroupLesson={isFirstGroupLesson} />
			</GroupLessonItem>
		);
	},
);
