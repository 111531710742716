import * as React from "react";
import { Container } from "./CounterLeft.styled";
import { CounterItem } from "./CounterItem";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { secondToDhm } from "../../../../helpers";

dayjs.extend(duration);

interface CounterLeftProps {}

export const CounterLeft = ({}: CounterLeftProps) => {
	const [days, setDays] = useState(0);
	const [hourse, setHourse] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);

	let idTimeout = 0;

	useEffect(() => {
		const endDate = dayjs("2023-01-09");
		const currentDate = dayjs();
		const diffDate = endDate.diff(currentDate, "seconds");
		const { daysCurrent, hoursCurrent, minutesCurrent, secondsCurrent } = secondToDhm(diffDate);

		setDays(daysCurrent);
		setHourse(hoursCurrent);
		setMinutes(minutesCurrent);
		setSeconds(secondsCurrent);

		startTimer();
	}, []);

	const startTimer = () => {
		const endDate = dayjs("2023-01-09");

		idTimeout = window.setTimeout(() => {
			const currentDate = dayjs();
			const diffDate = endDate.diff(currentDate, "seconds");
			const { daysCurrent, hoursCurrent, minutesCurrent, secondsCurrent } = secondToDhm(diffDate);

			setDays(daysCurrent);
			setHourse(hoursCurrent);
			setMinutes(minutesCurrent);
			setSeconds(secondsCurrent);

			startTimer();
		}, 1000);
		return () => {
			clearTimeout(idTimeout);
		};
	};

	return (
		<Container>
			<CounterItem count={days} description={"Days"} />
			<CounterItem count={hourse} description={"Hours"} />
			<CounterItem count={minutes} description={"Minutes"} />
			<CounterItem count={seconds} description={"Seconds"} />
		</Container>
	);
};
