import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const PercentageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: right;
	padding-right: 40px;
	text-align: right;
	row-gap: 15px;
`;

export const PercentageCurrent = styled.div`
	${fontMixins(40, 700)};
	line-height: 40px;
	height: 34px;
`;

export const PercentageDescription = styled.div`
	${fontMixins(18, 300)};
	color: ${COLORS.Dark_Gray};
	line-height: 22px;
`;
