import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { rgba } from "polished";
import { COLORS, phoneQuery } from "../../../styles";

export const Overlay = styled(motion.div)`
	position: fixed;
	inset: 0;
	background: ${rgba(COLORS.Bgrnd, 0.6)};
	z-index: 2000;
`;

export const Position = styled(motion.div)`
	position: fixed;
	inset: 0;
	overflow-y: auto;
	z-index: 11;
`;

interface ContainerProps {
	center: string;
}

export const Container = styled(motion.div)<ContainerProps>`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	padding: 30px 15px;

	${(props) =>
		props.center &&
		css`
			align-items: center;
		`}

	${phoneQuery(css`
		padding: 30px 20px;
	`)}
`;

interface BodyProps {
	transparent: boolean;
}

export const Body = styled.div<BodyProps>`
	padding: 20px;
	border-radius: 10px;
	background: ${COLORS.White};

	${(props) =>
		props.transparent &&
		css`
			background: transparent;
		`}

	${phoneQuery(css`
		width: 100%;
	`)}
`;

export const ButtonClose = styled.button`
	position: relative;
	border: 1px solid ${COLORS.White};
	border-radius: 50%;
	width: 30px;
	height: 30px;
	background: transparent;
	margin-left: auto;
	margin-bottom: 15px;

	&:active {
		transform: scale(0.95);
	}

	&:before,
	&:after {
		content: " ";
		position: absolute;
		top: 5px;
		left: 13px;
		height: 18px;
		width: 2px;
		background-color: ${COLORS.White};
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`;
