import { action, makeObservable, observable } from "mobx";
import { ProductPeriodStore } from "./product-period.store";
import { ProductPeriodI } from "../../interfaces";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { PRODUCT_PERIOD } from "../../constants";

export class ProductPeriodsStore {
	@observable list: ProductPeriodStore[] = [];
	@observable choosePeriod = PRODUCT_PERIOD.MONTH;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setChoosePeriod(value: PRODUCT_PERIOD) {
		this.choosePeriod = value;
	}

	@action.bound
	setList(values: ProductPeriodI[]) {
		this.list = (values || []).map((productPeriod) => new ProductPeriodStore(productPeriod));
	}

	@action.bound
	async getList() {
		try {
			const data = await API.request<ProductPeriodI[]>(`product-periods`);
			this.setList(data);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}
}
