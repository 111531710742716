import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../styles";

export const Container = styled.div`
	margin: 0 auto;
	cursor: pointer;
`;

export const NameInstrument = styled.div`
	${fontMixin({ size: 12, weight: 700 })};
	color: ${COLORS.White};
	text-transform: uppercase;
	text-align: center;
	margin: 0 0 5px;
`;

export const InstrumentIcon = styled.div`
	width: 45px;

	svg {
		width: 45px;
		height: 50px;
	}
`;
