import * as React from "react";
import {
	StatisticsLabels,
	StatisticsMonthContainer,
	StatisticsMonthRating,
	StatisticsMonthRatingDay,
	StatisticsMonthRatingItem,
	StatisticsMonthTitle,
} from "./StatisticsMonth.styled";
import { ContentBlock } from "../../../components/common";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useStores } from "../../../stores";

export const StatisticsMonth = observer(() => {
	const [currentDate, setCurrentDate] = useState(dayjs());
	const { mainStatisticsMonthStore } = useStores();

	useEffect(() => {
		(async () => {
			const cloneCurrentDate = currentDate.clone();
			const startDate = cloneCurrentDate.startOf("month").toISOString();
			const endDate = cloneCurrentDate.add(2, "months").endOf("month").toISOString();

			await mainStatisticsMonthStore.getList(startDate, endDate);
		})();
	}, [currentDate]);

	const handleOnPrev = () => {
		setCurrentDate((prevState) => prevState.subtract(1, "month"));
	};

	const handleOnNext = () => {
		setCurrentDate((prevState) => prevState.add(1, "month"));
	};

	const getPercent = (day: number) => {
		const findDay = `${day}-${currentDate.format("MM")}-${currentDate.format("YYYY")}`;
		const findProgress = mainStatisticsMonthStore.list[findDay];

		if (!findProgress) {
			return 0;
		}

		// Переводи текущее значение в часы
		const hoursPassed = findProgress / 60 / 60;
		return hoursPassed > 3 ? 100 : parseFloat(((hoursPassed * 100) / 3).toFixed(2));
	};

	return (
		<ContentBlock
			title={
				<>
					Main statistics <span>&nbsp;/&nbsp;Your daily activity in hours</span>
				</>
			}
			controls
			onNext={handleOnNext}
			onPrev={handleOnPrev}
		>
			<StatisticsMonthContainer>
				<StatisticsMonthTitle>{currentDate.format("MMMM YYYY")}</StatisticsMonthTitle>

				<StatisticsMonthRating>
					<StatisticsLabels>
						<span>3h</span>
						<span>2h</span>
						<span>1h</span>
					</StatisticsLabels>

					{Array.from(Array(currentDate.daysInMonth()).keys()).map((day) => {
						return (
							<StatisticsMonthRatingItem key={day} percent={getPercent(day + 1)}>
								<StatisticsMonthRatingDay>{day + 1}</StatisticsMonthRatingDay>
							</StatisticsMonthRatingItem>
						);
					})}
				</StatisticsMonthRating>
			</StatisticsMonthContainer>
		</ContentBlock>
	);
});
