import { action, makeObservable, observable } from "mobx";

export class ListStore {
	@observable isShow = false;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsShow(value: boolean) {
		this.isShow = value;
	}

	@action.bound
	open() {
		this.setIsShow(true);
	}

	@action.bound
	close() {
		this.setIsShow(false);
	}
}
