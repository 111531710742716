import * as React from "react";
import { Description, ItemContainer, ProgressCircle, ValueCircle } from "./CounterLeft.styled";

interface CounterItemProps {
	count: number;
	description: string;
}

export const CounterItem = ({ count, description }: CounterItemProps) => {
	return (
		<ItemContainer>
			<ProgressCircle>
				<ValueCircle>{count}</ValueCircle>
			</ProgressCircle>
			<Description>{description}</Description>
		</ItemContainer>
	);
};
