import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins, phoneQuery } from "../../../../styles";
import { rgba } from "polished";

export const Container = styled.div`
	position: relative;
	height: 50px;
	background: ${COLORS.White};
	border-radius: 10px;
	display: grid;
	grid-template-columns: 34px 1fr 30px;
	column-gap: 10px;
	align-items: center;
	padding: 5px;

	${phoneQuery(css`
		grid-template-columns: 34px 1fr 30px 130px;
		margin-right: 0;
		margin-bottom: 0;
	`)}
`;

export const NumberLesson = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 39px;
`;

export const CurrentLessonName = styled.div`
	display: flex;
	align-items: center;
	${fontMixins(16)};
	line-height: 25px;
	color: ${COLORS.Dark_Gray};
	overflow: hidden;

	${phoneQuery(css`
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	`)}
	div {
		width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

export const LessonTotalTime = styled.div`
	display: flex;
	align-items: center;
	${fontMixin({ size: 18 })};
	color: ${COLORS.MidGray};

	${phoneQuery(css`
		display: none;
	`)}
`;

export const IconPlayList = styled.button`
	cursor: pointer;
	display: flex;
	align-items: center;
	border: 0;
	padding: 0;
	margin: 0;
	background: transparent;
`;

export const Timer = styled.div`
	display: flex;
	align-items: center;
	color: ${COLORS.MidGray};
	${fontMixin({ size: 18 })};
	line-height: 25px;

	span {
		width: 22px;
		text-align: center;
	}
`;

export const PauseButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	padding: 0;
	margin: 0;
	background: transparent;

	&:before,
	&:after {
		content: "";
		position: absolute;
		top: 7px;
		width: 2px;
		height: 12px;
		background: ${COLORS.School};
	}

	&:before {
		margin-right: 5px;
	}

	&:after {
		margin-left: 5px;
	}
`;

export const TooltipLesson = styled.div`
	position: absolute;
	left: 0;
	top: 40px;
	display: flex;
	flex-direction: column;
	width: 244px;
	height: 78px;
	background: ${rgba(COLORS.Bgrnd, 0.85)};
	border-radius: 5px;
	z-index: 1000;
	padding: 10px 20px;
	font-size: 12px;
`;

export const TooltipTitle = styled.div`
	font-size: 12px;
	font-weight: 700;
	color: ${COLORS.Gold};
	letter-spacing: 0.24px;
	margin-bottom: 10px;

	span {
		color: ${COLORS.White};
	}
`;

export const TooltipList = styled.ul`
	display: flex;
	row-gap: 5px;
	flex-direction: column;
	margin: 0;
	padding: 0;
	list-style-position: inside;
	color: ${COLORS.Gold};

	li {
		list-style-position: inside;

		&:before {
			content: "\\2022"; /* Add content: \\2022 is the CSS Code/unicode for a bullet */
			color: ${COLORS.Gold}; /* Change the color */
			font-weight: bold; /* If you want it to be bold */
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
		}
	}
`;
