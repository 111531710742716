import {
	TrackListArrow,
	TrackListContainer,
	TrackListCurrentTrack,
	TrackListDropDown,
	TrackListDropDownItem,
} from "./TrackList.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { LibraryStore } from "../../../../../stores/library/library.store";
import { useEffect, useRef } from "react";
import { useOutside } from "../../../../../hooks";
import { LibraryScoreItemStore } from "../../../../../stores/library/library-score-item.store";
import { ServiceName } from "../../../../../constants";

export const TrackList = observer(() => {
	const trackListRef = useRef<HTMLDivElement>(null);
	const { lessonStore, audioPlayerStore, viewerStore } = useStores();

	const handleOnClose = (e: MouseEvent | TouchEvent) => {
		e.stopPropagation();
		e.preventDefault();

		audioPlayerStore.closeTrackList();
	};

	useOutside(trackListRef, handleOnClose);

	const handleOnToggle = () => {
		audioPlayerStore.setIsShowTrackList(!audioPlayerStore.isShowTrackList);
	};

	useEffect(() => {
		if (lessonStore.currentService === ServiceName.College) {
			if (lessonStore.charts.currentChart.firstLibrary) {
				audioPlayerStore.setSelectedLibrary(lessonStore.charts.currentChart.firstLibrary);
			}
		} else {
			if (lessonStore.scores.currentScore.firstLibrary) {
				audioPlayerStore.setSelectedLibrary(lessonStore.scores.currentScore.firstLibrary);
			}
		}
	}, []);

	const handleOnSetLibrary = async (library: LibraryStore | LibraryScoreItemStore, numberLibrary: number) => {
		audioPlayerStore.setSelectedLibrary(library);
		await audioPlayerStore.loadTrack();
		//viewerStore.setChooseItem(numberLibrary);
	};

	return (
		<TrackListContainer onClick={handleOnToggle}>
			<TrackListCurrentTrack>{audioPlayerStore.selectedLibrary.nameTrack || "No track"}</TrackListCurrentTrack>
			<TrackListArrow isShow={audioPlayerStore.isShowTrackList} />

			<TrackListDropDown show={audioPlayerStore.isShowTrackList}>
				{!lessonStore.scores.currentScore.isView &&
					lessonStore.scores.currentScore.allLibraries.map((library, numberLibrary) => {
						return (
							<TrackListDropDownItem
								key={library.id}
								className={audioPlayerStore.selectedLibrary.nameTrack === library.nameTrack ? "active" : ""}
								onClick={() => handleOnSetLibrary(library, numberLibrary)}
							>
								{library.nameTrack}
							</TrackListDropDownItem>
						);
					})}

				{lessonStore.scores.currentScore.isView &&
					viewerStore.libraries.map((library, numberLibrary) => {
						return (
							<TrackListDropDownItem
								key={library.id}
								className={audioPlayerStore.selectedLibrary.nameTrack === library.nameTrack ? "active" : ""}
								onClick={async (e) => {
									e.stopPropagation();
									e.preventDefault();

									await handleOnSetLibrary(library, numberLibrary);
								}}
							>
								{library.nameTrack}
							</TrackListDropDownItem>
						);
					})}
			</TrackListDropDown>
		</TrackListContainer>
	);
});
