import { action, computed, makeObservable, observable } from "mobx";
import { GrandChartCurrent, GrandChartGroupLesson } from "../../interfaces";
import { GrandChartCourseStore } from "./grand-chart-course.store";
import { GrandChartModuleStore } from "./grand-chart-module.store";
import { LessonStore } from "../lessons/lesson.store";
import { ServiceStore } from "../services/service.store";
import { InstrumentStore } from "../instruments/instrument.store";

export class GrandChartGroupLessonStore implements GrandChartGroupLesson {
	PATH = "grand-chart-group-lessons";

	@observable id = 0;
	@observable grand_chart_id = 0;
	@observable grand_chart_course_id = 0;
	@observable grand_chart_module_id = 0;
	@observable sort = 0;
	@observable slug = "";
	@observable meta_title = "";
	@observable meta_description = "";
	@observable meta_keywords = "";
	@observable name = "";
	@observable description = "";
	@observable is_active = true;
	@observable total_second = 0;
	@observable total_progress_second = 0;
	@observable total_lessons = 0;
	@observable grand_chart_current: GrandChartCurrent = {
		id: 0,
		sort: 0,
		service_id: 0,
		instrument_id: 0,
		title: "",
		is_active: false,
		service: new ServiceStore(null),
		instrument: new InstrumentStore(null),
	};
	@observable grand_chart_course = new GrandChartCourseStore(null);
	@observable grand_chart_module = new GrandChartModuleStore(null);
	@observable lessons: LessonStore[] = [];

	constructor(initialData: GrandChartGroupLesson | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	clearID() {
		this.id = 0;
	}

	@computed
	get currentName() {
		return this.name || `Unknown`;
	}

	@computed
	get passedProgress(): number {
		const totalPercent = Math.round((this.total_progress_second * 100) / this.total_second);

		if (!totalPercent) {
			return 0;
		}

		const completePercent = parseInt(String(totalPercent));
		return completePercent > 100 ? 100 : completePercent || 0;
	}

	@computed
	get passedHour() {
		let totalHours = Math.floor(this.total_progress_second / (60 * 60));
		let totalSeconds = this.total_progress_second - totalHours * 60 * 60;
		let totalMinutes = Math.floor(totalSeconds / 60);

		return `${totalHours}:${totalMinutes}`;
	}

	@computed
	get totalAllLessonsTime(): number {
		return this.lessons.reduce((acc, currentValue) => acc + currentValue.duration_second, 0);
	}

	@computed
	get totalProgressAllLessonsTime(): number {
		return this.lessons.reduce((acc, currentValue) => acc + currentValue.duration_second, 0);
	}

	@action
	fillStore(data: GrandChartGroupLesson) {
		const {
			id,
			grand_chart_id,
			grand_chart_course_id,
			grand_chart_module_id,
			sort,
			slug,
			meta_title,
			meta_description,
			meta_keywords,
			name,
			description,
			is_active,
			total_second,
			total_progress_second,
			total_lessons,
			grand_chart_current,
			grand_chart_course,
			grand_chart_module,
			lessons,
		} = data;

		this.id = id;
		this.grand_chart_id = grand_chart_id;
		this.grand_chart_course_id = grand_chart_course_id;
		this.grand_chart_module_id = grand_chart_module_id;
		this.sort = sort;
		this.slug = slug;
		this.meta_title = meta_title;
		this.meta_description = meta_description;
		this.meta_keywords = meta_keywords;
		this.name = name;
		this.description = description;
		this.is_active = is_active;
		this.total_second = total_second;
		this.total_progress_second = total_progress_second;
		this.total_lessons = total_lessons;
		this.grand_chart_current = grand_chart_current;
		this.grand_chart_course = new GrandChartCourseStore(grand_chart_course);
		this.grand_chart_module = new GrandChartModuleStore(grand_chart_module);
		this.lessons = (lessons || []).map((lesson) => new LessonStore(lesson));
	}
}
