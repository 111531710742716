import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../../../styles";
import { motion } from "framer-motion";

export const Container = styled.div`
	display: flex;
	position: absolute;
	bottom: 0;
	width: 954px;
	overflow-x: hidden;
`;

export const NoteItem = styled(motion.div)`
	position: relative;
	display: flex;
	justify-content: start;
	padding-inline: 2px;
	padding-top: 2px;
	align-items: center;
	width: 45px;
	height: 18px;
	background: ${COLORS.Gold};
	${fontMixins(8)};
	color: ${COLORS.White};
`;
