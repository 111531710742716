import styled from "styled-components";
import { COLORS } from "../../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-left: 1px solid ${COLORS.Red};
	padding: 30px;
	background: ${COLORS.Light_Gray_2};
	border-radius: 0 20px 20px 0;
	row-gap: 20px;
`;

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	background: ${COLORS.MidGray};
`;
