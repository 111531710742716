import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const GroupLessonContainer = styled.div`
	grid-column: 1/3;
	display: flex;
	margin: 19.5px 0 10px 0;
	${fontMixins(12, 500)};
	color: ${COLORS.Dark_Gray};
`;

export const GroupLessonCurrentName = styled.div`
	margin-left: 4px;
`;
