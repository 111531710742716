import { action, makeObservable, observable } from "mobx";
import { LibraryChartItemPivot } from "../../interfaces";

export class LibraryChartItemPivotStore implements LibraryChartItemPivot {
	@observable id = 0;
	@observable chart_item_id = 0;
	@observable library_id = 0;
	@observable name = "";

	constructor(initialData: LibraryChartItemPivot | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: LibraryChartItemPivot) {
		const { id, chart_item_id, library_id, name } = data;

		this.id = id;
		this.chart_item_id = chart_item_id;
		this.library_id = library_id;
		this.name = name;
	}
}
