import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../../styles";

export const CourseItemBlock = styled.div`
	position: relative;
	background: ${COLORS.Dark_Gray};
	min-height: 170px;
	border-radius: 10px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	color: ${COLORS.White};
	display: grid;
	grid-template-columns: 1fr;
	height: 250px;

	&[data-last="true"] {
		//margin-bottom: 0;
	}
`;

export const NumberCourse = styled.div`
	width: 147px;
	height: 20px;
	border-radius: 0 0 10px 10px;
	display: grid;
	grid-template-columns: 1fr;
	${fontMixin({ size: 12 })};
	line-height: 15px;
	margin: 0 auto;
	text-align: center;
	align-items: center;
`;

export const NameCourse = styled.div`
	${fontMixin({ size: 14 })};
	line-height: 17px;
	padding: 0 20px;
	text-transform: uppercase;
`;

export const PercentProcent = styled.div`
	position: absolute;
	right: 10px;
	bottom: 10px;
`;
