import { action, makeObservable, observable } from "mobx";
import { Category } from "../../interfaces";

export class CategoryStore implements Category {
	@observable id = 0;
	@observable parent_id = 0;
	@observable service_id = 0;
	@observable slug = "";
	@observable name = "";

	constructor(initialData: Category | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: Category) {
		const { id, parent_id, service_id, slug, name } = data;

		this.id = id;
		this.parent_id = parent_id;
		this.service_id = service_id;
		this.slug = slug;
		this.name = name;
	}
}
