import { Container, LeftBottomBig, LeftBottomSmall, RightTopBig } from "./UnitingModal.styled";
import { ModalPortal } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";

import { ReactComponent as StarIcon } from "./star.svg";
import { AccessDenied } from "./AccessDenied/AccessDenied";
import { GrandChartAccess } from "../../../../interfaces";
import { AccessAllowed } from "./AccessAllowed/AccessAllowed";

interface UnitingModalProps {}

export const UnitingModal = observer(({}: UnitingModalProps) => {
	const { grandCharts } = useStores();
	const { selected: grandChart } = grandCharts;

	return (
		<ModalPortal modalStore={grandCharts.unitingModal}>
			<Container>
				{grandChart.access !== GrandChartAccess.FULL && <AccessDenied grandChart={grandChart} />}
				{grandChart.access === GrandChartAccess.FULL && <AccessAllowed grandChart={grandChart} />}

				<RightTopBig>
					<StarIcon />
				</RightTopBig>

				<LeftBottomSmall>
					<StarIcon />
				</LeftBottomSmall>

				<LeftBottomBig>
					<StarIcon />
				</LeftBottomBig>
			</Container>
		</ModalPortal>
	);
});
