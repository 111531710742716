import { action, makeObservable, observable } from "mobx";
import { Module } from "../../interfaces/modules/module.interface";

export class ModuleStore implements Module {
	PATH = "modules";

	@observable id = 0;
	@observable sort = 0;
	@observable slug = "";
	@observable meta_title = "";
	@observable meta_description = "";
	@observable meta_keywords = "";
	@observable name = "";
	@observable description = "";
	@observable is_active = true;
	@observable is_delete = false;

	constructor(initialData: Module | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: Module): void {
		const { id, sort, slug, meta_title, meta_keywords, meta_description, name, description, is_active, is_delete } =
			data;

		this.id = id;
		this.sort = sort;
		this.slug = slug;
		this.meta_title = meta_title;
		this.meta_description = meta_description;
		this.meta_keywords = meta_keywords;
		this.name = name;
		this.description = description;
		this.is_active = is_active;
		this.is_delete = is_delete;
	}
}
