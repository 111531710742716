import { Container } from "./WaveRecord.styled";
import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useStores } from "../../../../../../../stores";
import { RecordBlock, TimeBlock } from "../WorkArea.styled";
import { getTotalTimeRecordAI } from "../../../../../../../helpers";

interface WaveRecordProps {}

export const WaveRecord = observer(({}: WaveRecordProps) => {
	const { aiStore } = useStores();

	const containerRef = useRef<HTMLDivElement>(null);

	const [timer, setTimer] = useState(getTotalTimeRecordAI(aiStore.chosenBPM));

	useEffect(() => {
		if (aiStore.recordNow && !aiStore.isShowCounter) {
			startTimer();
		} else {
		}
	}, [aiStore.recordNow, aiStore.isShowCounter]);

	const startTimer = () => {
		setTimeout(() => {
			setTimer((prevState) => {
				const currentState = prevState - 0.05;

				if (currentState >= 0) {
					startTimer();
				} else {
					return getTotalTimeRecordAI(aiStore.chosenBPM);
				}

				return currentState;
			});
		}, 50);
	};

	// Вычисление ширины записанного времени
	const getWidth = () => {
		if (containerRef.current) {
			const element: HTMLDivElement = containerRef.current.parentNode as HTMLDivElement;

			return (
				((getTotalTimeRecordAI(aiStore.chosenBPM) - timer) * element.offsetWidth) /
				getTotalTimeRecordAI(aiStore.chosenBPM)
			);
		}

		return 0;
	};

	// Если обратный отчет еще не начался то не показываем индикатор записи
	if (timer === getTotalTimeRecordAI(aiStore.chosenBPM)) {
		return null;
	}

	return (
		<Container ref={containerRef}>
			<RecordBlock width={getWidth()}>
				<div>Recording</div>
				<TimeBlock>{Number(timer).toFixed(0)}</TimeBlock>
			</RecordBlock>
		</Container>
	);
});
