import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService, phoneQuery } from "../../../../styles";
import { ServiceName } from "../../../../constants";

interface ContainerProps {
	theme: ServiceName;
}

export const Container = styled.div<ContainerProps>`
	display: flex;
	flex-direction: column;
	width: 1058px;
	padding: 44px 44px 97px;
	background: ${COLORS.White};
	border-radius: 11px;

	${phoneQuery(css`
		width: 100%;
	`)}

	${(props) =>
		props.theme &&
		css`
			${ButtonGrandChart} {
				background: ${getColorByService(props.theme)};
			}

			${Plan} {
				border-color: ${getColorByService(props.theme)};
			}
		`}
`;

export const Title = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	column-gap: 12px;
	line-height: 45px;
	${fontMixins(38, 800)};
	color: ${COLORS.Dark_Gray};

	svg {
		width: 27px;
		height: 27px;
	}
`;

export const ButtonGrandChart = styled.button`
	position: relative;
	top: 5px;
	align-self: baseline;
	display: flex;
	column-gap: 9px;
	padding: 11px 12px;
	border: 0;
	border-radius: 5px;
	color: ${COLORS.White};
	${fontMixins(14, 600)};
	height: 40px;
	cursor: pointer;
	margin-left: 20px;
	transition: all 0.2s;

	svg {
		width: 15px;
		height: 16px;
	}

	&:active {
		transform: scale(0.98);
	}
`;

export const ButtonClose = styled.button`
	position: relative;
	top: 12px;
	align-self: baseline;
	display: flex;
	border: 0;
	background: transparent;
	cursor: pointer;
	margin-left: auto;
`;

export const Divider = styled.div`
	display: flex;
	height: 1px;
	width: 100%;
	background: #d5d5d5;
	margin: 27px 0 43px;
`;

export const Body = styled.div`
	display: grid;
	grid-template-columns: 1fr 248px;
	column-gap: 29px;
`;

export const Information = styled.div`
	display: flex;
	flex-direction: column;

	p {
		line-height: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		> br {
		}
	}

	.mtd-hint {
		font-weight: 700;
	}

	strong,
	b,
	.mtd-homework {
		font-weight: 700;
	}

	a {
		font-weight: 500;

		&:hover {
			text-decoration: none;
		}
	}


  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom: 25px;
  }

  h2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 25px;
  }

	h3 {
		font-size: 26px;
		font-weight: 700;
		margin-bottom: 25px;
	}

	h4 {
		font-size: 24px;
		font-weight: 700;
	}

	h5 {
		font-size: 20px;
		font-weight: 700;
	}

	i {
		font-style: italic;
	}
`;

export const Plan = styled.div`
	border: 1px solid transparent;
	border-radius: 12px;
	padding: 28px 37px;
`;

export const VideoBlock = styled.div`
	display: flex;
	width: 629px;
	height: 356px;
	margin-bottom: 20px;
`;

export const Description = styled.div`
	display: flex;
	flex-direction: column;
	${fontMixins(16, 400)};
	color: ${COLORS.Dark_Gray};
	line-height: 26px;
`;

export const TitlePlan = styled.div`
	display: flex;
	${fontMixins()};
	color: ${COLORS.Black};
	margin-bottom: 8px;
`;

export const InformationPlan = styled.div``;
