import styled from "styled-components";
import { fontMixins } from "../../styles";

export const Content = styled.div`
	div > ol > li > div {
		${fontMixins(24, 700)};
		margin-bottom: 25px;
	}

	div > ol > li > ol > li {
		${fontMixins(18)};
		margin-bottom: 15px;
		line-height: 30px;
	}

	h4 {
		${fontMixins(24, 700)};
		margin-bottom: 25px;
	}

	p {
		${fontMixins(18)};
		margin-bottom: 25px;
		line-height: 30px;
	}
`;
