import styled from "styled-components";
import { COLORS } from "../../../../styles";

export const MainContainer = styled.main`
	grid-area: main;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	background: ${COLORS.Light_Gray};
	align-items: flex-start;
	min-height: 100vh;
`;
