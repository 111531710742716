import * as React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { HEADER_TYPE } from "../../constants";
import { Banners, MyDayList } from "../../components";

export const MyDayPage = observer(() => {
	const { settingsStore, bannersStore } = useStores();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);

		(async () => {
			await bannersStore.getList();
		})();
	}, []);

	return (
		<>
			<Banners />
			<MyDayList />
		</>
	);
});
