import * as React from "react";
import { useStores } from "../../../../../stores";
import { CourseItemBlock, NameCourse, NumberCourse, PercentProcent } from "./Courses.styled";
import { GrandChartCourseStore } from "../../../../../stores/grand-charts/grand-chart-course.store";
import { observer } from "mobx-react";
import { CircleProgressBar } from "../../../../common";
import { calculatePercent } from "../../../../../helpers";

interface CoursesProps {}

export const Courses = observer(({}: CoursesProps) => {
	const { grandCharts, statisticsStore } = useStores();
	const { selected } = grandCharts;

	const getClass = (grandChartCourse: GrandChartCourseStore): string => {
		return grandChartCourse.id === selected.selectedGrandChartGroupLesson.grand_chart_course_id ? "active" : "";
	};

	return (
		<>
			{selected.grand_chart_courses.map((grandChartCourse, index) => {
				const currentPassedSecond = statisticsStore.getByCourseId(grandChartCourse.id);
				const currentPassedProgress = calculatePercent(grandChartCourse.total_second, currentPassedSecond);

				return (
					<CourseItemBlock
						key={grandChartCourse.id}
						data-last={selected.grand_chart_courses.length - 1 === index}
						className={getClass(grandChartCourse)}
					>
						<NumberCourse>Course {grandChartCourse.sort || 1}</NumberCourse>
						<NameCourse>{grandChartCourse.course.name}</NameCourse>

						<PercentProcent>
							<CircleProgressBar
								strokeWidth={2}
								sqSize={40}
								percentage={currentPassedProgress}
								payload={`${currentPassedProgress}%`}
								custom
							/>
						</PercentProcent>
					</CourseItemBlock>
				);
			})}
		</>
	);
});
