import { Button, Container, StopButton } from "./SampleButton.styled";
import { ReactComponent as PlayIcon } from "./images/play.svg";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useStores } from "../../../../../../stores";
// @ts-ignore
import MidiPlayer from "web-midi-player";

interface SampleButtonProps {}

export const SampleButton = observer(({}: SampleButtonProps) => {
	const { viewerStore } = useStores();
	const { chooseItem } = viewerStore;
	const midiPlayer = new MidiPlayer();

	const [isPlay, setIsPlay] = useState(false);

	useEffect(() => {}, [chooseItem.ai_midi.path]);

	const handleOnPlay = () => {
		if (!isPlay) {
			midiPlayer.play({ url: chooseItem.ai_midi.fullPathAudio });
			setIsPlay(true);
		} else {
			midiPlayer.pause();
			midiPlayer.stop();
			setIsPlay(false);
		}
	};

	return (
		<Container>
			<Button onClick={handleOnPlay}>
				<span>
					{!isPlay && <PlayIcon />} {isPlay && <StopButton />}
				</span>
				Sample
			</Button>
		</Container>
	);
});
