import * as React from "react";
import { ScoresHeaderContainer } from "./Scores.styled";
import { Pagination } from "../../../../components/common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";

interface ScoresHeaderProps {}

export const ScoresHeader = observer(({}: ScoresHeaderProps) => {
	const { lessonStore, audioPlayerStore } = useStores();
	const { scores } = lessonStore;

	const handleOnChangePage = async (page: number) => {
		await audioPlayerStore.onStop();

		scores.setCurrentNumber(page);
		audioPlayerStore.setSelectedLibrary(scores.currentScore.firstLibrary);
		await audioPlayerStore.loadTrack();
	};

	return (
		<ScoresHeaderContainer>
			<Pagination currentPage={scores.currentNumber + 1} totalPages={scores.total} onChange={handleOnChangePage} />
		</ScoresHeaderContainer>
	);
});
