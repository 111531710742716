import { action, computed, makeObservable, observable } from "mobx";
import { Service } from "../../interfaces";
import { ServiceName } from "../../constants";
import dayjs from "dayjs";

export class ServiceStore implements Service {
	@observable id = 0;
	@observable sort = 0;
	@observable name = "";
	@observable slug = ServiceName.School;
	@observable is_active = false;
	@observable is_delete = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: Service | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get nameOther() {
		const mapping = {
			[ServiceName.School]: "School",
			[ServiceName.College]: "College",
			[ServiceName.University]: "University",
			[ServiceName.PreBeginner]: "Pre-School",
			[ServiceName.PreAdvanced]: "Pre-College",
			[ServiceName.PreProfessional]: "Pre-University",
		};

		// @ts-ignore
		return mapping[this.slug] || "";
	}

	@action
	fillStore(data: Service) {
		const { id, sort, name, slug, is_active, is_delete, created_at, updated_at } = data;

		this.id = id;
		this.sort = sort;
		this.name = name;
		this.slug = slug;
		this.is_active = is_active;
		this.is_delete = is_delete;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
	}
}
