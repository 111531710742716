import { PRODUCT_PERIOD } from "./product.constants";

interface ProductDescriptionI {
	[key: string]: {
		[key: string]: {
			items: Array<string>;
			extra: string;
		};
	};
}

export const PRODUCT_DESCRIPTION: ProductDescriptionI = {
	school: {
		[`1-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`149 Lessons <b></b>`,
				`190 Midi Tracks`,
				`150 Backing Tracks`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},
		[`2-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`135 Complete Lessons`,
				`190 Midi Tracks`,
				`145 Backing Tracks`,
				`50 SRM<sup>1</sup> Sheets`,
				`50 REM<sup>2</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},
		[`4-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`115 Complete Lessons`,
				`210 Midi Tracks`,
				`130 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},

		// Year
		[`1-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`149 Complete Lessons <b></b>`,
				`190 Midi Tracks`,
				`150 Backing Tracks`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},
		[`2-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`135 Complete Lessons`,
				`190 Midi Tracks`,
				`145 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},
		[`4-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`115 Complete Lessons`,
				`210 Midi Tracks`,
				`130 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},

		// Year
		[`1-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`149 Complete Lessons <b></b>`,
				`190 Midi Tracks`,
				`150 Backing Tracks`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},
		[`2-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`135 Complete Lessons`,
				`190 Midi Tracks`,
				`145 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},
		[`4-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`115 Complete Lessons`,
				`210 Midi Tracks`,
				`130 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`30 Classical Compositions`,
			],
			extra: "Includes 4 ADVANCED Lessons",
		},
	},
	college: {
		[`1-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`192 Complete Lessons`,
				`180 Midi Tracks`,
				`240 Backing Tracks`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},
		[`2-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`175 Complete Lessons`,
				`160 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},
		[`4-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`188 Complete Lessons`,
				`145 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},

		// Year
		[`1-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`192 Complete Lessons`,
				`180 Midi Tracks`,
				`240 Backing Tracks`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},
		[`2-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`175 Complete Lessons`,
				`160 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},
		[`4-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`188 Complete Lessons`,
				`145 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},

		// FOREVER
		[`1-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`192 Complete Lessons`,
				`180 Midi Tracks`,
				`240 Backing Tracks`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},
		[`2-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`175 Complete Lessons`,
				`160 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},
		[`4-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`188 Complete Lessons`,
				`145 Backing Tracks`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM<sup>3</sup> Sheets`,
				`48 Fusion Compositions`,
			],
			extra: "Includes BEGINNER subscription",
		},
	},
	university: {
		[`1-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`1001 Complete Lessons`,
				`420 Backing Tracks`,
				`110 000 IGM Sheets`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},
		[`2-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`870 Complete Lessons`,
				`890 Backing Tracks`,
				`70 000 IGM Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},
		[`4-${PRODUCT_PERIOD.MONTH}`]: {
			items: [
				`640 Complete Lessons`,
				`750 Backing Tracks`,
				`70 000 IGM Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},

		// Year
		[`1-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`1001 Complete Lessons`,
				`420 Backing Tracks`,
				`110 000 IGM Sheets`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},
		[`2-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`870 Complete Lessons`,
				`890 Backing Tracks`,
				`70 000 IGM Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},
		[`4-${PRODUCT_PERIOD.YEAR}`]: {
			items: [
				`640 Complete Lessons`,
				`750 Backing Tracks`,
				`70 000 IGM Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,

				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},

		// FOREVER
		[`1-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`1001 Complete Lessons`,
				`420 Backing Tracks`,
				`110 000 IGM Sheets`,
				`12 GYM<sup>1</sup> Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},
		[`2-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`870 Complete Lessons`,
				`890 Backing Tracks`,
				`70 000 IGM Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},
		[`4-${PRODUCT_PERIOD.FOREVER}`]: {
			items: [
				`640 Complete Lessons`,
				`750 Backing Tracks`,
				`70 000 IGM Sheets`,
				`50 SRM<sup>2</sup> Sheets`,
				`50 REM2<sup>3</sup> Sheets`,
				`20 Jazz-Fusion Compositions`,
				`8 Jazz-Blues Compositions`,
				`20 Jazz Standards`,
			],
			extra: "Everything from ADVANCED",
		},
	},
};
