import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div`
	display: flex;
	column-gap: 4px;
	margin-bottom: 16px;
`;

interface ButtonProps {
	isActive: boolean;
}

export const Button = styled.button<ButtonProps>`
	display: flex;
	height: 45px;
	column-gap: 11px;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	background: ${(props) => (props.isActive ? COLORS.Gold : "transparent")};
	cursor: pointer;
	padding-inline: 13px 15px;
	${fontMixins(18, 700)};
	color: ${(props) => (props.isActive ? COLORS.White : COLORS.Gold)};
	border: 1.7px solid ${COLORS.Gold};

	svg {
		width: 24px;
		height: 26px;

		path {
			stroke: ${COLORS.Gold};
		}
	}

	${(props) =>
		props.isActive &&
		css`
			svg {
				path {
					stroke: ${COLORS.White};
				}
			}
		`}
`;
