import { action, makeObservable, observable } from "mobx";
import { Course } from "../../interfaces/courses/course.interface";

export class CourseStore implements Course {
	PATH = "courses";

	@observable isLoading = false;

	@observable id = 0;
	@observable sort = 0;
	@observable slug = "";
	@observable meta_title = "";
	@observable meta_description = "";
	@observable meta_keywords = "";
	@observable name = "";
	@observable description = "";
	@observable is_active = true;
	@observable is_delete = false;

	constructor(initialData: Course | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setSort(value: number) {
		this.sort = value;
	}

	@action.bound
	setIsActive(value: boolean) {
		this.is_active = value;
	}

	@action
	fillStore(data: Course) {
		const { id, sort, slug, meta_title, meta_description, meta_keywords, name, description, is_active, is_delete } =
			data;

		this.id = id;
		this.sort = sort;
		this.slug = slug;
		this.meta_title = meta_title;
		this.meta_description = meta_description;
		this.meta_keywords = meta_keywords;
		this.name = name;
		this.description = description;
		this.is_active = is_active;
		this.is_delete = is_delete;
	}
}
