import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const Container = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

export const Avatar = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	color: ${COLORS.Gold};
	background: ${COLORS.Dark_Gray_Hover};
`;

export const AvatarImage = styled.img`
	display: flex;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
`;

interface UserProfileDropDownI {
	isShow: boolean;
}

export const UserProfileDropDown = styled.div<UserProfileDropDownI>`
	position: absolute;
	z-index: 1000;
	right: 0;
	top: 60px;
	width: 210px;
	background: ${COLORS.Light_Gray};
	border-radius: 10px;
	padding: 10px;
	display: ${(props) => (props.isShow ? "flex" : "none")};
	flex-direction: column;
	box-shadow: 0 20px 20px rgba(0, 0, 0, 0.25);
`;

export const UserProfileDropDownUserName = styled.div`
	display: flex;
	align-items: center;
	min-height: 50px;
	padding: 0 10px;
	${fontMixins(18, 700)};
	color: ${COLORS.Gold};
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
`;

export const UserProfileDropDownDivider = styled.div`
	width: 100%;
	height: 1px;
	background: ${COLORS.MidGray};
	margin: 10px 0 0;
`;

export const UserProfileDropDownLink = styled.button`
	background: transparent;
	border: 0;
	display: flex;
	align-items: center;
	min-height: 50px;
	${fontMixins(18, 400)};
	color: ${COLORS.Dark_Gray};
	transition: all 0.2s;
	cursor: pointer;

	&:hover {
		color: ${COLORS.Red};
	}
`;
