import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	${fontMixins(18)};
	color: ${COLORS.MidGray};
	line-height: 25px;
	row-gap: 25px;

	p {
	}

	ul {
		li {
			${fontMixins(14)};
			line-height: 24px;
			color: ${COLORS.White};
			margin-bottom: 5px;

			sup {
				position: relative;
				top: -2px;
				vertical-align: top;
				${fontMixins(10)};
			}
		}
	}
`;

export const Divider = styled.div`
	display: flex;
	height: 1px;
	background: ${COLORS.White};
	margin: 23px 0 43px;
`;
