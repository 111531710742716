import * as React from "react";
import { Container } from "./LeftSide.styled";
import { UserBlock } from "./UserBlock/UserBlock";
import { Menu } from "./Menu/Menu";

interface LeftSideProps {}

export const LeftSide = ({}: LeftSideProps) => {
	return (
		<Container>
			<UserBlock />
			<Menu />
		</Container>
	);
};
