import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px;
	background: ${COLORS.White};
	border-radius: 10px;
`;

export const Title = styled.div`
	display: flex;
	flex: 1;
	height: 62px;
	${fontMixins(36, 700)};
	justify-content: center;
	align-items: center;
	padding: 58px 0;
	color: ${COLORS.Gold};
`;

export const Description = styled.div`
	${fontMixins(18, 700)};
	color: ${COLORS.Dark_Gray};
	text-align: center;
	line-height: 24px;
	height: 100px;
`;

export const Text = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${fontMixins(18)};
	color: ${COLORS.Dark_Gray};
	text-align: center;
	height: 40px;
`;

export const Actions = styled.div`
	display: flex;
	justify-content: center;
	margin: 20px 0 0;
	column-gap: 40px;
`;
