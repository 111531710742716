import { BPM, Container, Count, Title } from "./ButtonBpm.styled";
import { Ratings } from "./Ratings";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { AiBpm } from "../../../../../../../constants";

interface ButtonBpmProps {
	bpm: AiBpm;
	count: number;
	isActive?: boolean;
}

export const ButtonBpm = observer(({ bpm, count, isActive = false }: ButtonBpmProps) => {
	const { aiStore } = useStores();

	const handleOnChoose = () => {
		aiStore.clearRecord();
		aiStore.chooseBPM(bpm);
	};

	const getRatingsCount = () => {
		switch (bpm) {
			case 60:
				return 3;
			case 90:
				return 4;
			default:
				return 5;
		}
	};

	const getTitle = () => {
		switch (bpm) {
			case AiBpm.Bpm60:
				return "Learning";
			case AiBpm.Bpm90:
				return "Practice";
			default:
				return "Master";
		}
	};

	return (
		<Container isActive={isActive} onClick={handleOnChoose}>
			<Title>{getTitle()}</Title>
			<BPM>{bpm} bpm</BPM>

			<Ratings count={getRatingsCount()} bpm={bpm} />

			<Count>{count}</Count>
		</Container>
	);
});
