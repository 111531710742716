import { Container } from "./RecordBlock.styled";
import { TestMe } from "./TestMe";
import { ListenToRecord } from "./ListenToRecord";

interface RecordBlockProps {}

export const RecordBlock = ({}: RecordBlockProps) => {
	return (
		<Container>
			<TestMe />
			<ListenToRecord />
		</Container>
	);
};
