import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";
import { rgba } from "polished";

export const Container = styled.div`
	display: flex;
	position: relative;

	svg {
		cursor: pointer;
	}
`;

interface NotificationWindowProps {
	isShow: boolean;
}

export const NotificationWindow = styled.div<NotificationWindowProps>`
	position: absolute;
	right: 0;
	top: 30px;
	display: ${(props) => (props.isShow ? "flex" : "none")};
	flex-direction: column;
	width: 300px;
	min-height: 250px;
	background: ${COLORS.White};
	border-radius: 10px;
	box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
	padding: 15px;
`;

export const Title = styled.div`
	${fontMixins(16, 700)};
	display: flex;
	justify-content: center;
	user-select: none;
	padding-bottom: 15px;
	border-bottom: 1px solid ${rgba(COLORS.Dark_Gray, 0.2)};
`;

export const List = styled.ul`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	flex: 1;
`;

export const Item = styled.li`
	${fontMixins(13)};
`;

export const Empty = styled.div`
	display: flex;
	flex-direction: column;
	${fontMixins(14, 500)};
	flex: 1;
	align-items: center;
	justify-content: center;
`;
