import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../../styles";

export const Container = styled.div`
	background: ${COLORS.White};
	border-radius: 10px;
	padding: 20px 20px 20px 21px;
	margin-bottom: 20px;

	img {
		width: 100%;
		margin-bottom: 42px;
	}

	p {
		${fontMixins(14)};
		line-height: 22px;
		margin-bottom: 10px;

		&[data-margin] {
			margin-bottom: 40px;
		}
	}
`;

export const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-bottom: 25px;
	gap: 15px;

	${phoneQuery(css`
		grid-template-columns: repeat(1, 1fr);
	`)}
`;

export const Item = styled.div`
	background-color: ${COLORS.Dark_Gray};
	padding: 10px;
	color: ${COLORS.White};
	${fontMixins(13, 300)};
	line-height: 22px;
	cursor: pointer;
	transition: 0.2s all;
	border-radius: 8px;

	&:hover {
		background-color: ${COLORS.Gold};
	}
`;

export const Video = styled.div`
	margin-bottom: 13px;

	img {
		margin-bottom: 0;
	}
`;

export const Header = styled.div`
	${fontMixins(18, 700)};
	line-height: 30px;
`;

export const Tags = styled.div`
	display: flex;
	column-gap: 5px;
	margin-bottom: 20px;
`;

export const Tag = styled.div`
	${fontMixins(14)};
	line-height: 17px;
`;

export const ButtonTop = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	width: 100px;
	cursor: pointer;

	button {
		background: transparent;
		padding: 0;
		margin: 0;
		width: 34px;
		height: 34px;
		border-radius: 50%;
		border: 0;
		margin-bottom: 3px;
		cursor: pointer;
	}

	span {
		${fontMixins(12, 300)};
		line-height: 20px;
		color: ${COLORS.Red};
	}
`;

export const TutorialsPreview = styled.img`
	display: flex;
	border-radius: 10px;
`;
