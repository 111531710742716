import { ButtonActionBtn, ButtonActionContainer } from "./ButtonAction.styled";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";

interface ButtonActionProps {
	grandChartMyDayStore: GrandChartMyDayStore;
}

export const ButtonAction = observer(({ grandChartMyDayStore }: ButtonActionProps) => {
	const navigate = useNavigate();

	const handleOnGoLesson = () => {
		navigate(`/cabinet/lessons/${grandChartMyDayStore.lastLessonData?.lesson_uuid}`);
	};

	return (
		<ButtonActionContainer>
			<ButtonActionBtn onClick={handleOnGoLesson}>
				{grandChartMyDayStore.lastLessonData?.date ? `Continue Learning` : ` Start Learning`}
			</ButtonActionBtn>
		</ButtonActionContainer>
	);
});
