import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../../styles";

interface MetronomeContainerI {
	isHorizontal: boolean;
}

export const MetronomeContainer = styled.div<MetronomeContainerI>`
	width: 100%;
	height: 200px;
	background: ${COLORS.Light_Gray};
	border-radius: 10px;
	margin-right: 10px;

	${(props) =>
		props.isHorizontal &&
		css`
			display: grid;
			grid-template-columns: 20px 10px 1fr;
			width: 250px;
			height: 150px;

			${MetronomeHeader} {
				overflow: hidden;
				width: 10px;
				height: 150px;
				margin: 0 0 0 15px;

				span {
					transform: rotate(270deg);
				}
			}

			${MetronomeDivider} {
				height: 150px;
				width: 1px;
			}

			${MetronomeBody} {
				margin-top: auto;
				column-gap: 10px;
			}

			${MetronomeControls} {
				padding-inline: 15px;
			}
		`}
`;

export const MetronomeHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin: 10px 20px 9.5px;
	text-align: center;
	${fontMixins(16, 300)};
	color: ${COLORS.Dark_Gray};
`;

export const MetronomeDivider = styled.div`
	position: relative;
	top: 9px;
	height: 1px;
	background: ${COLORS.MidGray};
	margin: 0 20px;
`;

export const MetronomeBody = styled.div`
	display: grid;
	grid-template-columns: 1fr 20px;
	//grid-template-columns: 120px 13px;
	column-gap: 20px;
	padding: 10.5px 20px 20px 20px;
	${phoneQuery(css`
		justify-content: center;
	`)};
`;

export const MetronomeControls = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 20px;
`;

export const MetronomeButtons = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	height: 40px;
	width: 120px;
`;

export const MetronomeInput = styled.input`
	max-width: 120px;
	height: 40px;
	border-radius: 20px;
	border: 0;
	background: ${COLORS.White};
	${fontMixins(18)};
	line-height: 25px;
	text-align: center;
`;

interface MetronomeButtonProps {
	left?: boolean;
	right?: boolean;
	minus?: boolean;
}

export const MetronomeButton = styled.button<MetronomeButtonProps>`
	position: absolute;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 0;
	cursor: pointer;
	color: ${COLORS.White};
	${fontMixins(30)};
	line-height: 30px;
	transition: all 0.2s;
	background: ${COLORS.Gold};

	${(props) =>
		props.left &&
		css`
			left: 0;
		`}
	${(props) =>
		props.right &&
		css`
			right: 0;
		`}
  ${(props) =>
		props.minus &&
		css`
			span {
				width: 13px;
				height: 2px;
				background: ${COLORS.White};
			}
		`}
  &:active {
		transform: scale(0.95);
	}
`;

export const MetronomeVolume = styled.div``;

export const MetronomeIconVolume = styled.div`
	margin: 8px 0 0;

	svg {
		path {
			fill: ${COLORS.Gold};
		}
	}
`;

export const MetronomeButtonStart = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 47px;
	width: 120px;
	${fontMixins(18)};
	line-height: 25px;
	color: ${COLORS.White};
	border-radius: 20px;
	border: 0;
	cursor: pointer;
	padding-top: 5px;
	background: ${COLORS.Gold};
`;

export const MetronomeButtonStartIcon = styled.div`
	margin-right: 10px;
`;
