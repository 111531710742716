import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../../../styles";
import { rgba } from "polished";

export const Container = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	justify-content: center;
	inset: 0;
	height: 330px;
	background: ${rgba(COLORS.Gold, 0.75)};
	transition: all 0.2s;
	text-transform: uppercase;
	${fontMixins(18, 700)};
	color: ${COLORS.White};
	width: 100%;
	z-index: 1000;
`;
