import { Button, Container } from "./Filters.styled";
import {
	ICON_INSTRUMENT_GUITAR,
	ICON_INSTRUMENT_KEYBOARD,
	ICON_INSTRUMENT_SAXOPHONE,
	IconInfinityComponent,
} from "../../../components/common/Icons";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { INSTRUMENT_NAME } from "../../../constants";
import { useEffect } from "react";
import { LocalStorage } from "../../../core";

interface FiltersProps {}

export const Filters = observer(({}: FiltersProps) => {
	const { grandCharts } = useStores();
	const { filter, setFilter } = grandCharts;

	useEffect(() => {
		const filterInstrument = LocalStorage.get("filterInstrument");
		setFilter(filterInstrument);
	}, []);

	return (
		<>
			<Container>
				<Button isActive={filter === null} onClick={() => setFilter(null)}>
					<IconInfinityComponent></IconInfinityComponent> All
				</Button>
				<Button isActive={filter === INSTRUMENT_NAME.GUITAR} onClick={() => setFilter(INSTRUMENT_NAME.GUITAR)}>
					<ICON_INSTRUMENT_GUITAR /> Guitar
				</Button>
				<Button isActive={filter === INSTRUMENT_NAME.KEYBOARD} onClick={() => setFilter(INSTRUMENT_NAME.KEYBOARD)}>
					<ICON_INSTRUMENT_KEYBOARD /> Keyboard
				</Button>
				<Button isActive={filter === INSTRUMENT_NAME.SAXOPHONE} onClick={() => setFilter(INSTRUMENT_NAME.SAXOPHONE)}>
					<ICON_INSTRUMENT_SAXOPHONE /> Saxophone
				</Button>
			</Container>
		</>
	);
});
