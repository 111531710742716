import { Dayjs } from "dayjs";
import { Service } from "../service";
import { ServiceName } from "../../constants";
import { GrandChartStore } from "../../stores/grand-charts/grand-chart.store";
import { Instrument } from "../instrument/instrument.interface";
import { GrandChartModule } from "./grand-chart-module.interface";
import { GrandChartCourse } from "./grand-chart-course.interface";
import { GrandChartGroupLessonMapI, GrandChartLessonProgressI } from "./grand-chart-group-lesson-map.interface";
import { Good } from "../goods";
import { GrandChartUnited } from "./grand-chart-united.interface";

export interface GrandChart {
	id: number;
	sort: number;
	service_id: number;
	instrument_id: number;
	title: string;
	video_url: string;
	description: string;
	is_active: boolean;
	uniting: boolean;
	created_at: Dayjs;
	updated_at: Dayjs;
	access: GrandChartAccess;
	service: Service;
	instrument: Instrument;
	grand_chart_modules: GrandChartModule[];
	grand_chart_courses: GrandChartCourse[];
	group_lessons_map: GrandChartGroupLessonMapI;
	lesson_progress: GrandChartLessonProgressI;
	goods: Array<Good>;
	grand_chart_uniteds: Array<GrandChartUnited>;
}

export type GrandChartList = {
	[key in ServiceName]?: GrandChartStore[];
};

export interface GrandChartCurrent {
	id: number;
	sort: number;
	service_id: number;
	instrument_id: number;
	title: string;
	is_active: boolean;
	service: Service;
	instrument: Instrument;
}

export enum GrandChartAccess {
	DENIED = "denied",
	FULL = "full",
	TRIAL = "trial",
}
