import * as React from "react";
import { Container, TextField } from "./FullName.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";

interface FullNameProps {}

export const FullName = observer(({}: FullNameProps) => {
	const { authStore } = useStores();
	const { userStore } = authStore;

	return (
		<Container>
			{(userStore.first_name && <TextField>{userStore.first_name}</TextField>) || <TextField>Name</TextField>}
			{(userStore.last_name && <TextField>{userStore.last_name}</TextField>) || <TextField>Last Name</TextField>}
		</Container>
	);
});
