import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService } from "../../../styles";
import { ServiceName } from "../../../constants";
import { ProgressContainer } from "../../../pages/MySubscriptionsOldPage/SubscriptionItem/InformationPlan/InformationPlan.styled";

interface NextLessonContainerI {
	service: ServiceName;
	isShow: boolean;
}

export const NextLessonContainer = styled.div<NextLessonContainerI>`
	position: fixed;
	display: none;
	flex-direction: column;
	top: 50%;
	left: 50%;
	z-index: 1000;
	transform: translate(-50%, -50%);
	width: 600px;
	height: 410px;
	background: ${COLORS.White};
	border-radius: 10px;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
	border: ${(props) => `2px solid ${getColorByService(props.service)}`};

	${(props) =>
		props.isShow &&
		css`
			display: flex;
		`}

	${(props) => css`
		${ProgressLine} {
			background: ${getColorByService(props.service)};
		}

		${ProgressContainer} {
			&:after {
				background: ${getColorByService(props.service)};
			}
		}

		${Timer} {
			color: ${getColorByService(props.service)};
		}

		${Logotype} {
			svg {
				circle {
					stroke: ${getColorByService(props.service)};
				}

				path {
					stroke: ${getColorByService(props.service)};
				}

				&#keys {
					path {
						&:nth-child(2),
						&:nth-child(4),
						&:nth-child(8),
						&:nth-child(10),
						&:nth-child(12) {
							fill: ${getColorByService(props.service)};
						}
					}
				}
			}
		}
	`}
`;

export const Logotype = styled.div`
	display: flex;
	width: 40px;
	height: 44px;
	margin: 40px auto 15px;

	svg {
		width: 40px;
		height: 44px;
	}
`;

export const Description = styled.div`
	${fontMixins(18, 700)};
	line-height: 22px;
	color: ${COLORS.Dark_Gray};
	margin: 0 auto;
	width: 340px;
	text-align: center;
	padding: 8px 0;
`;

export const Timer = styled.div`
	${fontMixins(36, 700)};
	line-height: 44px;
	text-align: center;
`;

interface ProgressI {}

export const Progress = styled.div<ProgressI>`
	position: relative;
	height: 10px;
	background: ${COLORS.MidGray};
	border-radius: 10px;
	width: 519px;
	margin: 10px auto 0;
`;

export const ProgressLine = styled.div`
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	border-radius: 10px;
`;

export const Actions = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 30px;
	width: 380px;
	margin: 30px auto 40px;
`;
