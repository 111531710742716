import { RadioCheckContainer } from "./RadioCheck.styled";
import { HTMLProps } from "react";

interface RadioCheckProps extends HTMLProps<HTMLDivElement> {
	checked: boolean;
}

export const RadioCheck = ({ checked, onClick }: RadioCheckProps) => {
	return <RadioCheckContainer checked={checked} onClick={onClick}></RadioCheckContainer>;
};
