import { action, computed, makeObservable, observable } from "mobx";
import { LibraryChartItem } from "../../interfaces";
import dayjs from "dayjs";
import { TrackStore } from "../tracks/track.store";
import { LibraryChartItemPivotStore } from "./library-chart-item-pivot.store";
import { LibraryType } from "../../constants";

export class LibraryChartItemStore implements LibraryChartItem {
	@observable id = 0;
	@observable service_id = 0;
	@observable library_type_id = 0;
	@observable sort = 0;
	@observable name = "";
	@observable is_active = false;
	@observable is_repeat = false;
	@observable repeat_start = dayjs();
	@observable repeat_end = dayjs();
	@observable tracks: TrackStore[] = [];
	@observable pivot: LibraryChartItemPivotStore = new LibraryChartItemPivotStore(null);

	constructor(initialData: LibraryChartItem | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get nameTrack() {
		return this.pivot.name || this.name;
	}

	@computed
	get isSingle() {
		return this.library_type_id === LibraryType.SINGLE;
	}

	@action
	fillStore(data: LibraryChartItem) {
		const {
			id,
			service_id,
			library_type_id,
			sort,
			name,
			is_active,
			is_repeat,
			repeat_start,
			repeat_end,
			tracks,
			pivot,
		} = data;

		this.id = id;
		this.service_id = service_id;
		this.library_type_id = library_type_id;
		this.sort = sort;
		this.name = name;
		this.is_active = is_active;
		this.is_repeat = is_repeat;
		this.repeat_start = dayjs(repeat_start);
		this.repeat_end = dayjs(repeat_end);
		this.tracks = (tracks || []).map((track) => new TrackStore(track));
		this.pivot = new LibraryChartItemPivotStore(pivot);
	}
}
