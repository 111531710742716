import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	position: relative;
	padding: 0 50px 50px;
	margin: 0;
`;

export const Title = styled.div`
	${fontMixins(36, 700)};
	text-align: center;
	color: ${COLORS.Gold};
	padding: 50px 0 40px;
`;

export const Message = styled.div`
	${fontMixins(14)};
	line-height: 17px;
	padding: 0;
	text-align: center;
	color: ${COLORS.Dark_Gray};

	p {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const EmailUser = styled.div`
	margin: 25px 0;
	padding-inline: 50px;
	text-align: center;
	color: ${COLORS.Dark_Gray};
`;

export const Icons = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
	transform: translateX(-50%);
`;
