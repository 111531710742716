import { Container } from "./WaveMidi.styled";
import { COLORS } from "../../../../../../../styles";
import { AudioVisualizer } from "react-audio-visualize";
import { useEffect, useRef, useState } from "react";
import { useStores } from "../../../../../../../stores";

interface WaveMidiProps {}

export const WaveMidi = ({}: WaveMidiProps) => {
	const { viewerStore } = useStores();
	const { chooseItem } = viewerStore;

	const [midiBlob, setMidiBlob] = useState<Blob | null>(null);

	useEffect(() => {
		(async () => {
			if (chooseItem.ai_midi.path) {
			}
		})();
	}, [chooseItem.ai_midi.path]);

	const visualizerRef = useRef<HTMLCanvasElement>(null);

	return (
		<Container>
			<AudioVisualizer
				ref={visualizerRef}
				blob={midiBlob || new Blob()}
				width={941}
				height={375}
				barWidth={1}
				gap={0}
				barColor={COLORS.Gold}
			/>
		</Container>
	);
};
