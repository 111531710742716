import { action, computed, makeObservable, observable } from "mobx";
import { AlarmClockTime } from "../../constants";

export class AlarmClockStore {
	@observable currentTime: AlarmClockTime = AlarmClockTime.First;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setCurrentTime(value: AlarmClockTime) {
		this.currentTime = value;
	}

	@action.bound
	changeCurrentTime() {
		let value = AlarmClockTime.First;

		if (this.currentTime === AlarmClockTime.First) {
			value = AlarmClockTime.Second;
		}

		if (this.currentTime === AlarmClockTime.Second) {
			value = AlarmClockTime.Third;
		}

		this.currentTime = value;
	}

	@computed
	get clearTime() {
		switch (this.currentTime) {
			case AlarmClockTime.First:
				return this.currentTime - 5;
			case AlarmClockTime.Second:
				return this.currentTime - 10;
			case AlarmClockTime.Third:
				return this.currentTime - 15;
			default:
				return this.currentTime;
		}
	}
}
