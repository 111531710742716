import { action, computed, makeObservable, observable } from "mobx";
import { ChartI } from "../../interfaces";
import { ChartItemStore } from "./chart-item.store";
import { SCORE_TYPE } from "../../constants";
import { LibraryChartItemStore } from "../library/library-chart-item.store";

export class ChartStore implements ChartI {
	@observable id = 0;
	@observable lesson_id = 0;
	@observable title = "";
	@observable sub_title = "";
	@observable items: ChartItemStore[] = [];

	@observable isView = false;
	@observable viewChartIndex: null | number = null;

	constructor(initialData: ChartI | null) {
		makeObservable(this);
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setIsView(value: boolean) {
		this.isView = value;
	}

	@action.bound
	setViewChartIndex(value: number | null) {
		this.viewChartIndex = value;
	}

	@computed
	get allLibraries(): LibraryChartItemStore[] {
		if (this.isView && this.viewChartIndex) {
			const findItem = this.items.find((item) => item.id === this.viewChartIndex);

			if (!findItem) {
				return [];
			}

			return findItem.libraries.flat();
		}

		return this.items
			.filter((chartItem) => chartItem.chart_type_id === SCORE_TYPE.IMAGE)
			.filter((chartItem) => chartItem.libraries.length > 0)
			.map((chartItem) => chartItem.libraries.map((library) => library))
			.flat()
			.reduce((accumulator: LibraryChartItemStore[], current) => {
				if (!accumulator.find((item) => item.nameTrack === current.nameTrack)) {
					accumulator.push(current);
				}
				return accumulator;
			}, []);
	}

	@computed
	get firstLibrary(): LibraryChartItemStore {
		return this.allLibraries?.[0] || new LibraryChartItemStore(null);
	}

	@action
	fillStore(data: ChartI) {
		const { id, lesson_id, title, sub_title, items } = data;

		this.id = id;
		this.lesson_id = lesson_id;
		this.title = title;
		this.sub_title = sub_title;
		this.items = (items || []).map((item) => new ChartItemStore(item));
	}
}
