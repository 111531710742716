import { action, computed, makeObservable, observable } from "mobx";
import { ChartItem } from "../../interfaces";
import { ChartContentStore } from "./chart-content.store";
import { ChartTypeStore } from "./chart-type.store";
import { SCORE_TYPE } from "../../constants";
import { LibraryChartItemStore } from "../library/library-chart-item.store";

export class ChartItemStore implements ChartItem {
	@observable id = 0;
	@observable chart_id = 0;
	@observable chart_type_id: SCORE_TYPE = 0;
	@observable sort = 0;
	@observable content = new ChartContentStore(null);
	@observable type = new ChartTypeStore(null);
	@observable libraries: LibraryChartItemStore[] = [];

	constructor(initialData: ChartItem | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get isTypeTitle() {
		return this.chart_type_id === SCORE_TYPE.TITLE;
	}

	@computed
	get isTypeImage() {
		return this.chart_type_id === SCORE_TYPE.IMAGE;
	}

	@action
	fillStore(data: ChartItem) {
		const { id, chart_id, chart_type_id, sort, content, type, libraries } = data;

		this.id = id;
		this.chart_id = chart_id;
		this.chart_type_id = chart_type_id;
		this.sort = sort;
		this.content = new ChartContentStore(content);
		this.type = new ChartTypeStore(type);
		this.libraries = (libraries || []).map((library) => new LibraryChartItemStore(library));
	}
}
