import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService, pxToEm } from "../../../../../styles";
import { ServiceName } from "../../../../../constants";

export const ProductsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 300px);
	column-gap: 30px;
	margin: 80px 0 60px;
`;

interface ProductsItemContainerI {
	isActive: boolean;
	serviceName: ServiceName;
}

export const ProductsItemContainer = styled.div<ProductsItemContainerI>`
	position: relative;
	display: flex;
	flex-direction: column;
	background: ${COLORS.Dark_Gray};
	color: ${COLORS.White};
	border: 2px solid ${COLORS.White};
	border-radius: 10px;
	padding: 45px 20px 23px 20px;
	cursor: pointer;
	transition: all 0.2s;

	${(props) =>
		props.serviceName === ServiceName.School &&
		css`
			border-color: ${COLORS.School};
			background: linear-gradient(180deg, #2d9cdb 0%, #000000 100%), #4d4d4d;
			transition: all 0.2s;

			&:hover {
				background: linear-gradient(180deg, #30a5e8 0%, #000000 100%), #4d4d4d;
			}
		`}

	${(props) =>
		props.serviceName === ServiceName.College &&
		css`
			border-color: ${COLORS.College};
			background: linear-gradient(180deg, ${COLORS.College} 0%, #000000 100%), #4d4d4d;

			&:hover {
				background: linear-gradient(180deg, #ff8b09 0%, #000000 100%), #4d4d4d;
			}
		`}


  ${(props) =>
		props.serviceName === ServiceName.University &&
		css`
			border-color: ${COLORS.University};
			background: linear-gradient(180deg, ${COLORS.University} 0%, #000000 100%), #4d4d4d;

			&:hover {
				background: linear-gradient(180deg, #00b57a 0%, #000000 100%), #4d4d4d;
			}
		`}

  ${(props) => css`
		${ProductLearnMore} {
			color: ${getColorByService(props.serviceName)};

			svg {
				path {
					stroke: ${getColorByService(props.serviceName)};
				}

				circle {
					fill: ${getColorByService(props.serviceName)};
				}
			}
		}
	`}
`;

export const ProductIcon = styled.div`
	text-align: center;
	height: 95px;
`;

export const ProductName = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${fontMixins(36, 300)};
	height: 70px;
	text-transform: uppercase;
`;

export const ProductLevel = styled.div`
	display: flex;
	justify-content: center;
	height: 65px;
	${fontMixins(20)};
`;

export const ProductCurrentPrice = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: center;
	${fontMixins(36, 700)};
	color: ${COLORS.White};
	margin: 0 0 20px;

	span {
		font-size: ${pxToEm(24)};

		&.currency {
			margin-right: 6px;
		}
	}
`;

interface ProductOldPriceProps {
	hide: boolean;
}

export const ProductOldPrice = styled.div<ProductOldPriceProps>`
	position: relative;
	display: inline-flex;
	align-items: flex-end;
	justify-content: center;
	${fontMixins(24)};
	color: ${COLORS.White};
	margin: 0 auto;

	${(props) =>
		props.hide &&
		css`
			visibility: hidden;
		`}
	span {
		font-size: ${pxToEm(18)};

		&.currency {
			position: relative;
			top: -2px;
			margin-right: 6px;
		}
	}

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 120%;
		height: 2px;
		background: ${COLORS.White};
	}
`;

export const ProductActions = styled.div`
	display: flex;
	flex-direction: column;
	margin: 50px 0 0;
`;

interface ProductButtonCardI {
	serviceName: string;
}

export const ProductButtonCard = styled.button<ProductButtonCardI>`
	display: flex;
	align-items: center;
	width: 100%;
	height: 60px;
	border-radius: 10px;
	border: 0;
	margin: 0 0 19px;
	padding: 17px 31px;
	cursor: pointer;
	color: ${COLORS.White};
	${fontMixins(18, 700)};

	${(props) => css`
		background: ${getColorByService(props.serviceName as ServiceName)};
	`}
	svg {
		margin-right: 18px;
	}
`;

export const ProductDescription = styled.div`
	margin-bottom: 30px;
`;

interface ProductDescriptionItemI {
	serviceName: ServiceName;
}

export const ProductDescriptionItem = styled.div<ProductDescriptionItemI>`
	color: ${COLORS.White};
	${fontMixins(14)};
	line-height: 30px;

	sup {
		position: relative;
		top: -2px;
		vertical-align: top;
		${fontMixins(9)};
	}

	span {
		margin-left: 10px;
	}

	${(props) => css`
		svg {
			path {
				stroke: ${getColorByService(props.serviceName)};
			}
		}
	`}
`;

interface ProductDividerI {
	serviceName: ServiceName;
}

export const ProductDivider = styled.div<ProductDividerI>`
	width: 100%;
	height: 1px;
	background: ${(props) => getColorByService(props.serviceName)};
	margin: auto 0 0 0;
`;

export const ProductExtraDescription = styled.div`
	margin: 20px 0;
	${fontMixins(14)};
	color: ${COLORS.White};
`;

export const ProductLearnMore = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	${fontMixins(15, 500)};
	margin: 10px 0 0;

	svg {
		margin-right: 5px;
	}
`;

export const ProductExtraInformation = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	background: ${COLORS.White};
	opacity: 1;
	border-radius: 8px;
	height: 0;
	overflow: auto;
`;

export const CloseButton = styled.button`
	position: absolute;
	top: 10px;
	right: 11px;
	width: 30px;
	height: 30px;
	border: 2px solid ${COLORS.Red};
	border-radius: 50%;
	background: transparent;
	padding: 0;
	margin: 0;
	cursor: pointer;
	transition: all 0.2s;

	&:active {
		transform: scale(0.95);
	}

	&:before,
	&:after {
		content: " ";
		position: absolute;
		top: 4px;
		left: 12px;
		height: 18px;
		width: 2px;
		background-color: ${COLORS.Red};
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`;

interface TitleProps {
	serviceName: ServiceName;
}

export const Title = styled.div<TitleProps>`
	${fontMixins(18, 700)};
	line-height: 22px;
	color: ${(props) => getColorByService(props.serviceName)};
	text-transform: uppercase;
	margin-bottom: 33px;
`;

export const Body = styled.div`
	padding: 53px 20px 20px;
`;

export const DescriptionPlan = styled.div`
	${fontMixins(13, 300)};
	color: ${COLORS.Dark_Gray};
	line-height: 20px;
`;
