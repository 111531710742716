import * as React from "react";
import { VolumeContainer, VolumeProgressLine, VolumeRange } from "./Volume.styled";
import { InputHTMLAttributes, useEffect, useRef } from "react";

interface VolumeProps extends InputHTMLAttributes<HTMLInputElement> {}

export const Volume = ({ name, min, max, defaultValue, step = 0.1, onChange }: VolumeProps) => {
	const progressRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		drawProgress(Number(defaultValue));
	}, []);

	useEffect(() => {
		drawProgress(Number(defaultValue));
	}, [defaultValue]);

	const drawProgress = (value: number) => {
		const val = value;
		const minV = min ? parseFloat(String(min)) : 0;
		const maxV = max ? parseFloat(String(max)) : 100;
		const newVal = Number(((val - minV) * 100) / (maxV - minV));

		if (progressRef.current) {
			progressRef.current.style.height = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
		}
	};

	return (
		<VolumeContainer>
			<VolumeRange
				type={"range"}
				name={name}
				min={min}
				max={max}
				defaultValue={defaultValue}
				step={step}
				onChange={onChange}
				ref={containerRef}
			/>
			<VolumeProgressLine ref={progressRef} />
		</VolumeContainer>
	);
};
