import styled, { css, keyframes } from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const dots = keyframes`
  0% {
    content: "...";
    opacity: 0.2;
  }


  50% {
    content: "...";
    opacity: 1;
  }

  100% {
    content: "...";
    opacity: 0.2;
  }


`;

export const Container = styled.div`
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	background: ${COLORS.White};
	z-index: 2000;
	display: grid;
	align-items: center;
	justify-content: center;
	grid-template-columns: clamp(320px, 90%, 1200px) auto;
`;

export const Body = styled.div`
	display: grid;
	grid-template-rows: 200px;
	row-gap: 60px;
`;

interface PreloaderProps {
	isRotate: boolean;
}

export const Preloader = styled.div<PreloaderProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 47px;

	svg {
		width: 117px;

		${(props) =>
			props.isRotate &&
			css`
				animation: ${rotate} 2s linear infinite;
			`}
	}
`;

export const Title = styled.div`
	${fontMixins(54, 700)};
	color: ${COLORS.Gold};
	transition: all 0.2s;

	&::after {
		content: "...";
		animation: ${dots} 1.2s infinite;
		opacity: 0;
	}
`;

export const TextBlock = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 60px;

	p {
		text-align: center;
		${fontMixins(26)};
		line-height: 40px;
		max-width: 880px;

		&:first-child {
			${fontMixins(46, 600)};
			line-height: 62px;
			max-width: 100%;
		}
	}
`;
