import * as React from "react";
import { ContentBlock } from "../../components/common";
import { observer } from "mobx-react";
import { Calendar } from "./Calendar/Calendar";
import { MyStatisticsPageContainer } from "./Calendar/MyStatisticsPage.styled";
import dayjs from "dayjs";
import { useStores } from "../../stores";
import { useEffect, useState } from "react";
import { HEADER_TYPE } from "../../constants";
import { StatisticsMonth } from "./StatisticsMonth/StatisticsMonth";

export const MyStatisticsPage = observer(() => {
	const { settingsStore, mainStatisticsMyDayStore } = useStores();
	const [currentDate, setCurrentDate] = useState(dayjs());

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
		const cloneCurrentDate = currentDate.clone();
		const startDate = cloneCurrentDate.startOf("month").toISOString();
		const endDate = cloneCurrentDate.add(2, "months").endOf("month").toISOString();

		(async () => {
			await mainStatisticsMyDayStore.getList(startDate, endDate);
		})();
	}, [currentDate]);

	const handleOnPrev = () => {
		setCurrentDate((prevState) => prevState.subtract(1, "month"));
	};

	const handleOnNext = () => {
		setCurrentDate((prevState) => prevState.add(1, "month"));
	};

	return (
		<>
			<ContentBlock
				title={
					<>
						Main statistics <span>&nbsp;/&nbsp;Your overall activity</span>
					</>
				}
				controls
				onNext={handleOnNext}
				onPrev={handleOnPrev}
			>
				<MyStatisticsPageContainer>
					<Calendar date={currentDate} mainStatisticsMyDayStore={mainStatisticsMyDayStore} />
					<Calendar date={currentDate.add(1, "month")} mainStatisticsMyDayStore={mainStatisticsMyDayStore} />
					<Calendar date={currentDate.add(2, "month")} mainStatisticsMyDayStore={mainStatisticsMyDayStore} />
				</MyStatisticsPageContainer>
			</ContentBlock>

			<StatisticsMonth />
		</>
	);
});
