import { Dayjs } from "dayjs";
import { DayJsNull, NumberNull, StringNull } from "../index";
import { UserPurchaseStore } from "../../stores/user-purchases/user-purchase.store";

export interface UserPurchase {
	id: number;
	user_id: number;
	good_id: number;
	grand_chart_id: number;
	session_id: StringNull;
	amount_total: NumberNull;
	mode: string;
	customer_id: StringNull;
	email: string;
	full_name: string;
	payment_status: StringNull;
	payment_method_types: StringNull;
	subscription_id: StringNull;
	period_start: Dayjs;
	period_end: DayJsNull;
	current_status: string;
	duration: DurationPurchase;
	created_at: Dayjs;
	updated_at: Dayjs;
	good_name: string;
	token: string;
	uniting_ids: Array<number>;
}

export interface FlatPurchases {
	[key: number]: UserPurchaseStore;
}

export enum DurationPurchase {
	Monthly = "Monthly",
	Yearly = "Yearly",
	Forever = "Forever",
}
