import styled from "styled-components";
import { COLORS } from "../../../../../styles";
import { rgba } from "polished";

interface ScoreViewShadowProps {
	show: boolean;
}

export const ScoreViewShadow = styled.div<ScoreViewShadowProps>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${rgba(COLORS.Black, 0.5)};
	z-index: 9999999;
	display: ${(props) => (props.show ? "flex" : "none")};
`;

export const ScoreViewContainer = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	min-height: 300px;
	background: ${COLORS.White};
	border-radius: 10px;
	padding: 0 10px;
	display: grid;
	grid-template-columns: 1fr 200px;
	gap: 10px;
	color: ${COLORS.Black};
`;

export const ScoreViewAudioPlayer = styled.div``;

export const ScoreViewMetronome = styled.div`
	margin-top: 10px;
`;

export const ScoreViewArrow = styled.div`
	grid-column: 1 / 3;
	color: ${COLORS.Black};
`;
