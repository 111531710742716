import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	display: grid;
`;

export const Item = styled.div`
	display: flex;
	align-items: center;
	padding: 20px 40px 20px 16px;
	background: ${COLORS.White};
	border-radius: 10px;
	height: 118px;
`;

export const Indicator = styled.div`
	display: flex;
	width: 14px;
	position: relative;
	margin-right: 10px;

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 6px;
		height: 6px;
		background: ${COLORS.Red};
		border-radius: 50%;
	}
`;

export const IconInstrument = styled.div`
	display: flex;
	width: 40px;
	margin-right: 30px;
`;

export const NameInstrument = styled.div`
	display: flex;
	color: ${COLORS.Dark_Gray};
	${fontMixins(18, 700)};
	margin-right: 30px;
`;

export const Divider = styled.div`
	display: flex;
	align-items: stretch;
	width: 1px;
	height: 100%;
	background: #d9d9d9;
	margin-right: 30px;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding-right: 100px;
`;

export const Title = styled.div`
	${fontMixins(14, 700)};
	color: ${COLORS.Dark_Gray};
	margin-bottom: 10px;
`;

export const Description = styled.div`
	${fontMixins(14)};
	color: ${COLORS.Dark_Gray};
	line-height: 20px;
	margin-bottom: 5px;
`;

export const TimeAgo = styled.div`
	${fontMixins(10, 700)};
	color: ${COLORS.Dark_Gray};
`;

export const Actions = styled.div`
	display: flex;
`;
