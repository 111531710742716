import { action, computed, makeObservable, observable } from "mobx";
import dayjs from "dayjs";
import { ProductPurchaseI } from "../../interfaces";
import { ProductStore } from "./product.store";

export class ProductPurchaseStore implements ProductPurchaseI {
	@observable id = 0;
	@observable product_id = 0;
	@observable user_id = 0;
	@observable start_date = dayjs();
	@observable date_end = dayjs();
	@observable price = 0;
	@observable type_payment = "";
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable days_remain = 0;
	@observable months_remain = 0;
	@observable days_passed = 0;
	@observable is_expired = true;
	@observable product: ProductStore = new ProductStore(null);
	@observable total_days = 0;

	constructor(initialData: ProductPurchaseI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get passedPercent() {
		return (this.days_passed * 100) / this.total_days;
	}

	@action
	fillStore(data: ProductPurchaseI) {
		const {
			id,
			product_id,
			user_id,
			start_date,
			date_end,
			price,
			type_payment,
			created_at,
			updated_at,
			days_remain,
			months_remain,
			days_passed,
			is_expired,
			product,
			total_days,
		} = data;

		this.id = id;
		this.product_id = product_id;
		this.user_id = user_id;
		this.start_date = start_date;
		this.date_end = date_end;
		this.price = price;
		this.type_payment = type_payment;
		this.created_at = created_at;
		this.updated_at = updated_at;
		this.days_remain = days_remain;
		this.months_remain = months_remain;
		this.days_passed = days_passed;
		this.is_expired = is_expired;
		this.product = new ProductStore(product);
		this.total_days = total_days;
	}
}
