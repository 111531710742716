import styled from "styled-components";

export const VideoBlockContainer = styled.div`
	width: 100%;
	height: 750px;
`;

export const Video = styled.video`
	width: 100%;
	height: 750px;
	object-fit: cover;
`;

export const Pattern = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url("/images/pattern/dots.webp");
	background-image: image-set(url("/images/pattern/dots.png") 1x);
	background-repeat: repeat;
`;
