import { action, makeObservable, observable } from "mobx";
import { GrandChartModule } from "../../interfaces";
import { CrudStore } from "../common/crud.store";
import { ModuleStore } from "../modules/module.store";
import { GrandChartModuleResultStore } from "./grand-chart-module-result.store";

export class GrandChartModuleStore extends CrudStore<GrandChartModule> implements GrandChartModule {
	PATH = "admin/grand-chart-modules";

	@observable grand_chart_id = 0;
	@observable module_id = 0;
	@observable sort = 0;
	@observable module = new ModuleStore(null);
	@observable result = new GrandChartModuleResultStore(null);

	constructor(initialData: GrandChartModule | null) {
		super();

		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: GrandChartModule) {
		const { id, grand_chart_id, module_id, sort, module, result } = data;

		this.id = id || 0;
		this.grand_chart_id = grand_chart_id;
		this.module_id = module_id;
		this.sort = sort;
		this.module = new ModuleStore(module || null);
		this.result = new GrandChartModuleResultStore(result);
	}
}
