import * as React from "react";
import { Body, ButtonAction, Container, CountCourseLessons, Icon, NameInstrument } from "./SubscriptionItem.styled";
import { GrandChartStore } from "../../../stores/grand-charts/grand-chart.store";
import { checkProduct, getIconInstrument } from "../../../helpers";
import { InformationPlan } from "./InformationPlan/InformationPlan";
import { observer } from "mobx-react";
import { NeedSubscription } from "./NeedSubscription/NeedSubscription";
import { useStores } from "../../../stores";
import { useNavigate } from "react-router-dom";

interface SubscriptionItemProps {
	grandChartStore: GrandChartStore;
}

export const SubscriptionItem = observer(({ grandChartStore }: SubscriptionItemProps) => {
	const { authStore, grandCharts, productInstrumentsStore, statisticsStore } = useStores();
	const { userStore } = authStore;
	const navigation = useNavigate();

	const resultCheckProduct = checkProduct(grandChartStore.id, userStore);

	const handleOnSelectGrandChart = async () => {
		grandCharts.setSelected(grandChartStore);
		await statisticsStore.getByGrandChart(grandChartStore.id);
		await grandCharts.selected.get();
		grandCharts.modal.open();
	};

	const getInformation = () => {
		const serviceInstrument = `${grandChartStore.service.id}-${grandChartStore.instrument.id}`;

		switch (serviceInstrument) {
			case "1-1":
				return `3 Courses / 149 Lessons`;
			case "1-2":
				return `3 Courses / 135 Lessons`;
			case "1-4":
				return `3 Courses / 115 Lessons`;
			case "2-1":
				return `4 Courses / 192 Lessons`;
			case "2-2":
				return `4 Courses / 175 Lessons`;
			case "2-4":
				return `4 Courses / 188 Lessons`;
			case "3-1":
				return `7 Courses / 1001 Lessons`;
			case "3-2":
				return `7 Courses / 870 Lessons`;
			case "3-4":
				return `7 Courses / 640 Lessons`;
			default:
				return "";
		}
	};

	const handleGoToPrice = () => {
		productInstrumentsStore.setChooseInstrument(grandChartStore.instrument.slug);
		productInstrumentsStore.setChooseInstrumentId(grandChartStore.instrument.id);

		navigation("/pricing");

		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<Container service={grandChartStore.service.slug}>
			<Body>
				<Icon>{getIconInstrument(grandChartStore.instrument.slug)}</Icon>
				<NameInstrument>
					{grandChartStore.instrument.name} {grandChartStore.service.nameOther}
				</NameInstrument>

				<CountCourseLessons>{getInformation()}</CountCourseLessons>

				{resultCheckProduct && (
					<>
						<ButtonAction disabled={grandChartStore.isLoading} onClick={handleOnSelectGrandChart}>
							Grand Chart
						</ButtonAction>
						<InformationPlan resultCheckProduct={resultCheckProduct} />
					</>
				)}

				{!resultCheckProduct && (
					<>
						<ButtonAction onClick={handleGoToPrice}>Purchase</ButtonAction>
						<NeedSubscription />
					</>
				)}
			</Body>
		</Container>
	);
});
