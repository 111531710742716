import { action, computed, makeObservable, observable } from "mobx";
import { GrandChart, GrandChartList } from "../../interfaces";
import { ErrorAPI } from "../../helpers";
import { API, LocalStorage } from "../../core";
import { INSTRUMENT_NAME, ServiceName } from "../../constants";
import { GrandChartStore } from "./grand-chart.store";
import { ModalStore } from "../common";

export class GrandChartsStore {
	PATH = "grand-charts";

	@observable list: GrandChartList = {};
	@observable selected = new GrandChartStore(null);
	@observable detailModal = new ModalStore();
	@observable detailSelected = new GrandChartStore(null);
	@observable modal = new ModalStore();
	@observable unitingModal = new ModalStore();

	@observable filter: INSTRUMENT_NAME | null = null;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setDetailSelected(value: GrandChartStore) {
		this.detailSelected = value;
	}

	@action.bound
	clearDetailSelected() {
		this.detailSelected = new GrandChartStore(null);
	}

	@action.bound
	setFilter(value: INSTRUMENT_NAME | null) {
		LocalStorage.set("filterInstrument", value);
		this.filter = value;
	}

	@action.bound
	setList(values: GrandChartList) {
		let data: GrandChartList = {};

		Object.keys(values).forEach((key, index) => {
			const service_key = key as ServiceName;

			// Проходим по всем гранд чартам сервиса
			values[service_key]?.forEach((grandChart) => {
				if (!Array.isArray(data[service_key])) {
					data[service_key] = [];
				}

				// Добавляем гранд чарты в сервис
				data[service_key]?.push(new GrandChartStore(grandChart));
			});

			// Записывем результат
			this.list = data;
		});
	}

	@action.bound
	async getList() {
		try {
			const data = await API.request<GrandChartList>(`${this.PATH}`);
			this.setList(data);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}

	@action.bound
	async getById(id: number) {
		try {
			const data = await API.request<GrandChart>(`${this.PATH}/${id}`);
			this.setSelected(new GrandChartStore(data));
		} catch (e) {
			ErrorAPI("getById", e);
		}
	}

	@action.bound
	setSelected(value: GrandChartStore) {
		this.selected = value;
	}

	@action.bound
	clearSelected() {
		this.selected = new GrandChartStore(null);
	}

	@computed
	get theCore(): Array<GrandChartStore[]> {
		if (!this.filter) {
			return [this.list.school || [], this.list.college || [], this.list.university || []];
		}

		return [
			this.list.school?.filter((grandChart) => grandChart.instrument.slug === this.filter) || [],
			this.list.college?.filter((grandChart) => grandChart.instrument.slug === this.filter) || [],
			this.list.university?.filter((grandChart) => grandChart.instrument.slug === this.filter) || [],
		];
	}

	@computed
	get preCourse(): Array<GrandChartStore[]> {
		if (!this.filter) {
			return [this.list.pre_school || [], this.list.pre_college || [], this.list.pre_university || []];
		}

		return [
			this.list.pre_school?.filter((grandChart) => grandChart.instrument.slug === this.filter) || [],
			this.list.pre_college?.filter((grandChart) => grandChart.instrument.slug === this.filter) || [],
			this.list.pre_university?.filter((grandChart) => grandChart.instrument.slug === this.filter) || [],
		];
	}

	@computed
	get skill_bundles(): Array<GrandChartStore[]> {
		if (!this.filter) {
			return [this.list.skill_bundles || []];
		}

		return [this.list.skill_bundles?.filter((grandChart) => grandChart.instrument.slug === this.filter) || []] || [];
	}
}
