import * as React from "react";
import {
	HistoryItemContainer,
	HistoryItemIcon,
	HistoryItemNameGroup,
	HistoryItemNameService,
} from "./HistoryPage.styled";
import { HistoryItemI } from "../../interfaces/history";
import { getIconInstrument } from "../../helpers";
import { INSTRUMENT_NAME } from "../../constants";
import { observer } from "mobx-react";
import { Item } from "./Item";
import dayjs from "dayjs";

interface HistoryItemProps {
	date: string;
	item: HistoryItemI;
}

export const HistoryItem = observer(({ date, item }: HistoryItemProps) => {
	const [nameGroupLesson] = Object.keys(item.lessons);

	const mapping = {
		School: "Beginner",
		College: "Advanced",
		University: "Professional",
	};

	const result = mapping[item.name_service as "School" | "College" | "University"] || "";

	return (
		<HistoryItemContainer theme={item.name_service.toLowerCase()}>
			<HistoryItemNameService>
				<HistoryItemIcon>{getIconInstrument(item.name_instrument.toLowerCase() as INSTRUMENT_NAME)}</HistoryItemIcon>
				<span>
					{item.name_instrument} {result}
				</span>
			</HistoryItemNameService>

			<HistoryItemNameGroup>{nameGroupLesson}</HistoryItemNameGroup>

			{Object.keys(item.lessons).map((key) => {
				return item.lessons[key].map((lesson) => {
					return <Item key={dayjs(lesson.start_lesson).toString()} lesson={lesson} />;
				});
			})}
		</HistoryItemContainer>
	);
});
