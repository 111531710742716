import * as React from "react";
import {
	GroupLessonItemBlock,
	GroupLessonItemCount,
	GroupLessonItemProgressLine,
	GroupLessonName,
} from "./GroupLessons.styled";
import { GrandChartGroupLessonStore } from "../../../../../stores/grand-charts/grand-chart-group-lesson.store";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { LocalStorage } from "../../../../../core";
import { GROUP_LESSON_SELECTED } from "../../../../../constants";
import { calculatePercent } from "../../../../../helpers";

interface GroupLessonsItemProps {
	grandChartGroupLesson: GrandChartGroupLessonStore;
}

export const GroupLessonsItem = observer(({ grandChartGroupLesson }: GroupLessonsItemProps) => {
	const { grandCharts, statisticsStore } = useStores();
	const { selected } = grandCharts;

	const currentPassedSecond = statisticsStore.getByGroupLessonId(grandChartGroupLesson.id);
	const currentPassedProgress = calculatePercent(grandChartGroupLesson.totalAllLessonsTime, currentPassedSecond);

	const handleOnSelectGroupLesson = () => {
		selected.setShowGroupLesson(true);
		selected.setSelectedGrandChartGroupLesson(grandChartGroupLesson);

		// Записываем выбранную группу уроков
		LocalStorage.set(GROUP_LESSON_SELECTED, `groupLesson-${grandChartGroupLesson.id}`);

		// Скорлл до выбранной группы уроков
		/*setTimeout(() => {
			const findElement = document.getElementById(`group-lesson-${grandChartGroupLesson.id}`);
			if (findElement) {
				findElement.scrollIntoView({ behavior: "smooth" });
			}
		}, 0);*/
	};

	return (
		<GroupLessonItemBlock
			id={`groupLesson-${grandChartGroupLesson.id}`}
			theme={selected.service.slug}
			isActive={grandChartGroupLesson.id === selected.selectedGrandChartGroupLesson.id}
			onClick={handleOnSelectGroupLesson}
			className={grandChartGroupLesson.id === selected.selectedGrandChartGroupLesson.id ? "is_active" : ""}
		>
			<GroupLessonName>{grandChartGroupLesson.name}</GroupLessonName>
			<GroupLessonItemCount>{grandChartGroupLesson.lessons.length}</GroupLessonItemCount>
			<GroupLessonItemProgressLine progress={currentPassedProgress} />
		</GroupLessonItemBlock>
	);
});
