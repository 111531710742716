import { action, makeObservable, observable } from "mobx";
import { Duration } from "../../interfaces";

export class DurationStore implements Duration {
	@observable id = 0;
	@observable name = "";

	constructor(initialData: Duration | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: Duration) {
		const { id, name } = data;

		this.id = id;
		this.name = name;
	}
}
