import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	position: relative;
	${fontMixins(18)};
	color: ${COLORS.Dark_Gray};
	padding: 54px 50px;
	text-align: center;
	line-height: 30px;
	border: 1px solid ${COLORS.Gold};
	border-radius: 10px;
	min-height: 670px;
	width: 800px;

	b {
		font-weight: 700;
	}
`;

export const Title = styled.div`
	${fontMixins(53, 700)};
	color: ${COLORS.Gold};
	margin-bottom: 50px;
	line-height: 55px;
`;

export const Description = styled.div`
	${fontMixins(18)};
	line-height: 30px;
	margin-bottom: 50px;

	strong {
		font-weight: 600;
	}
`;

export const GoodsList = styled.div`
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(2, 153px);
	column-gap: 20px;
	row-gap: 20px;
	margin-bottom: 32px;
`;

export const GoodItem = styled.div`
	display: flex;
	justify-content: center;
	font-weight: 700;

	img {
		display: flex;
		width: 153px;
		height: 153px;
		border-radius: 10px;
	}
`;

export const LeftBottomBig = styled.div`
	position: absolute;
	bottom: 50px;
	left: 43px;
`;

export const LeftBottomSmall = styled.div`
	position: absolute;
	bottom: 115px;
	left: 88px;

	svg {
		width: 20px;
		height: 20px;
	}
`;

export const RightTopBig = styled.div`
	position: absolute;
	top: 271px;
	right: 34px;
`;
