import { Container } from "./Durations.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { DurationItem } from "./DurationItem";

interface DurationsProps {}

export const Durations = observer(({}: DurationsProps) => {
	const { durationsStore } = useStores();

	const handleOnChoose = (id: number) => {
		durationsStore.setChooseId(id);
	};

	return (
		<>
			<Container>
				{durationsStore.list.map((duration) => {
					return (
						<DurationItem
							key={duration.id}
							duration={duration}
							isActive={durationsStore.choose_id === duration.id}
							onClick={handleOnChoose}
						/>
					);
				})}
			</Container>
		</>
	);
});
