import * as React from "react";
import { FirstBlockContainer } from "./FirstBlock.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { VideoBlock } from "../VideoBlock/VideoBlock";
import { ContentService } from "../ContentService/ContentService";
import { ImageBackground } from "../ImageBackground/ImageBackground";

interface FirstBlockProps {}

export const FirstBlock = observer(({}: FirstBlockProps) => {
	const { pageStore } = useStores();

	console.log(pageStore.currentServiceInstrument);

	const getBackground = () => {
		if (["school-guitar", "college-guitar", "university-guitar"].includes(pageStore.currentServiceInstrument)) {
			return (
				<VideoBlock url={pageStore.currentServiceInstrument}>
					<ContentService />
				</VideoBlock>
			);
		} else {
			return (
				<ImageBackground>
					<ContentService />
				</ImageBackground>
			);
		}
	};

	return <FirstBlockContainer>{getBackground()}</FirstBlockContainer>;
});
