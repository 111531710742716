import * as React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { Container } from "./MySubscriptionsPage.styled";
import { SubscriptionItem } from "./SubscriptionItem/SubscriptionItem";
import { HEADER_TYPE, ServiceName } from "../../constants";

export const MySubscriptionsOldPage = observer(() => {
	const { settingsStore, grandCharts } = useStores();

	useEffect(() => {
		(async () => {
			settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
			await grandCharts.getList();
		})();
	}, []);

	return (
		<Container>
			{Object.keys(grandCharts.list).map((serviceName) => {
				return grandCharts.list[serviceName as ServiceName]?.map((grandChart) => {
					return <SubscriptionItem key={grandChart.id} grandChartStore={grandChart} />;
				});
			})}
		</Container>
	);
});
