import * as React from "react";
import { HTMLAttributes } from "react";
import { ImageBackgroundContainer, Picture } from "./ImageBackground.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";

interface ImageBackgroundProps extends HTMLAttributes<HTMLDivElement> {}

export const ImageBackground = observer(({ children }: ImageBackgroundProps) => {
	const { pageStore } = useStores();

	return (
		<ImageBackgroundContainer>
			<Picture>
				<source srcSet={`/images/services/${pageStore.instrument}.webp`} type="image/webp" />
				<img src={`/images/services/${pageStore.instrument}.jpg`} alt={""} />
			</Picture>
			{children}
		</ImageBackgroundContainer>
	);
});
