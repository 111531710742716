import * as React from "react";
import { Container } from "./Lessons.styled";
import { observer } from "mobx-react";
import { LessonStore } from "../../../../../stores/lessons/lesson.store";
import { LessonItem } from "./LessonItem";
import { useStores } from "../../../../../stores";

interface LessonsProps {
	isFirstGroupLesson: boolean;
	lessons: LessonStore[];
}

export const Lessons = observer(({ isFirstGroupLesson, lessons }: LessonsProps) => {
	const {
		grandCharts: { selected: selectedGrandChart },
	} = useStores();

	return (
		<Container>
			{lessons.map((lesson, index) => {
				return (
					<LessonItem
						key={lesson.id}
						isFirst={index === 0 && [1, 2, 3, 7, 8, 9, 10].includes(selectedGrandChart.service_id)}
						isFirstGroupLesson={isFirstGroupLesson}
						access={selectedGrandChart.access}
						lessonStore={lesson}
					/>
				);
			})}
		</Container>
	);
});
