import * as React from "react";
import { Container } from "./RightSide.styled";

interface RightSideProps {
	children: React.ReactNode;
}

export const RightSide = ({ children }: RightSideProps) => {
	return <Container>{children}</Container>;
};
