import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { ButtonToggle, IconArrow, Item } from "./TheCore.styled";
import { getColorByService } from "../../../../styles";
import { ICON_ARROW_RIGHT_COMPONENT } from "../../../../components/common/Icons";
import { MouseEvent, useState } from "react";
import { GrandChartItem } from "../../GrandChartItem/GrandChartItem";

import { boolean } from "boolean";

interface TheCoreItemProps {
	grandChartsService: GrandChartStore[];
	isPreCourse?: boolean;
}

export const TheCoreItem = ({ grandChartsService, isPreCourse }: TheCoreItemProps) => {
	// Получаем service_id
	const firstElement = grandChartsService[0];
	const currentIsShow = localStorage.getItem(firstElement?.service?.nameOther);

	const [isShow, setIsShow] = useState(currentIsShow ? boolean(currentIsShow) : true);

	const handleOnToggle = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();

		setIsShow((prevIsShow) => {
			const currentState = !prevIsShow;
			localStorage.setItem(firstElement?.service?.nameOther, String(currentState));
			return currentState;
		});
	};

	return (
		<Item isShow={isShow}>
			<ButtonToggle
				color={getColorByService(firstElement.service.slug)}
				isPreCourse={isPreCourse}
				onClick={handleOnToggle}
			>
				<IconArrow>
					<ICON_ARROW_RIGHT_COMPONENT />
				</IconArrow>
				{firstElement.service.nameOther}
			</ButtonToggle>

			{isShow &&
				grandChartsService.map((grandChart) => {
					return <GrandChartItem key={grandChart.id} grandChart={grandChart} />;
				})}
		</Item>
	);
};
