import * as React from "react";
import { useState } from "react";
import {
	AudioPlayerContainer,
	AudioPlayerControls,
	AudioPlayerDivider,
	AudioPlayerHeader,
	AudioPlayerLoader,
	AudioPlayerMain,
	AudioPlayerToggle,
	AudioPlayerTrackBlocks,
	AudioPlayerVolumeBlock,
} from "./AudioPlayer.styled";
import { TrackList } from "./TrackList/TrackList";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { Body } from "./Body/Body";
import { ReactComponent as ArrowIcon } from "./arrow.svg";
import { Switcher } from "./Switcher/Switcher";
import { Volume } from "../../../../components/common";
import { PLAYER_CONST } from "../../../../constants";
import { ICON_VOLUME_WITHOUT_VIBE_COMPONENT } from "../../../../components/common/Icons";

interface AudioPlayerProps {
	isLesson?: boolean;
	isView?: boolean;
}

export const AudioPlayer = observer(({ isLesson = false, isView = false }: AudioPlayerProps) => {
	const { audioPlayerStore } = useStores();

	const [currentVolume, setCurrentVolume] = useState(Number(localStorage.getItem(PLAYER_CONST.VOLUME) || -6));
	const [isHideTracks, setIsHideTracks] = useState(false);

	const handleOnChangeVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
		const volume = Number(e.currentTarget.value);

		setCurrentVolume(volume);
		audioPlayerStore.setVolume(volume);

		// Записываем уровень громкости
		localStorage.setItem(PLAYER_CONST.VOLUME, String(volume));

		console.log("set volume", String(volume));

		e.preventDefault();
		e.stopPropagation();
	};

	const handleOnMuteTrack = (player_id: 0 | 1 | 2) => {
		audioPlayerStore.onMute(player_id);
	};

	const handleOnToggleTracks = () => {
		setIsHideTracks((prevState) => !prevState);
	};

	return (
		<AudioPlayerContainer isHideTracks={isHideTracks} isLesson={isLesson} isView={isView}>
			<AudioPlayerLoader isLoading={audioPlayerStore.isLoading}>Loading track...</AudioPlayerLoader>

			<AudioPlayerMain>
				<AudioPlayerHeader>
					<TrackList />
				</AudioPlayerHeader>

				<Body />
			</AudioPlayerMain>

			<AudioPlayerControls>
				<AudioPlayerDivider>
					<AudioPlayerToggle onClick={handleOnToggleTracks}>
						<ArrowIcon style={{ marginLeft: 3 }} />
					</AudioPlayerToggle>
				</AudioPlayerDivider>

				<AudioPlayerTrackBlocks>
					<Switcher
						label={"Drums"}
						mute={audioPlayerStore.drumsMute}
						disabled={audioPlayerStore.selectedLibrary.isSingle}
						onChangeMute={() => handleOnMuteTrack.bind(null, 1)()}
					/>
					<Switcher
						label={"Bass"}
						mute={audioPlayerStore.bassMute}
						disabled={audioPlayerStore.selectedLibrary.isSingle}
						onChangeMute={() => handleOnMuteTrack.bind(null, 0)()}
					/>
					<Switcher
						label={"Keyboard"}
						mute={audioPlayerStore.keysMute}
						disabled={audioPlayerStore.selectedLibrary.isSingle}
						onChangeMute={() => handleOnMuteTrack.bind(null, 2)()}
					/>
				</AudioPlayerTrackBlocks>

				<AudioPlayerVolumeBlock>
					<Volume defaultValue={currentVolume} min={-50} max={-6} name={"volume"} onChange={handleOnChangeVolume} />

					<div style={{ marginTop: 8 }}>
						<ICON_VOLUME_WITHOUT_VIBE_COMPONENT />
					</div>
				</AudioPlayerVolumeBlock>
			</AudioPlayerControls>
		</AudioPlayerContainer>
	);
});
