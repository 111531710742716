import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const ContentServiceContainer = styled.div`
	position: absolute;
	top: 150px;
	bottom: 70px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 200;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 1000px;
`;

export const Logotype = styled.picture`
	position: relative;
	display: flex;

	img {
		width: 380px;
	}
`;

export const Title = styled.div`
	position: relative;
	margin: 30px 0 0;
	line-height: 75px;
	text-align: center;
	color: ${COLORS.White};
	${fontMixins(60, 700)};
`;

export const SubTitle = styled.div`
	margin: 40px 0 0;
	text-align: center;
	color: ${COLORS.White};
	${fontMixins(22)}
`;

export const ActionButton = styled.div`
	margin-top: auto;
`;
