export enum ButtonTheme {
	GOLD = "gold",
	RED = "red",
	DEFAULT = "default",
	GRAY = "gray",
	FACEBOOK = "facebook",
	GOOGLE = "google",
	LINK = "link",
	LINK_LARGE = "link_large",
}
