import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../styles";

export const Container = styled.div`
	padding: 36px 58px;
`;

export const Title = styled.div`
	${fontMixins(20, 700)};
	color: ${COLORS.Gold};
	text-align: center;
	margin-bottom: 18px;
`;

export const Message = styled.div`
	${fontMixins(14)};
	color: ${COLORS.Dark_Gray};
	text-align: center;

	p {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	span {
		&[data-title] {
			text-transform: uppercase;
		}
	}
`;

export const ActionsButton = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 25px;
	column-gap: 5px;

	button {
		width: 125px;
		height: 37px;
	}
`;
