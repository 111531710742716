import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../../../styles";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 30px;
`;

export const Link = styled.a`
	${fontMixin({ size: 12 })};
	color: ${COLORS.School};
	text-decoration: underline;
	cursor: pointer;
	transition: all 0.2s;
	display: flex;
	align-items: center;
	column-gap: 6px;

	&:hover {
		text-decoration: none;
	}
`;
