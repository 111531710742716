import styled, { css } from "styled-components";
import { phoneQuery } from "../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: 45px 1fr 200px 200px;
	column-gap: 10px;

	${phoneQuery(css`
		height: auto;
		grid-template-columns: 50px 1fr;
		row-gap: 10px;
	`)}
`;

export const ContainerLogotype = styled.div`
	width: 45px;
	height: 50px;
`;

export const ContainerBreadcrumbsAndListLessons = styled.div`
	display: grid;
	grid-template-columns: 1fr 330px;
	column-gap: 20px;
	align-items: center;
	height: 50px;

	${phoneQuery(css`
		grid-column: 2/5;
	`)}
`;

export const ContainerAudioPlayer = styled.div`
	grid-column: 1/3;
	height: 140px;
	align-self: flex-end;

	${phoneQuery(css`
		grid-column: 1/5;
	`)}
`;

export const ContainerExtra = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column: 3/5;
	grid-row: 1/3;
	column-gap: 10px;

	${phoneQuery(css`
		grid-column: 1/5;
		grid-row: auto;
	`)}
`;
