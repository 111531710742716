import styled, { css } from "styled-components";
import { COLORS, getColorByService, phoneQuery } from "../../styles";
import { ServiceName } from "../../constants";
import { InstrumentIcon, NameInstrument } from "./Header/Logotype/Logotype.styled";
import { ContainerBreadcrumbs, NameService } from "./Header/Breadcrumbs/Breadcrumbs.styled";
import { ButtonLessonListWrapper } from "./Header/LessonList/Button/Button.styled";
import { FillProgressLine } from "./Header/LessonList/ProgressLine/ProgressLine.styled";
import { IconPlayList } from "./Header/LessonList/LessonList.styled";
import { ListNumberLesson } from "./Header/LessonList/List/List.styled";
import { VolumeProgressLine, VolumeRange } from "../../components/common/Volume/Volume.styled";
import {
	MetronomeButton,
	MetronomeButtons,
	MetronomeButtonStart,
	MetronomeIconVolume,
} from "./Header/Metronome/Metronome.styled";
import { ModuleButton } from "./Header/Modules/Modules.styled";
import { MethodButton, MethodDropDownMenu, MethodTextContainer } from "./Body/Method/Method.styled";
import {
	AudioPlayerDivider,
	AudioPlayerPlus,
	AudioPlayerToggle,
	AudioPlayerVolumeBlock,
} from "./Header/AudioPlayer/AudioPlayer.styled";
import { TrackListContainer, TrackListDropDownItem } from "./Header/AudioPlayer/TrackList/TrackList.styled";
import { PaginationButtonFirstEnd, PaginationContainer } from "../../components/common/Pagination/Pagination.styled";
import {
	AudioPlayerBackIcon,
	AudioPlayerPauseButton,
	AudioPlayerPlayButton,
	AudioPlayerProgressLine,
} from "./Header/AudioPlayer/Body/Body.styled";
import { SwitcherElement } from "./Header/AudioPlayer/Switcher/Switcher.styled";
import { CircleProgressPercentBlock } from "./Header/Modules/AlarmClock/CircleProgress/CircleProgress.styled";
import { SwitcherPick } from "./Body/Method/Switcher/Switcher.styled";
import { PieContainer } from "./Header/Modules/AlarmClock/CircleProgress/Pie/Pie.styled";
import { rgba } from "polished";
import { BREAKPOINT } from "../../styles/breakpoints";
import {
	CircleMainBackground,
	CircleProgress,
} from "../../components/common/CircleProgressBar/CircleProgressBar.styled";
import { ButtonControl, PauseButton } from "./Header/LessonTotalTimeLeft/LessonTotalTimeLeft.styled";
import { CounterDownTimerContainer } from "./Header/LessonTotalTimeLeft/CountDownTimer/CountDownTimer.styled";
import { ProgressCircle, ProgressGhost } from "./Header/Modules/Clock/Clock.styled";

interface ContainerI {
	theme: ServiceName;
}

export const Container = styled.div<ContainerI>`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	row-gap: 10px;
	background: ${COLORS.Black};
	padding: 10px 20px 0;
	color: ${COLORS.White};
	height: 100%;

	@media (max-width: ${BREAKPOINT.VERTICAL}px) {
		display: none;
	}

	${phoneQuery(css`
		grid-template-rows: 1fr;
		row-gap: 10px;
	`)}

	${(props) => css`

    ${ProgressGhost}, ${ProgressCircle} {
      stroke: ${getColorByService(props.theme)};
    }

    ${ButtonControl} {
      svg {
        circle, path {
          stroke: ${getColorByService(props.theme)};
        }
      }
    }

    ${VolumeRange} {
      &::-webkit-slider-thumb {
        background-color: ${getColorByService(props.theme)};
      }
    }

    ${AudioPlayerProgressLine} {
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        position: relative;
        top: -7.5px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 5px solid ${getColorByService(props.theme)};
        border-radius: 50%;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px -1px 2px rgba(0, 0, 0, 0.25);
      }
    }

    ${MetronomeButton}, ${AudioPlayerToggle} {
      background: {
        getColorByService(props.theme)
      };
    }

    ${CounterDownTimerContainer} {
      color: ${getColorByService(props.theme)};
    }

    ${PauseButton} {
      border-color: ${getColorByService(props.theme)};

      &:before,
      &:after {
        background: ${getColorByService(props.theme)};
      }
    }

    ${ButtonLessonListWrapper} {
      &:after {
        border-color: ${getColorByService(props.theme)};
      }
    }

    ${CircleProgress} {
      stroke: ${getColorByService(props.theme)};
    }

    ${CircleMainBackground} {
      fill: ${getColorByService(props.theme)};
    }

    ${PaginationButtonFirstEnd} {
      svg {
        path {
          stroke: ${getColorByService(props.theme)};
        }
      }
    }

    ${PieContainer} {
      circle#pie-progress {
        stroke: ${rgba(getColorByService(props.theme), 0.25)};
      }
    }

    ${SwitcherPick} {
      background: ${getColorByService(props.theme)};
    }

    ${NameService} {
      color: ${getColorByService(props.theme)};
    }
  `} // Универсальный метод окраса 
  ${(props) =>
		props.theme &&
		css`
			${MethodDropDownMenu} {
				li {
					&:hover {
						background: ${getColorByService(props.theme)};
					}
				}
			}

			${CircleProgressPercentBlock} {
				stroke: ${getColorByService(props.theme)};
			}

			${TrackListDropDownItem} {
				&.active {
					background: ${getColorByService(props.theme)};
				}
			}

			${AudioPlayerDivider} {
				border-color: ${getColorByService(props.theme)};
			}

			${MethodTextContainer} {
				a {
					color: ${getColorByService(props.theme)};
				}
			}

			${PauseButton} {
				border: 2px solid ${getColorByService(props.theme)};

				&:before,
				&:after {
					background: ${getColorByService(props.theme)};
				}
			}

			${AudioPlayerBackIcon} {
				svg {
					g {
						&:first-child {
							path {
								fill: ${getColorByService(props.theme)};
							}
						}

						path {
							stroke: ${getColorByService(props.theme)};
						}
					}
				}
			}

			${PaginationContainer} {
				.rc-pagination-item-active {
					a {
						background: ${getColorByService(props.theme)};
					}
				}

				.rc-pagination-next,
				.rc-pagination-prev {
					svg {
						path {
							stroke: ${getColorByService(props.theme)};
						}
					}
				}
			}

			${MetronomeButtons} {
				button {
					background: ${getColorByService(props.theme)};
				}
			}

			${MetronomeIconVolume}, ${AudioPlayerVolumeBlock} {
				svg {
					path {
						fill: ${getColorByService(props.theme)};
					}
				}
			}

			${NameInstrument} {
				color: ${getColorByService(props.theme)};
			}

			${FillProgressLine}, ${ListNumberLesson}, ${VolumeProgressLine},
            ${MetronomeButtonStart}, ${ModuleButton}, ${MethodButton}, ${AudioPlayerToggle}, ${AudioPlayerPlus},
            ${TrackListContainer}, ${AudioPlayerPlayButton}, ${AudioPlayerPauseButton}, ${SwitcherElement} {
				background: ${getColorByService(props.theme)};
			}

			${InstrumentIcon}, ${IconPlayList} {
				svg {
					circle {
						stroke: ${getColorByService(props.theme)};
					}

					path {
						stroke: ${getColorByService(props.theme)};
					}

					&#keys {
						path {
							&:nth-child(2),
							&:nth-child(4),
							&:nth-child(8),
							&:nth-child(10),
							&:nth-child(12) {
								fill: ${getColorByService(props.theme)};
							}
						}
					}
				}
			}

			${ContainerBreadcrumbs} {
				span {
					color: ${getColorByService(props.theme)};
				}
			}
		`}
`;
