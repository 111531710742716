import { action, computed, makeObservable, observable } from "mobx";
import { Banner } from "../../interfaces";
import dayjs, { Dayjs } from "dayjs";
import { API } from "../../core";

export class BannerStore implements Banner {
	@observable id = 0;
	@observable title = "";
	@observable description = "";
	@observable text_button = "";
	@observable text_link = "";
	@observable text_button_color = "#ffffff";
	@observable link = "";
	@observable banner_image = "";
	@observable time_view = 0;
	@observable start_date: Dayjs = dayjs();
	@observable end_date: Dayjs = dayjs();

	constructor(initialData: Banner | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get fullPathBannerImage(): string {
		return `${API.getFilesPath}/${this.banner_image}`;
	}

	@action
	fillStore(data: Banner) {
		const {
			id,
			title,
			description,
			text_button,
			text_link,
			text_button_color,
			link,
			banner_image,
			time_view,
			start_date,
			end_date,
		} = data;

		this.id = id;
		this.title = title;
		this.description = description;
		this.text_button = text_button;
		this.text_link = text_link;
		this.text_button_color = text_button_color;
		this.link = link;
		this.banner_image = banner_image;
		this.time_view = time_view;
		this.start_date = dayjs(start_date);
		this.end_date = dayjs(end_date);
	}
}
