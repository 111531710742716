import { Container } from "./Goods.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { GoodItem } from "./GoodItem";
import { GoodPack } from "./GoodPack";

interface GoodsProps {}

export const Goods = observer(({}: GoodsProps) => {
	const { goodsStore, categoriesStore } = useStores();

	return (
		<>
			<Container isMarginBottom={[3].includes(categoriesStore.choose_id)}>
				{goodsStore.list.map((goodStore, index: number) => {
					if ([1].includes(categoriesStore.choose_id)) {
						return <GoodItem key={goodStore.id} index={index} goodStore={goodStore} />;
					}

					if ([2].includes(categoriesStore.choose_id)) {
						return <GoodItem key={goodStore.id} index={index} goodStore={goodStore} isWhite />;
					}

					if ([3].includes(categoriesStore.choose_id)) {
						return <GoodPack key={goodStore.id} goodStore={goodStore} />;
					}

					return null;
				})}
			</Container>
		</>
	);
});
