import styled, { css } from "styled-components";
import { ServiceName } from "../../../constants";
import { HeaderBreadCrumbs } from "./Header/Header.styled";
import { getColorByService } from "../../../styles";

interface ContainerProps {
	trialMode: boolean;
	theme: ServiceName;
}

export const Container = styled.div<ContainerProps>`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr auto;
	grid-template-areas:
		"header"
		"main"
		"footer";

	${(props) =>
		props.trialMode &&
		css`
			grid-template-rows: auto auto 1fr auto;
			grid-template-areas:
				"header"
				"trial-mode"
				"main"
				"footer";
		`}

	${(props) => css`
		${HeaderBreadCrumbs} {
			span {
				color: ${getColorByService(props.theme)};
			}
		}
	`}
`;
