import { action, makeObservable, observable } from "mobx";
import { AccompanimentStore } from "./accompaniment.store";
import { AccompanimentI } from "../../interfaces/accompaniments/accompaniment.interface";

export class AccompanimentsStore {
	@observable list: AccompanimentStore[] = [];
	@observable selected: AccompanimentStore = new AccompanimentStore(null);

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(data: AccompanimentI[]) {
		this.list = (data || []).map((accompaniment) => new AccompanimentStore(accompaniment));

		// Устанавливаем первый аккопанимент
		this.setFirst();
		// Устанавливаем первую библиотеку
		this.selected.setFirst();
	}

	@action.bound
	setFirst() {
		if (this.list.length > 0) {
			const accompaniment = this.list[0];
			this.setSelected(accompaniment);
		}
	}

	@action.bound
	setSelected(value: AccompanimentStore) {
		this.selected = value;
	}

	@action.bound
	chooseLibrary(value: number) {
		// Проверем есть ли для страницы треки
		const isFindAccompaniment = this.list[value];

		if (isFindAccompaniment) {
			this.selected = isFindAccompaniment;

			// Получаем первую библиотеку и загружаем ее
			const firstLibrary = this.selected.libraries[0];

			if (firstLibrary) {
				this.selected.setLibrary(firstLibrary);
			}
		} else {
			this.selected = new AccompanimentStore(null);
		}
	}
}
