import { action, makeObservable, observable } from "mobx";
import { MainStatisticsMonthI } from "../../interfaces";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";

export class MainStatisticsMonthStore {
	@observable list: MainStatisticsMonthI = {};

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: MainStatisticsMonthI) {
		this.list = values;
	}

	@action.bound
	async getList(start_date: string, end_date: string) {
		try {
			const response = await API.request<MainStatisticsMonthI>(
				`main-statistics/1?start_date=${start_date}&end_date=${end_date}`,
			);
			this.setList(response);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}
}
