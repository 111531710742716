import { action, computed, observable } from "mobx";

import { CrudStore } from "../common/crud.store";
import dayjs from "dayjs";
import { API } from "../../core";
import { ScoreItemAiMidi } from "../../interfaces";

export class ScoreItemAiMidiStore extends CrudStore<ScoreItemAiMidi> implements ScoreItemAiMidi {
	@observable score_item_id = 0;
	@observable name = "";
	@observable origin_name = "";
	@observable path = "";
	@observable created_at = dayjs();
	@observable updated_at = dayjs();

	constructor(initialData: ScoreItemAiMidi | null) {
		super();
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get existFile() {
		return this.id > 0;
	}

	@computed
	get fullPathAudio() {
		return `${API.getFilesPath}${this.path}`;
	}

	@action
	fillStore(data: ScoreItemAiMidi) {
		const { id, name, origin_name, path, created_at, updated_at } = data;

		this.id = id;
		this.name = name;
		this.origin_name = origin_name;
		this.path = path;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}
