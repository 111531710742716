import { action, computed, makeObservable, observable } from "mobx";
import { ScoreContent } from "../../interfaces/scores";
import { API } from "../../core";

export class ScoreContentStore implements ScoreContent {
	@observable align = "center";
	@observable title = "";
	@observable image = "";

	constructor(initialData: ScoreContent | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get imagePath() {
		// TODO Убрать при деплоее
		//return `https://files.musicabinet.com/files/${this.image}`;
		return `${API.getFilesPath}${this.image}`;
	}

	@action
	fillStore(data: ScoreContent) {
		const { align, title, image } = data;

		this.title = title;
		this.align = align;
		this.image = image;
	}
}
