import * as React from "react";
import { Actions, Container, FormWrapper } from "./ProfileForm.styled";
import { Button, InputField } from "../../../common";
import { useFormik } from "formik";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { removeEmoji } from "../../../../helpers";

export const ProfileForm = observer(() => {
	const { authStore, notificationsStore } = useStores();
	const { userStore } = authStore;

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			first_name: userStore.first_name,
			last_name: userStore.last_name,
			country: userStore.country,
			city: userStore.city,
			education: userStore.education,
			music_education: userStore.music_education,
		},
		onSubmit: async (values) => {
			try {
				values.first_name = removeEmoji(values.first_name);
				values.last_name = removeEmoji(values.last_name);

				console.log("update profile", values);

				// Обновление профиля
				await userStore.update(values);

				// Уведомление о успешном обновлении
				notificationsStore.add({
					title: "Profile updated successfully",
				});
			} catch (e: any) {
				notificationsStore.error({
					message: e.errors.join("<br/>"),
				});
			}
		},
	});

	return (
		<Container>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputField
					small
					label={"Name"}
					name={"first_name"}
					defaultValue={formik.values.first_name}
					onChange={formik.handleChange}
					placeholder={"Your name"}
				/>

				<InputField
					small
					label={"Last Name"}
					name={"last_name"}
					defaultValue={formik.values.last_name}
					onChange={formik.handleChange}
					placeholder={"Your last name"}
				/>

				<InputField
					small
					label={"Country"}
					name={"country"}
					defaultValue={formik.values.country}
					onChange={formik.handleChange}
					placeholder={"Your country"}
				/>

				<InputField
					small
					label={"City"}
					name={"city"}
					defaultValue={formik.values.city}
					placeholder={"Your city"}
					onChange={formik.handleChange}
				/>

				<InputField
					small
					label={"Education"}
					name={"education"}
					defaultValue={formik.values.education}
					onChange={formik.handleChange}
					placeholder={"Your education"}
				/>

				<InputField
					small
					label={"Music Education"}
					name={"music_education"}
					defaultValue={formik.values.music_education}
					onChange={formik.handleChange}
					placeholder={"Your music education"}
				/>
				<Actions data-action>
					<Button type={"submit"}>Update</Button>
				</Actions>
			</FormWrapper>
		</Container>
	);
});
