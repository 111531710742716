import * as React from "react";
import { Container, FourthBlockContainer, Information, List, Picture } from "./FourthBlock.styled";
import { Title } from "../../../components/common";
import { getColorByService } from "../../../styles";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";

interface FourthBlockProps {}

export const FourthBlock = observer(({}: FourthBlockProps) => {
	const { pageStore } = useStores();

	return (
		<FourthBlockContainer>
			<Container>
				<Title
					style={{
						fontSize: "42px",
						textAlign: "left",
						marginBottom: "50px",
						color: getColorByService(pageStore.service),
					}}
				>
					Daily practice — solid results
				</Title>

				<Information>
					<div>
						<Picture>
							<source
								srcSet={`/images/daily-practice/circle-of-fifths.webp, /images/daily-practice/circle-of-fifths@2x.webp 2x`}
								type="image/webp"
							/>
							<img
								alt={""}
								src={`/images/daily-practice/circle-of-fifths.jpg`}
								srcSet={`/images/daily-practice/circle-of-fifths@2x.jpg 2x`}
							/>
						</Picture>
					</div>

					<div>
						<Title style={{ fontSize: "22px", lineHeight: "30px", textAlign: "left", fontWeight: 400 }}>
							We adhere to the theory of small steps that allow us to achieve big goals.
						</Title>

						<List>
							<li>Determine the time you are willing to devote to the studying with the program.</li>
							<li>Select any lesson from the Grand Chart.</li>
							<li>
								The system will guide you throughout the learning materials like a mentor. From simple to complex.
							</li>
							<li>You will learn to play your favorite music, improvise and create your own.</li>
						</List>
					</div>
				</Information>
			</Container>
		</FourthBlockContainer>
	);
});
