import * as React from "react";
import { CalendarContainer, CalendarHeader, CalendarTable } from "./Calendar.styled";
import { observer } from "mobx-react";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useMemo } from "react";
import { MainStatisticsMyDayStore } from "../../../stores/main-statistics/main-statistics-my-day.store";
import { useNavigate } from "react-router-dom";

interface CalendarProps {
	date: Dayjs;
	mainStatisticsMyDayStore: MainStatisticsMyDayStore;
}

export const Calendar = observer(({ date, mainStatisticsMyDayStore }: CalendarProps) => {
	const navigate = useNavigate();
	const currentDay = useMemo(() => dayjs().toDate(), []);
	const firstDayOfTheMonth = useMemo(() => date.clone().startOf("month"), [date]);
	const firstDayOfFirstWeekOfMonth = useMemo(() => dayjs(firstDayOfTheMonth).startOf("week"), [firstDayOfTheMonth]);

	const generateFirstDayOfEachWeek = useCallback((day: Dayjs): Dayjs[] => {
		const dates: Dayjs[] = [day];
		for (let i = 1; i < 5; i++) {
			const date = day.clone().add(i, "week");
			dates.push(date);
		}
		return dates;
	}, []);

	const generateWeek = useCallback((day: Dayjs): Date[] => {
		const dates: Date[] = [];
		for (let i = 0; i < 7; i++) {
			const date = day.clone().add(i, "day").toDate();
			dates.push(date);
		}
		return dates;
	}, []);

	const generateWeeksOfTheMonth = useMemo((): Date[][] => {
		const firstDayOfEachWeek = generateFirstDayOfEachWeek(firstDayOfFirstWeekOfMonth);
		return firstDayOfEachWeek.map((date) => generateWeek(date));
	}, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

	const getClassBase = (date: Dayjs, currentDay: Dayjs, day: Date) => {
		return date.clone().toDate().getMonth() !== day.getMonth()
			? "nextMonth"
			: currentDay.isSame(day, "date")
			? "today"
			: "default";
	};

	const getClassLearning = (day: Date) => {
		const findDate = dayjs(day).format("YYYY-MM-DD");
		const isFind = mainStatisticsMyDayStore.list[findDate];
		return isFind ? "learning" : "";
	};

	const handleOnHistory = (day: Date) => {
		const findDate = dayjs(day).format("YYYY-MM-DD");
		const isFind = mainStatisticsMyDayStore.list[findDate];

		if (isFind) {
			navigate(`/cabinet/my-statistics/history/${findDate}`);
		}
	};

	return (
		<CalendarContainer>
			<CalendarHeader>
				{date.format("MMMM")} ' {date.format("YY")}
			</CalendarHeader>

			<CalendarTable>
				<thead>
					<tr>
						<th>Su</th>
						<th>Mo</th>
						<th>Tu</th>
						<th>We</th>
						<th>Th</th>
						<th>Fr</th>
						<th>Sa</th>
					</tr>
				</thead>
				<tbody>
					{generateWeeksOfTheMonth.map((week, weekIndex) => {
						return (
							<tr key={week.toString()}>
								{week.map((day, dayIndex) => (
									<td
										key={day.toDateString()}
										className={`${getClassBase(date, dayjs(currentDay), day)} ${getClassLearning(day)}`}
										onClick={() => handleOnHistory(day)}
									>
										{day.getDate()}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</CalendarTable>
		</CalendarContainer>
	);
});
