import { EmptyContainer } from "./Empty.styled";

interface EmptyProps {}

export const Empty = ({}: EmptyProps) => {
	return (
		<EmptyContainer>
			Sorry, we couldn’t find anything, please check your filter settings and make sure you’ve selected at least one
			Instrument and Level
		</EmptyContainer>
	);
};
