import styled, { css } from "styled-components";
import { COLORS, fontMixin, phoneQuery } from "../../../../styles";

interface CommonFormContainerI {
	onlyForm?: boolean;
}

export const CommonFormContainer = styled.div<CommonFormContainerI>`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: auto;
	width: 100%;
	height: 100%;
	background: ${COLORS.White};

	${phoneQuery(css`
		grid-template-columns: 1fr;
	`)}

	${(props) =>
		props.onlyForm &&
		css`
			grid-template-columns: 1fr;
			max-width: 440px;
			margin: 0 auto;

			${CommonFormRightSide} {
				padding-inline: 0;

				${phoneQuery(css`
					padding-inline: 15px;
				`)}
			}
		`}
`;

export const CommonFormIcon = styled.div`
	${phoneQuery(css`
		img {
			width: 60px;
		}
	`)}
`;

export const CommonFormLeftSide = styled.div`
	display: grid;
	background: ${COLORS.Bgrnd};
	padding: 40px 40px 46.15px 50px;

	${phoneQuery(css`
		padding: 20px 15px;
		grid-row-start: 2;
	`)}
`;

export const CommonFormRightSide = styled.div`
	padding: 50px 40px 60px 40px;
	background: ${COLORS.White};
`;

export const CommonFormRightSideFooter = styled.div`
	display: grid;
	justify-content: center;
`;

export const CommonFormList = styled.div`
	display: grid;
	row-gap: 18px;
	margin: 35px 0 44px;
`;

export const CommonFormListItem = styled.div`
	display: grid;
	grid-template-columns: 90px 1fr;
	gap: 20px;
	align-items: center;
	${fontMixin({ size: 18 })};
	line-height: 22px;
	color: ${COLORS.Gold};

	${phoneQuery(css`
		grid-template-columns: 60px 1fr;
		gap: 15px;
	`)}
`;

export const CommonFormText = styled.div`
	color: ${COLORS.Gold};

	${phoneQuery(css`
		font-size: 16px;
	`)}
`;

export const CommonFormLeftSideFooter = styled.div`
	${fontMixin({ size: 14 })};
	line-height: 17px;
	color: ${COLORS.White};
	width: 346px;
	margin-top: auto;

	${phoneQuery(css`
		width: 100%;
		line-height: 20px;
	`)}
`;

export const CommonFormSocial = styled.div`
	display: grid;
	grid-template-rows: 1fr;
	row-gap: 20px;
	margin-bottom: 30px;
`;

export const CommonFormDescription = styled.div`
	display: grid;
	justify-content: center;
	align-items: center;
	${fontMixin({ size: 14 })};
	line-height: 30px;
	color: ${COLORS.Dark_Gray};
	text-align: center;
`;

export const CommonFormWrapper = styled.form`
	display: grid;
	gap: 20px;
	margin: 40px 0 20px;
`;
