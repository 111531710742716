import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
`;

export const TourContainer = styled.div`
	background: ${COLORS.White};
	padding-top: 40px;
`;

export const TourBody = styled.div`
	margin: 0 auto;
	width: 960px;

	${phoneQuery(css`
		width: 100%;
		padding-inline: 15px;
	`)}
`;

export const SubTitle = styled.div`
	${fontMixins(24, 200)};
	line-height: 38px;
	text-align: center;
	margin-bottom: 40px;

	${phoneQuery(css`
		${fontMixins(18, 200)};
		line-height: 24px;
		padding-inline: 15px;
		margin-bottom: 25px;
	`)}
`;
