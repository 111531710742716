import { action, makeObservable, observable } from "mobx";
import { API } from "../../core";
import { MethodRequest } from "../../constants";
import { PaymentRegistrationRequest } from "../../requests";
import { UserPurchaseStore } from "../user-purchases/user-purchase.store";
import { UserPurchase } from "../../interfaces";
import { CoreResponse } from "../../response";

export class PaymentStore {
	@observable isLoading = false;
	@observable isSuccessRequest = false;
	@observable purchaseGood = new UserPurchaseStore(null);

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setIsSuccessRequest(value: boolean) {
		this.isSuccessRequest = value;
	}

	@action.bound
	async registration(values: PaymentRegistrationRequest) {
		this.setIsSuccessRequest(false);

		try {
			const { data } = await API.request<CoreResponse<UserPurchase>>(`payments`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});

			// Записываем авторизационный токен
			if (data.token) {
				API.saveToken = data.token;
			}

			this.purchaseGood.fillStore(data);

			this.setIsSuccessRequest(true);

			return {
				id: data.id,
				good_id: data.good_id | 0,
				amount_total: data.amount_total,
			};
		} catch (e) {
			console.error(`Error in method registration : `, e);
			throw e;
		} finally {
		}
	}
}
