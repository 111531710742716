import * as React from "react";
import { Container, ItemInstrument, NameInstrument } from "./InstrumentList.styled";
import {
	ICON_INSTRUMENT_GUITAR,
	ICON_INSTRUMENT_KEYBOARD,
	ICON_INSTRUMENT_SAXOPHONE,
} from "../../../../components/common/Icons";

interface InstrumentListProps {}

export const InstrumentList = ({}: InstrumentListProps) => {
	return (
		<Container>
			<ItemInstrument>
				<ICON_INSTRUMENT_KEYBOARD />
				<NameInstrument>Keyboard</NameInstrument>
			</ItemInstrument>
			<ItemInstrument>
				<ICON_INSTRUMENT_GUITAR />
				<NameInstrument>Guitar</NameInstrument>
			</ItemInstrument>
			<ItemInstrument>
				<ICON_INSTRUMENT_SAXOPHONE />
				<NameInstrument>Saxophone</NameInstrument>
			</ItemInstrument>
		</Container>
	);
};
