import { action, makeObservable, observable } from "mobx";
import { HistoryI } from "../../interfaces/history";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";

export class HistoryStore {
	@observable list: HistoryI = {};

	constructor() {
		makeObservable(this);
	}

	@action.bound
	async getList(date: string) {
		try {
			this.list = await API.request<HistoryI>(`history?date=${date}`);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}
}
