import * as React from "react";
import {
	ScoreViewArrow,
	ScoreViewAudioPlayer,
	ScoreViewContainer,
	ScoreViewMetronome,
	ScoreViewShadow,
} from "./ScoreView.styled";
import { AudioPlayer } from "../../../Header/AudioPlayer/AudioPlayer";
import { Metronome } from "../../../Header/Metronome/Metronome";
import { observer } from "mobx-react";
import { useRef } from "react";
import { useOutside } from "../../../../../hooks";
import { useStores } from "../../../../../stores";

interface ScoreViewProps {
	show: boolean;
}

export const ScoreView = observer(({ show }: ScoreViewProps) => {
	const { lessonStore, scoreViewStore } = useStores();
	const refContainer = useRef<HTMLDivElement>(null);

	const handleOnClose = () => {
		lessonStore.scores.scoreView.close();
	};

	useOutside(refContainer, handleOnClose);

	return (
		<ScoreViewShadow show={show}>
			<ScoreViewContainer ref={refContainer}>
				<ScoreViewAudioPlayer>
					<AudioPlayer />

					<ScoreViewArrow>Arrow</ScoreViewArrow>
					<div>{scoreViewStore.currentScore}</div>
				</ScoreViewAudioPlayer>

				<ScoreViewMetronome>
					<Metronome />
				</ScoreViewMetronome>
			</ScoreViewContainer>
		</ScoreViewShadow>
	);
});
