import * as React from "react";
import { ItemContainer, ItemDate, ItemName, ItemStatistics } from "./HistoryPage.styled";
import { HistoryLessonI } from "../../interfaces/history";
import dayjs from "dayjs";
import { CircleProgressBar } from "../../components/common";

interface ItemProps {
	lesson: HistoryLessonI;
}

export const Item = ({ lesson }: ItemProps) => {
	return (
		<ItemContainer>
			<ItemDate>{dayjs(lesson.start_lesson).format("h:mm a")}</ItemDate>

			<CircleProgressBar
				strokeWidth={2}
				sqSize={44}
				percentage={lesson.progress_percent}
				payload={lesson.lesson_name ? lesson.lesson_name.replace(/^\D+/g, "") : ""}
				color={"#fff"}
			/>

			<ItemName>{lesson.lesson_name}</ItemName>

			<ItemStatistics>
				Studied for <span>{lesson.progress_minute} min</span>
			</ItemStatistics>
		</ItemContainer>
	);
};
