import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../styles";

export const Container = styled.div`
	position: absolute;
	left: 190px;
	width: calc(100% - 195px);
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 25px;
	z-index: 200;
`;

export const BackButton = styled.button`
	position: absolute;
	top: 30px;
	left: 30px;
	display: flex;
	width: 40px;
	height: 25px;
	align-items: center;
	justify-content: center;
	margin: 0;
	padding: 0;
	background: transparent;
	border: 0;
	cursor: pointer;

	svg {
		width: 40px;
		height: 25px;
	}
`;

export const GroupLessonItem = styled.div`
	position: relative;
	background: ${COLORS.White};
	padding: 70px 30px 30px;
	border-radius: 10px;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
	display: grid;
	grid-template-columns: 1fr minmax(300px, 431px);
`;

export const NameGroupLesson = styled.div`
	display: flex;
	align-items: center;
	min-height: 100px;
	${fontMixin({ size: 18, weight: 700 })}
	line-height: 25px;
`;

export const StatisticsGroupLesson = styled.div``;

export const PercentGroupLesson = styled.div`
	text-align: right;
	${fontMixin({ size: 36, weight: 300 })};
	margin-bottom: 10px;
	color: ${COLORS.Gold};
`;

interface PercentProgressGroupLessonI {
	percent: number;
}

export const PercentProgressGroupLesson = styled.div<PercentProgressGroupLessonI>`
	position: relative;
	height: 6px;
	width: 100%;
	border-radius: 10px;
	background: ${COLORS.MidGray};
	margin-bottom: 14px;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: ${(props) => props.percent}%;
		background: red;
		border-radius: 10px;
	}
`;

export const HoursProgress = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 10px;
`;

export const HoursPassed = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export const HoursRemain = styled.div`
	display: flex;
	text-align: right;
	justify-content: right;
	align-items: center;
	gap: 10px;
`;

export const HoursText = styled.div`
	display: flex;
	width: 50px;
	${fontMixin({ size: 12 })};
	color: ${COLORS.Black};
	line-height: 15px;
`;

export const HoursTime = styled.div`
	${fontMixin({ size: 36, weight: 300 })};
	line-height: 44px;
	color: ${COLORS.Red};
`;
