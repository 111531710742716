import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../../styles";

export const CountDay = styled.div`
	${fontMixins(36, 700)};
	text-align: center;
	color: ${COLORS.Gold};
	text-transform: uppercase;
`;

export const MainMessage = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 60px;
	${fontMixins(18, 700)};
`;

export const ProgressLine = styled.div`
	position: relative;
	width: 100%;
	height: 10px;
	background: ${COLORS.MidGray};
	border-radius: 10px;
	margin-bottom: 36px;
	overflow: hidden;
`;

export const ProgressSuccess = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 10px;
	background: ${COLORS.Gold};
	border-radius: 10px;
`;

export const Descriptions = styled.div`
	${fontMixins(18, 300)};
	line-height: 23px;
	text-align: center;
	width: 520px;
	margin: 0 auto 40px;
`;

export const Actions = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 40px;
`;

interface ContainerProps {
	isShow: boolean;
	isFinish: boolean;
}

export const Container = styled.div<ContainerProps>`
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: ${(props) => (props.isShow ? "flex" : "none")};
	flex-direction: column;
	background: ${COLORS.White};
	border: 2px solid ${COLORS.Gold};
	border-radius: 10px;
	width: 600px;
	height: 360px;
	z-index: 10000;
	padding: 50px 40px 30px;

	${(props) =>
		props.isFinish &&
		css`
			${CountDay} {
				text-transform: none;
				margin: 0 auto 23px;
				max-width: 350px;
				line-height: 44px;
			}

			${MainMessage}, ${ProgressLine} {
				display: none;
			}

			${Descriptions} {
				max-width: 390px;
			}
		`}

	${phoneQuery(css`
		width: calc(100% - 30px);
		height: auto;
		margin: 0 auto;

		${CountDay}, ${Descriptions} {
			max-width: 100%;
		}
	`)}
`;
