import styled, { css } from "styled-components";
import { COLORS, phoneQuery } from "../../../../styles";

interface ContainerI {
	isShow: boolean;
}

export const Container = styled.div<ContainerI>`
	display: ${(props) => (props.isShow ? "flex" : "none")};
	position: fixed;
	width: 1200px;
	height: 600px;
	background: ${COLORS.Dark_Gray};
	z-index: 1001;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	${phoneQuery(css`
		width: calc(100% - 30px);
		height: 90%;
	`)}
`;

interface ShadowI {
	isShow: boolean;
}

export const Shadow = styled.div<ShadowI>`
	display: ${(props) => (props.isShow ? "flex" : "none")};
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1000;
`;

export const IFrame = styled.iframe`
	width: 100%;
	height: 100%;
`;
