import { action, computed, observable } from "mobx";
import { ScoreItem } from "../../interfaces/scores";
import { ScoreContentStore } from "./score-content.store";
import { ScoreTypeStore } from "./score-type.store";
import { SCORE_TYPE } from "../../constants";
import { LibraryScoreItemStore } from "../library/library-score-item.store";
import { ScoreItemAiMidiStore } from "../score-item-ai-midi";
import { ScoreItemAiMidiResultStore } from "../score-item-ai-midi-result/score-item-ai-midi-result.store";
import { ScoreItemAiMidiResult } from "../../interfaces";

export class ScoreItemStore implements ScoreItem {
	@observable id = 0;
	@observable score_id = 0;
	@observable score_type_id = 0;
	@observable sort = 0;
	@observable content = new ScoreContentStore(null);
	@observable type = new ScoreTypeStore(null);
	@observable libraries: LibraryScoreItemStore[] = [];
	@observable ai_midi = new ScoreItemAiMidiStore(null);
	@observable ai_midi_result: Array<ScoreItemAiMidiResultStore> = [];

	@observable video_iframe = "";

	constructor(initialData: ScoreItem | null) {
		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	removeResult(bpm: number) {
		let listResult = [...this.ai_midi_result];
		listResult = listResult.filter((item) => item.bpm !== bpm);

		this.ai_midi_result = ([...listResult] || []).map((item) => new ScoreItemAiMidiResultStore(item));
	}

	@action.bound
	addResult(value: ScoreItemAiMidiResult) {
		// Проверяем есть ли уже результат с таким bpm
		let listResult = [...this.ai_midi_result];
		listResult = listResult.filter((item) => item.bpm !== value.bpm);

		listResult = ([...listResult] || []).map((item) => new ScoreItemAiMidiResultStore(item));
		listResult.push(new ScoreItemAiMidiResultStore(value));

		// Обновление стора
		this.ai_midi_result = [...listResult].map((item) => new ScoreItemAiMidiResultStore(item));
	}

	@computed
	get isTypeTitle() {
		return this.score_type_id === SCORE_TYPE.TITLE;
	}

	@computed
	get isTypeImage() {
		return this.score_type_id === SCORE_TYPE.IMAGE;
	}

	getAiResultByBpm = (bpm: number): ScoreItemAiMidiResultStore | undefined => {
		return computed(() => {
			return this.ai_midi_result.find((item) => item.bpm === bpm);
		}).get();
	};

	@action
	fillStore(data: ScoreItem) {
		const { id, score_id, score_type_id, sort, content, type, libraries, ai_midi, ai_midi_result } = data;

		this.id = id;
		this.score_id = score_id;
		this.score_type_id = score_type_id;
		this.sort = sort;
		this.content = new ScoreContentStore(content);
		this.type = new ScoreTypeStore(type);
		this.libraries = (libraries || []).map((library) => new LibraryScoreItemStore(library));
		this.ai_midi = new ScoreItemAiMidiStore(ai_midi);
		this.ai_midi_result = (ai_midi_result || []).map(
			(ai_midi_result_item) => new ScoreItemAiMidiResultStore(ai_midi_result_item),
		);
	}
}
