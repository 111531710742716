import * as React from "react";
import { observer } from "mobx-react";
import { ChartItemStore } from "../../../../stores/charts/chart-item.store";
import { ChartsImageBlock, ChartsTextBlock } from "./Charts.styled";

interface ChartItemProps {
	chartItem: ChartItemStore;
	order: number;
	onClick: (idx: number) => void;
}

export const ChartItem = observer(({ chartItem, order, onClick }: ChartItemProps) => {
	if (chartItem.isTypeImage) {
		return (
			<ChartsImageBlock
				src={chartItem.content.imagePath}
				loading={"lazy"}
				onClick={() => onClick(order)}
				onDragStart={(e) => e.preventDefault()}
			/>
		);
	}

	if (chartItem.isTypeTitle) {
		return (
			<ChartsTextBlock style={{ textAlign: chartItem.content.align as "left" | "right" | "center" }}>
				{chartItem.content.title}
			</ChartsTextBlock>
		);
	}

	return null;
});
