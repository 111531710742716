import * as React from "react";
import { MouseEvent, useRef, useState } from "react";
import {
	Avatar,
	AvatarImage,
	Container,
	UserProfileDropDown,
	UserProfileDropDownDivider,
	UserProfileDropDownLink,
	UserProfileDropDownUserName,
} from "./UserProfile.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { useNavigate } from "react-router-dom";
import { useOutside } from "../../../../../hooks";
import { ICON_AVATAR_DEFAULT } from "../../../../common/Icons";

interface UserProfileProps {}

export const UserProfile = observer(({}: UserProfileProps) => {
	const { authStore } = useStores();
	const { userStore } = authStore;
	const navigate = useNavigate();

	const [isShow, setIsShow] = useState(false);
	const dropDownRef = useRef<HTMLDivElement>(null);

	const onShow = () => setIsShow(true);

	const onClose = () => setIsShow(false);

	useOutside(dropDownRef, onClose);

	const handleOnLogout = (e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		authStore.signOut();
		onClose();
	};

	const handleOnMyDay = (e: MouseEvent) => {
		e.stopPropagation();
		e.preventDefault();

		navigate("/cabinet/my-day");
		onClose();
	};

	if (!authStore.isAuth) return null;

	return (
		<Container onClick={onShow}>
			<Avatar>
				{userStore.existAvatar && <AvatarImage src={`${userStore.pathAvatar}`} />}
				{!userStore.existAvatar && <ICON_AVATAR_DEFAULT />}
			</Avatar>

			<UserProfileDropDown ref={dropDownRef} isShow={isShow}>
				<UserProfileDropDownUserName onClick={handleOnMyDay}>
					{authStore.userStore.nickName}
				</UserProfileDropDownUserName>
				<UserProfileDropDownDivider />
				<UserProfileDropDownLink onClick={handleOnLogout}>Sign Out</UserProfileDropDownLink>
			</UserProfileDropDown>
		</Container>
	);
});
