import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins, phoneQuery } from "../../../../../styles";

export const MottoContainer = styled.div`
	display: flex;
	align-items: center;
	${fontMixins(18)};
	color: ${COLORS.White};

	${phoneQuery(css`
		display: none;
	`)}
`;
