import { action, makeObservable, observable } from "mobx";
import { ProductPeriodI } from "../../interfaces";
import { PRODUCT_PERIOD } from "../../constants";

export class ProductPeriodStore implements ProductPeriodI {
	@observable id = PRODUCT_PERIOD.MONTH;
	@observable count_month = 0;
	@observable name = "";

	constructor(initialData: ProductPeriodI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: ProductPeriodI) {
		const { id, count_month, name } = data;

		this.id = id;
		this.count_month = count_month;
		this.name = name;
	}
}
