import styled, { css, keyframes } from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const CounterDownTimerContainer = styled.div`
	display: flex;
	flex-direction: column;
	${fontMixins(18)};
	column-gap: 5px;
	align-items: center;
`;

const dividerAnimate = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

interface DividerTimeProps {
	isAnimate?: boolean;
}

export const DividerTime = styled.div<DividerTimeProps>`
	margin-bottom: 2px;

	${(props) =>
		props.isAnimate &&
		css`
			animation-name: ${dividerAnimate};
			animation-duration: 1s;
			animation-iteration-count: infinite;
		`}
`;

export const TimeContainer = styled.div`
	display: flex;
	column-gap: 5px;
`;

export const TimeAttribute = styled.div`
	width: 100%;
	flex: 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
	${fontMixins(9)};
	color: ${COLORS.Light_Gray};

	span {
		display: flex;

		&:first-child {
			margin-left: 5px;
		}

		&:last-child {
			margin-left: 9px;
		}
	}
`;
