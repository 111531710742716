import { ButtonListen, Container, Title } from "./ListenToRecord.styled";
import { ReactComponent as ListenIcon } from "./images/listen.svg";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { useState } from "react";

interface ListenToRecordProps {}

export const ListenToRecord = observer(({}: ListenToRecordProps) => {
	const { aiStore, lessonStore, viewerStore } = useStores();
	const { waveSurferInstance } = aiStore;
	const { chooseItem } = viewerStore;

	const [isPlay, setIsPlay] = useState(false);

	const audioPlayer = new Audio();

	const handleOnClick = async () => {
		if (waveSurferInstance) {
			if (waveSurferInstance.isPlaying()) {
				waveSurferInstance.pause();
				setIsPlay(false);
			} else {
				await waveSurferInstance.play();
				setIsPlay(true);
			}
		}
	};

	return (
		<Container>
			<ButtonListen
				isPlay={isPlay}
				isActive={aiStore.existRecord || Boolean(chooseItem.getAiResultByBpm(aiStore.chosenBPM)?.user_track)}
				theme={lessonStore.currentService}
				onClick={handleOnClick}
			>
				<ListenIcon />
			</ButtonListen>

			<Title>
				{waveSurferInstance?.isPlaying() && (
					<>
						Stop
						<br />
						Listening
					</>
				)}

				{!waveSurferInstance?.isPlaying() && (
					<>
						Listen to
						<br /> the recording
					</>
				)}
			</Title>
		</Container>
	);
});
