import * as React from "react";
import { useEffect } from "react";
import { Container } from "./MainPage.styled";
import { BackgroundBlock } from "./BackgroundBlock/BackgroundBlock";
import { WhatIsMusicabinet } from "./WhatIsMusicabinet/WhatIsMusicabinet";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { HEADER_TYPE } from "../../constants";
import { WeAreComingSoon } from "./WeAreComingSoon/WeAreComingSoon";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export const MainPage = observer(() => {
	const { settingsStore, authStore } = useStores();
	const navigate = useNavigate();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.ABSOLUTE);
		settingsStore.showFooter();
	}, []);

	useEffect(() => {
		if (authStore.isAuth) {
			navigate("/cabinet/my-day");
		}
	}, [authStore.isAuth]);

	//"position: relative; padding-bottom: calc(50.46874999999999% + 41px); height: 0;"
	// "position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"

	return (
		<>
			<Helmet>
				<title>MUSICABINET | Online Music Education Platform</title>
				<meta
					name="description"
					content="Comprehensive Music Education. Learn to play music instruments 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals."
				/>
			</Helmet>

			<Container>
				<BackgroundBlock />

				<WhatIsMusicabinet />

				<WeAreComingSoon />
			</Container>
		</>
	);
});
