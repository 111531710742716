import { Container } from "./Categories.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { CategoryItem } from "./CategoryItem";

interface CategoriesProps {}

export const Categories = observer(({}: CategoriesProps) => {
	const { categoriesStore } = useStores();

	const handleOnChooseCategory = (id: number) => {
		categoriesStore.setChooseId(id);
	};

	return (
		<>
			<Container>
				{categoriesStore.list.map((category) => {
					return (
						<CategoryItem
							key={category.id}
							category={category}
							isActive={categoriesStore.choose_id === category.id}
							onClick={handleOnChooseCategory}
						/>
					);
				})}
			</Container>
		</>
	);
});
