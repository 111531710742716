import { action, makeObservable, observable } from "mobx";
import { LessonProgressI } from "../../interfaces/lessons/lesson-progress.interface";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { RootStore } from "../index";

export class LessonProgressStore {
	PATH = "lessons-progress";

	@observable list: LessonProgressI = {};

	rootStore: RootStore;

	constructor(rootStore: RootStore) {
		makeObservable(this);

		this.rootStore = rootStore;
	}

	@action.bound
	async getList(grand_chart_id: number, grand_chart_course_id: number) {
		try {
			this.list = await API.request<LessonProgressI>(
				`${this.PATH}?grand_chart_id=${grand_chart_id}&grand_chart_course_id=${grand_chart_course_id}`,
			);
		} catch (e) {
			ErrorAPI("getList", e);
		}
	}

	@action.bound
	prev(): string {
		const grand_chart_course_id = this.rootStore.lessonStore.grand_chart_group_lesson.grand_chart_course_id;
		const grand_chart_module_id = this.rootStore.lessonStore.grand_chart_group_lesson.grand_chart_module_id;
		const findKey = `${grand_chart_course_id}-${grand_chart_module_id}`;

		// Находим следующий модуль
		const arrList = Object.keys(this.list);
		const findIndex = arrList.findIndex((itemKey) => itemKey === findKey);

		// Проверяем есть ли следующий элемент
		const prevElement = findIndex - 1;
		let lastKey = "";

		if (arrList[prevElement]) {
			lastKey = arrList[prevElement];
		} else {
			lastKey = arrList[0];
		}

		let findLessonUUID = Object.keys(this.list[lastKey])[0];

		if (!findLessonUUID) {
			lastKey = arrList[0];
			findLessonUUID = Object.keys(this.list[lastKey])[0];
		}

		// Берем следующий урок из модуля
		return findLessonUUID;
	}

	@action.bound
	next(): string {
		const grand_chart_course_id = this.rootStore.lessonStore.grand_chart_group_lesson.grand_chart_course_id;
		const grand_chart_module_id = this.rootStore.lessonStore.grand_chart_group_lesson.grand_chart_module_id;
		const findKey = `${grand_chart_course_id}-${grand_chart_module_id}`;

		// Находим следующий модуль
		const arrList = Object.keys(this.list);
		const findIndex = arrList.findIndex((itemKey) => itemKey === findKey);

		// Проверяем есть ли следующий элемент
		const nextElement = findIndex + 1;
		let nextKey = "";

		if (arrList[nextElement]) {
			nextKey = arrList[nextElement];
		} else {
			nextKey = arrList[0];
		}

		let findLessonUUID = Object.keys(this.list[nextKey])[0];

		if (!findLessonUUID) {
			nextKey = arrList[0];
			findLessonUUID = Object.keys(this.list[nextKey])[0];
		}

		// Берем следующий урок из модуля
		return findLessonUUID;
	}
}
