import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../styles";

export const Container = styled.div`
	height: 100%;
	display: grid;
	grid-row: 4 / 1000;
	grid-auto-columns: 1fr;
	padding: 25px 0 35px;
	align-items: end;
`;

export const Description = styled.div`
	${fontMixin({ size: 12 })};
	line-height: 15px;
	color: ${COLORS.Black};
	text-align: center;
`;
