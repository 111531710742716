import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins, phoneQuery } from "../../../styles";

interface DescriptionContainerI {
	size: "default" | "small" | "large";
}

export const DescriptionContainer = styled.div<DescriptionContainerI>`
	${fontMixins(18, 200)};
	line-height: 25px;
	color: ${COLORS.Dark_Gray};
	text-align: center;
	margin: 0 auto;

	${(props) =>
		props.size === "large" &&
		css`
			width: 100%;
		`}

	${(props) =>
		props.size === "small" &&
		css`
			width: 570px;
		`}

  ${(props) =>
		props.size === "default" &&
		css`
			width: 734px;
		`}
	
	${phoneQuery(css`
		width: 100%;
		padding-inline: 15px;
	`)}
`;
