import styled, { keyframes } from "styled-components";
import { COLORS, fontMixins } from "../../../styles";
import { rgba } from "polished";

export const MessageRotateContainer = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: ${rgba(COLORS.Black, 1)};
	z-index: 15000;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${COLORS.Gold};
	${fontMixins(20, 700)};
	row-gap: 15px;
`;

const rotateAnimate = keyframes`
	0% {
		transform: rotate(0deg);
	}
	
	50%{
		transform: rotate(-90deg);
	}

  100%{
    transform: rotate(0deg);
  }
`;

export const IconContainer = styled.div`
	img {
		animation-name: ${rotateAnimate};
		animation-duration: 3s;
		animation-iteration-count: infinite;
	}
`;
