import * as React from "react";
import { InputHTMLAttributes } from "react";
import { Container, ErrorMessage, Input, Label } from "./InputField.styled";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
	small?: boolean;
	label?: string;
	errorMessage?: string | undefined;
}

export const InputField = ({ errorMessage = undefined, small = false, label = "", ...props }: InputFieldProps) => {
	return (
		<Container data-input>
			{label && <Label>{label}</Label>}
			<Input isValid={errorMessage !== undefined} isSmall={small} {...props} />
			{errorMessage !== undefined && <ErrorMessage>{errorMessage}</ErrorMessage>}
		</Container>
	);
};
