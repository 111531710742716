import styled, { css } from "styled-components";
import { COLORS } from "../../../../../../../../styles";
import { ReactComponent as StarIcon } from "./images/star.svg";

export const Container = styled.div`
	svg {
		width: 18px;

		path {
			fill: ${COLORS.Dark_Gray_2};
		}
	}
`;

interface WrapperStarIconProps {
	isGold?: boolean;
}

export const WrapperStarIcon = styled(StarIcon)<WrapperStarIconProps>`
	${(props) =>
		props.isGold &&
		css`
			path {
				fill: ${COLORS.Gold} !important;
			}
		`}
`;
