import * as React from "react";
import { CloseButton, Container, IFrame, Shadow } from "./TutorialFrame.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useEffect } from "react";
import { USER_CONST } from "../../../../constants";

interface TutorialFrameProps {}

export const TutorialFrame = observer(({}: TutorialFrameProps) => {
	const { tutorialFrameStore } = useStores();

	useEffect(() => {
		// Проверка на первый вход пользователя
		if (!localStorage.getItem(USER_CONST.FIRST)) {
			localStorage.setItem(USER_CONST.FIRST, "true");
			tutorialFrameStore.open();
		}
	}, []);

	if (!tutorialFrameStore.isShow) {
		return null;
	}

	return (
		<>
			<Container>
				<CloseButton onClick={tutorialFrameStore.close} />
				<IFrame src={`https://edu.musicabinet.com/lessonguide`} />
			</Container>
			<Shadow />
		</>
	);
});
