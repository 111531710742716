import styled from "styled-components";
import { COLORS } from "../../styles";

export const VerifyEmailContainer = styled.div`
	background: ${COLORS.White};
	display: grid;
	height: 100%;
`;

export const PasswordChangeForm = styled.form`
	max-width: 600px;
	margin: 0 auto;
	display: grid;
	gap: 20px;
`;
