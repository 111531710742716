export enum INSTRUMENT_NAME {
	GUITAR = "guitar",
	KEYBOARD = "keyboard",
	SAXOPHONE = "saxophone",
}

export enum INSTRUMENT_IDS {
	GUITAR = 1,
	KEYBOARD = 2,
	SAXOPHONE = 4,
}
