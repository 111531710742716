import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService } from "../../../../styles";
import { ServiceName } from "../../../../constants";

export const Container = styled.div`
	display: flex;
	justify-content: right;
	${fontMixins(14)}
`;

interface ButtonProps {
	theme: ServiceName;
	isCancel?: boolean;
	isSuccessCancel?: boolean;
}

export const Button = styled.button<ButtonProps>`
	height: 30px;
	border: none;
	border-radius: 4px;
	padding-inline: 12px;
	${fontMixins(14)};
	cursor: pointer;
	width: 107px;

	${(props) =>
		props.theme &&
		css`
			background: ${getColorByService(props.theme.slug)};
			color: ${COLORS.White};
		`}

	${(props) =>
		props.isCancel &&
		css`
			background: #ededed;
			color: ${COLORS.Dark_Gray};
		`}

  ${(props) =>
		props.isSuccessCancel &&
		css`
			opacity: 0.5;
			cursor: not-allowed;
		`}
`;
