import { observer } from "mobx-react";
import { Button } from "../../../../common";
import { Container } from "./PricingButton.styled";
import { useNavigate } from "react-router-dom";

interface PricingButtonProps {}

export const PricingButton = observer(({}: PricingButtonProps) => {
	const navigate = useNavigate();

	const handleOnPricing = () => {
		navigate("/pricing");
	};

	return (
		<Container>
			<Button isWhite onClick={handleOnPricing}>
				Pricing
			</Button>
		</Container>
	);
});
