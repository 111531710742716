import * as React from "react";
import { DescriptionContainer } from "./Description.styled";

interface DescriptionProps {
	size?: "default" | "small" | "large";
	children?: React.ReactNode;
}

export const Description = ({ size = "default", children }: DescriptionProps) => {
	return <DescriptionContainer size={size}>{children}</DescriptionContainer>;
};
