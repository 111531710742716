import * as React from "react";
import { ButtonLessonListWrapper } from "./Button.styled";
import { observer } from "mobx-react";

interface ButtonLessonListProps {
	isShow: boolean;
}

export const ButtonLessonList = observer(({ isShow }: ButtonLessonListProps) => {
	return <ButtonLessonListWrapper isShow={isShow} />;
});
