import { action, computed, makeObservable, observable } from "mobx";
import { LessonColorI } from "../../interfaces/lessons/lesson-color.interface";

export class LessonColorStore implements LessonColorI {
	@observable id = 0;
	@observable name = "";
	@observable color = "";

	constructor(initialData: LessonColorI | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get isWhite() {
		return this.color === "#FFFFFF";
	}

	@action
	fillStore(data: LessonColorI) {
		const { id, name, color } = data;

		this.id = id;
		this.name = name;
		this.color = color;
	}
}
