import * as React from "react";
import { Container, LinkItem } from "./Menu.styled";
import { observer } from "mobx-react";

export const Menu = observer(() => {
	return (
		<Container>
			<LinkItem to={"/cabinet/my-day"}>My Day</LinkItem>
			<LinkItem to={"/cabinet/my-statistics"}>My Statistics</LinkItem>
			<LinkItem to={"/cabinet/my-subscriptions"}>My Subscriptions</LinkItem>
			<LinkItem to={"/cabinet/tutorials"}>Tutorials</LinkItem>
			<LinkItem to={"/cabinet/notifications"}>Notifications</LinkItem>
		</Container>
	);
});
