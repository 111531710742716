import styled from "styled-components";
import { COLORS } from "../../../../../../styles";
import { NavLink } from "react-router-dom";

interface DropDownContainerI {
	show: boolean;
}

export const DropDownContainer = styled.ul<DropDownContainerI>`
	display: ${(props) => (props.show ? "flex" : "none")};
	position: absolute;
	background: ${COLORS.Black};
	top: 50px;
	left: 0;
	border-radius: 10px;
	padding: 15px 20px;
	flex-direction: column;
	row-gap: 15px;
	width: 270px;
	z-index: 1000;
`;
export const Link = styled(NavLink)`
	color: ${COLORS.White};
	text-decoration: none;
`;

export const Item = styled.li`
	&:hover {
		${Link} {
			color: ${COLORS.Gold};
		}
	}
`;
