import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { Container } from "./TheCore.styled";
import { TheCoreItem } from "./TheCoreItem";

interface TheCoreProps {
	grandCharts: Array<GrandChartStore[]>;
	isPreCourse?: boolean;
}

export const TheCore = ({ grandCharts, isPreCourse }: TheCoreProps) => {
	return (
		<Container>
			{grandCharts.map((grandChartsService, index) => {
				return (
					grandChartsService[0] && (
						<TheCoreItem key={index} grandChartsService={grandChartsService} isPreCourse={isPreCourse} />
					)
				);
			})}
		</Container>
	);
};
