import { Container } from "./Instruments.styled";
import { observer } from "mobx-react";
import { InstrumentItem } from "./InstrumentItem";
import { useStores } from "../../../stores";

interface InstrumentsProps {}

export const Instruments = observer(({}: InstrumentsProps) => {
	const { productInstrumentsStore } = useStores();

	const handleOnChooseInstrument = (instrument_id: number) => {
		productInstrumentsStore.setChooseInstrumentId(instrument_id);
	};

	return (
		<Container>
			{productInstrumentsStore.list.map((productInstrument) => {
				return (
					<InstrumentItem
						key={productInstrument.id}
						productInstrument={productInstrument}
						isActive={productInstrument.id === productInstrumentsStore.chooseInstrumentID}
						onClick={handleOnChooseInstrument}
					/>
				);
			})}
		</Container>
	);
});
