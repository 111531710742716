import { action, makeObservable, observable } from "mobx";

export class NextLessonStore {
	@observable isInit = false;
	@observable isShow = false;
	@observable isView = false;
	@observable totalSeconds = 0;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsInit(value: boolean) {
		this.isInit = value;
	}

	@action.bound
	open() {
		this.isShow = true;
	}

	@action.bound
	close() {
		this.isShow = false;
	}

	@action.bound
	setIsView(value: boolean) {
		this.isView = value;
	}

	@action.bound
	setTotalSeconds(value: number) {
		this.totalSeconds = value;
	}
}
