import styled, { css } from "styled-components";
import { phoneQuery } from "../../../../styles";

export const VideoBlockContainer = styled.div`
	margin-bottom: 56px;
	display: none;

	video {
		position: relative;
		width: 100%;
		cursor: pointer;

		${phoneQuery(css``)}
	}
`;
