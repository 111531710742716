export enum PRODUCT_PERIOD {
	MONTH = 1,
	YEAR = 2,
	FOREVER = 3,
}

interface PricingTariff {
	[key: number]: {
		trial: string;
		standard: string;
	};
}

export const PRODUCT_STRIPE_ID: PricingTariff = {
	// Guitar Month
	1: {
		trial: "price_1MNjJEDRw2Rl9gFu2JIIDx43",
		standard: "price_1MNjJEDRw2Rl9gFuSEu8x5QY",
	},
	2: {
		trial: "price_1MNjJ7DRw2Rl9gFu3nob40iQ",
		standard: "price_1MNjJ7DRw2Rl9gFuPupsaAFH",
	},
	3: {
		trial: "price_1MNjJ0DRw2Rl9gFurkDSwcya",
		standard: "price_1MNjJ0DRw2Rl9gFuKwYscp2q",
	},

	// Guitar Yearly
	10: {
		trial: "price_1MNjJADRw2Rl9gFuy3Gxe0sb",
		standard: "price_1MNjJADRw2Rl9gFuV8JBsNhE",
	},
	11: {
		trial: "price_1MNjJ3DRw2Rl9gFuwrbTS22L",
		standard: "price_1MNjJ3DRw2Rl9gFuHVFJ1r3s",
	},
	12: {
		trial: "price_1MNjIxDRw2Rl9gFuhUfxIHTy",
		standard: "price_1MNjIxDRw2Rl9gFujpdrEKa9",
	},

	// Guitar Forever
	21: {
		trial: "price_1MNjItDRw2Rl9gFuhABnCVPX",
		standard: "price_1MNjItDRw2Rl9gFuprE7hCa2",
	},

	/**
	 * Keys Month
	 */
	4: {
		trial: "price_1MNjIpDRw2Rl9gFuTWHaifHF",
		standard: "price_1MNjIpDRw2Rl9gFubadsskcT",
	},
	5: {
		trial: "price_1MNjIhDRw2Rl9gFuGwYLqSCJ",
		standard: "price_1MNjIhDRw2Rl9gFueUxcZ3ep",
	},
	6: {
		trial: "price_1MNjK4DRw2Rl9gFuU79ClaVg",
		standard: "price_1MNjK4DRw2Rl9gFupRa1G4KV",
	},

	// Keys Yearly
	13: {
		trial: "price_1MNjIjDRw2Rl9gFuRYzXpUAK",
		standard: "price_1MNjIkDRw2Rl9gFuBqs7ZoCJ",
	},
	14: {
		trial: "price_1MNjIdDRw2Rl9gFuMICpFn8m",
		standard: "price_1MNjIdDRw2Rl9gFuL94GNnym",
	},
	15: {
		trial: "price_1MNimxDRw2Rl9gFucQmuYdI9",
		standard: "price_1MNimxDRw2Rl9gFu7yQW4dGx",
	},

	// Keys Forever
	24: {
		trial: "price_1MNjIZDRw2Rl9gFuPnQYWPGy",
		standard: "price_1MNjIZDRw2Rl9gFu6b70EjhP",
	},

	/**
	 * Sax Month
	 */
	7: {
		trial: "price_1MNjIWDRw2Rl9gFuuILuyxDY",
		standard: "price_1MNjIWDRw2Rl9gFuQDRIXrmf",
	},
	8: {
		trial: "price_1MNjIQDRw2Rl9gFuU5nNxpgk",
		standard: "price_1MNjIQDRw2Rl9gFuXfKaZHPP",
	},
	9: {
		trial: "price_1MNjIIDRw2Rl9gFulbDUSI3O",
		standard: "price_1MNjIIDRw2Rl9gFuRebYQjXS",
	},

	// Sax Yearly
	16: {
		trial: "price_1MNjITDRw2Rl9gFufRkyUqCw",
		standard: "price_1MNjITDRw2Rl9gFuS7U2CuNz",
	},
	17: {
		trial: "price_1MNjIMDRw2Rl9gFu7y8L5EaT",
		standard: "price_1MNjIMDRw2Rl9gFuRqfC0MOv",
	},
	18: {
		trial: "price_1MNjIFDRw2Rl9gFuDFKuIluv",
		standard: "price_1MNjIFDRw2Rl9gFuSsm7ebLJ",
	},

	// Sax Forever
	27: {
		trial: "price_1MNjICDRw2Rl9gFuVnJPscsK",
		standard: "price_1MNjICDRw2Rl9gFuV7HrvDOD",
	},
};
