import * as React from "react";
import { LessonItemWrapper, Lock } from "./Lessons.styled";
import { LessonStore } from "../../../../../stores/lessons/lesson.store";
import { CircleProgressBar } from "../../../../common";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useStores } from "../../../../../stores";
import { GrandChartAccess } from "../../../../../interfaces";
import { calculatePercent } from "../../../../../helpers";
import { IconLock } from "../../../../common/Icons";

interface LessonItemProps {
	isFirst: boolean;
	isFirstGroupLesson: boolean;
	access: GrandChartAccess;
	lessonStore: LessonStore;
}

export const LessonItem = observer(({ isFirst, access, lessonStore, isFirstGroupLesson }: LessonItemProps) => {
	const { grandCharts, lessonStore: selectedLesson, statisticsStore, accessLimitedStore } = useStores();

	const currentPassedSecond = statisticsStore.getByLessonUUID(lessonStore.uuid);
	const currentPassedProgress = calculatePercent(lessonStore.duration_second, currentPassedSecond);

	const navigate = useNavigate();

	const handleOnGoToLesson = async () => {
		if ([GrandChartAccess.DENIED].includes(access)) {
			accessLimitedStore.modal.open();
			return false;
		}

		if ([GrandChartAccess.TRIAL].includes(access) && !isFirst) {
			accessLimitedStore.modal.open();
			return false;
		}

		grandCharts.modal.close();
		navigate(`/cabinet/lessons/${lessonStore.uuid}`);
	};

	return (
		<LessonItemWrapper
			theme={selectedLesson.grand_chart_group_lesson.grand_chart_current.service.slug}
			disabled={([GrandChartAccess.TRIAL].includes(access) && !isFirst) || !isFirstGroupLesson}
			onClick={handleOnGoToLesson}
			className={`${selectedLesson.id === lessonStore.id ? "active" : ""}`}
		>
			<CircleProgressBar
				strokeWidth={2}
				sqSize={44}
				percentage={currentPassedProgress}
				payload={lessonStore.sort}
				color={lessonStore.color.color}
			/>

			{access === GrandChartAccess.DENIED ||
				(access === GrandChartAccess.TRIAL && !isFirst && (
					<Lock>
						<IconLock />
					</Lock>
				))}
		</LessonItemWrapper>
	);
});
