import styled, { css } from "styled-components";
import { COLORS, fontMixin, phoneQuery } from "../../../../styles";
import { rgba } from "polished";

interface AudioPlayerContainerI {
	isHideTracks: boolean;
	isLesson?: boolean;
	isView?: boolean;
}

export const AudioPlayerContainer = styled.div<AudioPlayerContainerI>`
	position: relative;
	background: ${COLORS.Light_Gray};

	width: calc(100%);
	height: 100%;
	border-radius: 10px;
	display: grid;
	grid-template-columns: 1fr 250px;
	color: ${COLORS.Dark_Gray};

	${(props) =>
		props.isView &&
		css`
			${AudioPlayerVolumeBlock} {
				margin-top: 20px;
			}
		`}

	${(props) =>
		props.isLesson &&
		css`
			//left: -50px;
			//width: calc(100% + 46px);
		`}

	${(props) =>
		props.isHideTracks &&
		css`
			grid-template-columns: 1fr 89px;

			${AudioPlayerTrackBlocks} {
				display: none;
			}

			${AudioPlayerVolumeBlock} {
				margin-left: 25px;
			}

			${AudioPlayerToggle} {
				transform: translate(-50%, -50%) rotate(180deg);
			}
		`}
`;

export const AudioPlayerMain = styled.div`
  display: grid;
  grid-auto-rows: auto 1fr;
}`;

export const AudioPlayerHeader = styled.div`
	display: flex;
	align-items: center;
	height: 30px;
	column-gap: 16px;
`;

export const AudioPlayerPlus = styled.button`
	position: relative;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), inset 0px -1px 2px rgba(0, 0, 0, 0.25);
	margin: 0 0 0 10px;
	background: ${COLORS.MidGray} !important;
	cursor: not-allowed;

	&:before {
		content: "";
		position: absolute;
		top: 4px;
		left: 50%;
		transform: translateX(-50%);
		width: 2px;
		height: 16px;
		background: #fff;
	}

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 16px;
		height: 2px;
		background: #fff;
	}
`;

export const AudioPlayerControls = styled.div`
	display: grid;
	grid-template-columns: 24px 1fr 40px;
	align-items: center;

	${phoneQuery(css`
		grid-template-columns: 24px 1fr 64px;
	`)}
`;

export const AudioPlayerDivider = styled.div`
	position: relative;
	width: 1px;
	height: calc(100% - 20px);
	border-left: 1px solid ${COLORS.Gold};
	margin: 0 auto;

	&:after {
		content: "";
		position: absolute;
		width: 10px;
		height: 40px;
		background: ${COLORS.Light_Gray};
		top: 50%;
		left: -5px;
		transform: translateY(-50%);
	}
`;

export const AudioPlayerToggle = styled.button`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 24px;
	height: 24px;
	border: 0;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	background: ${COLORS.Gold};
`;

export const AudioPlayerCurrentAccompaniment = styled.div`
	display: inline-flex;
	align-items: center;
	${fontMixin({ size: 14 })};
	line-height: 17px;
`;

export const AudioPlayerTrackBlocks = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-left: 32px;
`;

export const AudioPlayerVolumeBlock = styled.div`
	align-self: flex-start;
	margin: 10px 0 0 0;
	width: 64px;

	svg {
		path {
			fill: ${COLORS.Gold};
		}
	}
`;

interface AudioPlayerLoaderI {
	isLoading: boolean;
}

export const AudioPlayerLoader = styled.div<AudioPlayerLoaderI>`
	z-index: 1000;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${rgba(COLORS.Black, 0.5)};
	cursor: not-allowed;
	display: ${(props) => (props.isLoading ? "flex" : "none")};
	justify-content: center;
	align-items: center;
	${fontMixin({ size: 20, weight: 600 })};
	color: ${COLORS.White};
	border-radius: 10px;
`;
