import { Button, Container } from "./ButtonActions.styled";
import { observer } from "mobx-react";
import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { useStores } from "../../../../stores";
import { useCheckPurchase } from "../../../../hooks/useCheckPurchase";
import dayjs from "dayjs";
import { DurationPurchase } from "../../../../interfaces";
import { UserPurchaseStore } from "../../../../stores/user-purchases/user-purchase.store";

interface ButtonActionsProps {
	grandChart: GrandChartStore;
}

export const ButtonActions = observer(({ grandChart }: ButtonActionsProps) => {
	const {
		authStore: { userStore },
	} = useStores();

	let isGroupGrandChart = false;

	let userPurchaseStore: UserPurchaseStore | null = null;

	const result = useCheckPurchase(userStore.flatPurchases, grandChart.id);

	// Условие для группового гранд чарта
	if (Array.isArray(result) && result[0]) {
		userPurchaseStore = result[0];
	} else {
		userPurchaseStore = userStore.unitingPurchases[grandChart.id];
		isGroupGrandChart = true;
	}

	const handleOnPricePage = () => {
		grandChart.choosePlanModal.open();
	};

	const handleOnCancelPlan = () => {
		grandChart.cancelModal.open();
	};

	const getButtons = () => {
		if (userPurchaseStore) {
			// Если пользователь отменил подписку
			if (userPurchaseStore.period_end) {
				// Подписка кончилась
				const isSubscribeExpire = userPurchaseStore.period_end.isBefore(dayjs());

				// Если подписка кончилась
				if (isSubscribeExpire) {
					return (
						<Button theme={grandChart.service} onClick={handleOnPricePage}>
							Subscribe
						</Button>
					);
				} else if (!isGroupGrandChart) {
					return (
						<Button theme={grandChart.service} isCancel isSuccessCancel={Boolean(userPurchaseStore.period_end)}>
							Cancel Plan
						</Button>
					);
				}
			} else {
				if (userPurchaseStore.duration === DurationPurchase.Forever || isGroupGrandChart) {
					return false;
				}

				return (
					<Button
						theme={grandChart.service}
						isCancel
						isSuccessCancel={Boolean(userPurchaseStore.period_end)}
						onClick={handleOnCancelPlan}
					>
						Cancel Plan
					</Button>
				);
			}
		} else {
			return (
				<Button theme={grandChart.service} onClick={handleOnPricePage}>
					Subscribe
				</Button>
			);
		}
	};

	return <Container>{getButtons()}</Container>;
});
