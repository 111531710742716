import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins } from "../../../../../styles";

export const TrackListContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 119px;
	height: 30px;
	cursor: pointer;
	border-radius: 10px 0;
	${fontMixins(14)};
	color: ${COLORS.White};
	padding-inline: 20px 50px;
	background: ${COLORS.Gold};
`;

export const TrackListCurrentTrack = styled.div`
	${fontMixins(14)};
	color: ${COLORS.White};
`;

interface TrackListArrowProps {
	isShow: boolean;
}

export const TrackListArrow = styled.div<TrackListArrowProps>`
	position: absolute;
	right: 16px;
	border: solid ${COLORS.White};
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 4.5px;
	top: 7px;
	transform: rotate(45deg);
	transition: all 0.2s;

	${(props) =>
		props.isShow &&
		css`
			top: 13px;
			transform: rotate(-135deg);
		`}
`;

export const TrackListRow = styled.div`
	display: flex;
	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const TrackListDot = styled.span`
	display: inline-flex;
	width: 2px;
	height: 2px;
	background: ${COLORS.White};
	margin-right: 3px;
`;

export const TrackListLine = styled.span`
	display: inline-flex;
	width: 15px;
	height: 2px;
	background: ${COLORS.White};
`;

interface TrackListDropDownI {
	show: boolean;
}

export const TrackListDropDown = styled.div<TrackListDropDownI>`
	display: ${(props) => (props.show ? "flex" : "none")};
	flex-direction: column;
	padding: 5px 0;
	position: absolute;
	left: 0;
	top: 30px;
	width: 150px;
	max-height: 300px;
	overflow-y: auto;
	border: 10px;
	filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.25));
	background: ${COLORS.Black};
	z-index: 1000;
`;

export const TrackListDropDownItem = styled.div`
	${fontMixin({ size: 14 })};
	line-height: 17px;
	padding: 5px 10px;
	color: ${COLORS.White};
`;
