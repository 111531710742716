import { CategoryStore } from "../../../stores/categories";
import { Item } from "./Categories.styled";

interface CategoryItemProps {
	category: CategoryStore;
	isActive: boolean;
	onClick: (id: number) => void;
}

export const CategoryItem = ({ category, isActive, onClick }: CategoryItemProps) => {
	return (
		<Item isActive={isActive} onClick={() => onClick(category.id)}>
			{category.name}
		</Item>
	);
};
