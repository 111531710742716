import * as React from "react";
import { MainContainer } from "./Main.styled";

interface MainProps {
	children: React.ReactNode;
}

export const Main = ({ children }: MainProps) => {
	return <MainContainer>{children}</MainContainer>;
};
