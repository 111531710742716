import * as React from "react";
import { Container } from "./GroupLessonView.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { GrandChartGroupLessonStore } from "../../../../stores/grand-charts/grand-chart-group-lesson.store";
import { GroupLessonViewItem } from "./GroupLessonViewItem";

interface GroupLessonViewProps {}

export const GroupLessonView = observer(({}: GroupLessonViewProps) => {
	const { grandCharts } = useStores();
	const { selected } = grandCharts;
	const { selectedGrandChartGroupLesson } = selected;

	if (!grandCharts.selected.showGroupLesson) {
		return null;
	}

	const grandChartGroupLessons: GrandChartGroupLessonStore[] =
		selected.group_lessons_map[
			`${selectedGrandChartGroupLesson.grand_chart_course_id}-${selectedGrandChartGroupLesson.grand_chart_module_id}`
		] || [];

	return (
		<Container>
			{grandChartGroupLessons.map((grandChartGroupLesson, index) => {
				return (
					<GroupLessonViewItem
						key={grandChartGroupLesson.id}
						isFirstGroupLesson={index === 0}
						isFirst={index === 0}
						grandChartGroupLesson={grandChartGroupLesson}
					/>
				);
			})}
		</Container>
	);
});
