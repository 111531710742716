import { NotificationsList } from "../../components";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { HEADER_TYPE } from "../../constants";
import { useStores } from "../../stores";

interface NotificationsPageProps {}

export const NotificationsPage = observer(({}: NotificationsPageProps) => {
	const { settingsStore } = useStores();

	useEffect(() => {
		(async () => {
			settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
		})();
	}, []);

	return <NotificationsList />;
});
