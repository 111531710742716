import { Button, Modal } from "../../../common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useEffect } from "react";
import { Actions, Container, Description, Text, Title } from "./AccessLimitModal.styled";
import { ButtonTheme } from "../../../../constants";
import { useNavigate } from "react-router-dom";

interface AccessLimitModalProps {}

export const AccessLimitModal = observer(({}: AccessLimitModalProps) => {
	const { accessLimitedStore, grandCharts } = useStores();
	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener("keyup", detectEsc);

		return () => {
			window.removeEventListener("keyup", detectEsc);
		};
	}, []);

	const handleOnPricePage = () => {
		grandCharts.modal.close();
		accessLimitedStore.modal.close();
		navigate("/pricing");
	};

	const handleOnClose = () => {
		accessLimitedStore.modal.close();
	};

	const detectEsc = (e: KeyboardEvent) => {
		if (e.key === "Escape") {
			handleOnClose();
		}
	};

	return (
		<Modal modalStore={accessLimitedStore.modal} isShow={accessLimitedStore.modal.isShow} maxWidth={680}>
			<Container>
				<Title>Access Limited...</Title>
				<Description>In order to access this lesson, you must subscribe to a corresponding plan.</Description>
				<Text>Do you want to proceed and choose a plan?</Text>

				<Actions>
					<Button theme={ButtonTheme.GRAY} onClick={handleOnClose}>
						Cancel
					</Button>
					<Button onClick={handleOnPricePage}>Purchase</Button>
				</Actions>
			</Container>
		</Modal>
	);
});
