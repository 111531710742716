import * as React from "react";
import { ModalCloseButton, ModalContainer, ModalDialog } from "./Modal.styled";
import { ModalStore } from "../../../stores/common";

interface ModalProps {
	children?: React.ReactNode;
	modalStore: ModalStore;
	maxWidth?: number | string;
	isShow: boolean;
	auto?: boolean;
	grandChart?: boolean;
	transparent?: boolean;
	hideClose?: boolean;
}

export const Modal = ({
	modalStore = new ModalStore(),
	children = null,
	maxWidth,
	isShow,
	auto = false,
	grandChart = false,
	transparent = false,
	hideClose = false,
}: ModalProps) => {
	const handleOnClose = () => {
		modalStore.setShow(false);
	};

	return (
		<ModalContainer isShow={isShow} auto={auto} grandChart={grandChart} onClick={handleOnClose}>
			<ModalDialog
				transparent={transparent}
				style={{ maxWidth: `${typeof maxWidth === "number" ? `${maxWidth}px` : maxWidth}` }}
				isShow={modalStore.isShow}
				onClick={(e) => e.stopPropagation()}
			>
				<ModalCloseButton hide={hideClose} onClick={handleOnClose} />
				{children}
			</ModalDialog>
		</ModalContainer>
	);
};
