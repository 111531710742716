import styled, { css } from "styled-components";
import { COLORS, fontMixin } from "../../../../../styles";
import { ServiceName } from "../../../../../constants";

export const Container = styled.div`
	position: relative;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(1, 1fr);
	//grid-template-rows: min-content minmax(min-content, max-content) min-content;
	//align-content: start;
	width: 220px;
	height: 250px;
	border-radius: 10px;
	user-select: none;
	z-index: 100;

	& > div:last-child {
		//margin-bottom: 10px;
	}
`;

interface GroupLessonItemBlockI {
	isActive: boolean;
}

interface GroupLessonItemBlockI {
	theme: ServiceName;
}

export const GroupLessonItemBlock = styled.div<GroupLessonItemBlockI>`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 10px;
	align-items: center;
	background: ${COLORS.White};
	${fontMixin({ size: 12 })};
	line-height: 17px;
	color: ${COLORS.Dark_Gray};
	padding: 10px 20px;
	border-radius: 10px 10px 0 0;
	filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
	cursor: pointer;

	${(props) =>
		css`
			&.is_active {
				${GroupLessonItemProgressLine} {
					&:before {
						background: ${COLORS.Dark_Gray};
					}
				}
			}
		`}
	&:hover {
		background: ${COLORS.Light_Gray};
	}
`;

interface GroupLessonItemProgressLineI {
	progress: number;
}

export const GroupLessonItemProgressLine = styled.div<GroupLessonItemProgressLineI>`
	background: ${COLORS.White};
	width: 100%;
	height: 4px;
	position: absolute;
	bottom: 0;
	left: 0;

	&:before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		height: 4px;
		width: ${(props) => props.progress}%;
		border-radius: 10px;
	}
`;

export const GroupLessonItemCount = styled.div`
	position: absolute;
	right: 10px;
	bottom: 8px;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${COLORS.White};
`;

export const GroupLessonName = styled.div`
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;
