import { Container, NoteItem } from "./Notes.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../../stores";
import { getTotalTimeRecordAI } from "../../../../../../../helpers";

export const Notes = observer(() => {
	const { viewerStore, aiStore, authStore } = useStores();
	const { chooseItem } = viewerStore;

	const resultChooseBpm = chooseItem.getAiResultByBpm(aiStore.chosenBPM);

	if (!resultChooseBpm || aiStore.recordNow || resultChooseBpm.user_id === authStore.userStore.id) {
		return null;
	}

	return (
		<Container>
			{resultChooseBpm.mergeResultNotes(getTotalTimeRecordAI(aiStore.chosenBPM)).map((note, index) => {
				const [nameNote, start, end, marginLeftPercent, width] = note;

				const delay = 0.02 * (index + 1);

				return (
					<NoteItem
						key={start}
						style={{ marginLeft: `${marginLeftPercent}%`, width: `${width}%` }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						transition={{ duration: 0.5, delay: delay }}
					>
						{nameNote}
					</NoteItem>
				);
			})}
		</Container>
	);
});
