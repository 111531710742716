import { action, makeObservable, observable } from "mobx";
import { Stripe } from "@stripe/stripe-js";
import { API } from "../../core";
import { CoreResponse, PaymentLinkResponse } from "../../response";
import { MethodRequest } from "../../constants";

export class StripeStore {
	@observable isLoading = false;
	@observable instance: Stripe | undefined = undefined;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setInstance(value: Stripe) {
		this.instance = value;
	}

	@action.bound
	async redirectPaymentLink(values: { good_id: number; price_id: string }) {
		this.setIsLoading(true);

		try {
			const { data } = await API.request<CoreResponse<PaymentLinkResponse>>(`user-purchases`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});

			window.open(data.link, "_self");
		} catch (e) {
			console.error(`Error in method getPaymentLink : `, e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}
}
