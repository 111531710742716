import styled, { css } from "styled-components";
import { ServiceName } from "../../../../constants";
import { COLORS, fontMixin, getColorByService } from "../../../../styles";

export const Icon = styled.div`
	svg {
		width: 39.43px;
		height: 43.49px;
	}
`;

export const Text = styled.div`
	${fontMixin({ size: 18, weight: 700 })};
	color: ${COLORS.Gold};
`;

interface ContainerI {
	theme: ServiceName;
}

export const Container = styled.div<ContainerI>`
	grid-area: logotype;
	padding: 20px;
	display: grid;
	grid-template-columns: 42px 1fr;
	column-gap: 10px;

	${(props) =>
		props.theme &&
		css`
			${Icon} {
				svg {
					path {
						stroke: ${getColorByService(props.theme)};
					}

					&#keys {
						path {
							&:nth-child(2),
							&:nth-child(4),
							&:nth-child(8),
							&:nth-child(10),
							&:nth-child(12) {
								fill: ${getColorByService(props.theme)};
							}
						}
					}
				}
			}

			${Text} {
				color: ${getColorByService(props.theme)};
			}
		`}
`;
