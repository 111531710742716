import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	${phoneQuery(css`
		grid-template-columns: repeat(2, 1fr);
		row-gap: 25px;
	`)}
`;

export const ItemContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	justify-content: center;
`;

export const ProgressCircle = styled.div`
	display: grid;
	align-items: center;
	justify-content: center;
	width: 110px;
	height: 110px;
	border: 1px solid ${COLORS.White};
	border-radius: 50%;
	margin: 0 auto 10px;
`;

export const ValueCircle = styled.div`
	${fontMixins(36, 700)};
	color: ${COLORS.White};
`;

export const Description = styled.div`
	${fontMixins(18)};
	color: ${COLORS.White};
	height: 41px;
	display: grid;
	align-items: center;
	justify-content: center;
`;
