import * as React from "react";
import {
	CloseButton,
	CloseContainer,
	CustomModalContainer,
	ModalContainer,
	ModalDialog,
	Overlay,
} from "./CustomModal.styled";
import { HTMLAttributes, useEffect, useRef } from "react";
import { Portal } from "../../../helpers";
import { ModalStore } from "../../../stores/common";
import { useOutside } from "../../../hooks";

interface CustomModalProps extends HTMLAttributes<HTMLDivElement> {
	isOpen?: boolean;
	isCloseButton?: boolean;
	modalStore: ModalStore;
}

export const CustomModal = ({ isOpen = false, isCloseButton = true, modalStore, children }: CustomModalProps) => {
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (isOpen) {
			const bodyElement = window.document.getElementsByTagName("body");

			if (bodyElement.length > 0) {
				bodyElement[0].classList.add("overflow-hidden");
			}
		}

		return () => {
			const bodyElement = window.document.getElementsByTagName("body");

			if (bodyElement.length > 0) {
				bodyElement[0].classList.remove("overflow-hidden");
			}
		};
	}, [isOpen]);

	const handleOnClose = () => {
		modalStore.close();
	};

	useOutside(modalRef, handleOnClose);

	if (!isOpen) return null;

	return (
		<Portal>
			<CustomModalContainer>
				<Overlay />
				<ModalContainer>
					{isCloseButton && (
						<CloseContainer>
							<CloseButton onClick={handleOnClose} />
						</CloseContainer>
					)}

					<ModalDialog ref={modalRef}>{children}</ModalDialog>
				</ModalContainer>
			</CustomModalContainer>
		</Portal>
	);
};
