import { action, computed, makeObservable, observable } from "mobx";
import { Score, ScoreVideoI } from "../../interfaces/scores";
import { ScoreItemStore } from "./score-item.store";
import { API } from "../../core";
import { CoreResponse } from "../../response";
import { SCORE_TYPE } from "../../constants";
import { LibraryScoreItemStore } from "../library/library-score-item.store";

export class ScoreStore implements Score {
	@observable isLoading = false;

	@observable id = 0;
	@observable lesson_id = 0;
	@observable sort = 0;
	@observable name = "";
	@observable sub_title = "";
	@observable video_url = "";
	@observable video_link = "";
	@observable content = "";
	@observable items: ScoreItemStore[] = [];

	@observable video_iframe = "";
	@observable isView = false;
	@observable viewScoreIndex: null | number = null;

	constructor(initialData: Score | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setIsView(value: boolean) {
		this.isView = value;
	}

	@action.bound
	setViewScoreIndex(value: number | null) {
		this.viewScoreIndex = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	async getVideo() {
		if (!this.id) {
			return false;
		}

		this.setIsLoading(true);

		try {
			const {
				data: { video_iframe },
			} = await API.request<CoreResponse<ScoreVideoI>>(`scores-video/${this.id}`);

			this.setVideoIframe(video_iframe);
		} catch (e) {
			this.setVideoIframe("");
			console.error(`Error in method ScoreVideoStore.getVideo : `, e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	setVideoIframe(value: string) {
		this.video_iframe = value;
	}

	@computed
	get emptyVideo() {
		return this.video_iframe === "";
	}

	@computed
	get allLibraries(): LibraryScoreItemStore[] {
		if (this.isView && this.viewScoreIndex) {
			const findItem = this.items.find((item) => item.id === this.viewScoreIndex);

			if (!findItem) {
				return [];
			}

			return findItem.libraries.flat();
		}

		return this.items
			.filter((scoreItem) => scoreItem.score_type_id === SCORE_TYPE.IMAGE)
			.filter((scoreItem) => scoreItem.libraries.length > 0)
			.map((scoreItem) => scoreItem.libraries.map((library) => library))
			.flat()
			.reduce((accumulator: LibraryScoreItemStore[], current) => {
				if (!accumulator.find((item) => item.nameTrack === current.nameTrack)) {
					accumulator.push(current);
				}
				return accumulator;
			}, []);
	}

	@computed
	get firstLibrary(): LibraryScoreItemStore {
		return this.allLibraries?.[0] || new LibraryScoreItemStore(null);
	}

	@action
	fillStore(data: Score) {
		const { id, lesson_id, sort, name, sub_title, video_url, video_link, content, items } = data;

		this.id = id;
		this.lesson_id = lesson_id;
		this.sort = sort;
		this.name = name;
		this.sub_title = sub_title;
		this.video_url = video_url;
		this.video_link = video_link;
		this.content = content;
		this.items = (items || []).map((item) => new ScoreItemStore(item));
	}
}
