import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../styles";

export const WhiteContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	background: ${COLORS.White};
	height: 100%;
`;

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	width: 960px;
	margin: 0 auto;
	padding: 50px 0 69px;

	${phoneQuery(css`
		width: auto;
		padding: 25px 0;
	`)}
`;

export const LearningList = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 20px;
	margin: 50px 0 60px;

	${phoneQuery(css`
		grid-template-columns: repeat(1, 1fr);
		margin: 25px 0;
		row-gap: 25px;
		padding-inline: 15px;
	`)}
`;

export const LearningListItem = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	text-align: center;
	align-self: flex-start;
`;

export const LearningIcon = styled.div``;

export const LearningTitle = styled.h2`
	${fontMixins(36)};
	margin: 0 0 21px;
	padding: 0;
	font-weight: 700;
	line-height: 60px;

	${phoneQuery(css`
		${fontMixins(30, 700)};
		line-height: 40px;
	`)}
`;

export const LearningDescription = styled.div`
	${fontMixins(18, 200)};
	line-height: 25px;
	color: ${COLORS.Dark_Gray};
`;

export const Actions = styled.div`
	display: grid;
	justify-content: center;

	${phoneQuery(css`
		margin: 50px 0 0;
	`)}
`;
