import * as React from "react";
import { SwitcherBody, SwitcherCircle, SwitcherContainer, SwitcherElement, SwitcherLabel } from "./Switcher.styled";
import { HTMLAttributes, useState } from "react";

interface SwitcherProps extends HTMLAttributes<HTMLDivElement> {
	label?: string;
	mute: boolean;
	disabled: boolean;
	onChangeMute: () => void;
}

export const Switcher = ({ label = "", mute, disabled, onChangeMute }: SwitcherProps) => {
	const [isMute, setIsMute] = useState(mute);

	const handleOnChange = () => {
		if (disabled) {
			return false;
		}

		setIsMute((prevState) => !prevState);
		onChangeMute();
	};

	return (
		<SwitcherContainer>
			<SwitcherBody>
				<span>Off</span>

				<SwitcherElement isMute={isMute} onClick={handleOnChange}>
					On <SwitcherCircle />
				</SwitcherElement>
			</SwitcherBody>
			<SwitcherLabel>{label}</SwitcherLabel>
		</SwitcherContainer>
	);
};
