import {
	CircleBody,
	Container,
	LessonBreak,
	ProgressCircle,
	ProgressGhost,
	ProgressRing,
	TimeBlock,
} from "./Clock.styled";
import { useEffect, useState } from "react";

interface ClockProps {
	width?: number;
	height?: number;
	strokeWidth?: number;
	percent?: number;
	backgroundPercent?: number;
	lessonBreakPercent?: number;
	minute?: number | string;
	second?: number | string;
	onClick?: () => void;
}

export const Clock = ({
	width = 130,
	height,
	strokeWidth = 10,
	percent = 0.3,
	backgroundPercent = 0,
	lessonBreakPercent = 0,
	minute = 0,
	second = 0,
	onClick,
}: ClockProps) => {
	const [isShow, setIsShow] = useState(true);

	height = height || width;
	const innerWidth = width;

	useEffect(() => {
		/*setTimeout(() => {
			setIsShow(true);
		}, 1000);*/
	}, []);

	const getWidthHeightBody = () => {
		return innerWidth / 2;
	};
	const getRadiusBody = () => {
		return innerWidth / 2 - 15;
	};

	const getRadius = () => {
		if (!isShow) {
			return 0;
		}

		return width / 2 - strokeWidth * 2;
	};

	const getWidthHeight = () => {
		if (!isShow) {
			return 0;
		}

		return width / 2;
	};

	const getCircumference = () => {
		if (!isShow) {
			return 0;
		}
		return 2 * Math.PI * getRadius();
	};

	const getProgress = () => {
		if (!isShow) {
			return 0;
		}

		return getCircumference() - (percent / 100) * getCircumference() || 0;
	};

	const getProgressGhost = () => {
		return getCircumference() - (backgroundPercent / 100) * getCircumference() || 0;
	};

	const getLessonBreak = () => {
		return getCircumference() - (lessonBreakPercent / 100) * getCircumference() || 0;
	};

	return (
		<Container>
			<ProgressRing width={width} height={height} backgroundPercent={backgroundPercent} onClick={onClick}>
				<CircleBody cx={getWidthHeightBody()} cy={getWidthHeightBody()} r={getRadiusBody()} />

				<ProgressGhost
					cx={getWidthHeight()}
					cy={getWidthHeight()}
					r={getRadius()}
					strokeWidth={strokeWidth}
					strokeDasharray={`${getCircumference()} ${getCircumference()}`}
					strokeDashoffset={getProgressGhost()}
				/>
				<ProgressCircle
					cx={getWidthHeight()}
					cy={getWidthHeight()}
					r={getRadius()}
					strokeWidth={strokeWidth}
					strokeDasharray={`${getCircumference()} ${getCircumference()}`}
					strokeDashoffset={getProgress()}
				/>
				<LessonBreak
					cx={getWidthHeight()}
					cy={getWidthHeight()}
					r={getRadius()}
					strokeWidth={strokeWidth}
					strokeDasharray={`${getCircumference()} ${getCircumference()}`}
					strokeDashoffset={getLessonBreak()}
				/>
			</ProgressRing>

			<TimeBlock onClick={onClick}>
				<span>{minute}</span>
				<span>:</span>
				<span>{second}</span>
			</TimeBlock>
		</Container>
	);
};
