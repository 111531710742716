import styled from "styled-components";
import { COLORS } from "../../../../../styles";

export const ContainerProgressLine = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: calc(100% - 50px);
	height: 4px;
	background: ${COLORS.MidGray};
`;

export const FillProgressLine = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	height: 4px;
	width: 0;
	z-index: 900;
`;
