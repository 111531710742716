import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

interface FiltersContainerProps {
	isPadding: boolean;
}

export const FiltersContainer = styled.div<FiltersContainerProps>`
	margin: 30px 0 0 25px;
	width: 192px;

	${(props) =>
		props.isPadding &&
		css`
			top: 230px;
		`}
`;

export const FilterHeader = styled.div`
	display: flex;
	${fontMixins(18, 700)};
	margin-bottom: 20px;
`;

export const FilterBlock = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
`;

export const FilterTitle = styled.div`
	display: flex;
	height: 30px;
	${fontMixins(14, 700)};
`;

interface FilterItemProps {
	color?: string;
}

export const FilterItem = styled.div<FilterItemProps>`
	display: flex;
	align-items: center;
	column-gap: 5px;
	${fontMixins(14, 400)};
	color: ${(props) => props.color || COLORS.Dark_Gray};
	height: 30px;
	cursor: pointer;
`;
