import { HTMLAttributes, useEffect, useState } from "react";
import ReactDOM from "react-dom";

interface PortalI extends HTMLAttributes<HTMLDivElement> {}

export const Portal = ({ children, className = "root-portal" }: PortalI) => {
	const [container] = useState(() => document.createElement("div"));

	useEffect(() => {
		container.classList.add(className);
		document.body.appendChild(container);

		return () => {
			document.body.removeChild(container);
		};
	}, []);

	return ReactDOM.createPortal(children, container);
};
