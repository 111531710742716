import * as React from "react";
import { Container, Grid, Item, TutorialsPreview } from "./Tutorials.styled";

import { TUTORIALS_LINKS } from "../../../../constants";
import { ucFirst } from "../../../../helpers";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";

interface TutorialsProps {}

export const Tutorials = observer(({}: TutorialsProps) => {
	const { viewIframeStore } = useStores();

	let number = 0;

	const handleOnShow = ({ link }: { title: string; link: string }) => {
		viewIframeStore.setUrl(link);
		viewIframeStore.setIsShow(true);
	};

	return (
		<Container>
			<Grid>
				{TUTORIALS_LINKS.map((item) => {
					number++;
					return (
						<Item key={number} onClick={() => handleOnShow(item)}>
							<TutorialsPreview src={item.image} />

							<span>
								{number}. {ucFirst(item.title)}
							</span>
						</Item>
					);
				})}
			</Grid>
		</Container>
	);
});
