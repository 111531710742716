import styled from "styled-components";
import { COLORS, fontMixin, fontMixins } from "../../../../styles";

export const MethodContainer = styled.div`
	background: ${COLORS.White};
	border-radius: 10px;
	user-select: none;
`;

export const MethodHeader = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	height: 50px;
	background: ${COLORS.Light_Gray};
	border-radius: 10px 10px 0 0;
`;

export const MethodButton = styled.button`
	display: flex;
	flex-direction: column;
	width: 50px;
	height: 50px;
	border-radius: 10px 0 0 0;
	border: 0;
	justify-content: center;
	align-items: center;
	padding: 0;
	margin: 0;
	cursor: pointer;
	row-gap: 5px;

	span {
		display: flex;
		width: 25px;
		height: 2px;
		background: ${COLORS.White};
		border-radius: 4px;
	}
`;

interface MethodDropDownMenuI {
	show: boolean;
}

export const MethodDropDownMenu = styled.ul<MethodDropDownMenuI>`
	position: absolute;
	top: 50px;
	left: 0;
	background: ${COLORS.Bgrnd};
	z-index: 99999;
	display: ${(props) => (props.show ? "flex" : "none")};
	flex-direction: column;
	justify-content: left;

	li {
		position: relative;
		display: flex;
		align-items: center;
		padding: 20px;
		height: 40px;
		cursor: pointer;

		&:hover > ul {
			visibility: visible;
			display: block;
		}

		ul {
			display: none;
			visibility: hidden;
			position: absolute;
			transition: all 0.5s ease;
			left: 220px;
			width: 370px;
			top: 0;
			background: ${COLORS.Bgrnd};
			margin: 0;

			li {
				line-height: 22px;
			}
		}
	}

	button {
		${fontMixin({ size: 16 })};
		color: ${COLORS.MidGray};
		background: transparent;
		border: 0;
		margin: 0 0 10px;
		padding: 5px 10px;
		cursor: pointer;
		text-align: left;

		&:hover {
			background: ${COLORS.Dark_Gray};
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const MethodVideoContainer = styled.div`
	display: flex;
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
	height: auto;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

export const MethodVideoIframeContainer = styled.div`
	display: flex;
	position: relative;
	padding-bottom: 56.25%;
	overflow: hidden;
	max-width: 100%;
	height: auto;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

interface MethodVideoIframeLoadingI {
	isLoading: boolean;
}

export const MethodVideoIframeLoading = styled.div<MethodVideoIframeLoadingI>`
	${fontMixins(18, 700)};
	color: ${COLORS.Dark_Gray};
	text-align: center;
	padding: 25px;
	display: ${(props) => (props.isLoading ? "flex" : "none")};
	justify-content: center;
`;

export const MethodTextContainer = styled.div`
	padding: 42px 53px;
	margin-bottom: 25px;
	overflow: auto;
	color: ${COLORS.Black};
	${fontMixin({ size: 18 })}
	line-height: 30px;

	p {
		${fontMixin({ size: 18 })};
		line-height: 30px;
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.mtd-hint {
		font-weight: 700;
	}

	strong,
	b,
	.mtd-homework {
		font-weight: 700;
	}

	a {
		font-weight: 500;

		&:hover {
			text-decoration: none;
		}
	}

	h3 {
		font-size: 26px;
		font-weight: 700;
		margin-bottom: 25px;
	}

	h4 {
		font-size: 24px;
		font-weight: 700;
	}

	h5 {
		font-size: 20px;
		font-weight: 700;
	}

	i {
		font-style: italic;
	}
`;

export const NameMethod = styled.div`
	position: relative;
	${fontMixins(18)};
	line-height: 22px;
	color: ${COLORS.Dark_Gray};
	margin: 0 auto;
	left: -25px;
`;

export const ContainerCollege = styled.div`
	color: ${COLORS.Dark_Gray};
`;
