import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../../../../styles";

interface ContainerProps {
	isActive: boolean;
}

export const Container = styled.div<ContainerProps>`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 110px;
	height: 102px;
	align-items: center;
	row-gap: 2px;
	background: ${COLORS.Dark_Gray};
	border-radius: 20px;
	border: 4px solid ${COLORS.Dark_Gray};
	padding: 18px 0 0;
	color: ${COLORS.White};
	cursor: pointer;

	${(props) =>
		props.isActive &&
		css`
			background: ${COLORS.White};
			border-color: ${COLORS.Gold};
			color: ${COLORS.Dark_Gray};
			cursor: default;
		`}
`;

export const Title = styled.div`
	${fontMixins(14, 700)};
	margin-bottom: 3px;
`;

export const BPM = styled.div`
	${fontMixins(14, 300)};
	margin-bottom: 7px;
`;

export const Count = styled.div`
	position: absolute;
	bottom: -20px;
	display: flex;
	width: 30px;
	height: 30px;
	align-items: center;
	justify-content: center;
	background: ${COLORS.Gold};
	color: ${COLORS.White};
	border-radius: 50%;
	${fontMixins(14, 700)};
	border: 2px solid ${COLORS.White};
`;
