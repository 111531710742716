import * as React from "react";
import { useEffect, useState } from "react";
import { SignPageContainer } from "./SignPage.styled";
import { Container, Description, Title } from "../ContactPage/ContactPage.styled";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { HEADER_TYPE, SIGN_PAGE } from "../../constants";
import { CongratulationsModal, SignInForm, SignUpForm } from "../../components";
import { ForgotForm } from "../../components/ui/Forms/ForgotForm/ForgotForm";
import { useNavigate, useSearchParams } from "react-router-dom";

interface SignPageProps {}

export const SignPage = observer(({}: SignPageProps) => {
	const { settingsStore, authStore } = useStores();
	const [currentForm, setCurrentForm] = useState(SIGN_PAGE.SIGN_UP);
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
	}, []);

	useEffect(() => {
		const tab = searchParams.get("tab");

		if (tab === "sign_in") {
			setCurrentForm(SIGN_PAGE.SIGN_IN);
		}
	}, [searchParams]);

	useEffect(() => {
		if (authStore.isAuth) {
			navigate("/cabinet/my-day");
		}
	}, [authStore.isAuth]);

	const handleChangeForm = (value: SIGN_PAGE) => {
		setCurrentForm(value);
	};

	const getName = () => {
		switch (currentForm) {
			case SIGN_PAGE.SIGN_UP:
				return "Sign Up & Start Your <br /> FREE TRIAL";
			case SIGN_PAGE.SIGN_IN:
				return "Sign In to<br/> Continue Learning";
			default:
				return "Recover Your Password";
		}
	};

	return (
		<>
			<SignPageContainer>
				<Container>
					<Title dangerouslySetInnerHTML={{ __html: getName() }} />

					<div style={{ height: 30 }} />

					<Description
						dangerouslySetInnerHTML={{
							__html:
								currentForm === SIGN_PAGE.RECOVER
									? "Enter your email in the form below. <br/> If it is registered with our system, you will receive an email with a recovery link."
									: "We love music and work to make music education accessible to everyone <br/><br/> Get FREE 1st Lessons of Each Module<br/> in every Program & Course",
						}}
					></Description>

					{currentForm === SIGN_PAGE.SIGN_UP && <SignUpForm onChangeForm={handleChangeForm} />}

					{currentForm === SIGN_PAGE.SIGN_IN && <SignInForm onChangeForm={handleChangeForm} />}

					{currentForm === SIGN_PAGE.RECOVER && <ForgotForm onChangeForm={handleChangeForm} />}
				</Container>
			</SignPageContainer>
			<CongratulationsModal onChangeForm={handleChangeForm} />
		</>
	);
});
