import { action, makeObservable, observable } from "mobx";

export class ViewIframeStore {
	@observable isShow = false;
	@observable url = "";

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsShow(value: boolean) {
		this.isShow = value;
	}

	@action.bound
	setUrl(value: string) {
		this.url = value;
	}

	@action.bound
	close() {
		this.setUrl('');
		this.setIsShow(false);
	}
}
