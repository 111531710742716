import styled from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div``;

export const PayButtonPrice = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-inline: 20px;
	border: 0;
	height: 60px;
	border-radius: 10px;
	${fontMixins(18, 700)};
	color: ${COLORS.White};
	cursor: pointer;

	svg {
		margin-right: 10px;
	}
`;
