import styled, { css } from "styled-components";
import { COLORS, fontMixins, getColorByService } from "../../../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	height: 330px;
`;

export const Part = styled.div`
	text-align: right;
	${fontMixins(11, 400)};
	line-height: 14px;
	color: ${COLORS.Gold};

	${(props) =>
		props.theme &&
		css`
			color: ${getColorByService(props.theme)};
		`}
	&:first-child {
		margin-bottom: 60px;
	}

	&:last-child {
		margin-top: auto;
	}
`;
