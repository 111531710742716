import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { Button, Modal } from "../../../common";
import { observer } from "mobx-react";
import { ActionsButton, Container, Description, NameProduct, Title } from "./ChoosePlanModal.styled";
import { ButtonTheme, PriceDuration } from "../../../../constants";
import { useStores } from "../../../../stores";

interface ChoosePlanModalProps {
	grandChart: GrandChartStore;
}

export const ChoosePlanModal = observer(({ grandChart }: ChoosePlanModalProps) => {
	const { stripeStore } = useStores();

	const monthGoodStore = grandChart.getPrice(PriceDuration.Monthly);
	const yearlyGoodStore = grandChart.getPrice(PriceDuration.Yearly);

	const handleOnMonthly = async () => {
		if (monthGoodStore) {
			await stripeStore.redirectPaymentLink({
				good_id: monthGoodStore.id,
				price_id: monthGoodStore.stripe_default_price,
			});
		}
	};

	const handleOnYearly = async () => {
		if (yearlyGoodStore) {
			await stripeStore.redirectPaymentLink({
				good_id: yearlyGoodStore.id,
				price_id: yearlyGoodStore.stripe_default_price,
			});
		}
	};

	const isNotShow = grandChart.title.includes("Pre") || grandChart.title.includes("Grand chart");

	return (
		<>
			<Modal modalStore={grandChart.choosePlanModal} isShow={grandChart.choosePlanModal.isShow} maxWidth={620}>
				<Container>
					<Title>Choose Plan</Title>
					<NameProduct>
						{grandChart.instrument.name} | {isNotShow ? "" : grandChart.title}{" "}
						{/*{grandChart.service.slug && mappingService[grandChart.service.slug]}*/}
					</NameProduct>

					<Description>
						<p>Monthly or yearly subscription?</p>
						<p>
							<span>SAVE up to 75%</span> with an annual plan!
						</p>
					</Description>
					<ActionsButton>
						{monthGoodStore && (
							<Button theme={ButtonTheme.GRAY} onClick={handleOnMonthly} isLoading={stripeStore.isLoading}>
								Monthly - ${monthGoodStore.priceFormat}
							</Button>
						)}

						{yearlyGoodStore && (
							<Button theme={ButtonTheme.GOLD} onClick={handleOnYearly} isLoading={stripeStore.isLoading}>
								Yearly - ${yearlyGoodStore.priceFormat}
							</Button>
						)}
					</ActionsButton>
				</Container>
			</Modal>
		</>
	);
});
