import styled from "styled-components";
import { COLORS, fontMixin } from "../../../../styles";

export const Container = styled.div`
	position: relative;
	grid-area: modules;
	display: grid;
	grid-template-columns: repeat(auto-fit, 220px);
	height: 86px;
	column-gap: 10px;
	z-index: 100;
`;

interface ItemI {
	isActive?: boolean;
}

export const ModuleItemBlock = styled.div<ItemI>`
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	border-radius: 10px;
	background: ${COLORS.Dark_Gray};
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	padding: 0 20px;
	text-align: center;
	color: ${COLORS.White};
	line-height: 17px;

	span {
		text-transform: uppercase;
		${fontMixin({ size: 14 })};
	}
`;

export const NumberModule = styled.div`
	width: 190px;
	height: 20px;
	border-radius: 0 0 10px 10px;
	display: grid;
	grid-template-columns: 1fr;
	${fontMixin({ size: 12 })};
	line-height: 15px;
	margin: 0 auto;
	text-align: center;
	align-items: center;
`;
