import * as React from "react";
import {
	Container,
	CounterDays,
	CounterPassed,
	CounterRemain,
	Days,
	DaysInformation,
	ForeverBlock,
	InfinitySymbol,
	Period,
	ProgressContainer,
	ProgressLine,
} from "./InformationPlan.styled";
import { ProductPurchaseStore } from "../../../../stores/products/product-purchase.store";
import { TrialVersionStore } from "../../../../stores/user/trial-version.store";
import { PRODUCT_PERIOD } from "../../../../constants";
import { createLabel } from "../../../../helpers";

interface InformationPlanProps {
	resultCheckProduct: ProductPurchaseStore | TrialVersionStore;
}

export const InformationPlan = ({ resultCheckProduct }: InformationPlanProps) => {
	if (resultCheckProduct instanceof ProductPurchaseStore) {
		return (
			<Container>
				<Period>
					{resultCheckProduct.product.product_period_id === PRODUCT_PERIOD.FOREVER ? (
						<ForeverBlock>Forever</ForeverBlock>
					) : (
						resultCheckProduct.total_days
					)}
				</Period>

				<ProgressContainer>
					<ProgressLine style={{ width: `${resultCheckProduct.passedPercent}%` }} />
				</ProgressContainer>

				<CounterDays>
					<CounterPassed>
						<Days>
							{resultCheckProduct.product.product_period_id === PRODUCT_PERIOD.FOREVER ? (
								<InfinitySymbol />
							) : (
								resultCheckProduct.days_passed
							)}
						</Days>
						<DaysInformation>
							{createLabel(resultCheckProduct.days_passed, ["Day", "Days", "Days"])} passed
						</DaysInformation>
					</CounterPassed>

					<CounterRemain>
						<Days>
							{resultCheckProduct.product.product_period_id === PRODUCT_PERIOD.FOREVER ? (
								<InfinitySymbol />
							) : (
								resultCheckProduct.days_remain
							)}
						</Days>
						<DaysInformation>
							{createLabel(resultCheckProduct.days_remain, ["Day", "Days", "Days"])} remain
						</DaysInformation>
					</CounterRemain>
				</CounterDays>

				{/** TODO Пока не решили что делать с кнопкой отменить подсписку */}
				{/*<CancelSubscription onClick={handleOnCancelSubscription}>Cancel this subscription</CancelSubscription>*/}
			</Container>
		);
	}

	return (
		<Container>
			<Period>
				{resultCheckProduct.total_days} {createLabel(resultCheckProduct.total_days, ["Day", "Days", "Days"])}
			</Period>
			<ProgressContainer>
				<ProgressLine style={{ width: `${resultCheckProduct.passedPercent}%` }} />
			</ProgressContainer>

			<CounterDays>
				<CounterPassed>
					<Days>{resultCheckProduct.days_passed}</Days>
					<DaysInformation>
						{createLabel(resultCheckProduct.days_passed, ["Day", "Days", "Days"])} passed
					</DaysInformation>
				</CounterPassed>

				<CounterRemain>
					<Days>{resultCheckProduct.days_remain}</Days>
					<DaysInformation>
						{createLabel(resultCheckProduct.days_remain, ["Day", "Days", "Days"])} remain
					</DaysInformation>
				</CounterRemain>
			</CounterDays>
		</Container>
	);
};
