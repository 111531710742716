import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const LessonContainer = styled.div`
	display: flex;
	column-gap: 5px;
	align-items: center;
	align-self: center;
	margin-right: 25px;
`;

export const LessonCircle = styled.div`
	display: flex;
`;

export const LessonName = styled.div`
	${fontMixins(12, 300)};
	color: ${COLORS.Dark_Gray};
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`;
