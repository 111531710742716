import styled, { css } from "styled-components";
import { phoneQuery } from "../../../styles";

export const MyStatisticsPageContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	column-gap: 20px;

	${phoneQuery(css`
		grid-template-columns: 1fr;

		div {
			display: none;

			&:first-child {
				display: block;
			}
		}
	`)}
`;
