import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useEffect } from "react";
import { Container, Description, Body, SocialContainer, Form } from "./IFrame.styled";
import { Button, Divider, InputField } from "../../components/common";
import { ButtonTheme } from "../../constants";
import * as React from "react";
import { useFormik } from "formik";
import { AuthSignUpRequest } from "../../requests";
import { AuthSchema } from "../../validation-schems";
import { gtmEvent } from "../../helpers";
import GoogleLogin from "react-google-login";
import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

export const IFrame = observer(() => {
	const { authStore } = useStores();

	useEffect(() => {}, []);

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: async (values) => {
			try {
				await authStore.signUp(values as AuthSignUpRequest);
			} catch (e: any) {}
		},
		validationSchema: AuthSchema,
	});

	const handleOnFacebook = async (data: any) => {
		try {
			await authStore.signFacebook(data);
			gtmEvent({ event: "Sign_up", email: data.profileObj.email.toLowerCase() });
		} catch (e) {
			console.log("facebook error", e);
		}
	};

	const handleOnGoogle = async (data: any) => {
		try {
			await authStore.signGoogle({
				access_token: data.accessToken,
				email: data.profileObj.email,
				first_name: data.profileObj.givenName,
				last_name: data.profileObj.familyName,
				picture: data.profileObj.imageUrl,
			});

			gtmEvent({ event: "Sign_up", email: data.profileObj.email.toLowerCase() });
		} catch (e) {
			console.log("google error", e);
		}
	};

	return (
		<Container>
			<Body>
				<SocialContainer>
					<ReactFacebookLogin
						appId={"514163569493257"}
						callback={handleOnFacebook}
						disableMobileRedirect
						isMobile={false}
						fields="first_name,last_name,email,picture.type(large),birthday,location"
						render={(renderProps) => {
							return (
								<Button theme={ButtonTheme.FACEBOOK} onClick={renderProps.onClick}>
									Log in with Facebook
								</Button>
							);
						}}
					/>
					<Divider>or</Divider>
					<GoogleLogin
						clientId={"826618397728-phql2reajkfudj3q0uosjuqb7tpjdlhf.apps.googleusercontent.com"}
						buttonText="Login"
						onSuccess={handleOnGoogle}
						onFailure={(responseError) => console.log("responseError", responseError)}
						cookiePolicy={"single_host_origin"}
						render={(renderProps) => {
							return (
								<Button theme={ButtonTheme.GOOGLE} onClick={renderProps.onClick}>
									Log in with Google
								</Button>
							);
						}}
					/>
				</SocialContainer>

				<Description>or sign up with email</Description>

				<Form onSubmit={formik.handleSubmit}>
					<InputField
						type={"email"}
						name={"email"}
						value={formik.values.email}
						placeholder={"Your email"}
						errorMessage={formik.errors.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					<Description style={{ height: "60px" }}>
						Your password will be generated & sent onto your email. You will be able to change it once you login.
					</Description>

					<Button type={"submit"} disabled={!formik.dirty || formik.errors.email !== undefined}>
						Create account
					</Button>
				</Form>
			</Body>
		</Container>
	);
});
