export const COLORS = {
	Bgrnd: "#191919",
	Dark: "#1a1a1a",
	Dark_Gray: "#4D4D4D",
	Dark_Gray_2: "#969696",
	Dark_Gray_Hover: "#595959",
	Dark_Gray_Active: "#404040",
	White: "#FFFFFF",
	Gold: "#D89F5B",
	Gold_Down: "#AB7D47",
	Red: "#A80E3A",
	Red_Hover: "#BF1042",
	Red_Down: "#8C0C30",
	School: "#2D9CDB",
	College: "#EE8208",
	University: "#00A871",
	MidGray: "#C4C4C4",
	Light_Gray: "#E5E5E5",
	Light_Gray_2: "#f4f4f4",
	Note_Blue: "#517BE8",
	Note_Red: "#EA4141",
	Note_Purple: "#8A3277",
	Black: "#000",

	Facebook: "#355A97",
	Google: "#4184F3",
};
