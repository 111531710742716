import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../styles";

export const ContactPageContainer = styled.div`
	display: grid;
	height: 100%;
	background: ${COLORS.White};
`;

export const Container = styled.div`
	width: 960px;
	margin: 0 auto;
	padding: 70px 0 220px;

	${phoneQuery(css`
		width: auto;
		padding: 35px 0 100px;
	`)}
`;

export const Title = styled.div`
	display: flex;
	height: 140px;
	align-items: center;
	justify-content: center;
	${fontMixins(60, 700)};
	line-height: 73px;
	text-align: center;
	color: ${COLORS.Dark_Gray};

	${phoneQuery(css`
		height: auto;
		${fontMixins(30, 700)};
		line-height: 45px;
		margin-bottom: 15px;
	`)}
`;

export const Description = styled.div`
	text-align: center;
	${fontMixins(18)};
	line-height: 25px;
	width: 520px;
	margin: 0 auto 50px;
	color: ${COLORS.Dark_Gray};

	${phoneQuery(css`
		width: auto;
	`)}
`;

export const Blocks = styled.div`
	display: grid;
	grid-template-columns: 480px 290px;
	column-gap: 30px;
	width: 680px;
	margin: 0 auto;

	${phoneQuery(css`
		width: 100%;
		grid-template-columns: 1fr;
		padding: 0 15px;
	`)}
`;

export const Block = styled.div`
	line-height: 25px;

	${phoneQuery(css`
		&:last-child {
			margin-top: 125px;
		}
	`)}

	h4 {
		width: 235px;
		color: ${COLORS.Gold};
		${fontMixins(18, 700)};
		line-height: 22px;
		margin-bottom: 36px;

		${phoneQuery(css`
			width: 100%;
		`)}
	}

	p {
		${fontMixins(18)};
		line-height: 25px;
		color: ${COLORS.Dark_Gray};
		margin-bottom: 55px;
	}

	span,
	a {
		color: ${COLORS.Gold};
		${fontMixins(14)};
		line-height: 17px;
	}
`;

export const FormWrapper = styled.form`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 18px;

	${phoneQuery(css`
		width: 100%;
	`)}
`;

export const InputField = styled.input`
	width: 100%;
	height: 50px;
	padding-inline: 20px;
	border: 1px solid ${COLORS.MidGray};
	border-radius: 10px;
	${fontMixins(18)};
	color: ${COLORS.Dark_Gray};
`;

export const TextAreaField = styled.textarea`
	width: 100%;
	height: 180px;
	padding-inline: 20px;
	border: 1px solid ${COLORS.MidGray};
	border-radius: 10px;
	${fontMixins(18)};
	color: ${COLORS.Dark_Gray};
	resize: vertical;
	padding-top: 15px;
`;

export const Button = styled.button`
	background: ${COLORS.Gold};
	color: ${COLORS.White};
	${fontMixins(18)};
	border: 0;
	border-radius: 10px;
	height: 50px;
	width: 153px;
	margin-left: auto;
	transition: all 0.2s;
	cursor: pointer;

	&:active {
		background: ${COLORS.Gold_Down};
	}
`;

export const IconContainer = styled.div`
	position: absolute;
	bottom: -120px;
	left: -20px;
`;
