import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const ButtonActionContainer = styled.div`
	display: flex;
	align-self: center;
	margin-right: 40px;
`;

export const ButtonActionBtn = styled.button`
	width: 100%;
	height: 50px;
	background: ${COLORS.Gold};
	border-radius: 10px;
	border: 0;
	${fontMixins(18, 400)};
	color: ${COLORS.White};
	cursor: pointer;
`;
