import * as React from "react";
import { NotificationsContainer } from "./Notifications.styled";
import { Notification } from "./Notification";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";

interface NotificationsProps {}

export const Notifications = observer(({}: NotificationsProps) => {
	const { notificationsStore } = useStores();

	return (
		<NotificationsContainer>
			{notificationsStore.list.map((notificationStore) => {
				return <Notification key={notificationStore.id} notificationStore={notificationStore} />;
			})}
		</NotificationsContainer>
	);
});
