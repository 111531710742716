import * as React from "react";
import { ModuleItemBlock, NumberModule } from "./Modules.styled";
import { GrandChartModuleStore } from "../../../../stores/grand-charts/grand-chart-module.store";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";

interface ModuleItemProps {
	grandChartModule: GrandChartModuleStore;
}

export const ModuleItem = observer(({ grandChartModule }: ModuleItemProps) => {
	const { grandCharts } = useStores();
	const { selected } = grandCharts;

	const getClass = (): string => {
		return grandChartModule.id === selected.selectedGrandChartGroupLesson.grand_chart_module_id ? "active" : "";
	};

	return (
		<ModuleItemBlock className={getClass()}>
			<NumberModule>Module {grandChartModule.sort}</NumberModule>
			<span>{grandChartModule.module.name}</span>
		</ModuleItemBlock>
	);
});
