import { action, makeObservable, observable } from "mobx";
import { LessonMyDay } from "../../../interfaces/lessons";
import dayjs from "dayjs";
import { ErrorAPI } from "../../../helpers";
import { API } from "../../../core";
import { MethodRequest } from "../../../constants";
import { MyDaySendRequest } from "../../../requests";

export class LessonMyDayStore implements LessonMyDay {
	@observable id = 0;
	@observable user_id = 0;
	@observable service_id = 0;
	@observable grand_chart_id = 0;
	@observable grand_chart_course_id = 0;
	@observable grand_chart_module_id = 0;
	@observable lesson_uuid = "";
	@observable date = dayjs();
	@observable count_minute = 0;

	constructor(initialData: LessonMyDay | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	async send(values: MyDaySendRequest) {
		try {
			await API.request(`my-day`, {
				method: MethodRequest.Post,
				body: API.getFormData(values),
			});
		} catch (e) {
			ErrorAPI("send", e);
		}
	}

	@action
	fillStore(data: LessonMyDay) {
		const {
			id,
			user_id,
			service_id,
			grand_chart_id,
			grand_chart_course_id,
			grand_chart_module_id,
			lesson_uuid,
			date,
			count_minute,
		} = data;

		this.id = id;
		this.user_id = user_id;
		this.service_id = service_id;
		this.grand_chart_id = grand_chart_id;
		this.grand_chart_course_id = grand_chart_course_id;
		this.grand_chart_module_id = grand_chart_module_id;
		this.lesson_uuid = lesson_uuid;
		this.date = date;
		this.count_minute = count_minute;
	}
}
