import * as React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ChartsHeaderContainer } from "./Charts.styled";
import { Pagination } from "../../../../components/common";

interface ChartsHeaderProps {}

export const ChartsHeader = observer(({}: ChartsHeaderProps) => {
	const { lessonStore } = useStores();
	const { charts } = lessonStore;

	const handleOnChangePage = (page: number) => {
		charts.setCurrentNumber(page);
	};

	return (
		<>
			<ChartsHeaderContainer>
				<Pagination currentPage={charts.currentNumber + 1} totalPages={charts.total} onChange={handleOnChangePage} />
			</ChartsHeaderContainer>
		</>
	);
});
