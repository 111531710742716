import { PercentageContainer, PercentageCurrent, PercentageDescription } from "./Percentage.styled";
import { GrandChartMyDayStore } from "../../../../../stores/grand-charts/grand-charts-my-day/grand-chart-my-day.store";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";

interface PercentageProps {
	grandChartMyDayStore: GrandChartMyDayStore;
}

export const Percentage = observer(({ grandChartMyDayStore }: PercentageProps) => {
	const { grandChartsMyDayStore } = useStores();

	// Вычисляем итоговый процент
	const getPercent = () => {
		let timeSystem = 20;

		if (grandChartMyDayStore.service.id === 1) {
			timeSystem = 20;
		} else if (grandChartMyDayStore.service.id === 2) {
			timeSystem = 30;
		} else if (grandChartMyDayStore.service.id === 3) {
			timeSystem = 40;
		}

		const countModulesTime = grandChartMyDayStore.grand_chart_modules.length * timeSystem;

		let totalTime = 0;

		// Находим по вхождению курса
		Object.keys(grandChartsMyDayStore.listProgress).forEach((keyCourseModule, index) => {
			if (keyCourseModule.indexOf(`${grandChartMyDayStore.course_id}-`) !== -1) {
				console.log("find", grandChartsMyDayStore.listProgress[keyCourseModule]);

				totalTime += grandChartsMyDayStore.listProgress[keyCourseModule].count_minute;
			}
		});

		return totalTime >= countModulesTime ? 100 : Math.ceil((totalTime * 100) / countModulesTime);
	};

	getPercent();
	return (
		<PercentageContainer>
			<PercentageCurrent>{getPercent()}%</PercentageCurrent>
			<PercentageDescription>of your daily plan completed</PercentageDescription>
		</PercentageContainer>
	);
});
