import styled, { css } from "styled-components";
import { COLORS, phoneQuery, pxToEm } from "../../../styles";

export const BurgerContainer = styled.div`
	display: none;
	order: 1;

	${phoneQuery(css`
		display: flex;
	`)}
`;

export const BurgerButton = styled.button`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 6px;
	position: relative;
	padding: 0;
	margin: 0;
	background: ${COLORS.Red};
	width: 40px;
	height: 40px;
	border: 0;
	border-radius: ${pxToEm(10)};

	&:hover {
		background: ${COLORS.Red_Hover};
	}

	span {
		display: flex;
		width: 25px;
		height: 2px;
		background: ${COLORS.White};
		opacity: 0.8;
	}
`;
