import { Button, Description, ItemSlide, Title } from "./Banners.styled";
import { BannerStore } from "../../../../stores/banners/banner.store";
import { MouseEvent } from "react";

interface BannerItemProps {
	banner: BannerStore;
}

export const BannerItem = ({ banner }: BannerItemProps) => {
	const handleOnLink = (e: MouseEvent<HTMLDivElement>) => {
		if (!banner.link && !banner.text_link) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		if (!banner.link && banner.text_link && banner.text_link !== "undefined") {
			window.open(banner.text_link, "__blank");
			return;
		}

		if (!banner.link) {
			return;
		}

		if (banner.text_link === "undefined") {
			return false;
		}

		window.open(banner.link, "__blank");
	};

	const handleOnButtonLink = (e: MouseEvent<HTMLButtonElement>) => {
		if (!banner.link && !banner.text_link) {
			return false;
		}

		e.preventDefault();
		e.stopPropagation();

		if (banner.text_link !== "undefined") {
			window.open(banner.text_link, "__blank");
			return false;
		}

		if (!banner.link) {
			return;
		}

		window.open(banner.link, "__blank");
	};

	return (
		<ItemSlide banner={banner} className={"slide"} onClick={handleOnLink}>
			<Title>{banner.title}</Title>
			<Description>{banner.description}</Description>

			{banner.text_link && <Button onClick={handleOnButtonLink}>{banner.text_button}</Button>}
		</ItemSlide>
	);
};
