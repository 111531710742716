import styled from "styled-components";
import { COLORS } from "../../../../../../../styles";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
	height: 330px;
	border: 1px dashed ${COLORS.Dark_Gray};
	border-width: 1px 1px 0 1px;
`;

export const Notes = styled.div`
	display: flex;
	background: ${COLORS.Gold};
	height: 20px;
	width: 200px;
	position: absolute;
	bottom: 0;
`;
