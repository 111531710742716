import * as React from "react";
import { useRef } from "react";
import { Container } from "./GrandChart.styled";
import { Logotype } from "./Logotype/Logotype";
import { Modules } from "./Modules/Modules";
import { Matrix } from "./Matrix/Matrix";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";

interface GrandChartProps {}

export const GrandChart = observer(({}: GrandChartProps) => {
	const { grandCharts } = useStores();
	const { selected } = grandCharts;

	const refContainer = useRef<HTMLDivElement>(null);

	return (
		<Container
			ref={refContainer}
			theme={selected.service.slug}
			style={{ width: selected.widthSizeContainer, height: selected.heightSizeContainer }}
		>
			<Logotype />
			<Modules />
			<Matrix />
		</Container>
	);
});
