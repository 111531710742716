import { action, computed, makeObservable, observable } from "mobx";
import { FlatPurchases, UserI } from "../../interfaces";
import dayjs from "dayjs";
import { TrialVersionStore } from "./trial-version.store";
import { CrudStore } from "../common/crud.store";
import { API } from "../../core";
import { ProductPurchaseStore } from "../products/product-purchase.store";
import { isURL } from "../../helpers";
import { UserPurchaseStore } from "../user-purchases/user-purchase.store";

export class UserStore extends CrudStore<UserI> implements UserI {
	PATH = "users";

	@observable isUpload = false;

	@observable email = "";
	@observable first_name = "";
	@observable last_name = "";
	@observable birthday = dayjs();
	@observable country = "";
	@observable city = "";
	@observable education = "";
	@observable music_education = "";
	@observable tools_own = "";
	@observable own_group = "";
	@observable groups_played = "";
	@observable portfolio_link = "";
	@observable homepage_link = "";
	@observable avatar = "";
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable trial_version = new TrialVersionStore(null);
	@observable product_purchases: ProductPurchaseStore[] = [];
	@observable purchases: Array<UserPurchaseStore> = [];

	constructor(initialData: UserI | null) {
		super();
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setIsUpload(value: boolean) {
		this.isUpload = value;
	}

	@computed
	get unitingPurchases() {
		let complete: { [key: number]: UserPurchaseStore } = {};

		this.purchases.forEach((purchase) => {
			// Проверяем есть ли включенные гранд чарты
			if (purchase.uniting_ids.length > 0) {
				purchase.uniting_ids.forEach((uniting_id) => {
					complete[uniting_id] = purchase;
				});
			}
		});

		return complete;
	}

	@computed
	get existAvatar() {
		return this.avatar !== "avatars/default.jpg" && this.avatar.length > 0;
	}

	@computed
	get pathAvatar() {
		if (isURL(this.avatar)) {
			return this.avatar;
		} else {
			return `${API.getFilesPath}${this.avatar}`;
		}
	}

	@computed
	get nickName() {
		return this.first_name || "Username";
	}

	@computed
	get existPurchases() {
		return this.purchases.length > 0 && this.purchases.some((product_purchase) => !product_purchase.is_expired);
	}

	@computed
	get flatPurchases(): FlatPurchases {
		let completeData: FlatPurchases = {};

		this.purchases.forEach((userPurchase) => {
			completeData[userPurchase.grand_chart_id] = userPurchase;
		});

		return completeData;
	}

	@computed
	get accessAI() {
		return [573, 3, 5, 2, 35].includes(this.id);
	}

	@action
	fillStore(data: UserI) {
		const {
			id,
			email,
			first_name,
			last_name,
			birthday,
			country,
			city,
			education,
			music_education,
			tools_own,
			own_group,
			groups_played,
			portfolio_link,
			homepage_link,
			avatar,
			created_at,
			updated_at,
			trial_version,
			product_purchases,
			purchases,
		} = data;

		this.id = id;
		this.email = email;
		this.first_name = first_name;
		this.last_name = last_name;
		this.birthday = dayjs(birthday);
		this.country = country;
		this.city = city;
		this.education = education;
		this.music_education = music_education;
		this.tools_own = tools_own;
		this.own_group = own_group;
		this.groups_played = groups_played;
		this.portfolio_link = portfolio_link;
		this.homepage_link = homepage_link;
		this.avatar = avatar;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.trial_version = new TrialVersionStore(trial_version);
		this.product_purchases = (product_purchases || []).map(
			(product_purchase) => new ProductPurchaseStore(product_purchase),
		);
		this.purchases = (purchases || []).map((purchase) => new UserPurchaseStore(purchase));
	}
}
