import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../styles";

export const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	padding: 59px 0;
`;

interface ItemProps {
	isActive: boolean;
}

export const Item = styled.div<ItemProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 74px;
	color: ${COLORS.Gold};
	column-gap: 20px;
	cursor: pointer;
	${fontMixins(32, 700)};
	transition: all 0.2s;
	opacity: 0.5;

	${(props) =>
		props.isActive &&
		css`
			font-size: 40px;
			opacity: 1;
		`}
`;

export const Icon = styled.div`
	svg {
		width: 50px;
		height: 54px;
	}
`;
