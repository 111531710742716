import { Container, Switcher } from "./AutoRenew.styled";
import { ServiceName } from "../../../../constants";
import { observer } from "mobx-react";
import { GrandChartStore } from "../../../../stores/grand-charts/grand-chart.store";
import { useStores } from "../../../../stores";
import { useCheckPurchase } from "../../../../hooks/useCheckPurchase";
import { DurationPurchase } from "../../../../interfaces";

interface AutoRenewProps {
	service_name: ServiceName;
	grandChart: GrandChartStore;
}

export const AutoRenew = observer(({ grandChart, service_name }: AutoRenewProps) => {
	const {
		authStore: { userStore },
	} = useStores();

	const [userPurchaseStore] = useCheckPurchase(userStore.flatPurchases, grandChart.id);

	const getTitle = () => {
		if (userPurchaseStore) {
			return (
				<>
					Auto-Renew
					<Switcher
						checked={!userPurchaseStore.period_end && userPurchaseStore.duration !== DurationPurchase.Forever}
					/>
				</>
			);
		} else {
			return (
				<>
					Auto-Renew
					<Switcher checked={false} />
				</>
			);
		}
	};

	return <Container theme={service_name}>{getTitle()}</Container>;
});
