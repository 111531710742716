import * as React from "react";
import { BurgerButton, BurgerContainer } from "./Burger.styled";
import { observer } from "mobx-react";

export const Burger = observer(() => {
	return (
		<BurgerContainer>
			<BurgerButton>
				<span />
				<span />
				<span />
			</BurgerButton>
		</BurgerContainer>
	);
});
