import * as React from "react";
import { ColumnItem, Columns, Container, Information, SecondBlockContainer } from "./SecondBlock.styled";
import { Title } from "../../../components/common";
import { getColorByService } from "../../../styles";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";

interface SecondBlockProps {}

export const SecondBlock = observer(({}: SecondBlockProps) => {
	const { pageStore } = useStores();

	return (
		<SecondBlockContainer>
			<Container>
				<Title style={{ textAlign: "left", fontSize: "42px", color: getColorByService(pageStore.service) }}>
					{pageStore.header}
				</Title>

				<Columns>
					{pageStore.columns.map((column, index) => {
						return (
							<ColumnItem key={index} service={pageStore.service}>
								{column}
							</ColumnItem>
						);
					})}
				</Columns>

				<Information>
					<div>
						<picture>
							<source
								srcSet={`/images/playing/${pageStore.playingImage}.webp, /images/playing/${pageStore.playingImage}@2x.webp 2x`}
								type="image/webp"
							/>
							<img
								alt={""}
								src={`/images/playing/${pageStore.playingImage}.png`}
								srcSet={`/images/playing/${pageStore.playingImage}@2x.png 2x`}
							/>
						</picture>
					</div>

					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
						<Title
							style={{
								textAlign: "left",
								fontSize: "42px",
								lineHeight: "52px",
								marginBottom: "42px",
								color: getColorByService(pageStore.service),
							}}
						>
							{pageStore.headerPlaying}
						</Title>

						{pageStore.textPlaying.map((text, index) => (
							<p key={index}>{text}</p>
						))}
					</div>
				</Information>
			</Container>
		</SecondBlockContainer>
	);
});
