import * as React from "react";
import { Container, IFrame, Shadow } from "./ViewIframe.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { useRef } from "react";
import { useOutside } from "../../../../hooks";

interface ViewIframeProps {}

export const ViewIframe = observer(({}: ViewIframeProps) => {
	const { viewIframeStore } = useStores();

	const containerRef = useRef<HTMLDivElement>(null);

	const handleOnClose = (e: MouseEvent | TouchEvent) => {
		viewIframeStore.close();
	};

	useOutside(containerRef, handleOnClose);

	return (
		<>
			<Container isShow={viewIframeStore.isShow} ref={containerRef}>
				<IFrame src={viewIframeStore.url} />
			</Container>
			<Shadow isShow={viewIframeStore.isShow} />
		</>
	);
});
