import * as Yup from "yup";

export const ChangePasswordSchema = Yup.object({
	old_password: Yup.string().required("Current Password Is A Required Field"),
	password: Yup.string()
		.required()
		.min(6, "Your password is too short.")
		.oneOf([Yup.ref("password_confirmation"), null], "Passwords confirm must match"),
	password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
});
