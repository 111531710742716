export const GoodDescriptionConst = {
	"1-1": {
		items: [
			"149 Complete Lessons",
			"190 Midi Tracks",
			"150 Backing Tracks",
			"12 GYM<sup>1</sup> Sheets",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"30 Classical Compositions",
		],
		includes: ["Includes 4 COLLEGE Lessons"],
	},
	"1-2": {
		items: [
			"192 Complete Lessons",
			"180 Midi Tracks",
			"240 Backing Tracks",
			"12 GYM<sup>1</sup> Sheets",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"48 Fusion Compositions",
		],
		includes: ["Includes SCHOOL subscription"],
	},
	"1-3": {
		items: [
			"1001 Complete Lessons",
			"420 Backing Tracks",
			"110 000 IGM Sheets",
			"12 GYM<sup>1</sup> Sheets",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"20 Jazz-Fusion Compositions",
			"8 Jazz-Blues Compositions",
			"20 Jazz Standards",
		],
		includes: ["Everything from COLLEGE"],
	},

	"2-1": {
		items: [
			"135 Complete Lessons",
			"190 Midi Tracks",
			"145 Backing Tracks",
			"50 SRM<sup>1</sup> Sheets",
			"50 REM<sup>2</sup> Sheets",
			"30 Classical Compositions",
		],
		includes: ["Includes 4 COLLEGE Lessons"],
	},
	"2-2": {
		items: [
			"175 Complete Lessons",
			"160 Backing Tracks",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"48 Fusion Compositions",
		],
		includes: ["Includes SCHOOL subscription"],
	},
	"2-3": {
		items: [
			"870 Complete Lessons",
			"890 Backing Tracks",
			"70 000 IGM Sheets",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"20 Jazz-Fusion Compositions",
			"8 Jazz-Blues Compositions",
			"20 Jazz Standards",
		],
		includes: ["Everything from COLLEGE"],
	},

	"4-1": {
		items: [
			"115 Complete Lessons",
			"210 Midi Tracks",
			"130 Backing Tracks",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"30 Classical Compositions",
		],
		includes: ["Includes 4 COLLEGE Lessons"],
	},
	"4-2": {
		items: [
			"188 Complete Lessons",
			"145 Backing Tracks",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"48 Fusion Compositions",
		],
		includes: ["Includes SCHOOL subscription"],
	},
	"4-3": {
		items: [
			"640 Complete Lessons",
			"750 Backing Tracks",
			"70 000 IGM Sheets",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"20 Jazz-Fusion Compositions",
			"8 Jazz-Blues Compositions",
			"20 Jazz Standards",
		],
		includes: ["Everything from COLLEGE"],
	},

	"1-8": {
		items: [
			"36 Complete Lessons",
			"6 Backing Tracks",
			"Lesson Zero",
			"Fundamentals",
			"Five Progressions",
			"Combining Keys",
			"Entire Fretboard",
			"PRE-COLLEGE (3Les.)",
		],
		includes: [],
	},
	"1-9": {
		items: [
			"36 Complete Lessons",
			"32 Backing Tracks",
			"2-1, 3-1 Arpeggios Concept",
			"2-1, 3-1 Extended Arpeggio",
			"2-1, 3-1 (Ext.) Melodic minor",
			"Chord Relativity Chart I & II",
			"PRE-UNIVERSITY (5Les)",
		],
		includes: [],
	},
	"1-10": {
		items: [
			"80 Complete Lessons",
			"70 Backing Tracks",
			"2-1, 3-1 Outside Arpeggio",
			"2-1, 3-1 Extended Outside",
			"Melodic minor",
			"2-1, 3-1 Extended Outside - Harmonic minor",
			"2-1, 3-1 Extended Outside - Diminished scale",
		],
		includes: [],
	},

	"2-8": {
		items: [
			"135 Complete Lessons",
			"190 Midi Tracks",
			"145 Backing Tracks",
			"50 SRM<sup>1</sup> Sheets",
			"50 REM<sup>2</sup> Sheets",
			"30 Classical Compositions",
		],
		includes: ["Includes 4 COLLEGE Lessons"],
	},
	"2-9": {
		items: [
			"175 Complete Lessons",
			"160 Backing Tracks",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"48 Fusion Compositions",
		],
		includes: ["Includes SCHOOL subscription"],
	},
	"2-10": {
		items: [
			"870 Complete Lessons",
			"890 Backing Tracks",
			"70 000 IGM Sheets",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"20 Jazz-Fusion Compositions",
			"8 Jazz-Blues Compositions",
			"20 Jazz Standards",
		],
		includes: ["Everything from COLLEGE"],
	},

	"4-8": {
		items: [
			"115 Complete Lessons",
			"210 Midi Tracks",
			"130 Backing Tracks",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"30 Classical Compositions",
		],
		includes: ["Includes 4 COLLEGE Lessons"],
	},
	"4-9": {
		items: [
			"188 Complete Lessons",
			"145 Backing Tracks",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"48 Fusion Compositions",
		],
		includes: ["Includes SCHOOL subscription"],
	},
	"4-10": {
		items: [
			"640 Complete Lessons",
			"750 Backing Tracks",
			"70 000 IGM Sheets",
			"50 SRM<sup>2</sup> Sheets",
			"50 REM<sup>3</sup> Sheets",
			"20 Jazz-Fusion Compositions",
			"8 Jazz-Blues Compositions",
			"20 Jazz Standards",
		],
		includes: ["Everything from COLLEGE"],
	},
};
