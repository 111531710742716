import styled, { css } from "styled-components";
import { COLORS, fontMixin, phoneQuery } from "../../../../styles";

export const ContainerBreadcrumbs = styled.div`
	grid-column: 1/5;
	${fontMixin({ size: 12 })};
	color: ${COLORS.MidGray};
	display: flex;
	align-items: flex-end;
	margin-bottom: 5px;

	${phoneQuery(css`
		flex-wrap: wrap;
		line-height: 20px;
	`)}

	span {
		text-transform: uppercase;
		
		&:last-child {
			text-transform: initial;
			color: ${COLORS.MidGray} !important;
		}
	}
`;

export const NameService = styled.div`
	font-weight: 600;
	text-transform: uppercase;

	${phoneQuery(css`
		display: inline-flex;
	`)}
`;
