import styled, { css } from "styled-components";
import { phoneQuery } from "../../../../styles";

export const Container = styled.div`
	display: flex;
	height: 777px;
	width: 1000px;

	${phoneQuery(css`
		width: 100%;
	`)}
`;
