export enum ToasterType {
	SUCCESS = "success",
	ERROR = "error",
	DEFAULT = "default",
}

export interface ToasterI {
	type?: ToasterType;
	title: string;
	message?: string;
}
