import { GrandChartStore } from "../../../../../stores/grand-charts/grand-chart.store";
import { Description, GoodItem, GoodsList, Title } from "../UnitingModal.styled";

interface AccessAllowedProps {
	grandChart: GrandChartStore;
}

export const AccessAllowed = ({ grandChart }: AccessAllowedProps) => {
	return (
		<>
			<Title>{grandChart.title}</Title>
			<Description>
				You have access to the following products:
				<br />
				<br />
				{grandChart.goods.map((good) => {
					return <GoodItem key={good.id}>{good.name}</GoodItem>;
				})}
				<br />
				To start learning the selected product, click on its <b>GRAND CHART</b> icon
				<br /> in the product list in "My Subscriptions".
			</Description>

			<GoodsList>
				{grandChart.goods.map((good) => {
					return (
						<GoodItem key={good.id}>
							<img src={good.fullPathCover || ""} alt={good.id.toString()} />
						</GoodItem>
					);
				})}
			</GoodsList>
		</>
	);
};
