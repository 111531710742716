import { CounterDownTimerContainer, DividerTime, TimeAttribute, TimeContainer } from "./CountDownTimer.styled";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";

interface CountDownTimerProps {}

export const CountDownTimer = observer(({}: CountDownTimerProps) => {
	const { lessonStore } = useStores();

	const [hour, minute] = lessonStore.timeLeft;

	const getIsAnimate = () => {
		if (lessonStore.timer.isStart && (hour !== "00" || minute !== "00")) {
			return true;
		}

		return false;
	};

	return (
		<CounterDownTimerContainer>
			<TimeContainer>
				{hour} <DividerTime isAnimate={getIsAnimate()}>:</DividerTime> {minute}
			</TimeContainer>
			<TimeAttribute>
				<span>hrs</span>
				<span>min</span>
			</TimeAttribute>
		</CounterDownTimerContainer>
	);
});
