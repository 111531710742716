import styled, { css } from "styled-components";
import { COLORS, fontMixin, fontMixins, phoneQuery } from "../../../styles";

export const TitleContainer = styled.div`
	${fontMixin({ size: 48, weight: 700 })};
	line-height: 59px;
	color: ${COLORS.Dark_Gray};
	text-align: center;

	${phoneQuery(css`
		${fontMixins(30, 700)};
		line-height: 40px;
	`)}
`;

export const TitleDivider = styled.div`
	height: 50px;

	${phoneQuery(css`
		height: 25px;
	`)}
`;
