import styled, { css } from "styled-components";
import { COLORS, phoneQuery } from "../../../styles";

export const WeAreComingSoonContainer = styled.div`
	background: ${COLORS.Gold};
	padding: 0 0 13px;
`;

export const IconLeftSide = styled.div`
	position: absolute;
	left: -80px;
	top: 78px;

	${phoneQuery(css`
		left: 0;
		top: 10px;

		svg {
			width: 100px;
		}
	`)}
`;

export const IconRightSide = styled.div`
	position: absolute;
	right: -115px;
	top: 72px;

	${phoneQuery(css`
		display: none;
		right: 0;
		top: 150px;

		svg {
			width: 100px;
		}
	`)}
`;

export const Divider = styled.div`
	height: 76px;

	${phoneQuery(css`
		height: 25px;
	`)}
`;
