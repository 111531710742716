import { Container } from "./Timer.styled";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useStores } from "../../../../../../../stores";
import { getTotalTimeRecordAI } from "../../../../../../../helpers";

interface TimerProps {}

export const Timer = observer(({}: TimerProps) => {
	const { aiStore, metronomeStore } = useStores();

	const [isStart, setIsStart] = useState(false);
	const [counter, setCounter] = useState(4);

	useEffect(() => {
		if (aiStore.recordNow && aiStore.isShowCounter) {
			metronomeStore.onPlayTick();
			countdownHandler();
		}
	}, [aiStore.recordNow, !aiStore.isShowCounter]);

	const startRecord = () => {
		if (!aiStore.recorder) {
			console.log(`Not instance MediaRecorder`);
			return;
		}

		console.log("total time record", getTotalTimeRecordAI(aiStore.chosenBPM) * 1000);
		aiStore.recorder.start();

		setTimeout(() => {
			if (aiStore.recorder) {
				console.log("end");
				aiStore.recorder.stop();
				aiStore.setRecordNow(false);
			}
			// Время записи демо
		}, getTotalTimeRecordAI(aiStore.chosenBPM) * 1000);
	};

	const countdownHandler = () => {
		setTimeout(() => {
			setCounter((prevState) => {
				const actualValue = prevState - 1;

				if (actualValue > 0) {
					countdownHandler();
				} else {
					startRecord();

					aiStore.setIsShowCounter(false);
					setCounter(4);
				}

				if (actualValue >= 1) {
					metronomeStore.onPlayTick();
				}

				return actualValue;
			});

			/*setCounter((prevState) => {
				const actualValue = prevState - 1;

				if (actualValue > 0) {
					countdownHandler();
				} else {
					if (aiStore.recorder) {
						aiStore.recorder.start();

						setTimeout(() => {
							if (aiStore.recorder) {
								aiStore.recorder.stop();
								aiStore.setRecordNow(false);
							}
							// Время записи демо
						}, getTotalTimeRecordAI(aiStore.chosenBPM) * 1000);
					}

					aiStore.setIsShowCounter(false);
					setCounter(4);
				}

				if (actualValue >= 1) {
					metronomeStore.onPlayTick();
				}

				return actualValue;
			});*/
		}, 60000 / aiStore.chosenBPM);
	};

	if (!aiStore.isShowCounter) {
		return null;
	}

	if ((!aiStore.isShowCounter && aiStore.recordNow) || aiStore.existRecord) {
		return null;
	}

	return <Container>{counter}</Container>;
});
