import { action, makeObservable, observable } from "mobx";

export class TimerStore {
	@observable isStart = false;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setIsStart(value: boolean) {
		this.isStart = value;
	}
}
