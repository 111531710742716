import * as React from "react";
import { ContentBlock } from "../../components/common";
import { ProfileForm, SecurityForm } from "../../components";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { useEffect } from "react";
import { HEADER_TYPE } from "../../constants";

export const ProfilePage = observer(() => {
	const { settingsStore } = useStores();

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
	}, []);

	return (
		<>
			<ContentBlock title={"My Profile"}>
				<ProfileForm />
			</ContentBlock>

			<ContentBlock title={"Security"}>
				<SecurityForm />
			</ContentBlock>
		</>
	);
});
