import { action, makeObservable, observable } from "mobx";
import { MainStatisticsI } from "../../interfaces";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { CoreResponse } from "../../response";

export class MainStatisticsMyDayStore {
	@observable isLoading = false;
	@observable list: MainStatisticsI = {};

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setList(values: MainStatisticsI) {
		this.list = values;
	}

	@action.bound
	async getList(start_date: string, end_date: string) {
		this.setLoading(true);

		try {
			const { data } = await API.request<CoreResponse<MainStatisticsI>>(
				`main-statistics?start_date=${start_date}&end_date=${end_date}`,
			);

			this.setList(data);
		} catch (e) {
			ErrorAPI("getList", e);
		} finally {
			this.setLoading(false);
		}
	}
}
