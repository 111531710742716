import * as React from "react";
import { ImagePngContainer } from "./ImagePng.styled";
import { ImgHTMLAttributes } from "react";

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
	withRetina?: boolean;
}

export const ImagePng = ({ src, alt, className, withRetina = true }: ImageProps) => {
	return (
		<ImagePngContainer className={className}>
			{withRetina && <source srcSet={`${src}@2x.webp 2x`} type="image/png" />}
			{withRetina && <source srcSet={`${src}@2x.png 2x`} type="image/png" />}
			<img src={`${src}.png`} alt={alt} loading={"lazy"} />
			<source srcSet={`${src}.webp`} type="image/webp" />
		</ImagePngContainer>
	);
};
