import { action, computed, makeObservable, observable } from "mobx";
import { ChartContent } from "../../interfaces";
import { API } from "../../core";

export class ChartContentStore implements ChartContent {
	@observable align = "";
	@observable title = "";
	@observable image = "";

	constructor(initialData: ChartContent | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@computed
	get imagePath() {
		// TODO Убрать при деплое
		//return `https://files.musicabinet.com/files/${this.image}`;
		return `${API.getFilesPath}${this.image}`;
	}

	@action
	fillStore(data: ChartContent) {
		const { align, title, image } = data;

		this.align = align;
		this.title = title;
		this.image = image;
	}
}
