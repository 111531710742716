import * as React from "react";
import { Pattern, Video, VideoBlockContainer } from "./VideoBlock.styled";
import { VideoHTMLAttributes } from "react";

interface VideoBlockProps extends VideoHTMLAttributes<HTMLVideoElement> {
	url: string;
}

export const VideoBlock = ({ url, children }: VideoBlockProps) => {
	return (
		<VideoBlockContainer>
			<Video key={url} autoPlay loop muted playsInline poster={`/video-instruments/${url}.jpg`}>
				<source src={`/video-instruments/${url}.mp4`} type="video/mp4" />
				<source src={`/video-instruments/${url}.webm`} type="video/webm" />
				<source src={`/video-instruments/${url}.ogv`} type="video/ogg" />
			</Video>
			<Pattern />
			{children}
		</VideoBlockContainer>
	);
};
