import * as React from "react";
import { ButtonHTMLAttributes } from "react";
import { ButtonContainer, ButtonLoading } from "./Button.styled";
import { ButtonTheme } from "../../../constants";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	children?: React.ReactNode;
	theme?: ButtonTheme;
	isLoading?: boolean;
	isWhite?: boolean;
}

export const Button = ({
	children = null,
	isLoading = false,
	theme = ButtonTheme.GOLD,
	isWhite = false,
	...props
}: ButtonProps) => {
	return (
		<ButtonContainer theme={theme} isLoading={isLoading} isWhite={isWhite} {...props}>
			<ButtonLoading isLoading={isLoading} />
			{children}
		</ButtonContainer>
	);
};
