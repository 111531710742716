import styled, { css } from "styled-components";
import { COLORS, getColorByService, phoneQuery } from "../../../../../styles";
import { ServiceName } from "../../../../../constants";

export const MenuContainer = styled.nav`
	display: flex;
	flex: 1;
	justify-content: right;

	${phoneQuery(css`
		display: none;
		position: fixed;
		z-index: 1000;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: ${COLORS.Black};
	`)}
`;

export const MenuList = styled.ul`
	display: flex;
	//display: grid;
	//grid-template-columns: repeat(auto-fit, minmax(1px, auto));
	column-gap: 50px;
	justify-content: right;
	position: relative;
	top: 5px;

	${phoneQuery(css`
		grid-template-columns: 1fr;
		justify-content: center;
		align-items: center;
		column-gap: 10px;
		row-gap: 10px;
	`)}
`;

interface MenuListItemI {
	type?: ServiceName | undefined;
}

export const MenuListLink = styled.a`
	color: ${COLORS.White};
	text-decoration: none;
	cursor: pointer;
`;

export const MenuListItem = styled.li<MenuListItemI>`
	position: relative;
	display: flex;
	padding: 16px 0;

	${(props) =>
		props.type &&
		css`
			${MenuListLink} {
				color: ${getColorByService(props.type)};
			}
		`}
	&:nth-child(3) {
		&:after {
			position: relative;
			top: -5px;
			content: "";
			width: 1px;
			height: 30px;
			background: ${COLORS.White};
			margin-left: 30px;
			margin-right: -20px;
		}
	}
`;
