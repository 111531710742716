import { Body, Container, Preloader, TextBlock, Title } from "./TransactionBlock.styled";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ReactComponent as LoadingIcon } from "./images/loading.svg";
import { ReactComponent as SuccessIcon } from "./images/success.svg";
import { useStores } from "../../../stores";
import { gtmEvent } from "../../../helpers";
import { ErrorsResponse } from "../../../response";
import { Button } from "../../../components/common";
import { ButtonTheme } from "../../../constants";

interface TransactionBlockProps {}

export const TransactionBlock = observer(({}: TransactionBlockProps) => {
	const { paymentStore, authStore, goodsStore } = useStores();

	const navigation = useNavigate();
	const location = useLocation();
	const query = new URLSearchParams(location.search);

	// Получаем параметры
	const session_id = query.get("session_id");
	const good_id = query.get("good_id");

	// Показываем страницу
	const isShowBlock: boolean = Boolean(session_id && good_id);

	// После рендера отправляем данные для регистрации транщзакции
	useEffect(() => {
		(async () => {
			if (isShowBlock && session_id && !paymentStore.isSuccessRequest) {
				// Запрещае закрыть браузер
				window.onbeforeunload = (e) => `Don't close window`;

				// Добавляем CSS стиль
				document.body.classList.add("overflow-hidden-payment");

				try {
					// Обработка платежа
					paymentStore.setIsLoading(true);

					// Запрос на обработку
					const response = await paymentStore.registration({
						session_id,
					});

					if (response?.good_id) {
						goodsStore.clearCurrent();

						// Устанвливаем ID
						goodsStore.current.setId(response?.good_id);
						// Получаем информацию по товару
						await goodsStore.current.get();

						// Триггер GTM
						gtmEvent({
							event: "purchase",
							ecommerce: {
								transaction_id: response.id,
								value: response.amount_total ? response.amount_total / 100 : 0,
								currency: "USD",
								items: [
									{
										item_name: goodsStore.current.stripe_name,
										item_id: goodsStore.current.id,
										price: goodsStore.current.price / 100,
										quantity: 1,
									},
								],
							},
						});
					}

					// Получаем информацию по пользователю
					await authStore.check();
				} catch (e: unknown) {
					const typedError = e as ErrorsResponse;
					console.log("TRANSICTION ERRROR", typedError.errors);
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (paymentStore.isSuccessRequest) {
			// Убираем правило запрета закрыть браузер
			window.onbeforeunload = null;

			// Добавляем CSS стиль
			document.body.classList.remove("overflow-hidden-payment");
		}
	}, [paymentStore.isSuccessRequest]);

	if (!isShowBlock) {
		return null;
	}

	if (paymentStore.isSuccessRequest) {
		return (
			<Container>
				<Body>
					<Preloader isRotate={!paymentStore.isSuccessRequest}>
						<SuccessIcon />

						<Title>Congratulations!</Title>
					</Preloader>

					<TextBlock>
						<p>Your purchase was successful!</p>
						<p>
							Your product, '<strong>{goodsStore.current.name}</strong>' is now available in the{" "}
							<strong>My Subscription</strong> area of your profile
						</p>

						<p>Please check both your email and spam folder for purchase details.</p>

						<p>
							<Button
								theme={ButtonTheme.RED}
								style={{ width: 400 }}
								onClick={() => navigation("/cabinet/my-subscriptions")}
							>
								View in My Subscriptions
							</Button>
						</p>
					</TextBlock>
				</Body>
			</Container>
		);
	}

	return (
		<Container>
			<Body>
				<Preloader isRotate={!paymentStore.isSuccessRequest}>
					<LoadingIcon />

					<Title>Please wait</Title>
				</Preloader>

				<TextBlock>
					<p>Don't close this window and/or tab while your transaction is being processed.</p>

					<p>Closing this window and/or tab may lead to data loss and the inability to access purchased products.</p>
				</TextBlock>
			</Body>
		</Container>
	);
});
