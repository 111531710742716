import styled from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const CourseContainer = styled.div`
	position: relative;
	width: 289px;
	height: 100px;
	background: transparent;
	border-radius: 10px;
`;

export const CourseHeader = styled.div`
	display: flex;
	width: 221px;
	height: 20px;
	margin: 0 auto;
	border-radius: 0 0 10px 10px;
	background: ${COLORS.Dark_Gray};
	color: ${COLORS.White};
	justify-content: center;
	align-items: center;
	${fontMixins(12, 400)}
`;

export const CourseBody = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 221px;
	margin: 0 auto;
	height: calc(100% - 20px);
	${fontMixins(14, 400)};
	color: ${COLORS.White};
	text-transform: uppercase;
`;

export const CoursePie = styled.div`
	position: absolute;
	right: 10px;
	bottom: 10px;
`;
