import styled from "styled-components";
import { COLORS } from "../../../../styles";
import { fontMixin } from "../../../../styles";

export const SignUpModalContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: auto;
	width: 100%;
	height: 100%;
`;

export const SignUpModalLeft = styled.div`
	display: grid;
	background: ${COLORS.Bgrnd};
	padding: 40px 40px 46.15px 50px;
`;

export const SignUpList = styled.div`
	display: grid;
	row-gap: 18px;
	margin: 35px 0 44px;
`;

export const SignUpListItem = styled.div`
	display: grid;
	grid-template-columns: 90px 1fr;
	gap: 20px;
	align-items: center;
	${fontMixin({ size: 18 })};
	line-height: 22px;
	color: ${COLORS.Gold};
`;

export const SignUpText = styled.div`
	color: ${COLORS.Gold};
`;

export const SignUpIcon = styled.div``;

export const SignUpFooter = styled.div`
	${fontMixin({ size: 14 })};
	line-height: 17px;
	color: ${COLORS.White};
	width: 346px;
	margin-top: auto;
`;

export const SignUpModalRight = styled.div`
	padding: 50px 40px 60px 40px;
`;

export const SignUpModalSocial = styled.div`
	display: grid;
	grid-template-rows: 1fr;
	row-gap: 20px;
	margin-bottom: 30px;
`;

export const SignUpDescription = styled.div`
	display: grid;
	justify-content: center;
	align-items: center;
	${fontMixin({ size: 14 })};
	line-height: 17px;
	color: ${COLORS.Dark_Gray};
	text-align: center;
`;

export const SignUpFormContainer = styled.form`
	display: grid;
	gap: 20px;
	margin: 40px 0 50px;
`;

export const SignUpRightSideFooter = styled.div`
	display: grid;
	justify-content: center;
`;
