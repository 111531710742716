import { observer } from "mobx-react";
import { useEffect } from "react";
import { useStores } from "../../stores";
import { HEADER_TYPE } from "../../constants";
import { Filters } from "./Filters/Filters";
import { Accordion } from "./Accordion/Accordion";
import { DetailGrandChartModal } from "../../components";

interface MySubscriptionsPageProps {}

export const MySubscriptionsPage = observer(({}: MySubscriptionsPageProps) => {
	const { settingsStore, grandCharts } = useStores();

	useEffect(() => {
		(async () => {
			settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);

			await grandCharts.getList();
		})();
	}, []);

	return (
		<>
			<Filters />
			<Accordion />

			<DetailGrandChartModal />
		</>
	);
});
